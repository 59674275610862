import { useEffect, useRef, useState } from "react";
import logo from "../../assets/images/logo/logo.svg";
import iconDown from "../../assets/images/icons/import-outline.svg";
import Login from "Components/Login/Login";
import { useTranslation } from "react-i18next";
import "./NavBar.css";
import { Link } from "react-router-dom";
import DropDownLanguage from "Components/Languages/DropDownLanguage";
import axiosInstance from "./../../axios";
import HomeCountrySelect from "Components/CountrySelect/CountrySelect";
import CountrySelect from "Components/Ui/CountrySelect/CountrySelect";
import { useSavedState } from "assets/hooks";

const NavBar = ({ showModalDownloadApp }) => {
  const { i18n } = useTranslation();
  const [showModalLogin, setShowModalLogin] = useState(false);
  const [CountryId, setCountryId, clearSelectedCountry] = useSavedState(
    null,
    "homeSelectedCountry"
  );

  // OPEN MODAL
  const handleShowModalLogin = () => {
    setShowModalLogin(true);
  };

  // FIXED TOP NAVBAR
  const [isMenuFixed, setMenuFixed] = useState(false);

  const [activeLink, setActiveLink] = useState("hero"); // active link

  const sectionRefs = {
    hero: useRef(null),
    category: useRef(null),
    cards: useRef(null),
    "how-work": useRef(null),
    contact: useRef(null),
  };
  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position
      const scrollTop = window.scrollY;

      if (scrollTop === 0) {
        // If scroll position is zero, remove "active" from all nav-items and add "active" to the first nav-item
        setActiveLink("hero");
      } else {
        // If scroll position is not zero, dynamically update the active link based on the scrolled section
        for (const [sectionId, sectionRef] of Object.entries(sectionRefs)) {
          const sectionElement = sectionRef.current;

          if (sectionElement) {
            const rect = sectionElement.getBoundingClientRect();

            if (rect.top <= 0 && rect.bottom > 0) {
              setActiveLink(sectionId);
              break; // Stop once we find the active section
            }
          }
        }
      }

      const shouldBeFixed = scrollTop > 100; // Change 100 to the scroll position where you want the menu to become fixed

      // Update state to trigger the fixed or unfixed state of the menu
      setMenuFixed(shouldBeFixed);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup: remove the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Runs only once on mount

  const handleLinkClick = (link) => {
    setActiveLink(link);

    // Get the target element's offset from the top
    const targetElement = document.getElementById(link);
    const offsetTop = targetElement.offsetTop;

    // Scroll to the target element with smooth animation
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Login
        showModalLogin={showModalLogin}
        setShowModalLogin={setShowModalLogin}
      />
      <nav
        className={`navbar navbar-expand-lg z-3  ${
          isMenuFixed ? "menu-fixed" : ""
        }`}
      >
        <div className="container">
          <a href="#hero" className="navbar-brand" data-aos="fade-left">
            <img src={logo} alt="logo" />
          </a>
          <div className="main-button-lang d-flex align-items-center gap-2">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              data-aos="fade-right"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="all-lang d-flex aling-items-center gap-2">
              <DropDownLanguage newClass={"dropdown-lang-landing"} />
            </div>
          </div>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li
                className={`nav-item ${activeLink === "hero" ? "active" : ""}`}
              >
                <a
                  href="/#hero"
                  className="nav-link"
                  onClick={() => handleLinkClick("hero")}
                  aria-current="page"
                >
                  {i18n.language === "ar" ? "الرئيسية" : "Home"}
                </a>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "category" ? "active" : ""
                }`}
              >
                <a
                  href="/#category"
                  className="nav-link"
                  onClick={() => handleLinkClick("category")}
                  aria-current="page"
                >
                  {i18n.language === "ar" ? "القطاعات" : "Sectors"}
                </a>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "cards-ouro" ? "active" : ""
                }`}
              >
                <a
                  href="/#cards-ouro"
                  className="nav-link"
                  onClick={() => handleLinkClick("cards-ouro")}
                  aria-current="page"
                >
                  {i18n.language === "ar" ? "البطاقات" : "Cards"}
                </a>
              </li>
              <li
                className={`nav-item ${
                  activeLink === "how-work" ? "active" : ""
                }`}
              >
                <a
                  href="/#how-work"
                  className="nav-link"
                  onClick={() => handleLinkClick("how-work")}
                  aria-current="page"
                >
                  {i18n.language === "ar" ? "كيف نعمل" : "How it works"}
                </a>
              </li>

              <li
                className={`nav-item ${
                  activeLink === "contact" ? "active" : ""
                }`}
              >
                <a
                  href="/#contact"
                  className="nav-link"
                  onClick={() => handleLinkClick("contact")}
                  aria-current="page"
                >
                  {i18n.language === "ar" ? "اتصل بنا" : "Contact Us"}
                </a>
              </li>
            </ul>
            <div className="all-left-info d-flex align-items-center gap-2">
              {/* <CountrySelect
                selectedCountry={1}
                newClass={"country-select-nav"}
                isShowLabel={false}
                TextLabel={false}
                CountryData={countries.map((country) => country?.id)}
                CustomLabelCountry={countries.reduce((acc, country) => {
                  const translation =
                    i18n.language === "ar"
                      ? country?.translations.find((t) => t.locale === "ar")
                          ?.title
                      : country?.translations.find((t) => t.locale === "en")
                          ?.title;
                  if (translation) {
                    acc[country.id] = translation;
                  }
                  return acc;
                }, {})}
                placholerText={"اختار الدولة"}
              /> */}
              <HomeCountrySelect setValue={setCountryId} />
              {/* ========= START MAIN BUTTONS =========== */}
              <div
                className="main-buttons d-flex  align-items-center  gap-2"
                data-aos="fade-right"
              >
                <button
                  onClick={handleShowModalLogin}
                  className="btn-main btn-login btn-dark"
                >
                  <div className="text-button">
                    {" "}
                    {i18n.language === "ar" ? "تسجيل الدخول" : "Login"}
                  </div>
                </button>
                <button
                  onClick={showModalDownloadApp}
                  className="btn-main btn-down"
                >
                  <img src={iconDown} alt="icon" />
                  {i18n.language === "ar" ? "تحميل التطبيق" : "Download App"}
                </button>
              </div>

              <DropDownLanguage newClass={"dropdown-lang-landing"} />
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
