import React from "react";
import HeaderDays from "./HeaderDays";
import TopMainContent from "DashboardVendor/Pages/Home/Components/MainHomeVendor/TopMainContent/TopMainContent";

const TableDays = ({ reportsDats }) => {
  return (
    <div className="mt-3">
      <HeaderDays reportsDats={reportsDats} />
      <TopMainContent reportsDats={reportsDats} />
    </div>
  );
};

export default TableDays;
