import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrentCampaigns from "./TabsInfoMarketing/CurrentCampaigns";
import "./TabsMarkting.css";
import EndedCampaigns from "./TabsInfoMarketing/EndedCampaigns";
import ModalStepsMarketing from "../ModalStepsMarketing/ModalStepsMarketing";
import { useTranslation } from "react-i18next";
import { useState } from "react";

const TabsMarketing = ({ data }) => {
  const { marketing, not_paid } = data;
  const { t } = useTranslation();
  const [showModalSteps, setShowModalSteps] = useState(false);
  const showModalStepsMarketing = () => {
    setShowModalSteps(true);
  };
  const hideModalSteps = () => {
    setShowModalSteps(false);
  };
  const tabInfo = [
    {
      eventKey: "storeProfits",
      title: t("currentCampaigns"), // Translation key: "currentCampaigns"
      tabInfo: (
        <CurrentCampaigns
          showModalStepsMarketing={showModalStepsMarketing}
          marketing={marketing}
        />
      ),
    },
    {
      eventKey: "indebtedness",
      title: t("endedCampaigns"), // Translation key: "endedCampaigns"
      tabInfo: (
        <EndedCampaigns
          showModalStepsMarketing={showModalStepsMarketing}
          not_paid={not_paid}
        />
      ),
    },
  ];

  // SHOW MODAL STEPS

  return (
    <>
      <ModalStepsMarketing
        showModalSteps={showModalSteps}
        hideModalSteps={hideModalSteps}
      />

      <div className="all-tabs-items card-style-2 margin-top position-relative ">
        <TabsContentTb tabActive={"storeProfits"}>
          {tabInfo.map((item) => {
            return (
              <Tab
                key={item.eventKey}
                eventKey={item.eventKey}
                title={item.title}
              >
                {item.tabInfo}
              </Tab>
            );
          })}
        </TabsContentTb>
      </div>
    </>
  );
};

export default TabsMarketing;
