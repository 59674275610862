import axiosInstance from "../../../axios";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";

const CustomOption = ({ innerProps, label, data }) => (
  <div
    className="gap-2 d-flex"
    style={{ marginBottom: "8px", maxHeight: "40px", alignItems: "center" }}
    {...innerProps}
  >
    {data.image && (
      <img
        src={data.image}
        alt={label}
        style={{
          width: "24px",
          height: "auto",
          marginRight: "8px",
          borderRadius: "4px",
        }}
      />
    )}
    {data.label}
  </div>
);

const CategoryDropDown = ({ setValue, activeButton, width, selectedValue }) => {
  const normalizeValue = (value) => {
    if (value) {
      return {
        value: value,
        label:
          countryData.find((option) => option.value === value)?.label || value,
      };
    }
    return null;
  };

  const [countryData, setCountryData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    axiosInstance
      .get("/admin/category", {
        headers: {
          "Accept-Language": "ar",
        },
      })
      .then((res) => {
        const newData = res.data.data.map((item) => ({
          value: item.id,
          label: item.title,
          image: item.image,
        }));
        setCountryData(newData);
      });
  }, []);

  useEffect(() => {
    setSelectedOption(normalizeValue(selectedValue));
    setValue(selectedOption ? selectedOption : null);
  }, [selectedValue, countryData]);

  const handleChange = (selected) => {
    setSelectedOption(selected);
    setValue(
      selectedOption ? selectedOption : selected ? selected.value : null
    );
    if (activeButton) {
      activeButton(selected ? "custom" : "world");
    }
  };

  const CustomSingleValue = ({ innerProps, label, data }) => (
    <div className="" {...innerProps}>
      <img
        src={data.image}
        alt={label}
        style={{ width: "24px", height: "auto", marginRight: "8px" }}
      />
      {data.label}
    </div>
  );

  return (
    <div className="phoneContainer">
      <ReactSelect
        value={selectedOption}
        isRtl
        isMulti={false}
        options={countryData}
        onChange={handleChange}
        isClearable={true}
      />
    </div>
  );
};

export default CategoryDropDown;
