import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import CurrentCampaignsMain from "./CurrentCampaignsMain";
import { useEffect, useState } from "react";

const TabsMainMarketing = ({data}) => {


  const tabInfo = [
    {
      eventKey: "currentCampaigns",
      title: "الحملات الحالية",
      tabInfo: <CurrentCampaignsMain data={data} />
    },
    {
      eventKey: "indebtedness",
      title: "الحملات المنتهيه",
      tabInfo: ""
    }
  ];

  return (
    <>
      <div className="all-tabs-items card-style-2 margin-top position-relative ">
      <CurrentCampaignsMain data={data} />
      </div>
    </>
  );
};

export default TabsMainMarketing;
