import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React from "react";
import TabsReports from "./Components/TabsReports";
import "./ReportsPage.css";
const ReportsPage = () => {
  return (
    <>
      <HelmetInfo titlePage={"التقارير"} />
      <BreadcrumbPage
        routPageHome="/dashboardAdmin/homedashboard"
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={"التقارير"}
      />
      <TabsReports />
    </>
  );
};

export default ReportsPage;
