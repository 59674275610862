import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { toast } from "react-toastify";
import axiosInstance from "./../../axios";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import NavBar from "Components/NavBar/NavBar";
import Footer from "Components/Footer/Footer";
import { useSavedState } from "assets/hooks";

export default function PrivacyPolicy() {
  const { i18n, t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [privacyData, setPrivacyData, clearPrivacyData] = useSavedState(
    null,
    "privacyData"
  );

  const getPrivacy = async () => {
    setLoading(true);

    try {
      const { data } = await axiosInstance.get("/website/terms");
      setPrivacyData(data?.data);
    } catch (error) {
      toast.error(error?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrivacy();
  }, []);

  return (
    <div className="content-page">
      <header>
        <NavBar />
      </header>
      {loading && !privacyData ? (
        <div className="w-100 bg-white">
          <Loading />
        </div>
      ) : privacyData ? (
        <div>
          <main>
            <div className=" container vh-75 mt-3 ">
              <p className="fs-1">{t("termsAndConditions")}</p>
              {privacyData.map((record) => (
                <div className="d-flex flex-column justify-content-center card p-3  my-3">
                  <div className="my-2">
                    <p className="fs-2">
                      {i18n.language === "ar"
                        ? record?.translations[0]?.content
                        : record?.translations[1]?.content}
                    </p>
                  </div>
                  <div>
                    <div className="d-flex">
                      <p className="fs-5">{`${t("created_at")} : `}</p>
                      <p className="fs-5">
                        {moment(record?.created_at).format(
                          "DD-MM-YYYY , hh:mm"
                        )}
                      </p>
                    </div>
                    <div className="d-flex">
                      <p className="fs-5">{`${t("updated_at")} : `}</p>
                      <p className="fs-5">
                        {moment(record?.updated_at).format(
                          "DD-MM-YYYY , hh:mm"
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </main>
        </div>
      ) : (
        <div className="w-100 bg-white">
          <NoResults />
        </div>
      )}
      <Footer />
    </div>
  );
}
