import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormField from "Components/Forms/FormFiled";
import { useState } from "react";
import InputFiled from "Components/Forms/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import axiosInstance from "../../../../../../axios";
import { toast } from "react-toastify";

const ModalAddEmpolyee = ({ showModal, hideModal,refetch }) => {
  const { i18n } = useTranslation();
const [loading,setLoading]=useState(false);
  const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required(
      i18n.language === "ar" ? "إسم الموظف مطلوب" : "Employee Name is required"
    ),
    phoneNumber: Yup.string().required(
      i18n.language === "ar" ? "رقم الهاتف مطلوب" : "Phone number is required"
    ).min(10, i18n.language === "ar" ? "رقم الهاتف غير صحيح" : "Invalid phone number"),
    emailAddress: Yup.string()
      .email(
        i18n.language === "ar" ? "البريد الإلكتروني غير صحيح" : "Invalid email"
      )
      .required(
        i18n.language === "ar" ? "البريد الإلكتروني مطلوب" : "Email is required"
      ),
    password: Yup.string().required(
      i18n.language === "ar" ? "كلمة المرور مطلوبة" : "Password is required"
    )
  });

  const initialValues = {
    employeeName: "",
    phoneNumber: "",
    emailAddress: "",
    password: ""
  };
  const handleSubmit = (values) => {
    console.log(values);
    setLoading(true)
    axiosInstance.post("/admin/admin",
      {
        name:values.employeeName,
email:values.emailAddress,
phone:values.phoneNumber,
password:values.password,
pasword_confirmation:values.password
      },
      {
        headers:{
          "Accept-Language":i18n.language
        }
      }
    ).then(res=>{
      console.log(res.data)
      setLoading(false)
      toast.success(i18n.language === "ar" ? "تمت الإضافة بنجاح" : "Added successfully")
   
      hideModal()
      setTimeout(()=>{
        refetch()
      },1000)
    })
    .catch((err)=>{
      setLoading(false)
      console.log(err)
      toast.error(err.response.data.message)
    })
  };

  // SHOW PASSWORD INPUT
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const options = [
    { value: "1", label: "محاسب" },
    { value: "2", label: "محاسب 2" },
    { value: "3", label: "محاسب 3" }
  ];

  return (
    <CustomModal
      show={showModal}
      onHide={hideModal}
      title={i18n.language === "ar" ? "إضافة موظف" : "Add an employee"}
      newClass={"modal-add-employee"}
    >
      <FormField
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <InputField
          label={i18n.language === "ar" ? "إسم الموظف" : "Employee Name"}
          name="employeeName"
          type="text"
          placeholder={i18n.language === "ar" ? "إسم الموظف" : "Employee Name"}
          success
        />
        <InputField
          label={i18n.language === "ar" ? "رقم الهاتف" : "Phone number"}
          name="phoneNumber"
          type="number"
          placeholder={i18n.language === "ar" ? "رقم الهاتف" : "phone number"}
          success
        />
        <InputField
          label={i18n.language === "ar" ? "البريد الإلكتروني" : "E-mail"}
          name="emailAddress"
          type="email"
          placeholder={i18n.language === "ar" ? "البريد الإلكتروني" : "E-mail"}
          success
        />

        <div className="input-pass position-relative">
          <InputFiled
            label={i18n.language === "ar" ? "كلمة المرور" : "Password"}
            name="password"
            placeholder=""
            success
            value={password}
            onChange={handlePasswordChange}
            type={showPassword ? "text" : "password"}
          />

          <span
            className="icon-eye-button"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </span>
        </div>
{/* 
        <div className="select-option mt-3">
          <label htmlFor="select-option---1" className=" form-label">
            الصلاحية
          </label>
          <Select
            options={options}
            id="select-option---1"
            placeholder="الصلاحية"
          />
        </div> */}

        <button type="submit" disabled={loading} className="btn-main btn-submit w-100 mt-4">
       {
        loading ? <span className="loader"></span> :   i18n.language === "ar" ? "حفظ" : "Save"
       }
        </button>
      </FormField>
    </CustomModal>
  );
};

export default ModalAddEmpolyee;
