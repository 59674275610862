
const OffersIcon = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 21.5117C9.21 21.5117 8.46 21.1117 7.94 20.4017L6.93 19.0517C6.72 18.7717 6.44 18.6117 6.14 18.5917C5.84 18.5717 5.54 18.7117 5.3 18.9617L4.73 18.4617L5.28 18.9717C3.83 20.5117 2.73 20.3917 2.2 20.1817C1.66 19.9717 0.75 19.2817 0.75 17.0617V5.80172C0.75 1.36172 2.03 0.0117188 6.22 0.0117188H13.77C17.96 0.0117188 19.24 1.36172 19.24 5.80172V17.0617C19.24 19.2717 18.33 19.9617 17.79 20.1817C17.26 20.3917 16.16 20.5117 14.71 18.9717C14.47 18.7117 14.16 18.5817 13.86 18.6017C13.56 18.6217 13.27 18.7817 13.06 19.0617L12.05 20.4117C11.54 21.1117 10.79 21.5117 10 21.5117ZM6.08 17.0917C6.12 17.0917 6.17 17.0917 6.21 17.0917C6.95 17.1317 7.65 17.5217 8.12 18.1517L9.14 19.5017C9.63 20.1517 10.38 20.1517 10.87 19.5017L11.88 18.1517C12.35 17.5217 13.05 17.1317 13.8 17.0917C14.55 17.0517 15.28 17.3617 15.82 17.9417C16.58 18.7517 17.07 18.8517 17.25 18.7817C17.49 18.6817 17.75 18.1017 17.75 17.0617V5.80172C17.75 2.19172 17.12 1.51172 13.78 1.51172H6.22C2.88 1.51172 2.25 2.19172 2.25 5.80172V17.0617C2.25 18.1117 2.51 18.6917 2.75 18.7817C2.93 18.8517 3.42 18.7517 4.18 17.9417C4.7 17.3917 5.38 17.0917 6.08 17.0917Z"
        fill="white"
      />
    </svg>
  );
};

export default OffersIcon;
