
const EyeIcon2 = () => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 12.2474C7.7075 12.2474 6.2525 10.7924 6.2525 8.99994C6.2525 7.20744 7.7075 5.75244 9.5 5.75244C11.2925 5.75244 12.7475 7.20744 12.7475 8.99994C12.7475 10.7924 11.2925 12.2474 9.5 12.2474ZM9.5 6.87744C8.33 6.87744 7.3775 7.82994 7.3775 8.99994C7.3775 10.1699 8.33 11.1224 9.5 11.1224C10.67 11.1224 11.6225 10.1699 11.6225 8.99994C11.6225 7.82994 10.67 6.87744 9.5 6.87744Z"
        fill="#121212"
      />
      <path
        d="M9.5 15.7649C6.68 15.7649 4.0175 14.1149 2.1875 11.2499C1.3925 10.0124 1.3925 7.99486 2.1875 6.74986C4.025 3.88486 6.6875 2.23486 9.5 2.23486C12.3125 2.23486 14.975 3.88486 16.805 6.74986C17.6 7.98736 17.6 10.0049 16.805 11.2499C14.975 14.1149 12.3125 15.7649 9.5 15.7649ZM9.5 3.35986C7.0775 3.35986 4.76 4.81486 3.14 7.35736C2.5775 8.23486 2.5775 9.76486 3.14 10.6424C4.76 13.1849 7.0775 14.6399 9.5 14.6399C11.9225 14.6399 14.24 13.1849 15.86 10.6424C16.4225 9.76486 16.4225 8.23486 15.86 7.35736C14.24 4.81486 11.9225 3.35986 9.5 3.35986Z"
        fill="#121212"
      />
    </svg>
  );
};

export default EyeIcon2;
