import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import IconDate from "assets/Icons/IconDate";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import { faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const TransfersTable = ({information}) => {
  const {t}=useTranslation()
  const theadTrContent = [
    "رقم العملية",
    "المبلغ",
    "قيمة العملة",
t("content"),
t("Opreation Type"),
    "التاريخ"
  ];
  const [mainData,setMainData]=useState(information)
  const [data,setData]=useState()
  useEffect(()=>{


    setData(
      information?.activity?.map((item)=>(
        {
          id: 1,
          numberPay: item.id,
          name: item?.amount,
          category: `${item?.type} - ${item?.type_id}`,
          content: `${item?.content} `,
          type: `${item?.status} `,
    
          date: moment(item?.created_at).format("YYYY-MM-DD")
        }
        
      ))
    )
    setMainData(
      information?.activity?.map((item)=>(
        {
          id: 1,
          numberPay: item.id,
          name: item?.amount,
          category: `${item?.type} - ${item?.type_id}`,
          content: `${item?.content} `,
          type: `${item?.status} `,
    
          date: moment(item?.created_at).format("YYYY-MM-DD")
        }
        
      ))
    )
  },[information])



  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [selectedPage,setSelectdPage]=useState(0)
  const showModal = () => {
    setShowModalFilter(true);
  };

  // FUNCTION PAGINATION
  const handlePageClick = (e) => {
    setSelectdPage(e.selected);

  };
  return (
    <div className="table-transfers---2">
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={true}
          selectCategory={false}
          selectPay={false}
        />
      </div>

  {
    data?.length >0 ?
<>
<HeaderTableSearchFilter
    newClassHeaderContentSearch={"search-customer"}
    isTrueSearchInputFilter={true}
    textPlaceholder={"بحث"}
    functionSearchFilter={(e) => {
      console.log(e.target.value);
    }}
    functionIconSearch={(e) => {
      console.log("search");
    }}
    isTrueFilterButton={true}
    functionButtonFilter={showModal}
    isTrueHighestScore={false}
    functionButtonHighestScore={false}
    isNewButton={false}
    contentNewButton={false}
    isTrueContentLeftHeaderSearch={true}
    InfoContentLeftSearchFilter={
      <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
        <ButtonsExport dataExport={"Data Table Array"} />
      </div>
    }
  />
  <DataTableTwo
    theadTrContent={
      <>
        {theadTrContent.map((item) => {
          return <th>{item}</th>;
        })}
      </>
    }
    tbodyContent={
      <>
        {data?.slice(selectedPage *10 , selectedPage+1 *  10 ).map((item) => {
          return (
            <tr key={item.id}>
              <td>{item.numberPay}</td>
              <td>{item.name}</td>
              <td>{item.category}</td>
              <td>{item.content}</td>

              <td>{item.type== "in"?<FontAwesomeIcon style={{color:'#32a840',width:'40px',height:'40px'}} icon={faCirclePlus} /> : <FontAwesomeIcon  style={{color:'#9c0c19'}} icon={faCircleMinus} />}</td>

              <td>
                <IconDate /> {item.date}
              </td>
            </tr>
          );
        })}
      </>
    }
  /></>:<NoResults />
  }
      <PaginationPage itemCount={data?.length/10} handlePageClick={handlePageClick} />
    </div>
  );
};

export default TransfersTable;
