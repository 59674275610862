import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import ModalFilter from "Dashboard/Components/ModalFilter/ModalFilter";
import DatePickerInput from "Dashboard/Components/Ui/DatePickerInput/DatePickerInput";
import { useState } from "react";
import img from "../../../../../assets/images/dashboard/main/001.png";
import img2 from "../../../../../assets/images/main/02.png";

const ModalShowOfferVendor = ({ showModalOfferVendor, hideModalOfferVendor }) => {
  // ON SAVE DATA FILTER
  const onSaveOfferVendor = () => {
    hideModalOfferVendor();
  };
  // SELECT DATE
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);

  const [isChecked, setIsChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const imgCard = [{ id: 0 }, { id: 1 }, { id: 2 }, { id: 3 }];
  return (
    <>
      <ModalFilter
        showModalFilter={showModalOfferVendor}
        hideModalFilter={hideModalOfferVendor}
        modalFilterText={"معلومات العرض"}
        newClassModal={"modal-offers-cotnent-02"}
      >
        {/* ======= START FILTER DATE ======== */}
        <div className="filter-date mt-2">
          {/* ======= START DATE FORM ========= */}
          <div className="date-form d-flex align-items-center gap-3 flex-lg-nowrap flex-wrap  mt-3">
            {/* ======== START ROW ========= */}
            <div className="row g-3">
              {/* ========== START COL ========== */}
              <div className="col-12  col-sm-6">
                {/* ============ START DATA FORM ONE ============== */}
                <DatePickerInput
                  isLabel={true}
                  textLabel={"من"}
                  htmlForLabel={"formDate1"}
                  newClass={"input-date-1"}
                  idDate={"formDate1"}
                  selectedDateFunction={selectedDate}
                  OnChangeFunction={(date) => {
                    setSelectedDate(date);
                  }}
                  dateType={"dd/MM/yyyy"}
                  placeholderText={"dd/MM/yyyy"}
                  iconFont={true}
                  typeIconFont={faCalendarDays}
                  iconImg={false}
                  iconImgSrc={false}
                  altImg={false}
                />

                {/* ============ END DATA FORM ONE ============== */}
              </div>
              {/* ========== END COL ========== */}
              {/* ========== START COL ========== */}
              <div className="col-12  col-sm-6 col-lg-6">
                {/* ============ START DATA FORM ONE ============== */}
                <DatePickerInput
                  isLabel={true}
                  textLabel={"الى"}
                  htmlForLabel={"formDate2"}
                  newClass={"input-date-1"}
                  idDate={"formDate2"}
                  selectedDateFunction={selectedDate2}
                  OnChangeFunction={(date) => {
                    setSelectedDate2(date);
                  }}
                  dateType={"dd/MM/yyyy"}
                  placeholderText={"dd/MM/yyyy"}
                  iconFont={true}
                  typeIconFont={faCalendarDays}
                  iconImg={false}
                  iconImgSrc={false}
                  altImg={false}
                />

                {/* ============ END DATA FORM ONE ============== */}
              </div>
              {/* ========== END COL ========== */}
            </div>
            {/* ======== END ROW ========= */}
            <div className="checked-switch checked-switch-content d-flex align-items-center gap-3">
              <label className="switch-input" htmlFor="checkbox908">
                <input
                  type="checkbox"
                  id="checkbox908"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <div className="slider round"></div>
              </label>
              <p className="text fs-6 fw-bold">
                {isChecked ? (
                  <span className="main-text">مفعل</span>
                ) : (
                  "غير مفعل"
                )}
              </p>
            </div>
          </div>
          {/* ======= END DATE FORM ========= */}
        </div>
        {/* ======= END FILTER DATE ======== */}

        <div className="info-content-offers mt-3">
          {/** ======== START INFO TOP CONTENT ========== */}
          <div className="info-top-content">
            <p className="text-content mb-3">
              خصم 10% علي طلبات البروست والمسحب حتي الأسبوع القادم الموافق
              30/12/2024
            </p>
            <div className="img-bg">
              <img
                src={img}
                alt="img"
                className="w-100 object-fit-cover rounded-3"
                height={"150px"}
              />
            </div>
          </div>
          {/** ======== START INFO TOP CONTENT ========== */}
          {/* ======= START END CONTENT ========= */}
          <div className="end-content">
            <h2 className="title">تفاصيل العرض</h2>
            <p className="text">
              هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد
              هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو
              العديد من النصوص الأخرى
            </p>

            <div className="all-imgs">
              <h2 className="title mb-3">صور وفيديوهات العرض</h2>
              <div className="row g-3">
                {imgCard.map((item) => {
                  return (
                    <div className="col-6 col-md-3">
                      <div className="img-cards-1">
                        <img
                          src={img2}
                          alt="img"
                          className="w-100 h-100 object-fit-cover rounded-3"
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {/* ======= END END CONTENT ========= */}
        </div>

        {/* ========= START BUTTONS BOTTOM ========== */}
        <button className="btn-main w-100 mt-4" onClick={onSaveOfferVendor}>حفظ</button>
        {/* ========= END BUTTONS BOTTOM ========== */}
      </ModalFilter>
    </>
  );
};

export default ModalShowOfferVendor;
