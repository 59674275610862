import React, { useEffect } from 'react';
import canvasConfetti from 'canvas-confetti';

const Confetti = () => {
  useEffect(() => {
    // Configure the confetti options as needed
    const confettiOptions = {
      particleCount: 100,
      spread: 70,
      colors: ['#FF0000', '#00FF00', '#0000FF'], // Add your preferred colors
    };

    // Trigger the confetti animation
    canvasConfetti(confettiOptions);
  }, []);

  return <canvas id="confetti-canvas" style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none' }} />;
};

export default Confetti;
