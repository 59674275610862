import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React from "react";
import HeaderPayment from "./Components/HeaderPayment/HeaderPayment";
import MainPayment from "./Components/MainPayment/MainPayment";

const PaymentsVendor = () => {
  return (
    <>
      <HelmetInfo titlePage={"المدفوعات"} />
      <BreadcrumbPage
        routPageHome={"/dashboardVendor/homedashboardvendor"}
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={"المدفوعات"}
      />

      <header>
        <HeaderPayment />
      </header>
      <main>
        <MainPayment />
      </main>
    </>
  );
};

export default PaymentsVendor;
