const PortfolioIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.20165 11.2216C5.01165 11.2216 4.81165 11.1416 4.67165 11.0016C4.46165 10.7816 4.39165 10.4616 4.51165 10.1816L6.37165 5.7416C6.41165 5.6516 6.43165 5.5916 6.46165 5.5416C7.94165 2.1316 9.83165 1.3016 13.1716 2.5816C13.3616 2.6516 13.5116 2.8016 13.5916 2.9916C13.6716 3.1816 13.6716 3.3916 13.5916 3.5816L10.6616 10.3816C10.5416 10.6616 10.2716 10.8316 9.97165 10.8316H7.12165C6.55165 10.8316 6.01165 10.9416 5.49165 11.1616C5.40165 11.2016 5.30165 11.2216 5.20165 11.2216ZM10.6116 3.5116C9.37165 3.5116 8.61165 4.3216 7.82165 6.1616C7.81165 6.1916 7.79165 6.2216 7.78165 6.2516L6.47165 9.3616C6.69165 9.3416 6.90165 9.3316 7.12165 9.3316H9.47165L11.8816 3.7316C11.4116 3.5816 10.9916 3.5116 10.6116 3.5116Z"
        fill="white"
      />
      <path
        d="M18.2883 11.032C18.2183 11.032 18.1383 11.022 18.0683 11.002C17.6883 10.892 17.2883 10.832 16.8683 10.832H9.96827C9.71827 10.832 9.47827 10.702 9.33827 10.492C9.20827 10.282 9.17827 10.012 9.27827 9.78196L12.1783 3.05196C12.3283 2.69196 12.7683 2.44196 13.1383 2.57196C13.2583 2.61196 13.3683 2.66196 13.4883 2.71196L15.8483 3.70196C17.2283 4.27196 18.1483 4.87196 18.7483 5.59196C18.8683 5.73196 18.9683 5.88196 19.0683 6.04196C19.1783 6.21196 19.2783 6.41196 19.3483 6.62196C19.3783 6.69196 19.4283 6.82196 19.4583 6.96196C19.7383 7.91196 19.5983 9.07196 18.9983 10.572C18.8683 10.852 18.5883 11.032 18.2883 11.032ZM11.1083 9.33196H16.8783C17.1983 9.33196 17.5083 9.36196 17.8183 9.41196C18.0983 8.54196 18.1583 7.87196 17.9983 7.33196C17.9783 7.24196 17.9583 7.20196 17.9483 7.16196C17.8883 7.00196 17.8483 6.91196 17.7983 6.83196C17.7283 6.72196 17.6783 6.63196 17.5983 6.54196C17.1683 6.02196 16.4083 5.54196 15.2683 5.07196L13.2983 4.25196L11.1083 9.33196Z"
        fill="white"
      />
      <path
        d="M15.9 23.512H8.1C7.82 23.512 7.56 23.492 7.3 23.462C3.79 23.232 1.79 21.222 1.55 17.672C1.52 17.452 1.5 17.182 1.5 16.912V14.962C1.5 12.712 2.84 10.682 4.91 9.78203C5.62 9.48203 6.36 9.33203 7.13 9.33203H16.89C17.46 9.33203 18.01 9.41203 18.52 9.57203C20.87 10.282 22.52 12.502 22.52 14.962V16.912C22.52 17.132 22.51 17.342 22.5 17.542C22.28 21.452 20 23.512 15.9 23.512ZM7.12 10.832C6.55 10.832 6.01 10.942 5.49 11.162C3.97 11.822 2.99 13.312 2.99 14.962V16.912C2.99 17.122 3.01 17.332 3.03 17.532C3.22 20.382 4.62 21.782 7.43 21.972C7.68 22.002 7.88 22.022 8.09 22.022H15.89C19.19 22.022 20.81 20.572 20.97 17.472C20.98 17.292 20.99 17.112 20.99 16.912V14.962C20.99 13.152 19.78 11.532 18.06 11.002C17.68 10.892 17.28 10.832 16.86 10.832H7.12Z"
        fill="white"
      />
      <path
        d="M2.24219 15.7115C1.83219 15.7115 1.49219 15.3715 1.49219 14.9615V12.0315C1.49219 8.88152 3.72219 6.16152 6.80219 5.56152C7.07219 5.51152 7.35219 5.61152 7.53219 5.82152C7.70219 6.03152 7.75219 6.33152 7.64219 6.58152L5.89219 10.7615C5.81219 10.9415 5.67219 11.0815 5.50219 11.1615C3.98219 11.8215 3.00219 13.3115 3.00219 14.9615C2.99219 15.3715 2.66219 15.7115 2.24219 15.7115ZM5.60219 7.58152C4.32219 8.30152 3.39219 9.56152 3.10219 11.0315C3.54219 10.5815 4.05219 10.2015 4.63219 9.92152L5.60219 7.58152Z"
        fill="white"
      />
      <path
        d="M21.7611 15.7115C21.3511 15.7115 21.0111 15.3715 21.0111 14.9615C21.0111 13.1515 19.8011 11.5315 18.0811 11.0015C17.8811 10.9415 17.7111 10.8015 17.6211 10.6115C17.5311 10.4215 17.5211 10.2015 17.6011 10.0115C18.0711 8.8415 18.1911 7.9915 18.0011 7.3315C17.9811 7.2415 17.9611 7.2015 17.9511 7.1615C17.8211 6.8715 17.8911 6.5315 18.1211 6.3115C18.3511 6.0915 18.7011 6.0415 18.9811 6.1915C21.1611 7.3315 22.5111 9.5715 22.5111 12.0315V14.9615C22.5111 15.3715 22.1711 15.7115 21.7611 15.7115ZM19.2511 9.8515C19.8811 10.1415 20.4411 10.5515 20.9111 11.0415C20.7211 10.0615 20.2511 9.1715 19.5611 8.4715C19.5111 8.8915 19.4111 9.3515 19.2511 9.8515Z"
        fill="white"
      />
    </svg>
  );
};

export default PortfolioIcon;
