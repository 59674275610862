const HomeIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 10.7612H7C9 10.7612 10 9.76123 10 7.76123V5.76123C10 3.76123 9 2.76123 7 2.76123H5C3 2.76123 2 3.76123 2 5.76123V7.76123C2 9.76123 3 10.7612 5 10.7612Z"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 10.7612H19C21 10.7612 22 9.76123 22 7.76123V5.76123C22 3.76123 21 2.76123 19 2.76123H17C15 2.76123 14 3.76123 14 5.76123V7.76123C14 9.76123 15 10.7612 17 10.7612Z"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17 22.7612H19C21 22.7612 22 21.7612 22 19.7612V17.7612C22 15.7612 21 14.7612 19 14.7612H17C15 14.7612 14 15.7612 14 17.7612V19.7612C14 21.7612 15 22.7612 17 22.7612Z"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 22.7612H7C9 22.7612 10 21.7612 10 19.7612V17.7612C10 15.7612 9 14.7612 7 14.7612H5C3 14.7612 2 15.7612 2 17.7612V19.7612C2 21.7612 3 22.7612 5 22.7612Z"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
