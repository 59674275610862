import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import { useTranslation } from "react-i18next";
import TableOffersVendor from "../TableOffersVendor/TableOffersVendor";
import ExpiredTableOffer from "../TableOffersVendor/ExpiredTableOffer";
import CureentOffers from "../TableOffersVendor/CureentOffers";
const OffersMainTabVendor = ({ refetch, setRefetch, offers }) => {
  const { t, i18n } = useTranslation();
  const { approved, pending, refused, all, all_count } = offers;

  const tabInfo = [
    {
      eventKey: "approvedOffers",
      title: `${t("offers")} (${all_count})`,
      tabInfo: (
        <TableOffersVendor
          refetch={refetch}
          setRefetch={setRefetch}
          approved={all}
        />
      ),
    },
    // {
    //   eventKey: "pendingOffers",
    //   title: i18n.language === "ar" ? "العروض المعلقة" : "Pending Offers",
    //   tabInfo: (
    //     <CureentOffers
    //       refetch={refetch}
    //       setRefetch={setRefetch}
    //       pending={pending}
    //     />
    //   ),
    // },
    // {
    //   eventKey: "refused",
    //   title: i18n.language === "ar" ? "العروض المرفوضة" : "Refused Offers",
    //   tabInfo: (
    //     <ExpiredTableOffer
    //       refetch={refetch}
    //       setRefetch={setRefetch}
    //       refused={refused}
    //     />
    //   ),
    // },
  ];

  return (
    <>
      <div className="all-tabs-items card-style-2 margin-top position-relative ">
        <TabsContentTb tabActive={"approvedOffers"}>
          {tabInfo.map((item) => {
            return (
              <Tab
                key={item.eventKey}
                eventKey={item.eventKey}
                title={item.title}
              >
                {item.tabInfo}
              </Tab>
            );
          })}
        </TabsContentTb>
      </div>
    </>
  );
};

export default OffersMainTabVendor;
