import { useTranslation } from "react-i18next";

const CheckboxSwitch = ({ id, isChecked, handleCheckboxChange }) => {
  const { i18n } = useTranslation();
  return (
    <div className="checked-switch d-flex align-items-center gap-3">
      <label className="switch-input" htmlFor={`checkbox-${id}`}>
        <input
          type="checkbox"
          id={`checkbox-${id}`}
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        <div className="slider round"></div>
      </label>
      <p className="text fs-6 fw-medium text-dark">
        {isChecked ? (
          <span className="main-text fs-6 fw-medium    main-text">
            {i18n.language === "ar" ? "مفعل" : "Enabled"}
          </span>
        ) : i18n.language === "ar" ? (
          "غير مفعل"
        ) : (
          "Disabled"
        )}
      </p>
    </div>
  );
};

export default CheckboxSwitch;
