import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "./../../../../../axios";
import Loading from "Dashboard/Components/LottieFiles/Loading"; // Add import for Loading component
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useTranslation } from "react-i18next";
import icon1 from "../../../../../assets/images/dashboardVendor/eventsIcons/01.svg";
import icon2 from "../../../../../assets/images/dashboardVendor/eventsIcons/02.svg";
import icon3 from "../../../../../assets/images/dashboardVendor/eventsIcons/03.svg";
import icon4 from "../../../../../assets/images/dashboardVendor/eventsIcons/04.svg";
import TableCustomerVendor from "DashboardVendor/Pages/CustomersVendor/Components/TableCustomerVendor";
import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import TableEventsVendor from "./TableEventsVendor";

const EventInformation = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const [information, setInformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [ordersData, setOrdersData] = useState(null);

  const { t } = useTranslation();
  const getInformation = async (id) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/store/event/${id}`);
      setInformation(data?.data);
      setOrdersData(data?.data && data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInformation(id);
  }, [id, refetch]);
  const columnDefs = [
    {
      headerName: t("ID"),
      field: "id",
      flex: 0.5,
    },
    {
      headerName: t("image"),
      field: "title",
      cellRenderer: (params) => (
        <div className="d-flex justify-content-between align-items-center gap-2">
          <img
            width={40}
            className="mt-1 rounded object-fit-cover"
            src={params.data.image}
            alt=""
          />
          <p>{params.data.title || "title test"}</p>
        </div>
      ),

      flex: 1,
    },
    // {
    //   headerName: t("parent_id"),
    //   field: "parent_id",
    //   flex: 0.5,
    // },

    {
      headerName: t("status"),
      field: "status",
      flex: 0.5,
    },
    {
      headerName: t("created_at"),
      field: "created_at",
      flex: 1,
    },
    {
      headerName: t("updated_at"),
      field: "updated_at",
      flex: 1,
    },
    {
      headerName: t("orders"),
      field: "orders",
      flex: 0.5,
    },
  ];
  const cardsBox = [
    {
      id: 0,
      icon: icon3,
      title: t("orders_count"),
      text: ordersData?.orders_count,
    },
    {
      id: 1,
      icon: icon2,
      title: t("places"),
      text: ordersData?.places,
    },
    {
      id: 2,
      icon: icon1,
      title: t("date"),
      text: ordersData?.date,
    },
    {
      id: 3,
      icon: icon4,
      title: t("price"),
      text: `${ordersData?.price}`,
    },
  ];
  console.log(ordersData);
  return (
    <>
      <BreadcrumbPage
        routPageHome="/dashboardVendor/homedashboard"
        showMainTextPage={true}
        routePage={"/dashboardVendor/eventsVendor"}
        mainPageText={t("events")}
        showPageTwo={true}
        textPageTwo={`${t("events")}/${id}`}
      />

      <div className="all-conent-file card-style-2">
        {loading ? (
          <div className="w-100 bg-white rounded">
            <Loading />
          </div>
        ) : ordersData ? (
          <>
            <main>
              <div className="all-cards-bottom margin-top">
                {/* ======= START ALL CARDS BOTTOM ========= */}
                <div className="row g-3">
                  {cardsBox.map((item) => {
                    return (
                      <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3"
                        key={item.id}
                      >
                        <CardBox
                          iconBox={item.icon}
                          textInfo={item.title}
                          infoContent={item.text}
                          newClass={"card-item"}
                        />
                      </div>
                    );
                  })}
                </div>
                {/* ======= END ALL CARDS BOTTOM ========= */}
              </div>{" "}
              <div className="my-3">
                <TableEventsVendor ordersData={ordersData?.orders} />
              </div>
            </main>
          </>
        ) : (
          <div className="w-100 bg-white">
            <NoResults />
          </div>
        )}
      </div>
    </>
  );
};

export default EventInformation;
