import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

const MiddleContentVendor = ({ information }) => {
  const { t, i18n } = useTranslation();
  const cardItem = [
    {
      id: 2,
      title: i18n.language === "ar" ? "تاريخ  الفاتورة" : "Invoice Date",
      text: moment(information?.created_at).format("DD-MM-YYYY , hh:mm A"),
    },
    {
      id: 3,
      title: i18n.language === "ar" ? "طريقة الدفع" : "Payment Method",
      text: information?.payment_method,
    },
    {
      id: 4,
      title: i18n.language === "ar" ? "إجمالي ألفاتورة" : "Total Invoice",
      text: information?.price,
    },
    {
      id: 4,
      title: t("ouro"),
      text: information?.ouro,
    },
    {
      id: 4,
      title: t("points"),
      text: information?.customer?.points,
    },
  ];
  const cardCustomer = [
    {
      id: 2,
      title: t("customer"),
      text: `${information?.customer?.username} , ${information?.customer?.id}`,
    },
    {
      id: 3,
      title: `${t("branchInvoice")} (${information?.branch?.id})`,
      text: `${information?.branch?.address}`,
    },
  ];

  return (
    <>
      <div className="middle-content card-style-2 margin-top">
        <div className="all-middle-content ">
          <div className="row g-4  justify-content-around">
            {cardItem.map((item) => {
              return (
                <div
                  key={item.id}
                  className="col-content col-6 col-md-4 col-lg-3 col-xl-2"
                >
                  <div className="middle-content-one d-flex flex-column gap-3 text-center ">
                    <h2 className="title">{item.title}</h2>
                    <h2 className="info-title">{item.text}</h2>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="middle-content card-style-2 margin-top">
        <div className="all-middle-content ">
          <div className="row  g-4  justify-content-around">
            {cardCustomer.map((item) => {
              return (
                <div key={item.id} className="col-content col-md-6 ">
                  <div className="middle-content-one d-flex flex-column justify-content-center align-items-center gap-2  ">
                    <h2 className=" title ">{item.title}</h2>
                    <h2 className="info-title">{item.text}</h2>
                    <h2 className="info-title">{item?.text2 && item?.text2}</h2>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default MiddleContentVendor;
