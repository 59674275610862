import axiosInstance from "../../../axios";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";

const CustomOption = ({ innerProps, label, data }) => (
  <div
    className="gap-2 d-flex"
    style={{ marginBottom: "8px", maxHeight: "40px", alignItems: "center" }}
    {...innerProps}
  >
    {data.image && (
      <img
        src={data.image}
        alt={label}
        style={{
          width: "24px",
          height: "auto",
          marginRight: "8px",
          borderRadius: "4px",
        }}
      />
    )}
    {data.label}
  </div>
);

const CategorySelect = ({
  setValue,
  activeButton,
  width,
  isMulti,
  selectedValues,
}) => {
  const normalizeValues = (values) => {
    if (Array.isArray(values)) {
      return values.map((val) => ({
        value: val,
        label: countryData.find((option) => option.value === val)?.label || val,
      }));
    }
    if (values) {
      return [
        {
          value: values,
          label:
            countryData.find((option) => option.value === values)?.label ||
            values,
        },
      ];
    }
    return [];
  };

  const [countryData, setCountryData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/admin/category", {
        headers: {
          "Accept-Language": "ar",
        },
      })
      .then((res) => {
        const newData = res.data.data.map((item) => ({
          value: item.id,
          label: item.title,
          image: item.image,
        }));
        newData.unshift({ value: "select-all", label: "Select All" });
        setCountryData(newData);
      });
  }, []);

  useEffect(() => {
    setSelectedOptions(normalizeValues(selectedValues));
  }, [selectedValues, countryData]);

  const handleSelectAll = (options) => {
    const allOptions = options.filter(
      (option) => option.value !== "select-all"
    );
    setSelectedOptions(allOptions);
    setValue(allOptions);
    if (activeButton) {
      activeButton("custom");
    }
  };

  const handleChange = (selected) => {
    if (selected && selected.some((option) => option.value === "select-all")) {
      handleSelectAll(countryData);
    } else {
      const updatedSelectedOptions = isMulti
        ? [...new Set(selected)]
        : selected;

      setSelectedOptions(updatedSelectedOptions);
      setValue(updatedSelectedOptions);
      if (activeButton) {
        activeButton(
          updatedSelectedOptions && updatedSelectedOptions.length > 0
            ? "custom"
            : "world"
        );
      }
    }
  };

  const CustomSingleValue = ({ innerProps, label, data }) => (
    <div className="gap-2 d-flex SingleValue" {...innerProps}>
      <img
        src={data.image}
        alt={label}
        style={{ width: "24px", height: "auto", marginRight: "8px" }}
      />
      {data.label}
    </div>
  );

  return (
    <div className="phoneContainer">
      <ReactSelect
        value={selectedOptions}
        isRtl
        isMulti={isMulti}
        options={countryData}
        onChange={handleChange}
        isClearable={true}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            border: "1px solid #dcdcdd",
            borderRadius: "8px",
            width: width || "250px",
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            color: "black",
            padding: "0px",
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            padding: "5px",
            border: "1px solid #dcdcdd",
            borderRadius: "8px",
          }),
        }}
      />
    </div>
  );
};

export default CategorySelect;
