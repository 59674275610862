import imgUser from "../../../../../assets/images/user/02.png";
import imgCountry1 from "../../../../../assets/images/dashboard/flags/sa.svg";
import EyeIcon from "assets/Icons/EyeIcon";
import "./EndHome.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
const TopCategories = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [dataCategory, setDataCategory] = useState([]);

  useEffect(() => {
    axiosInstance.get("/admin/ourotop").then((res) => {
      setDataCategory(res.data?.global);
    });
  }, []);
  return (
    <div className="top-categories card-style-1">
      {/* =========== START HEADER CHART INFO =========== */}
      <div className="header-chart-info d-flex justify-content-between align-items-center flex-wrap gap-3 mb-3">
        <div className="right-info-head d-flex flex-column gap-2">
          <h2 className="title">{t("Ouro Top ")}</h2>
        </div>
      </div>
      {/* =========== END HEADER CHART INFO =========== */}
      {/* =========== START ALL TOP CATEGORIES ========== */}
      <div className="all-top-categories">
        {dataCategory.slice(0, 10)?.map((item) => {
          return (
            <>
              {/* ========== START CATEGORY ONE ======== */}
              <div
                key={item.id}
                className="category-top-one mb-3 d-flex justify-content-between align-items-center  flex-row-reverse"
              >
                {/* ======== START DETAILS CATEGORY RIGHT ========= */}
                <div className="details-category-right d-flex flex-row-reverse gap-3 ">
                  {/* ======== START IMAGE USER ======== */}
                  <div className="img-user">
                    <img
                      src={item?.image}
                      alt="img user"
                      width={"37px"}
                      height={"37px"}
                      className="img-user rounded-circle"
                    />
                  </div>
                  {/* ======== END IMAGE USER ======== */}
                  {/* ======== START DETAILS CATEGORY =========== */}
                  <div className="details-category">
                    <div className="head-details d-flex align-items-center flex-row-reverse  gap-3">
                      {item.top_status == "up" ? (
                        <div className="main-info-card green-color d-flex align-items-center gap-2">
                          <div className="num-per">
                            {Math.abs(item.id - item.old_order)}
                          </div>
                          <div className="icon-arrow">
                            <FontAwesomeIcon
                              style={{ color: "#32a840" }}
                              icon={faArrowUpLong}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="main-info-card red-color d-flex align-items-center gap-2">
                          <div className="num-per">
                            {Math.abs(item.id - item.old_order)}
                          </div>
                          <div className="icon-arrow">
                            <FontAwesomeIcon icon={faArrowUpLong} />
                          </div>
                        </div>
                      )}
                      <div className="img-country">
                        <img
                          src={item?.country?.image}
                          alt="img country"
                          width={"26px"}
                          height={"26px"}
                          style={{ borderRadius: "8px" }}
                        />
                      </div>

                      <h2 className="name-user">{item?.name}</h2>
                    </div>
                    <div className="details-info-category d-flex align-items-center  flex-row-reverse gap-2 mt-1">
                      <h2 className="num-one blue-text">{item?.points}</h2>
                      <h2
                        className="num-one green-text"
                        style={{
                          color: item?.percentage <= 0 ? "#9c0c19" : "#32a840",
                          display: item?.percentage === 0 ? "none" : "flex",
                        }}
                      >{`${item?.percentage.toFixed(2)}%`}</h2>
                    </div>
                  </div>
                  {/* ======== END DETAILS CATEGORY =========== */}
                </div>
                {/* ======== END DETAILS CATEGORY RIGHT ========= */}
                {/* ======== START EYE SHOW ========= */}
                <div
                  onClick={() => {
                    navigate(`/dashboardAdmin/clientFile/${item?.id}`);
                  }}
                  className="eye-show cursor-pointer-event"
                >
                  <EyeIcon />
                </div>
                {/* ======== END EYE SHOW ========= */}
              </div>
              {/* ========== END CATEGORY ONE ======== */}
            </>
          );
        })}
      </div>
      {/* =========== END ALL TOP CATEGORIES ========== */}
    </div>
  );
};

export default TopCategories;
