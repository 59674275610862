import CustomModal from "Components/CustomModal/CustomModal";
import CountrySelectFilter from "Dashboard/Components/ModalFilter/Components/CountrySelectFilter";
import React from "react";

const ModalClassificationCountry = ({
  showClassificationCountry,
  hideClassificationCountry
}) => {
  return (
    <CustomModal
      show={showClassificationCountry}
      onHide={hideClassificationCountry}
      title={"تصنيف حسب الدولة"}
      newClass={"modal-classification-country"}
    >
      <div className="form-modal-classific">
        <CountrySelectFilter />
        <div className="buttons-bottom-11 d-flex align-items-center gap-3 mt-4">
          <button onClick={hideClassificationCountry} className="btn-main w-100">حفظ</button>
          <button onClick={hideClassificationCountry} className="btn-main btn-main-outline w-100">
            إستعادة الفلتر
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalClassificationCountry;
