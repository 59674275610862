import { useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import { useTranslation } from "react-i18next";
import DataTable from "Components/DataTable/DataTable";
import EditIcon from "assets/Icons/EditIcon";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const CurrentCampaigns = ({ marketing, showModalStepsMarketing }) => {
  const { t, i18n } = useTranslation();

  const [rowsData, setRowsData] = useState(
    marketing?.map((event) => ({
      id: event?.id,
      title: event?.title,
      content: event?.content,
      image: event?.image,
      date: event?.date,
      time: event?.time,
      status: event?.status,
      payment_status: event?.payment_status,
      country: event?.countries
        ?.find((c) => c.translations.some((t) => t.locale === i18n.language))
        ?.translations.find((t) => t.locale === i18n.language)?.title,
      category: event?.categories
        ?.find((c) => c.translations.some((t) => t.locale === i18n.language))
        ?.translations.find((t) => t.locale === i18n.language)?.title,
      interests: event?.customers?.length,
      interestsFees: event?.customers?.map((customer) =>
        customer?.wallet.toFixed(2)
      ),
    }))
  );

  const [filteredData, setFilteredData] = useState(
    marketing?.map((event) => ({
      id: event?.id,
      title: event?.title,
      content: event?.content,
      image: event?.image,
      date: event?.date,
      time: event?.time,
      status: event?.status,
      payment_status: event?.payment_status,
      countryTitle: event?.countries
        ?.find((c) => c.translations.some((t) => t.locale === i18n.language))
        ?.translations.find((t) => t.locale === i18n.language)?.title,
      category: event?.categories
        ?.find((c) => c.translations.some((t) => t.locale === i18n.language))
        ?.translations.find((t) => t.locale === i18n.language)?.title,
      interests: event?.customers?.length,
      interestsFees: event?.customers?.map((customer) =>
        customer?.wallet.toFixed(2)
      ),
    }))
  );

  const columnDefs = [
    {
      headerName: t("ID"),
      field: "id",
    },
    {
      headerName: t("addNotify"),
      field: "stotitlereName",
      cellRenderer: (params) => (
        <div className="align-self-center d-flex gap-1  align-items-center  ">
          <img
            alt="offerImg"
            width={90}
            className=" object-fit-cover rounded"
            src={params?.data?.image}
          />
          <p>{params?.data?.title} </p>
        </div>
      ),
    },
    {
      headerName: t("startDate"),
      field: "date",
    },
    {
      headerName: t("endDate"),
      field: "date",
    },
    {
      headerName: t("aimmedCategory"),
      field: "category",
    },

    {
      headerName: t("iterrestsCount"),
      field: "interests",
    },
    {
      headerName: t("iterrestsFees"),
      field: "interestsFees",
    },

    {
      headerName: t("viewsCount"),
      field: "interests",
    },
    {
      headerName: t("actions"),
      cellRenderer: (params) => (
        <div className=" my-1 d-flex justify-content-start align-items-center gap-2">
          <button
            // onClick={() => {
            //   setEdittedItem(params.data);
            //   setShowModalEvent(true);
            // }}
            className=" btn-sm btn "
          >
            <EditIcon />
          </button>
        </div>
      ),
    },
  ];

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };
  const [showModalPending, setShowModalPending] = useState(false);
  const hideModalPendingAct = () => {
    setShowModalPending(false);
  };

  return (
    <>
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={true}
          selectCategory={false}
          selectPay={false}
        />
      </div>
      <div className="main-pending-active">
        <div className="all-data-table-shop all-table-notification all-table-pending all-table-p card-style-2 table-border--1 margin-top">
          <HeaderTableSearchFilter
            newClassHeaderContentSearch={"search-customer"}
            isTrueSearchInputFilter={true}
            functionIconSearch={() => {
              console.log("search");
            }}
            functionSearchFilter={(e) => {
              const searchValue = e.target.value.toLowerCase();

              const filteredData = rowsData.filter((row) => {
                return (
                  row.title.toString().toLowerCase().includes(searchValue) ||
                  row.content.toLowerCase().includes(searchValue)
                );
              });
              setFilteredData(filteredData);
            }}
            isTrueContentLeftHeaderSearch={true}
            InfoContentLeftSearchFilter={
              <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                <ButtonsExport
                  dataName={"pending events"}
                  dataExport={filteredData}
                />
                <div className="">
                  <button
                    onClick={showModalStepsMarketing}
                    className="btn-send btn-main"
                  >
                    {t("addNewCampaign")}{" "}
                    {/* Translation key: "addNewCampaign" */}
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
            }
          />

          <DataTable
            getRowHeight={(params) => {
              return 90;
            }}
            rowData={filteredData}
            columnDefs={columnDefs}
          />
        </div>
      </div>
    </>
  );
};

export default CurrentCampaigns;
