import PhoneIcon from "assets/Icons/PhoneIcon";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import EditIcon from "assets/Icons/EditIcon";
import TrashIcon from "assets/Icons/TrashIcon";
import "./BranchesVendor.css";
import { useTranslation } from "react-i18next";
import ModalBranchesVendor from "./ModalBranchesVendor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import axiosInstance from "./../../../../../../../axios";
import { toast } from "react-toastify";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useSavedState } from "assets/hooks";

const BranchesVendor = () => {
  const { t, i18n } = useTranslation();
  const [edittedItem, setEdittedItem] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [branches, setBranches, clearCustomerData] = useSavedState(
    [],
    `BranchesVendorData`
  );

  const getAllBranches = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/store/branch`);
      setBranches(data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllBranches();
  }, [refetch]);

  const [showModalBranchesVendor, setShowModalBranchesVendor] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [location, setLocation] = useState(null);
  const showModal = () => {
    setShowModalBranchesVendor(true);
  };
  const hideshowModal = () => {
    setEdittedItem(null);
    setShowModalBranchesVendor(false);
    setSelectedLocation(null);
    setLocation(null);
  };
  const handleActivation = async (item) => {
    try {
      await axiosInstance.put(`/store/branch/${item?.id}`, {
        ...item,
        is_main: item.is_main === 0 ? 1 : 0,
      });
      toast.success(
        i18n?.language === "ar" ? "تم التحديث" : "Updated Successfully"
      );
      setRefetch(!refetch);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };
  return (
    <>
      <ModalBranchesVendor
        selectedLocation={selectedLocation}
        setSelectedLocation={setSelectedLocation}
        location={location}
        setLocation={setLocation}
        refetch={refetch}
        setRefetch={setRefetch}
        edittedItem={edittedItem}
        showModalBranches={showModalBranchesVendor}
        hideModalBranches={hideshowModal}
      />

      <div className="information-tab information-branche d-flex flex-column  gap-4 w-100 ">
        <div onClick={showModal} className=" align-self-end">
          <button className="btn-send btn-main">
            {t("add")} <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>

        {loading && branches.length === 0 ? (
          <div className="w-100 bg-white">
            <Loading />
          </div>
        ) : !loading && branches.length === 0 ? (
          <>
            <NoResults />
          </>
        ) : (
          <>
            {branches.map((item) => {
              return (
                <>
                  {/* ========== START CONTENT CONATCT ========== */}
                  <div className=" shadow rounded-3 p-3 content-info d-flex justify-content-between align-items-center gap-3 ">
                    <div className="main-info d-flex flex-column gap-3">
                      <h2 className="title">
                        {i18n.language === "ar"
                          ? `فرع رقم ${item.id}`
                          : `Branch Number ${item.id}`}
                      </h2>
                      <p className="info-contact-one d-flex align-items-center gap-3">
                        {item.address}
                      </p>
                      <p className="info-contact-one d-flex align-items-center gap-3">
                        <PhoneIcon />
                        {item.phone}
                      </p>
                      {/* showOnMap */}
                    </div>
                    <div className="d-flex ">
                      <div
                        onClick={() => {
                          setShowModalBranchesVendor(true);
                          setEdittedItem(item);
                        }}
                        className="mx-3 cursor-pointer-event"
                      >
                        <EditIcon />
                      </div>
                      <CheckboxSwitch
                        key={item.id}
                        id={item.id}
                        isChecked={item.is_main}
                        handleCheckboxChange={() => {
                          handleActivation(item);
                        }}
                      />
                      {/* <button className=" btn btn-sm">
                    <TrashIcon />
                  </button> */}
                    </div>
                  </div>
                  {/* ========== END CONTENT CONATCT ========== */}
                </>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default BranchesVendor;
