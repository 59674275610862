import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import HeaderDebtBankAccount from "../HeaderDebtBankAccount/HeaderDebtBankAccount";
import TableAdvertisingProfits from "../TableDebtBankAccount/TableAdvertisingProfits";
import { useTranslation } from "react-i18next";

const MarketingCampaignsTab = ({information}) => {
  const {t}=useTranslation();
  return (
    <>
      <header>
        <HeaderDebtBankAccount firstText={t("Total number of marketing campaigns")} firstNum ={information?.marketing.length} secondNum={information?.marketing_total} />
      </header>
      <main>
      {
          information?.marketing.length >0?       <TableAdvertisingProfits information={information?.marketing} />:
          <NoResults />
        }

    
      </main>
    </>
  );
};

export default MarketingCampaignsTab;
