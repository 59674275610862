import { useLottie } from "lottie-react";
import NoData from "./NoDataFound.json";
import { useTranslation } from "react-i18next";
const NoResults = () => {
  const { t } = useTranslation();
  const options = {
    animationData: NoData,
    loop: true,
    style: { width: "300px", height: "300px" },
  };
  const { View } = useLottie(options);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {View}
    </div>
  );
};

export default NoResults;
