import Footer from "../Footer/Footer";
import { useEffect, useRef, useState } from "react";
import SideBarMain from "../SideNavBar/SideBarMain";
import LayoutMain from "./LayoutMain";
import LayoutVendor from "DashboardVendor/Components/Layout/LayoutVendor";
import SideBarVendor from "DashboardVendor/Components/SidBarVendor/SidBarVendor";
import { useLocation } from "react-router-dom";
import NavBarMain from "../NavBarDashboard/NavBarMain";
import NavBarVendor from "DashboardVendor/Components/NavBarVendor/NavBarVendor";
import Pusher from "pusher-js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import audio from "../../../assets/IPhoneWhatsAppsound.mp3"
const LayoutDasboard = () => {
  const userId=useSelector(state=>state.auth.userId);
  const soundEffect =useRef()
const {t,i18n}=useTranslation();
  useEffect(() => {
    // Set background color for dashboard layout
    document.body.style.backgroundColor = "#121212";
    return () => {
      document.body.style.backgroundColor = ""; // Reset to default background color
    };
  }, []);

  // Load initial state from local storage or default to false if not found
  const [isOpen, setIsOpen] = useState(() => {
    const storedState = localStorage.getItem("sidebarOpen");
    return storedState !== null ? JSON.parse(storedState) : false;
  });

  // Load initial activeOpen state from local storage or default to false if not found
  const [activeOpen, setActiveOpen] = useState(() => {
    const storedState = localStorage.getItem("activeOpen");
    return storedState !== null ? JSON.parse(storedState) : false;
  });

  // Save state in local storage whenever isOpen changes
  useEffect(() => {
    localStorage.setItem("sidebarOpen", JSON.stringify(isOpen));
  }, [isOpen]);

  // Save state in local storage whenever activeOpen changes
  useEffect(() => {
    localStorage.setItem("activeOpen", JSON.stringify(activeOpen));
  }, [activeOpen]);

  // OPEN SIDEBAR
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    setActiveOpen(!activeOpen);
  };

  // CLOSE SIDEBAR
  const closeToggleSidebar = () => {
    if (window.innerWidth <= 991) {
      setIsOpen(false);
      setActiveOpen(false);
    }
  };
  useEffect(() => {
    console.log(userId);
    const pusher = new Pusher("bfcdc2339b6855bf0aad", {
      cluster: "eu",
    });

    const channel = pusher.subscribe("my-channel");

    channel.bind("my-event", function (data) {
     console.log(data)
    });
    channel.bind("my-event", function (data) {
      console.log(data)
     });
    channel.bind("", function (data) {
      console.log("dsad", data);
    
    });
    channel.bind_global(function (eventName, data) {
      console.log("Received update from any event:", eventName, data);
    });
    channel.bind("App\\Events\\FlutterEvent", function (data) {
  if(data.to==userId){
    toast.success(t("you got a New Message"));
    soundEffect.current.play();

  }
 
    });

    return () => {
      pusher.unsubscribe("my-channel");
      pusher.disconnect();
    };
  }, []);
  useEffect(() => {
    // Add event listener to handle window resize
    const handleResize = () => {
      if (window.innerWidth <= 991) {
        closeToggleSidebar();
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Get the current location
  const location = useLocation();

  // SidebarVendor based URL
  const showSidebarVendor = location.pathname.startsWith("/dashboardVendor");

  // Check if the browser is Firefox
  if (navigator.userAgent.indexOf("Firefox") !== -1) {
    // Add a class to the body element
    document.body.classList.add("firefox");
  }

  return (
<>
<audio ref={soundEffect} src={audio}></audio>

<div className="all-page-content d-flex">
      {showSidebarVendor ? (
        <SideBarVendor
          isOpen={isOpen}
          closeToggleSidebar={closeToggleSidebar}
          toggleSidebar={toggleSidebar}
        />
      ) : (
        <SideBarMain
          isOpen={isOpen}
          closeToggleSidebar={closeToggleSidebar}
          toggleSidebar={toggleSidebar}
        />
      )}

      <div
        className={`content-page-dashboard d-flex  flex-column w-100 ${
          activeOpen ? "activeOpen" : ""
        }`}
      >
        {showSidebarVendor ? (
          <NavBarVendor toggleSidebar={toggleSidebar} />
        ) : (
          <NavBarMain toggleSidebar={toggleSidebar} />
        )}

        <div className="px-3 pb-4">
          {showSidebarVendor ? <LayoutMain /> : <LayoutVendor />}
        </div>
        <Footer />
      </div>
    </div></>
  );
};

export default LayoutDasboard;
