import { useTranslation } from "react-i18next";
import img from "../../../../../assets/images/dashboardVendor/main/01.png";
import "./HeaderAdvertise.css";
const HeaderAdvertise = () => {
  const { t } = useTranslation();
  return (
    <div className="all-header-advertise margin-top">
      {/* =========== START ROW ============= */}
      <div className="row g-3 align-items-center  flex-wrap-reverse">
        {/* ============ START COL =========== */}
        <div className="col-12 col-md-7">
          {/* ========= START RIGHT CONTENT ADV ========= */}
          <div className="right-content-adv">
            <h2 className="title">{t("advertiseYourself")}🔥</h2>
            <div className="text-content">
              <p className="text">{t("financialOffer")}</p>
              <p className="text">{t("resultsBasedOnPayments")}</p>
              <p className="text">{t("manageYourOffer")}</p>
            </div>
          </div>
          {/* ========= END RIGHT CONTENT ADV ========= */}
        </div>
        {/* ============ END COL =========== */}

        {/* ============ START COL =========== */}
        <div className="col-12 col-md-5">
          {/* ========= START LEFT CONTENT ADV ======= */}
          <div className="left-content-adv">
            <img src={img} alt="img" className="w-100 h-100 object-fit-cover" />
          </div>
          {/* ========= END LEFT CONTENT ADV ======= */}
        </div>
        {/* ============ END COL =========== */}
      </div>
      {/* =========== END ROW ============= */}
    </div>
  );
};

export default HeaderAdvertise;
