import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import TextAreaInput from "Components/Forms/TextArea";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import * as Yup from "yup";
import axiosInstance from "./../../../../../../axios";
import FormField from "Components/Forms/FormFiled";

const ModalTermsConditions = ({
  showModal,
  hideModal,
  editItem,
  setRefetch,
  refetch,
}) => {
  const { i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    enTitle: Yup.string().required(
      i18n.language === "ar"
        ? "العنوان الانجليزي مطلوب"
        : "English Title required"
    ),
    arTitle: Yup.string().required(
      i18n.language === "ar" ? "العنوان العربي مطلوب" : "Arabic Title required"
    ),
    enContent: Yup.string().required(
      i18n.language === "ar"
        ? "المحتوي الانجليزي مطلوب"
        : "English Content required"
    ),
    arContent: Yup.string().required(
      i18n.language === "ar"
        ? "المحتوي العربي مطلوب"
        : "Arabic Content required"
    ),
  });

  const initialValues = {
    enTitle: editItem ? editItem.enTitle : "",
    arTitle: editItem ? editItem.arTitle : "",
    enContent: editItem ? editItem.enContent : "",
    arContent: editItem ? editItem.arContent : "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("en[title]", values.enTitle);
    formData.append("ar[title]", values.arTitle);
    formData.append("en[content]", values.enContent);
    formData.append("ar[content]", values.arContent);

    try {
      if (editItem) {
        await axiosInstance.put(`/admin/terms/${editItem.id}`, {
          en: {
            title: values.enTitle,
            content: values.enContent,
          },
          ar: {
            title: values.arTitle,
            content: values.arContent,
          },
        });
      } else {
        await axiosInstance.post(`/admin/terms`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          },
        });
      }
      setRefetch(!refetch);
      resetForm();
      hideModal();
      toast.success("تم الحفظ بنجاح");
    } catch (error) {
      // Handle error
    }
  };

  return (
    <CustomModal
      show={showModal}
      onHide={hideModal}
      title={i18n.language === "ar" ? "إضافة جديدة" : "A new addition"}
      newClass={"modal-currencies"}
    >
      <FormField
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <InputField
          label={i18n.language === "ar" ? "الانجليزي العنوان" : "Address"}
          name="enTitle"
          type="text"
          placeholder={i18n.language === "ar" ? "العنوان" : "Address"}
          success
        />
        <InputField
          label={i18n.language === "ar" ? "العنوان العربي" : "Arabic Title"}
          name="arTitle"
          type="text"
          placeholder={
            i18n.language === "ar" ? "العنوان العربي" : "Arabic Title"
          }
          success
        />
        <TextAreaInput
          name="enContent"
          type="text"
          label={
            i18n.language === "ar" ? "المحتوي الانجليزي" : "English Content"
          }
          placeholder={
            i18n.language === "ar" ? "المحتوي الانجليزي" : "English Content"
          }
          success
        />
        <TextAreaInput
          name="arContent"
          type="text"
          label={i18n.language === "ar" ? "المحتوي العربي" : "Arabic Content"}
          placeholder={
            i18n.language === "ar" ? "المحتوي العربي" : "Arabic Content"
          }
          success
        />

        <button type="submit" className="btn-main btn-submit w-100 mt-3">
          {i18n.language === "ar" ? "حفظ" : "Save"}
        </button>
      </FormField>
    </CustomModal>
  );
};

export default ModalTermsConditions;
