import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import PowersTab from "Dashboard/Pages/ControlDashboard/Components/TabsControlContent/PowersTab/PowersTab";
import Mangers from "../TabsControlContent/MangersTab/Mangers";
import { useTranslation } from "react-i18next";

const TabsControlDashboardVendor = () => {
  const { t } = useTranslation();
  const tabInfo = [
    {
      eventKey: "employees",
      title: t("employees"),
      tabInfo: <Mangers />,
    },
  ];
  return (
    <div className="all-tabs-items tabs-dashboard-control  margin-top position-relative">
      <TabsContentTb tabActive={"employees"}>
        {tabInfo.map((item) => {
          return (
            <Tab
              key={item.eventKey}
              eventKey={item.eventKey}
              title={item.title}
            >
              {item.tabInfo}
            </Tab>
          );
        })}
      </TabsContentTb>
    </div>
  );
};

export default TabsControlDashboardVendor;
