import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderMainPage from "./Components/MainPage/HeaderMainPage";
import TableMainPage from "./Components/MainPage/TableMainPage";
import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";

import { useEffect, useState } from "react";

import axiosInstance from "./../../../axios";
import { toast } from "react-toastify";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import { useTranslation } from "react-i18next";
import { data2 } from "DashboardVendor/Pages/ControlDashBoardVendor/Components/TabsControlContent/PowersTab/Data/DataPowers";
import StepsFormForAdmin from "Components/StepsForm/StepsFormForAdmin";

const Shops = () => {
  const [rowsData, setRowsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [number, setNumber] = useState();
  const [showModalJoin, setShowModalJoin] = useState(false);
  const hideModalJoin = () => {
    setShowModalJoin(false);
  };
  const { i18n, t } = useTranslation();
  const getShopsData = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/admin/store`, {
        headers: {
          "Accept-Language": i18n.language,
        },
      });
      setRowsData(data.stores);
      setNumber(data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getShopsData();
  }, [refetch]);

  return (
    <>
      <HelmetInfo titlePage={"المتاجر"} />
      <StepsFormForAdmin
        showModalJoin={showModalJoin}
        hideModalJoin={hideModalJoin}
      />
      <div className="d-flex justify-content-between flex-wrap">
        <BreadcrumbPage
          routPageHome="/dashboardAdmin/homedashboard"
          showMainTextPage={false}
          routePage={false}
          mainPageText={false}
          showPageTwo={true}
          textPageTwo={"المتاجر"}
        />
        <div className="d-flex gap-2 flex-wrap">
          <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
            <button
              onClick={() => setShowModalJoin(true)}
              className="btn-send btn-main"
            >
              {t("Create Store")}
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="w-100 bg-white">
          <Loading />
        </div>
      ) : rowsData?.length === 0 ? (
        <NoResults />
      ) : (
        <>
          <HeaderMainPage number={number} data={rowsData} />
          <main>
            <TableMainPage
              refetch={refetch}
              setRefetch={setRefetch}
              data={rowsData}
            />
          </main>
        </>
      )}
    </>
  );
};

export default Shops;
