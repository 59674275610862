import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React, { useEffect, useState } from "react";
import HeaderCardsMoney from "./Components/HeaderCardsMoney/HeaderCardsMoney";
import TabsMoneyTransfers from "./Components/TabsMoneyTransfers/TabsMoneyTransfers";
import axiosInstance from "../../../axios";
import { useTranslation } from "react-i18next";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const MoneyTransfers = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    axiosInstance
      .get("/admin/transfers", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res);
        setData(res.data);
        setLoading(false);
      });
  }, []);
  const refetch = () => {
    axiosInstance
      .get("/admin/transfers", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        console.log(res);
        setData(res.data);
        setLoading(false);
      });
  };
  return (
    <>
      <HelmetInfo titlePage={t("Money transfers")} />
      <BreadcrumbPage
        routPageHome="/dashboardAdmin/homedashboard"
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={t("Money transfers")}
      />

      {loading ? (
        <Loading />
      ) : data ? (
        <>
          <header>
            <HeaderCardsMoney data={data} />
          </header>
          <main>
            <TabsMoneyTransfers refetch={refetch} data={data} />
          </main>
        </>
      ) : (
        <NoResults />
      )}
    </>
  );
};

export default MoneyTransfers;
