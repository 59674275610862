import { useEffect, useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalTransfers from "Dashboard/Pages/Shops/Components/StoreInformation/TabsContentStore/DataTab/TransfersData/ModalTransfers";
import DataTable from "Components/DataTable/DataTable";
import { useTranslation } from "react-i18next";
import { faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowTrendDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment";
const TablestoreProfitsVendor = ({ walletData }) => {
  const { t, i18n } = useTranslation();
  const theadTrContent = [
    "رقم العملية",
    "التاريخ",
    "المبلغ",
    "IBAN",
    "إيصال التحويل",
    "الملاحظات",
    "حالة التحويل",
  ];

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const fittedData = walletData?.storewallet?.map((store) => ({
      id: store?.id,
      store_id: store?.store_id,
      amount: store?.amount,
      type: store?.type,
      status: store?.status,
      type_id: store?.type_id,
      content: i18n.language === "ar" ? store?.content_ar : store?.content_en,
      payment_method: store?.payment_method,
      payment_status: store?.payment_status,
      payment_details: store?.payment_details,
      created_at: moment(store?.created_at).format("DD-MM-YYY , hh:mm"),
      updated_at: moment(store?.updated_at).format("DD-MM-YYY , hh:mm"),
    }));
    setRowsData(fittedData);
    setFilteredData(fittedData);
  }, [walletData, i18n.language]);

  const columnDefs = [
    { headerName: t("ID"), field: "id", flex: 0.5 },
    { headerName: t("amount"), field: "amount", flex: 0.5 },
    { headerName: t("type"), field: "type", flex: 0.5 },
    {
      headerName: t("status"),
      field: "status",
      flex: 0.5,
      cellRenderer: (params) => (
        <div>
          {params?.data?.status === "in" ? (
            <FontAwesomeIcon
              icon={faArrowTrendUp}
              style={{ color: "#03bd00" }}
              size="xl"
            />
          ) : (
            <FontAwesomeIcon
              icon={faArrowTrendDown}
              style={{ color: "#bd0000" }}
              size="xl"
            />
          )}
        </div>
      ),
    },
    { headerName: t("content"), field: "content", flex: 2 },
    // {
    //   headerName: t("payment_status"),
    //   field: "payment_status",
    //   flex: 1,
    //   cellRenderer: (params) => (
    //     <div
    //       style={{
    //         backgroundColor:
    //           params?.data?.payment_status === 1 ? "#32a8401a" : "#a832321a",
    //         color: params?.data?.payment_status === 1 ? "#32a840" : "#9c0c19",
    //       }}
    //       className={`btn px-4 fw-semibold`}
    //     >
    //       {params?.data?.payment_status === 1 ? t("done") : t("failed")}
    //     </div>
    //   ),
    // },
    { headerName: t("created_at"), field: "created_at", flex: 1.5 },
  ];
  const showModal = () => {
    setShowModalFilter(true);
  };
  const hideModal = () => {
    setShowModalFilter(false);
  };

  return (
    <>
      <ModalTransfers
        showModalFilter={showModalFilter}
        hideModal={hideModal}
        hideModalFilter={hideModal}
        isTrueButtonsTransfers={true}
        newClass={"modal-info-vendor-wallet"}
      />

      <div className="all-data-table-shop all-table-debtBank  margin-top">
        <HeaderTableSearchFilter
          newClassHeaderContentSearch={"search-customer"}
          isTrueSearchInputFilter={true}
          functionSearchFilter={(e) => {
            const searchValue = e.target.value.toLowerCase();

            const filteredData = rowsData.filter((row) => {
              return row.type.toLowerCase().includes(searchValue);
            });
            setFilteredData(filteredData);
          }}
          functionIconSearch={() => {
            console.log("search");
          }}
          isTrueFilterButton={true}
          functionButtonFilter={showModal}
          isTrueHighestScore={false}
          functionButtonHighestScore={false}
          isNewButton={false}
          contentNewButton={false}
          isTrueContentLeftHeaderSearch={true}
          InfoContentLeftSearchFilter={
            <>
              <ButtonsExport
                dataName={"storewallet"}
                dataExport={filteredData}
              />
            </>
          }
        />
        <DataTable rowData={filteredData} columnDefs={columnDefs} />
      </div>
    </>
  );
};

export default TablestoreProfitsVendor;
