import EllipsisIcon from "assets/Icons/EllipsisIcon";
import EyeIcon2 from "assets/Icons/EyeIcon2";
import TrashIcon from "assets/Icons/TrashIcon";
import EditIcon from "assets/Icons/EditIcon";
import { Dropdown } from "react-bootstrap";

const ActionData = ({ functionShow, functionEdit, functionDelete }) => {
  return (
    <div className="drop-menu-actions">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" id="dropdown-basic">
          <div className="icon-ellips">
            <EllipsisIcon />
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#">
            <div className="menu-item" onClick={functionEdit}>
              <EditIcon /> تعديل
            </div>
          </Dropdown.Item>
          <Dropdown.Item href="#">
            <div className="menu-item" onClick={functionShow}>
              <EyeIcon2 /> عرض
            </div>
          </Dropdown.Item>
          <Dropdown.Item href="#">
            {" "}
            <div className="menu-item" onClick={functionDelete}>
              <TrashIcon />
              حذف
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ActionData;
