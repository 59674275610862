import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import { useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import EditIcon from "assets/Icons/EditIcon";
import ModalEditContent from "Dashboard/Pages/NotificationsPage/ModalEditContent";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import IconPercentage from "assets/Icons/IconPercentage";
const TableDiscountVendor = () => {
  const theadTrContent = [
    "الكودالنقاط",
    "قيمة الخصم",
    "التاريخ من",
    "التاريخ إلي",
    "الفئة المستهدفة",
    " ",
  ];

  const [data, setData] = useState([
    {
      id: 1,
      numCode: "#55985",

      numDiscount: "10",

      dateFrom: "11/11/2024",
      dateTo: "11/11/2024",
      country: "السعودية",
    },
  ]);
  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };

  // FUNCTION PAGINATION
  const handlePageClick = () => {};

  // MODAL EDIT CONTENT
  const [showModalEdit, setShowModalEdit] = useState(false);
  const showModalContentEdit = (id) => {
    setShowModalEdit(true);
  };

  const hideModalEdit = () => {
    setShowModalEdit(false);
  };

  //   CHECKBOX SWICTH

  const handleCheckboxChange = (itemId) => {
    setData((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  return (
    <>
      {/* MODAL FILTER CONTENT TABLE */}
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={true}
          selectCategory={false}
          selectPay={false}
        />
      </div>
      <ModalEditContent
        showModalEdit={showModalEdit}
        hideModalEdit={hideModalEdit}
      />
      <div className="all-data-table-shop  card-style-2 margin-top">
        <HeaderTableSearchFilter
          newClassHeaderContentSearch={"search-customer"}
          isTrueSearchInputFilter={true}
          textPlaceholder={"بحث"}
          functionSearchFilter={(e) => {
            console.log(e.target.value);
          }}
          functionIconSearch={() => {
            console.log("search");
          }}
          isTrueFilterButton={true}
          functionButtonFilter={showModal}
          isTrueHighestScore={false}
          functionButtonHighestScore={false}
          isNewButton={false}
          contentNewButton={false}
          isTrueContentLeftHeaderSearch={true}
          InfoContentLeftSearchFilter={
            <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
              <ButtonsExport dataExport={"Data Table Array"} />
              <button className="btn-send btn-main">
                إضافة كود جديد
                <IconPercentage />
              </button>
            </div>
          }
        />
        <div className="table-actions-1">
          <DataTableTwo
            theadTrContent={
              <>
                {theadTrContent.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </>
            }
            tbodyContent={
              <>
                {data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.numCode}</td>
                      <td>{item.numDiscount}</td>

                      <td>
                        <IconDate /> {item.dateFrom}
                      </td>
                      <td>
                        <IconDate /> {item.dateTo}
                      </td>
                      <td>{item.country}</td>

                      <td>
                        <div className="actions-buttons d-flex align-items-center gap-4 justify-content-center">
                          <div
                            onClick={() => {
                              showModalContentEdit(item.id);
                              console.log(item.id);
                            }}
                            className="icon-action-one icon-edit"
                          >
                            <button className="btn-main btn-main-outline">
                              {" "}
                              تعديل <EditIcon />{" "}
                            </button>
                          </div>
                          <div className="switch-tab">
                            <CheckboxSwitch
                              key={item.id}
                              id={item.id}
                              isChecked={item.isChecked}
                              handleCheckboxChange={() =>
                                handleCheckboxChange(item.id)
                              }
                              
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
          <PaginationPage itemCount={"15"} handlePageClick={handlePageClick} />
        </div>
      </div>
    </>
  );
};

export default TableDiscountVendor;
