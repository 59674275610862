import React from "react";
import ChartThree from "../ChartsHome/ChartThree";
import TopCategories from "./TopCategories";

const EndHome = ({ homeData }) => {
  
  const { cards } = homeData;
  return (
    <div className="main-end-home margin-top">
      {/* =========== START ROW =========== */}
      <div className="row g-3">
        {/* ========== START COL ========== */}
        <div className="col-12 col-md-12 col-lg-6">
     
           <ChartThree
          cards={cards}
          isTrueButton={true}
          isTrueButtonCountry={false}
        />
       
        </div>
        {/* ========== END COL ========== */}
        {/* ========== START COL ========== */}
        <div className="col-12 col-md-12 col-lg-6">
          <TopCategories />
        </div>
        {/* ========== END COL ========== */}
      </div>
      {/* =========== END ROW =========== */}
    </div>
  );
};

export default EndHome;
