import Global2Icon from "assets/Icons/Global2Icon";
import React, { useState } from "react";
import ModalClassificationCountry from "./ModalClassificationCountry";
import ModalCategory from "../ModalCategory/ModalCategory";
import { useTranslation } from "react-i18next";

const MiddleContentReports = ({data}) => {
  const {t}=useTranslation()
  // SHOW MODAL CLASSIFICTION
  const [showModalClassific, setShowModalClassific] = useState(false);
  const showModal = () => {
    setShowModalClassific(true);
  };
  const hideModal = () => {
    setShowModalClassific(false);
  };

  // SHOW MODAL CATEGORY
  const [showModalCategory1, setShowModalCategory1] = useState(false);
  const showModalCategory = () => {
    setShowModalCategory1(true);
  };
  const hideModalCategory = () => {
    setShowModalCategory1(false);
  };
  return (
    <>
      <ModalClassificationCountry
        showClassificationCountry={showModalClassific}
        hideClassificationCountry={hideModal}
      />
      <ModalCategory
        showModalCategory={showModalCategory1}
        hideShowModalCategory={hideModalCategory}
      />
      <div className="middle-content-reports margin-top card-style-2 border">
        {/* START ALL MIDDLE CONTENT */}
        <div className="all-middle-content-reports">
          <div className="info-top d-flex align-items-center gap-3 flex-wrap">
            <h2 className="title fs-6 fw-medium">{t("intersts")}</h2>
            {/* <button onClick={showModal} className="btn-main btn-main-outline">
              تصنيف حسب الدولة
              <Global2Icon />
            </button> */}
          </div>
          <div className="cards-items-choose d-flex align-items-center gap-3 flex-wrap mt-3">
            {
              data?.map((item, index) => (            <div className="button-category--1">{item?.title} ({item?.customers_count})</div>))
            }

           
          </div>
        </div>
        {/* END ALL MIDDLE CONTENT */}
      </div>
    </>
  );
};

export default MiddleContentReports;
