import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import PendingActivities from "./TabsEvents/PendingActivities";
import AcceptableActivities from "./TabsEvents/AcceptableActivities";
import FinishedActivities from "./TabsEvents/FinishedActivities";
import { useTranslation } from "react-i18next";
const TabsEventsVendor = ({ setRefetch, refetch, ads }) => {
  const { t } = useTranslation();
  const { orders } = ads;
  const tabInfo = [
    {
      eventKey: "pendingActivities",
      title: `${t("ads")} (${orders.length})`,
      tabInfo: <PendingActivities orders={orders} />,
    },
    // {
    //   eventKey: "acceptableActivities",
    //   title:
    //     i18n.language === "ar" ? "اعلانات مقبولة" : "Accepted Advertisements",
    //   tabInfo: <AcceptableActivities approved={[]} />,
    // },
    // {
    //   eventKey: "OtherPayments",
    //   title:
    //     i18n.language === "ar" ? "اعلانات منتهية" : "Finished Advertisements",
    //   tabInfo: <FinishedActivities refused={[]} />,
    // },
  ];

  return (
    <>
      <div className="all-tabs-items card-style-2 margin-top position-relative ">
        <TabsContentTb tabActive={"pendingActivities"}>
          {tabInfo.map((item) => {
            return (
              <Tab
                key={item.eventKey}
                eventKey={item.eventKey}
                title={item.title}
              >
                {item.tabInfo}
              </Tab>
            );
          })}
        </TabsContentTb>
      </div>
    </>
  );
};

export default TabsEventsVendor;
