import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import FormField from "Components/Forms/FormFiled";
import PhoneInput from "Components/Ui/PhoneInput/PhoneInput";
import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import axiosInstance from "./../../../../../../axios";
import Select from "react-select";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import { useSavedState } from "assets/hooks";

const AddManager = ({
  showModal,
  hideModal,
  setRefetch,
  refetch,
  edittedItem,
}) => {
  const { t, i18n } = useTranslation();
  const [phoneNumbr, setPhoneNumber] = useState(null);
  const [country, setCountry] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [options, setOptions, clearBranchsOtions] = useSavedState(
    [],
    "branchsOptions"
  );
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [image, setImage] = useState(null);
  useEffect(() => {
    const updateSelectedBranch = () => {
      if (edittedItem?.branch_id) {
        const selected = options.find((c) => c.value === edittedItem.branch_id);
        console.log("selected", selected);
        if (selected) {
          setSelectedBranch({
            value: selected.id,
            label: selected.label,
          });
        } else {
          setSelectedBranch(null);
        }
      } else {
        setSelectedBranch(null);
      }
    };

    const updatePhoneNumber = () => {
      if (edittedItem?.phone) {
        setPhoneNumber(edittedItem?.phone);
        setCountry({ code: edittedItem?.country_code });
      } else {
        setPhoneNumber(null);
      }
    };
    updateSelectedBranch();
    updatePhoneNumber();
  }, [edittedItem, options]);

  const validationSchema = Yup.object().shape({
    password: Yup.string().required(
      i18n.language === "ar" ? "كلمة المرور مطلوبة" : "Password is required"
    ),
    employeeName: Yup.string().required(
      i18n.language === "ar" ? "إسم الموظف مطلوب" : "Employee Name is required"
    ),
    emailAddress: Yup.string()
      .email(
        i18n.language === "ar" ? "البريد الإلكتروني غير صحيح" : "Invalid email"
      )
      .required(
        i18n.language === "ar" ? "البريد الإلكتروني مطلوب" : "Email is required"
      ),
  });

  const initialValues = {
    employeeName: edittedItem?.name ? edittedItem?.name : "",
    emailAddress: edittedItem?.email ? edittedItem?.email : "",
    password: "",
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axiosInstance.get("/store/branch");
        setOptions(
          data?.data?.map((option) => ({
            value: option.id,
            label: option.address,
          }))
        );

      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    };

    fetchData();
  }, [i18n.language]);


  const handleSubmit = async (values, { resetForm }) => {
    try {
      const data = new FormData();
      data.append("phone", phoneNumbr);
      data.append("password", values?.password);
      data.append("name", values?.employeeName);
      data.append("email", values?.emailAddress);
      data.append("image", image?.file);
      data.append("country_code", country?.countryCallingCode || country?.code);
      data.append("branch_id", selectedBranch?.value);

      const headers = {
        "Content-Type": "multipart/form-data",
        "Accept": "multipart/form-data",
      };

      const url = edittedItem
        ? `store/managerupdate/${edittedItem?.id}`
        : "store/manager";
      const method = edittedItem ? "post" : "post";

      await axiosInstance[method](url, data, { headers });

      resetForm();
      setRefetch((prevState) => !prevState);
      toast.success(t("added"));
      setImage(null);
      hideModal();
      setPhoneNumber(null);
      setSelectedBranch(null);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  return (
    <CustomModal
      show={showModal}
      onHide={hideModal}
      title={
        i18n.language === "ar" && !edittedItem
          ? "إضافة مدير"
          : i18n.language === "en" && !edittedItem
          ? "Add a Manager"
          : i18n.language === "ar" && edittedItem
          ? "تعديل"
          : "Edit"
      }
      newClass={"modal-add-employee"}
    >
      <FormField
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <InputField
          label={i18n.language === "ar" ? "إسم الموظف" : "Employee Name"}
          name="employeeName"
          type="text"
          placeholder={i18n.language === "ar" ? "إسم الموظف" : "Employee Name"}
          success
        />
        <PhoneInput
          label={i18n.language === "ar" ? "رقم الهاتف" : "Phone number"}
          type="number"
          placeholder={i18n.language === "ar" ? "رقم الهاتف" : "phone number"}
          value={phoneNumbr}
          setValue={setPhoneNumber}
          country={country}
          setCountry={setCountry}
          mandatory
        />
        <InputField
          label={i18n.language === "ar" ? "البريد الإلكتروني" : "E-mail"}
          name="emailAddress"
          type="email"
          placeholder={i18n.language === "ar" ? "البريد الإلكتروني" : "E-mail"}
          success
        />
        <div className="input-pass position-relative">
          <InputField
            label={i18n.language === "ar" ? "كلمة المرور" : "Password"}
            name="password"
            placeholder=""
            success
            value={password}
            onChange={handlePasswordChange}
            type={showPassword ? "text" : "password"}
          />

          <div className="icon-eye-button" onClick={togglePasswordVisibility}>
            {showPassword ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </div>
        </div>

        <div className="select-option mt-3">
          <label htmlFor="select-option---22" className=" form-label">
            {t("branch")}
          </label>
          <Select
            value={selectedBranch}
            options={options}
            id="select-option---22"
            placeholder={t("branch")}
            onChange={(selectedOption) => setSelectedBranch(selectedOption)}
          />
        </div>
        <div className="my-3">
          <label className="my-2" htmlFor="img">
            {" "}
            {t("employeeImg")}
          </label>
          <FileUpload allowMultiple={false} setImage={setImage} />
        </div>
        <button
          disabled={!phoneNumbr || !selectedBranch?.value}
          type="submit"
          className="btn-main btn-submit w-100 mt-4"
        >
          {i18n.language === "ar" ? "حفظ" : "Save"}
        </button>
      </FormField>
    </CustomModal>
  );
};

export default AddManager;
