const AG_GRID_LOCALE_AR = {
    // Set Filter
    selectAll: '(تحديد الكل)',
    selectAllSearchResults: '(تحديد جميع نتائج البحث)',
    addCurrentSelectionToFilter: 'إضافة التحديد الحالي إلى التصفية',
    searchOoo: 'بحث...',
    blanks: '(فارغ)',
    noMatches: 'لا توجد تطابقات',

    // Number Filter & Text Filter
    filterOoo: 'تصفية...',
    equals: 'يساوي',
    notEqual: 'لا يساوي',
    blank: 'فارغ',
    notBlank: 'ليس فارغاً',
    empty: 'اختر واحد',

    // Number Filter
    lessThan: 'أقل من',
    greaterThan: 'أكبر من',
    lessThanOrEqual: 'أقل من أو يساوي',
    greaterThanOrEqual: 'أكبر من أو يساوي',
    inRange: 'بين',
    inRangeStart: 'من',
    inRangeEnd: 'إلى',

    // Text Filter
    contains: 'يحتوي على',
    notContains: 'لا يحتوي على',
    startsWith: 'يبدأ بـ',
    endsWith: 'ينتهي بـ',

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',
    before: 'قبل',
    after: 'بعد',

    // Filter Conditions
    andCondition: 'و',
    orCondition: 'أو',

    // Filter Buttons
    applyFilter: 'تطبيق',
    resetFilter: 'إعادة تعيين',
    clearFilter: 'مسح',
    cancelFilter: 'إلغاء',

    // Filter Titles
    textFilter: 'تصفية نصي',
    numberFilter: 'تصفية أرقام',
    dateFilter: 'تصفية تاريخ',
    setFilter: 'تعيين تصفية',

    // Group Column Filter
    groupFilterSelect: 'اختر الحقل:',

    // Advanced Filter
    advancedFilterContains: 'يحتوي',
    advancedFilterNotContains: 'لا يحتوي',
    advancedFilterTextEquals: 'يساوي',
    advancedFilterTextNotEqual: 'لا يساوي',
    advancedFilterStartsWith: 'يبدأ بـ',
    advancedFilterEndsWith: 'ينتهي بـ',
    advancedFilterBlank: 'فارغ',
    advancedFilterNotBlank: 'ليس فارغاً',
    advancedFilterEquals: '=',
    advancedFilterNotEqual: '!=',
    advancedFilterGreaterThan: 'أكبر من',
    advancedFilterGreaterThanOrEqual: 'أكبر من أو يساوي',
    advancedFilterLessThan: 'أقل من',
    advancedFilterLessThanOrEqual: 'أقل من أو يساوي',
    advancedFilterTrue: 'صحيح',
    advancedFilterFalse: 'خطأ',
    advancedFilterAnd: 'و',
    advancedFilterOr: 'أو',
    advancedFilterApply: 'تطبيق',
    advancedFilterBuilder: 'بناء',
    advancedFilterValidationMissingColumn: 'العمود مفقود',
    advancedFilterValidationMissingOption: 'الخيار مفقود',
    advancedFilterValidationMissingValue: 'القيمة مفقودة',
    advancedFilterValidationInvalidColumn: 'العمود غير موجود',
    advancedFilterValidationInvalidOption: 'الخيار غير موجود',
    advancedFilterValidationMissingQuote: 'القيمة تفتقر إلى اقتباس نهائي',
    advancedFilterValidationNotANumber: 'القيمة ليست رقماً',
    advancedFilterValidationInvalidDate: 'القيمة غير تاريخ صالح',
    advancedFilterValidationMissingCondition: 'الشرط مفقود',
    advancedFilterValidationJoinOperatorMismatch: 'عمليات الإنضمام ضمن شرط يجب أن تكون متطابقة',
    advancedFilterValidationInvalidJoinOperator: 'عملية الإنضمام غير موجودة',
    advancedFilterValidationMissingEndBracket: 'القوس النهائي مفقود',
    advancedFilterValidationExtraEndBracket: 'القوس النهائي زائد',
    advancedFilterValidationMessage: 'التعبير به خطأ. ${variable} - ${variable}.',
    advancedFilterValidationMessageAtEnd: 'التعبير به خطأ. ${variable} في نهاية التعبير.',
    advancedFilterBuilderTitle: 'تصفية متقدم',
    advancedFilterBuilderApply: 'تطبيق',
    advancedFilterBuilderCancel: 'إلغاء',
    advancedFilterBuilderAddButtonTooltip: 'إضافة تصفية أو مجموعة',
    advancedFilterBuilderRemoveButtonTooltip: 'إزالة',
    advancedFilterBuilderMoveUpButtonTooltip: 'نقل لأعلى',
    advancedFilterBuilderMoveDownButtonTooltip: 'نقل لأسفل',
    advancedFilterBuilderAddJoin: 'إضافة مجموعة',
    advancedFilterBuilderAddCondition: 'إضافة تصفية',
    advancedFilterBuilderSelectColumn: 'تحديد عمود',
    advancedFilterBuilderSelectOption: 'تحديد خيار',
    advancedFilterBuilderEnterValue: 'إدخال قيمة...',
    advancedFilterBuilderValidationAlreadyApplied: 'التصفية الحالي مُطبّق بالفعل.',
    advancedFilterBuilderValidationIncomplete: 'ليست كل الشروط مكتملة.',
    advancedFilterBuilderValidationSelectColumn: 'يجب تحديد عمود.',
    advancedFilterBuilderValidationSelectOption: 'يجب تحديد خيار.',
    advancedFilterBuilderValidationEnterValue: 'يجب إدخال قيمة.',

    // Side Bar
    columns: 'الأعمدة',
    filters: 'الفلاتر',

    // columns tool panel
    pivotMode: 'وضع الدوران',
    groups: 'مجموعات الصفوف',
    rowGroupColumnsEmptyMessage: 'اسحب هنا لتعيين مجموعات الصفوف',
    values: 'القيم',
    valueColumnsEmptyMessage: 'اسحب هنا للتجميع',
    pivots: 'تسميات الأعمدة',
    pivotColumnsEmptyMessage: 'اسحب هنا لتعيين تسميات الأعمدة',

    // Header of the Default Group Column
    group: 'مجموعة',

    // Row Drag
    rowDragRow: 'صف',
    rowDragRows: 'صفوف',

    // Other
    loadingOoo: 'جار التحميل...',
    loadingError: 'خطأ',
    noRowsToShow: 'لا توجد صفوف لعرضها',
    enabled: 'مفعل',

    // Menu
    pinColumn: 'تثبيت العمود',
    pinLeft: 'تثبيت على اليسار',
    pinRight: 'تثبيت على اليمين',
    noPin: 'غير مثبت',
    valueAggregation: 'تجميع القيمة',
    noAggregation: 'لا شيء',
    autosizeThiscolumn: 'تحديد حجم هذا العمود تلقائياً',
    autosizeAllColumns: 'تحديد حجم جميع الأعمدة تلقائياً',
    groupBy: 'تجميع حسب',
    ungroupBy: 'إلغاء التجميع حسب',
    ungroupAll: 'إلغاء التجميع للكل',
    addToValues: 'إضافة ${variable} إلى القيم',
    removeFromValues: 'إزالة ${variable} من القيم',
    addToLabels: 'إضافة ${variable} إلى التسميات',
    removeFromLabels: 'إزالة ${variable} من التسميات',
    resetColumns: 'إعادة تعيين الأعمدة',
    expandAll: 'توسيع جميع مجموعات الصفوف',
    collapseAll: 'طي جميع مجموعات الصفوف',
    copy: 'نسخ',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'نسخ مع الرؤوس',
    copyWithGroupHeaders: 'نسخ مع رؤوس المجموعات',
    cut: 'قص',
    paste: 'لصق',
    ctrlV: 'Ctrl+V',
    export: 'تصدير',
    csvExport: 'تصدير CSV',
    excelExport: 'تصدير Excel',

    // Enterprise Menu Aggregation and Status Bar
    sum: 'المجموع',
    first: 'أول',
    last: 'آخر',
    min: 'الحد الأدنى',
    max: 'الحد الأقصى',
    none: 'لا شيء',
    count: 'العدد',
    avg: 'المتوسط',
    filteredRows: 'تمت التصفية',
    selectedRows: 'الصفوف المحددة',
    totalRows: 'إجمالي الصفوف',
    totalAndFilteredRows: 'الصفوف',
    more: 'المزيد',
    to: 'إلى',
    of: 'من',
    page: 'الصفحة',
    pageLastRowUnknown: '؟',
    nextPage: 'الصفحة التالية',
    lastPage: 'الصفحة الأخيرة',
    firstPage: 'الصفحة الأولى',
    previousPage: 'الصفحة السابقة',
    pageSizeSelectorLabel: 'حجم الصفحة:',
    footerTotal: 'الإجمالي',

    // Pivoting
    pivotColumnGroupTotals: 'المجموع',

    // Enterprise Menu (Charts)
    pivotChartAndPivotMode: 'تسمية المخطط ووضع الدوران',
    pivotChart: 'مخطط التسمية',
    chartRange: 'نطاق المخطط',

    columnChart: 'عمود',
    groupedColumn: 'مجمع',
    stackedColumn: 'مكدس',
    normalizedColumn: 'مكدس 100%',

    barChart: 'شريط',
    groupedBar: 'مجمع',
    stackedBar: 'مكدس',
    normalizedBar: 'مكدس 100%',

    pieChart: 'دائري',
    pie: 'دائري',
    doughnut: 'دونات',

    line: 'خط',

    xyChart: 'X Y (مبعثر)',
    scatter: 'مبعثر',
    bubble: 'فقاعة',

    areaChart: 'مساحة',
    area: 'مساحة',
    stackedArea: 'مكدس',
    normalizedArea: 'مكدس 100%',

    histogramChart: 'توزيع ترددي',
    histogramFrequency: "التردد",

    combinationChart: 'مجموعة',
    columnLineCombo: 'عمود وخط',
    AreaColumnCombo: 'مساحة وعمود',

    // Charts
    pivotChartTitle: 'مخطط التسمية',
    rangeChartTitle: 'مخطط النطاق',
    settings: 'الإعدادات',
    data: 'البيانات',
    format: 'التنسيق',
    categories: 'الفئات',
    defaultCategory: '(لا شيء)',
    series: 'السلاسل',
    xyValues: 'قيم X Y',
    paired: 'الوضع المتزوج',
    axis: 'المحور',
    navigator: 'الملاح',
    color: 'اللون',
    thickness: 'السمك',
    xType: 'نوع X',
    automatic: 'تلقائي',
    category: 'فئة',
    number: 'رقم',
    time: 'وقت',
    autoRotate: 'تدوير تلقائي',
    xRotation: 'تدوير X',
    yRotation: 'تدوير Y',
    ticks: 'علامات',
    width: 'العرض',
    height: 'الارتفاع',
    length: 'الطول',
    padding: 'الحشو',
    spacing: 'التباعد',
    chart: 'المخطط',
    title: 'العنوان',
    titlePlaceholder: 'عنوان المخطط - انقر مرتين للتحرير',
    background: 'الخلفية',
    font: 'الخط',
    top: 'أعلى',
    right: 'يمين',
    bottom: 'أسفل',
    left: 'يسار',
    labels: 'التسميات',
    size: 'الحجم',
    minSize: 'الحجم الأدنى',
    maxSize: 'الحجم الأقصى',
    legend: 'المفتاح',
    position: 'الموضع',
    markerSize: 'حجم العلامة',
    markerStroke: 'العلامة الخارجية',
    markerPadding: 'الحشوة',
    itemSpacing: 'تباعد العناصر',
    itemPaddingX: 'الحشوة X للعنصر',
    itemPaddingY: 'الحشوة Y للعنصر',
    layoutHorizontalSpacing: 'التباعد الأفقي',
    layoutVerticalSpacing: 'التباعد الرأسي',
    strokeWidth: 'عرض الخط',
    lineDash: 'خط الانقطاع',
    offset: 'الإزاحة',
    offsets: 'الإزاحات',
    tooltips: 'تلميحات',
    callout: 'دعوة',
    markers: 'العلامات',
    shadow: 'الظل',
    blur: 'الضبابية',
    xOffset: 'إزاحة X',
    yOffset: 'إزاحة Y',
    lineWidth: 'عرض الخط',
    normal: 'عادي',
    bold: 'غامق',
    italic: 'مائل',
    boldItalic: 'غامق مائل',
    predefined: 'محدد مسبقاً',
    fillOpacity: 'شفافية التعبئة',
    strokeOpacity: 'شفافية الخط',
    histogramBinCount: 'عدد الحاويات',
    columnGroup: 'عمود',
    barGroup: 'شريط',
    pieGroup: 'دائري',
    lineGroup: 'خط',
    scatterGroup: 'X Y (مبعثر)',
    areaGroup: 'مساحة',
    histogramGroup: 'توزيع ترددي',
    combinationGroup: 'مجموعة',
    groupedColumnTooltip: 'مجمع',
    stackedColumnTooltip: 'مكدس',
    normalizedColumnTooltip: 'مكدس 100%',
    groupedBarTooltip: 'مجمع',
    stackedBarTooltip: 'مكدس',
    normalizedBarTooltip: 'مكدس 100%',
    pieTooltip: 'دائري',
    doughnutTooltip: 'دونات',
    lineTooltip: 'خط',
    groupedAreaTooltip: 'مساحة',
    stackedAreaTooltip: 'مكدس',
    normalizedAreaTooltip: 'مكدس 100%',
    scatterTooltip: 'مبعثر',
    bubbleTooltip: 'فقاعة',
    histogramTooltip: 'توزيع ترددي',
    columnLineComboTooltip: 'عمود وخط',
    areaColumnComboTooltip: 'مساحة وعمود',
    customComboTooltip: 'مجموعة مخصصة',
    noDataToChart: 'لا توجد بيانات متاحة للرسم.',
    pivotChartRequiresPivotMode: 'مخطط التسمية يتطلب تمكين وضع الدوران.',
    chartSettingsToolbarTooltip: 'القائمة',
    chartLinkToolbarTooltip: 'مرتبط بالشبكة',
    chartUnlinkToolbarTooltip: 'غير مرتبط بالشبكة',
    chartDownloadToolbarTooltip: 'تنزيل المخطط',
    seriesChartType: 'نوع مخطط السلاسل',
    seriesType: 'نوع السلسلة',
    secondaryAxis: 'المحور الثانوي',

    // ARIA
    ariaAdvancedFilterBuilderItem: '${variable}. المستوى ${variable}. اضغط ENTER للتحرير.',
    ariaAdvancedFilterBuilderItemValidation: '${variable}. المستوى ${variable}. ${variable} اضغط ENTER للتحرير.',
    ariaAdvancedFilterBuilderList: 'قائمة بناء تصفية متقدم',
    ariaAdvancedFilterBuilderFilterItem: 'شرط التصفية',
    ariaAdvancedFilterBuilderGroupItem: 'مجموعة التصفية',
    ariaAdvancedFilterBuilderColumn: 'العمود',
    ariaAdvancedFilterBuilderOption: 'الخيار',
    ariaAdvancedFilterBuilderValueP: 'القيمة',
    ariaAdvancedFilterBuilderJoinOperator: 'عامل الإنضمام',
    ariaAdvancedFilterInput: 'مدخل التصفية المتقدم',
    ariaChecked: 'محدد',
    ariaColumn: 'العمود',
    ariaColumnGroup: 'مجموعة العمود',
    ariaColumnFiltered: 'العمود متصفية',
    ariaColumnSelectAll: 'تبديل تحديد جميع الأعمدة',
    ariaDateFilterInput: 'مدخل تصفية التاريخ',
    ariaFilterInput: 'مدخل التصفية',
    ariaFilterToolPanelInput: 'مدخل أداة التصفية',
    ariaGrid: 'الشبكة',
    ariaHidden: 'مخفي',
    ariaLevel: 'المستوى',
    ariaMultiColumnFilter: 'تصفية متعدد الأعمدة',
    ariaNagivationFilterText: 'التصفية ممكّن، اضغط F2 للإنتقال إلى القيمة التصفيةية',
    ariaNoFilter: 'لا يوجد تصفية',
    ariaNumberFilterInput: 'مدخل تصفية الأرقام',
    ariaNumberFilterColumns: 'أعمدة تصفية الأرقام',
    ariaOperator: 'العامل',
    ariaRadio: 'نظام الراديو',
    ariaUnchecked: 'غير محدد',
    ariaRow: 'صف',
    ariaRowCheckbox: 'اختيار الصف',
    ariaRowDeselected: 'تم إلغاء تحديد الصف',
    ariaRowSelected: 'الصف المحدد',
    ariaRowToggleSelection: 'تبديل تحديد الصف',
    ariaSearch: 'بحث',
    ariaSearchColumns: 'بحث الأعمدة',
    ariaSearchFilterValues: 'بحث قيم التصفية',
    ariaSelectFiltered: 'اختيار من العمود المتصفية',
    ariaSortAscending: ': تم الترتيب تصاعدياً، انقر للترتيب بترتيب تنازلي',
    ariaSortColumnDescending: 'تم الترتيب تنازلياً حسب العمود ${column}. أدخل لتصفية الترتيب تصاعدياً',
    ariaSortColumnAscending: 'تم الترتيب تصاعدياً حسب العمود ${column}. أدخل لتصفية الترتيب تنازلياً',
    ariaSortDescending: ': تم الترتيب تنازلياً، انقر للإلغاء الترتيب',
    ariaSortUnsort: ': لم يتم الترتيب، انقر للترتيب تصاعدياً',
    ariaTreeExpandAll: 'توسيع الكل',
    ariaTreeCollapseAll: 'طي الكل',
    ariaTreeToggleExpansion: 'تبديل التوسيع',
    ariaUserFiltered: 'تم تصفية المستخدم',
    ariaWidget: 'الودجت',
    ariaWidgetTip: 'ودجت',
};
export default AG_GRID_LOCALE_AR;