import { useRef } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { useTranslation } from "react-i18next";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

registerPlugin(FilePondPluginFileValidateType);

const FileUpload = ({ allowMultiple, setImage,accept }) => {
  const { i18n } = useTranslation();
  const filePondRef = useRef(null);

  const fileIcon = `
    <div class="d-flex justify-content-center gap-3 align-items-center" style="cursor:pointer">
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.9974 28.334V18.334L11.6641 21.6673" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15 18.334L18.3333 21.6673" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M36.6693 16.6673V25.0007C36.6693 33.334 33.3359 36.6673 25.0026 36.6673H15.0026C6.66927 36.6673 3.33594 33.334 3.33594 25.0007V15.0007C3.33594 6.66732 6.66927 3.33398 15.0026 3.33398H23.3359" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M36.6693 16.6673H30.0026C25.0026 16.6673 23.3359 15.0007 23.3359 10.0007V3.33398L36.6693 16.6673Z" stroke="#121212" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      ${
        i18n.language === "ar"
          ? `<p> ضع ملفك هنا</p>`
          : `<p>Add Your File Here</p>`
      }
    </div>
  `;
  return (
    <>
      <FilePond
        ref={filePondRef}
        allowDrop={true}
        labelIdle={fileIcon}
        allowMultiple={allowMultiple}
        name="files"
        acceptedFileTypes={accept || "files/*"}
        onupdatefiles={() => {
          const files = filePondRef.current.getFiles();
          console.log(files);
          setImage(allowMultiple ? files : files[0]);
        }}
        onremovefile={() => {
          const files = filePondRef.current.getFiles();
          if (!files.length) {
            setImage(null);
          }
        }}
      />
    </>
  );
};

export default FileUpload;
