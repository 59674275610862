import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderDiscountVendor from "./Components/HeaderDiscountVendor";
import TableDiscountVendor from "./Components/TableDiscountVendor";

const DiscountCodesPoints = () => {
  return (
    <>
      <HelmetInfo titlePage={"أكواد الخصم والنقاط"} />
      <div>
        <BreadcrumbPage
        routPageHome={"/dashboardVendor/homedashboardvendor"}
          showMainTextPage={false}
          routePage={false}
          mainPageText={false}
          showPageTwo={true}
          textPageTwo={"أكواد الخصم والنقاط"}
        />

        <header>
          <HeaderDiscountVendor />
        </header>
        <main>
          <TableDiscountVendor />
        </main>
      </div>
    </>
  );
};

export default DiscountCodesPoints;
