import { useEffect, useState } from "react";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import img from "../../../../../../assets/images/main/02.png";
import "./TabsMainMarketing.css";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useTranslation } from "react-i18next";
const CurrentCampaignsMain = ({ data }) => {
  const {t}=useTranslation()
  const theadTrContent = [
    t("Store Name"),
    t("Advertisement Message"),
    t("Campaign Date")
  ];

  //    "تاريخ الإنتهاء",
  // "الفئة المستهدفة",
  //"عدد المهتمين",
  //"التكلفة لكل مهتم",
  // "عدد المشاهدين",
  // "المبلغ المدفوع",
  // ""
  const [Rows, setRows] = useState();

  useEffect(() => {
    setRows(
      data?.marketing?.map((item) => ({
        id: 2,
        imgRes: item?.store?.image || img,
        titleRes: item?.store?.title,
        img: item?.image,
        title: item?.title,
        text: item?.content,
        dateStart: `${item?.date} ${item?.time}`,
        dateEnd: "11/11/2024",
        regio: item?.countries?.map((country) => country.title).join(" - "),
        coast: "30",
        num: "10",
        views: "30",
        money: "500",
      }))
    );
  }, [data]);

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [ActivePage, setActivePage] = useState(0);
  const showModal = () => {
    setShowModalFilter(true);
  };

  // FUNCTION PAGINATION
  const handlePageClick = (e) => {
    setActivePage(e.selected);
  };

  return (
    <>
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={true}
          selectCategory={false}
          selectPay={false}
        />
      </div>
      <div className="main-pending-active main-current-active">
        <div className="all-data-table-shop all-table-notification all-table-pending all-table-p card-style-2 table-border--1 margin-top">
          <HeaderTableSearchFilter
            newClassHeaderContentSearch={"search-customer"}
            isTrueSearchInputFilter={true}
            textPlaceholder={t("Search")}
            functionSearchFilter={(e) => {
              console.log(e.target.value);
            }}
            functionIconSearch={() => {
              console.log("search");
            }}
            isTrueFilterButton={true}
            functionButtonFilter={showModal}
            isTrueHighestScore={false}
            functionButtonHighestScore={false}
            isNewButton={false}
            contentNewButton={false}
            isTrueContentLeftHeaderSearch={true}
            InfoContentLeftSearchFilter={
              <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                <ButtonsExport dataExport={"Data Table Array"} />
              </div>
            }
          />
          <div className="table-actions-1">
            {Rows?.length > 0 ? (
              <DataTableTwo
                theadTrContent={
                  <>
                    {theadTrContent.map((item, index) => {
                      return <th key={index}>{item}</th>;
                    })}
                  </>
                }
                tbodyContent={
                  <>
                    {Rows?.slice(ActivePage * 10, ActivePage + 1 * 10).map(
                      (item) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <div className="d-flex align-items-center gap-3 text-start">
                                <img
                                  src={item.imgRes}
                                  width={"50px"}
                                  height={"50px"}
                                  className=" rounded-circle object-fit-cover"
                                  alt="img user"
                                />{" "}
                                <div className="conent-info-2">
                                  <h2 className="title fs-6 fw-medium pb-1">
                                    {item.titleRes}
                                  </h2>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center gap-3 text-start">
                                <img
                                  src={item.img}
                                  width={"85px"}
                                  height={"85px"}
                                  className="rounded-3 object-fit-cover"
                                  alt="img user"
                                />{" "}
                                <div className="conent-info-2">
                                  <h2 className="title fs-6 fw-medium pb-1">
                                    {item.title}
                                  </h2>
                                  <p className="text fs-6 fw-medium">
                                    {item.text}
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <IconDate /> {item.dateStart}
                            </td>
                            {/*<td>
                            <IconDate /> {item.dateEnd}
                      </td> */}

                            {/*    <td>{item.regio}</td>
                          <td>{item.coast}  </td>
                          <td>{item.num}</td>
                          <td>{item.views}</td>
                          <td>{item.money}  </td>
                          <td>
                            <div className="pay-info---1">معلومات الدفع</div>
                    </td> */}
                          </tr>
                        );
                      }
                    )}
                  </>
                }
              />
            ) : (
          <NoResults />
            )}
            <PaginationPage
              itemCount={Rows?.length / 10}
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentCampaignsMain;
