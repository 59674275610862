import React, { useEffect, useState } from "react";
import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormField from "Components/Forms/FormFiled";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import { toast } from "react-toastify";
import axiosInstance from "./../../../../../axios";
import axios from "axios";

const ModalAddContent = ({
  showModalInter,
  hideModalInter,
  addCategory,
  editItem,
  itemsCard,
  setItemsCard,
  refetch,
  setRefetch,
  getInterests,
}) => {
  const { i18n } = useTranslation();
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);

  const { t } = useTranslation();

  // useEffect(()=>{
  //   console.log(image.file)
  // },[image])
  const [categoryNames, setCategoryNames] = useState({
    ar: "",
    en: "",
  });
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);
  const [error2, setError2] = useState(false);

  const validationSchema = Yup.object().shape({
    categoryNameAr: Yup.string().required(
      i18n.language === "ar"
        ? "اسم الهدية بالعربية مطلوب"
        : " gift title (arabic) is required"
    ),
    categoryNameEn: Yup.string().required(
      i18n.language === "ar"
        ? "اسم الهدية (انجليزي) مطلوب"
        : "gift title (English) is required"
    ),
    points: Yup.number().required(t("Points is Required")),
  });

  const initialValues = {
    categoryNameAr: editItem && editItem.ar_title ? editItem.ar_title : "",
    categoryNameEn: editItem && editItem.en_title ? editItem.en_title : "",
    points: editItem && editItem.points ? editItem.points : "",
  };
  console.log(editItem);
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);

      const data = new FormData();

      // data.append("image", image?.file && image?.file);
      data.append("ar[title]", values.categoryNameAr);
      data.append("en[title]", values.categoryNameEn);

      data.append("points", values.points);
      console.log(image2, image);
if(editItem)
  {
    data.append("gift_id", editItem.id);
  }
if(!editItem)
  {
    if (!image || !image2) {
      toast.error("Please Upload Image");
      setError(true);
      setError2(true);

      return;
    }
  }
  
      if (editItem && image?.file && image2?.file) {
        data.append("ar[image]", image?.file);
        data.append("en[image]", image2?.file);
   
      } else {
        if (image && image2) {  
          data.append("ar[image]", image?.file);
          data.append("en[image]", image2?.file);
        }
      }

      if (editItem) {
        await axiosInstance.post(`/admin/giftupdate`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          },
        });
        toast.success(
          i18n.language === "ar" ? "تم التعديل بنجاح" : "Edited Successfully"
        );
      } else {
        await axiosInstance.post(`/admin/gift`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
          },
        });
        toast.success("تم الإضافة بنجاح");
        setTimeout(() => {
          getInterests();
        }, 500);
      }

      resetForm();
      hideModalInter();
      // setRefetch(!refetch);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setError(error);
    } finally {
      setLoading(false); // Set loading state to false, whether the request succeeds or fails
    }
  };

  return (
    <CustomModal
      show={showModalInter}
      onHide={hideModalInter}
      title={editItem ? t("Edit Gift") : t("Add New Gift")}
      newClass={"modal-inter"}
    >
      <FormField
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <InputField
          label={t("gift Title in Arabic")}
          name="categoryNameAr"
          type="text"
          placeholder={t("gift Title in Arabic")}
          success
        />
        <InputField
          label={t("gift Title in English")}
          name="categoryNameEn"
          type="text"
          placeholder={t("gift Title in English")}
          success
        />

        <InputField
          label={t("points")}
          name="points"
          type="text"
          placeholder={t("points")}
          success
        />

        <div className="my-2">
          <label>{t("Arabic Image")}</label>
          <FileUpload setImage={setImage} allowMultiple={false} />{" "}
        </div>
        <div className="my-2">
          <label>{t("English Image")}</label>
          <FileUpload setImage={setImage2} allowMultiple={false} />{" "}
        </div>
        <button
          disabled={loading}
          type="submit"
          className="btn-main btn-submit w-100 mt-3"
        >
          {i18n.language === "ar" ? "حفظ" : "Save"}
        </button>
      </FormField>
    </CustomModal>
  );
};

export default ModalAddContent;
