import { DataTable } from "primereact/datatable";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import "./DataTableInfo.css";
const DataTableInfo = ({
  children,
  data,
  isTruePagination,
  itemsCountPagination,
  handlePageClick
}) => {
  
  return (
    <>
      <div className="card-table-info">
        <DataTable rowGroupMode="subheader" value={data} tableStyle={{ minWidth: "50rem" }}>
          {children}
        </DataTable>
      </div>
      {isTruePagination && (
        <PaginationPage
          itemCount={itemsCountPagination}
          handlePageClick={handlePageClick}
        />
      )}
    </>
  );
};

export default DataTableInfo;
