import iconExcel from "../../../../assets/images/dashboard/iconsTable/excel.svg";
import iconPdf from "../../../../assets/images/dashboard/iconsTable/pdf.svg";
import iconCopy from "../../../../assets/images/dashboard/iconsTable/copy.svg";
import iconPrinter from "../../../../assets/images/dashboard/iconsTable/printer.svg";
import "./HeaderDataExport.css";
const HeaderDataExport = ({
  handleExportExcel,
  handleExportPDF,
  handleCopy,
  handlePrint,
  dataExport,
}) => {
  const buttonsInfo = [
    { id: 0, icon: iconExcel, onClick: handleExportExcel },
    { id: 1, icon: iconPdf, onClick: handleExportPDF },
    { id: 2, icon: iconCopy, onClick: handleCopy },
    { id: 3, icon: iconPrinter, onClick: handlePrint },
  ];

  return (
    <>
      {/* ========= START LEFT DATA ========= */}
      <div className="header-data-export d-flex align-items-center gap-3">
        {buttonsInfo.map((item) => (
          <button
            disabled={!(dataExport?.length > 0)}
            className="btn-main btn-main-outline"
            key={item.id}
            onClick={item.onClick}
          >
            <img
              src={item.icon}
              alt="icon"
              width={"24px"}
              height={"24px"}
              className="object-fit-cover"
            />
          </button>
        ))}
      </div>
      {/* ========= END LEFT DATA ========= */}
    </>
  );
};

export default HeaderDataExport;
