import { Tab } from "react-bootstrap";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import TabDay from "./TabDay";
import { useEffect, useState } from "react";
import axiosInstance from "../../../../axios";
import { useTranslation } from "react-i18next";
const TabsReports = () => {
  const {t,i18n} = useTranslation();
  const [data, setData] = useState(null);
useEffect(()=>{
axiosInstance.get("/admin/reports",{headers:{
  "Accept-Language": i18n.language,

}}).then((res)=>{


  setData(res.data)
})
},[])

  const tabInfo = [
    { eventKey: "day", title:t("Today"), tabInfo: <TabDay data={data}/>},
    // {
    //   eventKey: "month",
    //   title: "آخر شهر",
    //   tabInfo:"آخر شهر"
    // },
    // {
    //   eventKey: "Last-6-months",
    //   title: "آخر 6 شهور",
    //   tabInfo:"آخر 6 شهور"
    // },
    // {
    //   eventKey: "lastYear",
    //   title: "آخر سنة",
    //   tabInfo:"آخر سنة"
    // },
   
  ];
  return (
    <div className="all-tabs-items all-tabs-items-reports card-style-2 margin-top position-relative">
   {/* <div className="all-buttons-export"> */}
   {/* <ButtonsExport dataExport={"Data Table To Export"} />
   </div>
      <TabsContentTb tabActive={"day"}>
        {tabInfo?.map((item) => {
          return (
            <Tab
              key={item.eventKey}
              eventKey={item.eventKey}
              title={item.title}
            >
              {item.tabInfo}
            </Tab>
          );
        })}
      </TabsContentTb> */}
<TabDay data={data}/>
    </div>
  );
};

export default TabsReports;
