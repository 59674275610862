import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderMarketing from "./Components/HeaderMarketing/HeaderMarketing";
import MainMarketing from "./Components/MainMarketing/MainMarketing";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "./../../../axios";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import { useTranslation } from "react-i18next";
import { useSavedState } from "assets/hooks";

const MarketingCampaignsVendor = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [data, setData, clearCustomerData] = useSavedState(
    null,
    `MarketingVendorData`
  );

  const getAllEvents = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/store/marketing`);
      setData(data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllEvents();
  }, [refetch]);
  return (
    <>
      <HelmetInfo titlePage={t("marketingPlans")} />
      <BreadcrumbPage
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={t("marketingPlans")}
        routPageHome={"/dashboardVendor/homedashboardvendor"}
      />

      {loading && !data ? (
        <div className="w-100 bg-white">
          <Loading />
        </div>
      ) : data ? (
        <>
          <header>
            <HeaderMarketing data={data} />
          </header>
          <main>
            <MainMarketing
              setRefetch={setRefetch}
              refetch={refetch}
              data={data}
            />
          </main>
        </>
      ) : (
        <>
          <div className="w-100 bg-white">
            <NoResults />
          </div>
        </>
      )}
    </>
  );
};

export default MarketingCampaignsVendor;
