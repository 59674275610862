import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerInput.css"
import moment from "moment";
const DatePickerInput = ({
  isLabel,
  textLabel,
  htmlForLabel,
  newClass,
  idDate,
  selectedDateFunction,
  OnChangeFunction,
  dateType,
  placeholderText,
  iconFont,
  typeIconFont,
  iconImg,
  iconImgSrc,
  altImg,
  startDate
}) => {
  return (
    <>
      {/* ============ START DATA FORM ONE ============== */}
      <div className="form-one-date d-flex  align-items-center  gap-3">
        {isLabel && (
          <label htmlFor={htmlForLabel} className="form-label">
            {textLabel}
          </label>
        )}
        <div className="date-input">
          <DatePicker
            selected={selectedDateFunction}
            onChange={OnChangeFunction}
            dateFormat={dateType}
            dropdownMode="select"
            className={`custom-datepicker w-100 form-control ${newClass}`}
            placeholderText={placeholderText}
            id={idDate}
            minDate={startDate && moment().toDate()} 
          />
          <div className="icon-date">
            {iconFont && <FontAwesomeIcon icon={typeIconFont} />}
            {iconImg && <img src={iconImgSrc} alt={`${altImg}`} />}
          </div>
        </div>
      </div>
      {/* ============ END DATA FORM ONE ============== */}
    </>
  );
};

export default DatePickerInput;
