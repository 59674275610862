import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import authSlice from "./auth";
export const store = configureStore({
  reducer: {

    auth:authSlice,
  },


});

setupListeners(store.dispatch);
