// LanguageContext.js
import React, { createContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const initialDirection = i18n.language === "ar" ? "rtl" : "ltr";
  const [direction, setDirection] = useState(initialDirection);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("i18nextLng");
    if (storedLanguage && storedLanguage !== language) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
      const newDirection = storedLanguage === "ar" ? "rtl" : "ltr";
      setDirection(newDirection);
      document.documentElement.setAttribute("lang", storedLanguage);
      document.documentElement.setAttribute("dir", newDirection);
    }
  }, []);

  const toggleLanguage = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    const newDirection = selectedLanguage === "ar" ? "rtl" : "ltr";
    setDirection(newDirection);
    localStorage.setItem("i18nextLng", selectedLanguage);
    document.documentElement.setAttribute("lang", selectedLanguage);
    document.documentElement.setAttribute("dir", newDirection);
  };

  return (
    <LanguageContext.Provider value={{ language, direction, toggleLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContext;
