import "../node_modules/bootstrap/dist/css/bootstrap.rtl.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/plugin/googleFont/IBM.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import ScrollToTopButton from "Components/ButtonScroll/ButtonScroll";
import "aos/dist/aos.css";
import "react-toastify/dist/ReactToastify.css";
import { RouterProvider } from "react-router-dom";
import routers from "Routes/Routers";
import AosAnimation from "Components/AosAnimation/AosAnimation";
import ToastContainerApp from "Components/ToastContainerApp/ToastContainerApp";
import { LanguageProvider } from "Components/Languages/LanguageContext";
import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import "./ltr.css";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
function App() {
  return (
    <Provider store={store}>
      <HelmetProvider>
        <LanguageProvider>
          <AosAnimation>
            <div className="App">
              <ToastContainerApp />
              <RouterProvider router={routers} />
              <ScrollToTopButton />
            </div>
          </AosAnimation>
        </LanguageProvider>
      </HelmetProvider>
    </Provider>
  );
}

export default App;
