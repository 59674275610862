import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import IconDate from "assets/Icons/IconDate";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const LogInOutTable = ({ information }) => {
  const { t } = useTranslation();
  const theadTrContent = [t("ID"), t("login"), t("duration"), t("logout")];

  // SHOW MODAL FILTER
  const [data, setData] = useState();
  const [mainData, setMainData] = useState();
  useEffect(() => {
    const fittedData = information?.login?.map((item) => ({
      id: item?.id,
      login: moment(item.created_at).format("YYYY-MM-DD"),
      date: `${moment
        .duration(moment(item.updated_at).diff(moment(item.created_at)))
        .asMinutes()
        .toFixed(2)} ${t("minuite")}`,
      logOut: moment(item.updated_at).format("YYYY-MM-DD"),
    }));
    setData(fittedData);
    setMainData(fittedData);
  }, [information]);

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [selectedPage, setSelectdPage] = useState(0);
  const showModal = () => {
    setShowModalFilter(true);
  };

  // FUNCTION PAGINATION
  const handlePageClick = (e) => {
    setSelectdPage(e.selected);
  };
  return (
    <div className="login-out-table">
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={true}
          selectCategory={false}
          selectPay={false}
        />
      </div>

      <HeaderTableSearchFilter
        newClassHeaderContentSearch={"search-customer"}
        isTrueSearchInputFilter={false}
        isTrueContentLeftHeaderSearch={true}
        InfoContentLeftSearchFilter={
          <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
            <ButtonsExport dataExport={"Data Table Array"} />
          </div>
        }
      />
      {mainData?.length > 0 ? (
        <DataTableTwo
          theadTrContent={
            <>
              {theadTrContent.map((item) => {
                return <th>{item}</th>;
              })}
            </>
          }
          tbodyContent={
            <>
              {mainData
                ?.slice(selectedPage * 10, selectedPage + 1 * 10)
                ?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>
                        <IconDate /> {item.login}
                      </td>
                      <td>{item.date}</td>
                      <td>
                        <IconDate /> {item.logOut}
                      </td>
                    </tr>
                  );
                })}
            </>
          }
        />
      ) : (
        <NoResults />
      )}
      <PaginationPage
        itemCount={mainData?.length / 10}
        handlePageClick={handlePageClick}
      />
    </div>
  );
};

export default LogInOutTable;
