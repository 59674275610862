import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import { useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import ModalTransfers from "Dashboard/Pages/Shops/Components/StoreInformation/TabsContentStore/DataTab/TransfersData/ModalTransfers";
import IconDate from "assets/Icons/IconDate";
const MainPayment = () => {
  const theadTrContent = [
    "رقم العملية",
    "التاريخ",
    "إجمالي المبلغ",
    "طريقة الدفع",
    "حالة التحويل",
  ];

  const data = [
    {
      id: 1,
      numOp: "886565",
      date: "15/1/2024",
      pay: "7560",
      paymentMethod: "Master card xxx 22",
      status: "عملية ناجحة",
    },
    {
      id: 2,
      numOp: "886565",
      date: "15/1/2024",
      pay: "7560",
      paymentMethod: "Master card xxx 22",
      status: "عملية ناجحة",
    },
    {
      id: 3,
      numOp: "886565",
      date: "15/1/2024",
      pay: "7560",
      paymentMethod: "Master card xxx 22",
      status: "عملية ناجحة",
    },
  ];
  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };
  const hideModal = () => {
    setShowModalFilter(false);
  };

  // FUNCTION PAGINATION
  const handlePageClick = () => {};

  return (
    <>
      <ModalTransfers
        showModalFilter={showModalFilter}
        hideModal={hideModal}
        hideModalFilter={hideModal}
        isTrueButtonsTransfers={true}
        newClass={"modal-debt-bank--1"}
      />
      <div className="all-data-table-shop all-table-debtBank card-style-2 margin-top">
        <HeaderTableSearchFilter
          newClassHeaderContentSearch={"search-customer"}
          isTrueSearchInputFilter={true}
          textPlaceholder={"بحث"}
          functionSearchFilter={(e) => {
            console.log(e.target.value);
          }}
          functionIconSearch={() => {
            console.log("search");
          }}
          isTrueFilterButton={true}
          functionButtonFilter={showModal}
          isTrueHighestScore={false}
          functionButtonHighestScore={false}
          isNewButton={false}
          contentNewButton={false}
          isTrueContentLeftHeaderSearch={true}
          InfoContentLeftSearchFilter={
            <>
              <ButtonsExport dataExport={"Data Table Array"} />
            </>
          }
        />
        <div className="table-actions-1">
          <DataTableTwo
            theadTrContent={
              <>
                {theadTrContent.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </>
            }
            tbodyContent={
              <>
                {data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.numOp}</td>
                      <td>
                        <IconDate /> {item.date}
                      </td>

                      <td>{item.pay}$</td>

                      <td>{item.paymentMethod}</td>

                      <td>
                        <div className={`success-bg`}>{item.status}</div>
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
          <PaginationPage itemCount={"15"} handlePageClick={handlePageClick} />
        </div>
      </div>
    </>
  );
};

export default MainPayment;
