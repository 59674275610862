import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axiosInstance from "./../../../../../../axios";
import { toast } from "react-toastify";
import * as Yup from "yup";
import HeaderContentTab from "../HeaderContentTab";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import CustomModal from "Components/CustomModal/CustomModal";
import FormField from "Components/Forms/FormFiled";
import TextAreaInput from "Components/Forms/TextArea";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import IconEdit from "assets/Icons/IconEdit";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import InputField from "Components/Forms/InputField";
import TrashIcon from "assets/Icons/TrashIcon";

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

const BannerTab = () => {
  const { i18n, t } = useTranslation();

  const [banner, setBanner] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);

  const [showEditModal, setShowEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);
  useEffect(() => {
    getBanner();
  }, [refetch]);

  const getBanner = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/admin/slider`);
      setBanner(data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      const respone = await axiosInstance.delete(`admin/slider/${id}`);
      setRefetch(!refetch);
      toast.success(
        `${
          i18n.language === "ar" ? "تم الحذف بنجاح .." : "Deleted Successfully"
        }`,
        { theme: "dark" }
      );
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }

    setDeleteModal(false);
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    console.log(image?.file);

    formData.append("ar[image]", image?.file);
    formData.append("en[image]", image2?.file);


    try {
      await axiosInstance.post(`/admin/slider`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      hideModal();
      setRefetch(!refetch);
      toast.success(t("updated"));
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const showModal = () => {
    setShowEditModal(true);
  };

  const hideModal = () => {
    setShowEditModal(false);
  };

  return (
    <>
      <CustomModal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
        }}
        title={i18n.language === "ar" ? "حذف" : "Delete"}
        newClass={"modal-inter"}
      >
        <div className="row w-50 justify-content-around mx-auto">
          <p className="text-center">
            {i18n.language === "ar"
              ? "هل انت متاكد من الحذف ؟"
              : "Are You Sure Want To Delete"}
          </p>
          <button
            onClick={() => {
              handleDeleteItem(deletedIndex);
            }}
            className="col-md-5 my-3 btn btn-danger"
          >
            {i18n.language === "ar" ? "حذف" : "Delete"}
          </button>
          <button
            onClick={() => {
              setDeleteModal(false);
            }}
            className="col-md-5 my-3 btn btn-outline-dark"
          >
            {i18n.language === "ar" ? "الغاء" : "Cancel"}
          </button>
        </div>
      </CustomModal>
      <div className="all-banner-tab">
        {loading ? (
          <Loading />
        ) : banner ? (
          <>
            <CustomModal
              show={showEditModal}
              onHide={hideModal}
              title={i18n.language === "ar" ? "إضافة جديدة" : "A new addition"}
              newClass={"modal-currencies"}
            >
              {banner?.image && (
                <img
                  src={banner?.image}
                  className="my-2 w-100 object-fit-cover"
                  height={"200px"}
                  alt="banner"
                />
              )}
              <div className="my-3">
                <label>
                  {t("Arabic Image")}
                </label>
                <FileUpload setImage={setImage} allowMultiple={false} />
              </div>
              <div className="my-3">
                <label>
                  {t("English Image")}
                </label>
                <FileUpload setImage={setImage2} allowMultiple={false} />
              </div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="btn-main btn-submit w-100 mt-3"
              >
                {t("save")}
              </button>
            </CustomModal>

            <HeaderContentTab
              textTitle={i18n.language === "ar" ? "البانر" : "Banner"}
              isShowButton={true}
              newClassButton={"button-one-add"}
              isTrueText={true}
              functionButton={() => {
                setShowEditModal(true);
              }}
              isShowButtonProfit={false}
              functionButtonProfit={false}
              ButtonText={t("add New Banner")}
            />

            <div className="all-currencies-content">
              {banner?.length === 0 ? (
                <NoResults />
              ) : (
                banner?.map((item) => (
                  <div
                    key={item.id}
                    className="currency-card-one  d-flex  align-items-center  justify-content-between gap-3 flex-wrap mt-4"
                  >
                    <div className="right-content d-flex  gap-2">
                      <img
                        src={item?.image}
                        alt="icon"
                        width={"350px"}
                        height={"250px"}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <div className="left-actions-content d-flex  align-items-center gap-3">
                      {/* <div
                        className="icon-one--1 icon-edit  cursor-pointer-event"
                        onClick={() => showModal(item)}
                      >
                        <IconEdit />
                      </div> */}
                      <div
                        className="icon-one--1 icon-trash cursor-pointer-event"
                        onClick={() => {
                          setDeleteModal(true);
                          setDeletedIndex(item.id);
                        }}
                      >
                        <TrashIcon />
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        ) : (
          <NoResults />
        )}
      </div>
    </>
  );
};

export default BannerTab;
