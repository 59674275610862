import CustomModal from "Components/CustomModal/CustomModal";
import "./ModalFilter.css";

const ModalFilter = ({
  showModalFilter,
  hideModalFilter,
  modalFilterText,
  newClassModal,
  size,
  children,
}) => {
  return (
    <CustomModal
      size={size}
      show={showModalFilter}
      onHide={hideModalFilter}
      title={`${modalFilterText}`}
      newClass={`modal-filter-result ${newClassModal}`}
    >
      {/* ======== START ALL FILTER MODAL ========= */}
      <div className="all-modal-filter">{children}</div>
      {/* ======== END ALL FILTER MODAL ========= */}
    </CustomModal>
  );
};

export default ModalFilter;
