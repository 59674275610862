import { faCalendarDays, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  { useState } from "react";
import DatePickerInput from "../../Ui/DatePickerInput/DatePickerInput";

const FilterDate = () => {
  const [active2, setActive2] = useState(false);

  // SELECT DATE
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  return (
    <>
      {/* ======= START FILTER DATE ======== */}
      <div className="filter-date mt-4">
        <div className="info-top-modal d-flex align-items-center gap-3">
          <div className={`icon ${active2 === true ? "active" : "icon"}`}>
            <FontAwesomeIcon icon={faCheck} />
          </div>
          تصفية حسب التاريخ
        </div>

        {/* ======= START DATE FORM ========= */}
        <div className="date-form mt-3">
          {/* ======== START ROW ========= */}
          <div className="row g-3">
            {/* ========== START COL ========== */}
            <div className="col-12 col-md-12 col-lg-6">
              {/* ============ START DATA FORM ONE ============== */}
              <DatePickerInput
                isLabel={true}
                textLabel={"من"}
                htmlForLabel={"formDate1"}
                newClass={"input-date-1"}
                idDate={"formDate1"}
                selectedDateFunction={selectedDate}
                OnChangeFunction={(date) => {
                  setSelectedDate(date);
                  setActive2(true);
                }}
                dateType={"dd/MM/yyyy"}
                placeholderText={"dd/MM/yyyy"}
                iconFont={true}
                typeIconFont={faCalendarDays}
                iconImg={false}
                iconImgSrc={false}
                altImg={false}
              />

              {/* ============ END DATA FORM ONE ============== */}
            </div>
            {/* ========== END COL ========== */}
            {/* ========== START COL ========== */}
            <div className="col-12 col-md-12 col-lg-6">
              {/* ============ START DATA FORM ONE ============== */}
              <DatePickerInput
                isLabel={true}
                textLabel={"الى"}
                htmlForLabel={"formDate2"}
                newClass={"input-date-1"}
                idDate={"formDate2"}
                selectedDateFunction={selectedDate2}
                OnChangeFunction={(date) => {
                  setSelectedDate2(date);
                  setActive2(true);
                }}
                dateType={"dd/MM/yyyy"}
                placeholderText={"dd/MM/yyyy"}
                iconFont={true}
                typeIconFont={faCalendarDays}
                iconImg={false}
                iconImgSrc={false}
                altImg={false}
              />

              {/* ============ END DATA FORM ONE ============== */}
            </div>
            {/* ========== END COL ========== */}
          </div>
          {/* ======== END ROW ========= */}
        </div>
        {/* ======= END DATE FORM ========= */}
      </div>
      {/* ======= END FILTER DATE ======== */}
    </>
  );
};

export default FilterDate;
