import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormField from "Components/Forms/FormFiled";
import { toast } from "react-toastify";

const ModalDetermineProfitPercentage = ({
  showModal,
  hideModal,
  accept,
  activeId,
}) => {
  const { i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    standardProfitRatio: Yup.string().required(
      i18n.language === "ar"
        ? "تحديد نسبة الربح مطلوبة"
        : "Determine the profit percentage required"
    ),
  });

  const initialValues = {
    standardProfitRatio: "",
  };

  const handleSubmit = (values) => {
    hideModal();
    accept(activeId, values.standardProfitRatio);
    toast.success("تم تحديد نسبة الربح بنجاح .");
  };

  return (
    <CustomModal
      show={showModal}
      onHide={hideModal}
      title={
        i18n.language === "ar"
          ? "تحديد نسبة الربح مطلوبة"
          : "Determine the profit percentage required"
      }
      newClass={"modal-profit--1"}
    >
      <FormField
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <InputField
          label={
            i18n.language === "ar"
              ? "نسبة الربح لكل فرد"
              : "Percentage of profit per person"
          }
          name="standardProfitRatio"
          type="number"
          placeholder={
            i18n.language === "ar"
              ? "نسبة الربح لكل فرد |  "
              : "Percentage of profit per person"
          }
          success
        />

        <button type="submit" className="btn-main btn-submit w-100 mt-3">
          {i18n.language === "ar"
            ? "تأكيد قبول الفعالية"
            : "Confirm Acceptance Event"}
        </button>
      </FormField>
    </CustomModal>
  );
};

export default ModalDetermineProfitPercentage;
