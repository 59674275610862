import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderStoreInformation from "./HeaderStoreInformation/HeaderStoreInformation";
import MiddleContentStore from "./MiddleContentStore.jsx/MiddleContentStore";
import TabsContentStore from "./TabsContentStore/TabsContentStore";
import axiosInstance from "./../../../../../axios";
import Loading from "./../../../../Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useTranslation } from "react-i18next";
import ModalEditContent from "../ModalEditContent";

const StoreInformation = () => {
  const { id } = useParams();

  const [information, setInformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const { t, i18n } = useTranslation();
  const getInformation = async (id) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/admin/store/${id}`, {
        headers: {
          "Accept-language": "ar",
        },
      });
      setInformation(data.data);
      console.log(data.data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInformation(id);
  }, [id, refetch]);

  return (
    <>
      <HelmetInfo titlePage={"معلومات المتجر"} />
      {information ? (
        <ModalEditContent
          id={id}
          setRefetch={setRefetch}
          refetch={refetch}
          editItem={information}
          showModalEdit={showModalEdit}
          hideModalEdit={() => setShowModalEdit(false)}
        />
      ) : (
        ""
      )}
      <div className="d-flex justify-content-between flex-wrap">
        <BreadcrumbPage
          routPageHome="/dashboardAdmin/homedashboard"
          showMainTextPage={false}
          routePage={"/dashboardAdmin/shops"}
          mainPageText={"المتاجر"}
          showPageTwo={true}
          textPageTwo={"معلومات المتجر"}
        />
        <div className="d-flex gap-2 flex-wrap">
          <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
            <button
              onClick={() => setShowModalEdit(true)}
              className="btn-send btn-main"
            >
              {t("Edit Store Info")}
            </button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="w-100 bg-white rounded">
          <Loading />
        </div>
      ) : (
        <div className="all-info-store">
          {information ? (
            <>
              <HeaderStoreInformation
                refetch={refetch}
                setRefetch={setRefetch}
                information={information}
              />
              <MiddleContentStore information={information} />
              <TabsContentStore information={information} />
            </>
          ) : (
            <NoResults />
          )}
        </div>
      )}
    </>
  );
};

export default StoreInformation;
