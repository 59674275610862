import CopyIcon from 'assets/Icons/CopyIcon';
import React, { useState } from 'react'
import { toast } from 'react-toastify';

const IbanCopy = ({IbanId}) => {
    //   COPY IBAN
  const [copied, setCopied] = useState(false);

  const handleCopy = (iban) => {
    navigator.clipboard.writeText(iban);
    setCopied(true);
    toast.success(`تم نسخ ال IBAN بنجاح . ${iban}`);
  };
  return (
    <div
    className="icon-copy cursor-pointer-event"
    onClick={() => handleCopy(IbanId)}
  >
    <CopyIcon />
  </div>
  )
}

export default IbanCopy