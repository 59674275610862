import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputField from "Components/Forms/InputField";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import PhoneInput from "Components/Ui/PhoneInput/PhoneInput";

const ForgetPassword = ({ setShowOtp }) => {
  const { t, i18n } = useTranslation();
  const [showOtpFields, setShowOtpFields] = useState(1);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [country, setCountry] = useState(null);

  const OTPLength = otp.filter((e) => e).length;

  const initialValues = {
    email: "",
    otp: otp.join(""),
    password: "",
    confirmPassword: "",
    phone: "",
  };
  const tabInfo = [
    {
      eventKey: "email",
      title: t("email"),
      tabInfo: (
        <InputField
          label={t("Your Email")}
          name="email"
          type="email"
          placeholder="mail@exmaple.com"
          success
        />
      ),
    },
    {
      eventKey: "phone",
      title: t("phone"),
      tabInfo: (
        <PhoneInput
          setCountryCode={setCountry}
          label={t("Your Phone")}
          name="phone"
          autoFocus
          value={phoneNumber}
          setValue={setPhoneNumber}
          country={country}
          setCountry={setCountry}
        />
      ),
    },
  ];
  const validationSchema = Yup.lazy((values) => {
    return Yup.object().shape({
      email:
        values.showOtpFields === 1
          ? Yup.string()
              .email(t("Invalid email address"))
              .required(t("Email is required"))
          : Yup.string().email(t("Invalid email address")),

      phone:
        values.showOtpFields === 1
          ? Yup.number().required(t("Phone is required"))
          : Yup.number(),

      otp: Yup.string().when("showOtpFields", {
        is: 2,
        then: Yup.string()
          .required(t("OTP is required"))
          .length(4, t("OTP must be exactly 4 characters long")),
      }),
      password: Yup.string().when("showOtpFields", {
        is: 3,
        then: Yup.string()
          .required(t("Password is required"))
          .min(8, t("Password must be at least 8 characters long")),
      }),
      confirmPassword: Yup.string().when(["showOtpFields", "password"], {
        is: (showOtpFields, password) => showOtpFields === 3 && !!password,
        then: Yup.string()
          .required(t("Confirm Password is required"))
          .oneOf([Yup.ref("password")], t("Passwords must match")),
      }),
    });
  });

  const handleSendCode = (values) => {
    // Handle sending verification code
    setShowOtpFields(2);
  };

  const handleVerifyOtp = (values) => {
    // Handle verifying OTP
    setShowOtpFields(3);
  };

  const handleResetPassword = (values) => {
    // Handle resetting password
    setShowOtp(false);
    toast.success(t("updated"));
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              if (showOtpFields === 1) {
                handleSendCode(values);
              } else if (showOtpFields === 2) {
                handleVerifyOtp(values);
              } else if (showOtpFields === 3) {
                handleResetPassword(values);
              }
            }}
          >
            <Form>
              {showOtpFields === 1 && (
                <>
                  {/* Step 1: Enter Email and Send Verification Code */}
                  <div className="form-group">
                    <div className="all-tabs-items card-style-2 margin-top position-relative ">
                      <TabsContentTb tabActive={"email"}>
                        {tabInfo.map((item) => {
                          return (
                            <Tab
                              key={item.eventKey}
                              eventKey={item.eventKey}
                              title={item.title}
                            >
                              {item.tabInfo}
                            </Tab>
                          );
                        })}
                      </TabsContentTb>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn-main btn-submit w-100 mt-3"
                  >
                    {t("Send Verification Code")}
                  </button>
                </>
              )}
              {showOtpFields === 2 && (
                <>
                  {/* Step 2: Enter OTP and Verify */}
                  <div className="form-group">
                    <>
                      <p className="text-muted">{t("OTP")}</p>
                      <div className="d-flex justify-content-between w-75 mx-auto">
                        {Array.from({ length: 4 }).map((_, index) => (
                          <div key={index} className="w-25 mx-1">
                            <input
                              autoFocus={index === 0}
                              value={otp[index] || ""}
                              onChange={(e) => {
                                const inputValue =
                                  e.target.value.slice(-1) || "";
                                if (!isNaN(inputValue)) {
                                  setOtp((prevOtp) => {
                                    const updatedOtp = [...prevOtp];
                                    updatedOtp[index] = inputValue;
                                    return updatedOtp;
                                  });
                                  e?.target?.parentElement.nextSibling &&
                                    inputValue &&
                                    e.target.parentElement.nextSibling.firstChild.focus();
                                  !inputValue &&
                                    e.target.parentElement.previousSibling &&
                                    e.target.parentElement.previousSibling.firstChild.focus();
                                }
                              }}
                              className="form-control  text-center "
                              maxLength={1}
                            />
                          </div>
                        ))}
                      </div>
                    </>

                    <ErrorMessage
                      name="otp"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn-main btn-submit w-100 mt-3"
                  >
                    {t("Verify OTP")}
                  </button>
                </>
              )}
              {showOtpFields === 3 && (
                <>
                  {/* Step 3: Enter New Password */}
                  <div className="form-group">
                    <InputField
                      label={t("New Password")}
                      type="password"
                      name="password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <InputField
                      label={t("Confirm New Password")}
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn-main btn-submit w-100 mt-3"
                  >
                    {t("Reset Password")}
                  </button>
                </>
              )}
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
