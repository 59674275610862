import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import AcceptableActivitiesMain from "./EventsTabsInfo/AcceptableActivitiesMain";
import PendingActivitiesMain from "./EventsTabsInfo/PendingActivitiesMain";
import FinishedActivitiesMain from "./EventsTabsInfo/FinishedActivitiesMain";
import { useTranslation } from "react-i18next";
const EventsMainTab = ({ setRefetch, refetch, events }) => {
  const { i18n } = useTranslation();
  const { approved, pending, refused } = events;

  const tabInfo = [
    {
      eventKey: "pendingActivities",
      title: i18n.language === "ar" ? "فعاليات معلقة" : "Pending Activities",
      tabInfo: (
        <PendingActivitiesMain
          setRefetch={setRefetch}
          refetch={refetch}
          pending={pending}
        />
      ),
    },
    {
      eventKey: "acceptableActivities",
      title: i18n.language === "ar" ? "فعاليات مقبولة" : "Accepted Activities",
      tabInfo: <AcceptableActivitiesMain approved={approved} />,
    },
    {
      eventKey: "OtherPayments",
      title: i18n.language === "ar" ? "فعاليات مرفوضة" : "Refused Activities",
      tabInfo: <FinishedActivitiesMain refused={refused} />,
    },
  ];

  return (
    <>
      <div className="all-tabs-items card-style-2 margin-top position-relative ">
        <TabsContentTb tabActive={"pendingActivities"}>
          {tabInfo.map((item) => {
            return (
              <Tab
                key={item.eventKey}
                eventKey={item.eventKey}
                title={item.title}
              >
                {item.tabInfo}
              </Tab>
            );
          })}
        </TabsContentTb>
      </div>
    </>
  );
};

export default EventsMainTab;
