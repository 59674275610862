import React from "react";

const PersonalIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 23.5115C11.3 23.5115 10.59 23.2415 10.06 22.7115L8.34998 21.0215C7.92998 20.6015 7.35001 20.3715 6.76001 20.3715H6C3.93 20.3715 2.25 18.7015 2.25 16.6515V5.74146C2.25 3.69146 3.93 2.02148 6 2.02148H18C20.07 2.02148 21.75 3.69146 21.75 5.74146V16.6515C21.75 18.7015 20.07 20.3715 18 20.3715H17.24C16.65 20.3715 16.07 20.6115 15.65 21.0215L13.94 22.7115C13.41 23.2415 12.7 23.5115 12 23.5115ZM6 3.51147C4.76 3.51147 3.75 4.51145 3.75 5.73145V16.6415C3.75 17.8715 4.76 18.8615 6 18.8615H6.76001C7.75001 18.8615 8.70997 19.2615 9.40997 19.9515L11.12 21.6415C11.61 22.1215 12.4 22.1215 12.89 21.6415L14.6 19.9515C15.3 19.2615 16.26 18.8615 17.25 18.8615H18C19.24 18.8615 20.25 17.8615 20.25 16.6415V5.73145C20.25 4.50145 19.24 3.51147 18 3.51147H6Z"
        fill="white"
      />
      <path
        d="M12.0019 11.5115C10.3019 11.5115 8.92188 10.1315 8.92188 8.43152C8.92188 6.73152 10.3019 5.35156 12.0019 5.35156C13.7019 5.35156 15.0819 6.73152 15.0819 8.43152C15.0819 10.1315 13.7019 11.5115 12.0019 11.5115ZM12.0019 6.85156C11.1319 6.85156 10.4219 7.56152 10.4219 8.43152C10.4219 9.30152 11.1319 10.0115 12.0019 10.0115C12.8719 10.0115 13.5819 9.30152 13.5819 8.43152C13.5819 7.56152 12.8719 6.85156 12.0019 6.85156Z"
        fill="white"
      />
      <path
        d="M16 16.6721C15.8661 16.6721 15.75 16.5559 15.75 16.4221C15.75 15.5447 15.285 14.7818 14.602 14.254C13.9191 13.7263 12.9971 13.4121 12 13.4121C11.0029 13.4121 10.0809 13.7263 9.39801 14.254C8.71504 14.7818 8.25 15.5447 8.25 16.4221C8.25 16.5559 8.13386 16.6721 8 16.6721C7.86614 16.6721 7.75 16.5559 7.75 16.4221C7.75 14.5641 9.57358 12.9121 12 12.9121C14.4264 12.9121 16.25 14.5641 16.25 16.4221C16.25 16.5559 16.1339 16.6721 16 16.6721Z"
        stroke="white"
      />
    </svg>
  );
};

export default PersonalIcon;
