import React from "react";
import "./Pagination.css";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
const PaginationPage = ({itemCount,handlePageClick}) => {

const {t}=useTranslation()

  return (
    <div className="all-pagination d-flex justify-content-center  align-items-center mt-5">
      <ReactPaginate
        breakLabel="..."
        nextLabel={t("Next")}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        pageCount={itemCount}
        previousLabel={t("pervios")}
        renderOnZeroPageCount={null}
        containerClassName="pagination d-flex align-items-center gap-2"
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        nextClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextLinkClassName={"page-link"}
        breakLinkClassName={"page-link"}
        activeClassName={"active-1"}
      />
    </div>
  );
};

export default PaginationPage;
