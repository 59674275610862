import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderNotifications from "./HeaderNotifications";
import TableNotifications from "./TableNotifications";
import "./NotificationsPage.css";
import { useEffect, useState } from "react";
import axiosInstance from "../../../axios";
import { toast } from "react-toastify";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useTranslation } from "react-i18next";

const VendorNotificationsPage = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [refetch, setRefetch] = useState(false);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    axiosInstance
      .get("/admin/marketing", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [refetch]);
  return (
    <>
      <HelmetInfo titlePage={t("Notifications")} />
      <BreadcrumbPage
        routPageHome="/dashboardAdmin/homedashboard"
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={t("Notifications")}
      />
      {loading ? (
        <Loading />
      ) : data ? (
        <div className="all-notifications-page margin-top">
          <header>
            <HeaderNotifications data={data} />
          </header>
          <main>
            <TableNotifications
              refetch={refetch}
              setRefetch={setRefetch}
              data={data}
            />
          </main>
        </div>
      ) : (
        <NoResults />
      )}
    </>
  );
};

export default VendorNotificationsPage;
