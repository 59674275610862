import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const HeaderContentTab = ({
  textTitle,
  isShowButton,
  newClassButton,
  isTrueText,
  functionButton,
  isShowButtonProfit,
  functionButtonProfit,
}) => {
  const { t } = useTranslation();
  return (
    <div className="header-content-tab mb-3 d-flex  align-items-center  justify-content-between gap-3 flex-wrap ">
      <h2 className="title fs-4 fw-medium  text-dark ">{textTitle}</h2>
      {isShowButton && (
        <button
          onClick={functionButton}
          className={`btn-main ${newClassButton}`}
        >
          {t("add")}
          <FontAwesomeIcon icon={faPlus} />
        </button>
      )}
      {isShowButtonProfit && (
        <button
          onClick={functionButtonProfit}
          className={`btn-main btn-main-outline`}
        >
          إستعادة الضبط لجميع المتاجر
        </button>
      )}
    </div>
  );
};

export default HeaderContentTab;
