import React from "react";

const IconTrash = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0933 6.97268C25.9467 6.75935 23.8 6.59935 21.64 6.47935V6.46602L21.3467 4.73268C21.1467 3.50602 20.8533 1.66602 17.7333 1.66602H14.24C11.1333 1.66602 10.84 3.42602 10.6267 4.71935L10.3467 6.42602C9.10667 6.50602 7.86667 6.58602 6.62667 6.70602L3.90667 6.97268C3.34667 7.02602 2.94667 7.51935 3.00001 8.06602C3.05334 8.61268 3.53334 9.01268 4.09334 8.95935L6.81334 8.69268C13.8 7.99935 20.84 8.26602 27.9067 8.97268C27.9467 8.97268 27.9733 8.97268 28.0133 8.97268C28.52 8.97268 28.96 8.58602 29.0133 8.06602C29.0533 7.51935 28.6533 7.02602 28.0933 6.97268Z"
        fill="#1E4D6D"
      />
      <path
        d="M25.64 10.854C25.32 10.5207 24.88 10.334 24.4266 10.334H7.57328C7.11995 10.334 6.66662 10.5207 6.35995 10.854C6.05328 11.1873 5.87995 11.6407 5.90662 12.1073L6.73328 25.7873C6.87995 27.814 7.06662 30.3473 11.72 30.3473H20.28C24.9333 30.3473 25.12 27.8273 25.2666 25.7873L26.0933 12.1207C26.12 11.6407 25.9466 11.1873 25.64 10.854ZM18.2133 23.6673H13.7733C13.2266 23.6673 12.7733 23.214 12.7733 22.6673C12.7733 22.1207 13.2266 21.6673 13.7733 21.6673H18.2133C18.76 21.6673 19.2133 22.1207 19.2133 22.6673C19.2133 23.214 18.76 23.6673 18.2133 23.6673ZM19.3333 18.334H12.6666C12.12 18.334 11.6666 17.8807 11.6666 17.334C11.6666 16.7873 12.12 16.334 12.6666 16.334H19.3333C19.88 16.334 20.3333 16.7873 20.3333 17.334C20.3333 17.8807 19.88 18.334 19.3333 18.334Z"
        fill="#1E4D6D"
      />
    </svg>
  );
};

export default IconTrash;
