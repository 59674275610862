import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "Components/CustomModal/CustomModal";
import axiosInstance from "../../../../../../../axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";

const ModalEditPhotoGallery = ({
  showModalEdit,
  hideModalEdit,
  images,
  setImages,
  setRefetch,
  refetch,
}) => {
  const [editedImages, setEditedImages] = useState([...images]);
  const [Gallarey, setGallarey] = useState(null);

  const { t } = useTranslation();
  const handleSaveChanges = () => {
    // Save changes made to editedImages
    const formData = new FormData();
    formData.append(`image`, Gallarey?.file);
    console.log(Gallarey?.file);
    axiosInstance
      .post("/store/addgallery ", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        toast.success(t("Added Successfully"));
        setRefetch(!refetch);
        hideModalEdit();
      });
  };

  const handleFileChange = (id, e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      const updatedImages = editedImages.map((image) =>
        image.id === id ? { ...image, src: reader.result } : image
      );
      setEditedImages(updatedImages);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <CustomModal
      show={showModalEdit}
      onHide={hideModalEdit}
      title={"تعديل الصور "}
      newClass={"edit-galley"}
    >
      {/* ========= START PHOTO GALLERY ======== */}
      <div className="all-photo-gallery">
        <div className="row g-3">
          {/* {editedImages.map((image) => (
            <div className="col-12 col-md-4" key={image.id}>
              <img
                height={"90px"}
                className="w-100  object-fit-cover rounded-3"
                src={image.src}
                alt="edited img"
              />
            </div>
          ))} */}
          <FileUpload
            accept={"image/*"}
            allowMultiple={false}
            setImage={setGallarey}
          />{" "}
        </div>
      </div>
      {/* ========= END PHOTO GALLERY ======== */}

      <button
        disabled={!Gallarey}
        className="btn-main w-100"
        onClick={handleSaveChanges}
      >
        {t("save")}
      </button>
    </CustomModal>
  );
};

export default ModalEditPhotoGallery;
