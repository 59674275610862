import { Tab } from "react-bootstrap";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import TableDaysBottom from "./TableDaysBottom";
import { useTranslation } from "react-i18next";

const TabsTableReports = ({ reportsDats }) => {
  const { t } = useTranslation();
  const tabInfo = [
    // {
    //   eventKey: "today",
    //   title: t("today"),
    //   tabInfo: <TableDaysBottom data={reportsDats?.today_orders} />,
    // },
    {
      eventKey: "week",
      title: t("lastWeek"),
      tabInfo: <TableDaysBottom data={reportsDats?.last_week} />,
    },
    {
      eventKey: "Last-6-months",
      title: t("lastMonth"),
      tabInfo: <TableDaysBottom data={reportsDats?.last_six_month} />,
    },
  ];
  return (
    <div className="all-tabs-items all-tabs-items-reports card-style-2 margin-top position-relative">
      <div className="d-flex justify-content-between  align-items-center flex-wrap">
        <h2 className="title fs-6 fw-bold  text-dark mb-3">
          {t("lastInvoices")}
        </h2>
      </div>
      <TabsContentTb tabActive={"week"}>
        {tabInfo.map((item) => {
          return (
            <Tab
              key={item.eventKey}
              eventKey={item.eventKey}
              title={item.title}
            >
              {item.tabInfo}
            </Tab>
          );
        })}
      </TabsContentTb>
    </div>
  );
};

export default TabsTableReports;
