import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import { useEffect, useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import IconDownload from "assets/Icons/IconDownload";
import ModalTransfers from "./ModalTransfers";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import moment from "moment";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useTranslation } from "react-i18next";

const TransfersData = ( {information} ) => {
  const [TableData, setTableData] = useState([]);
  const [fitlerData, setFilterData] = useState([]);
  const [activePage, setActivePage] = useState(0);
  const {t}=useTranslation()
  const theadTrContent = [
    t("ID"),
   t("date"),
    t("amount"),
    "IBAN",
    t("Transfer receipt"),
    t("notes"),
    // "حالة التحويل",
    // "الموظف المسئول"
  ];
useEffect(() => {

  setTableData(information?.transfers?.map((item)=>{

    return  {
      id: item?.id,
      date: moment(item?.created_at).format("DD/MM/YYYY"),
      numPrice: item?.amount,
      iban: item?.iban,
      typeRes: item?.image,
      notes: item?.content || "لا يوجد",
      status: "عملية ناجحة",
      nameEmployee: "محمد إبراهيم"
    }
    
  }));


  setFilterData(information?.transfers?.map((item)=>{

    return  {
      id: item?.id,
      date: moment(item?.created_at).format("DD/MM/YYYY"),
      numPrice: item?.amount,
      iban: item?.iban,
      typeRes: item?.image,
      notes: item?.content || "لا يوجد",
      status: "عملية ناجحة",
      nameEmployee: "محمد إبراهيم"
    }
    
  }));
}, [information]);

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };
  const hideModal = () => {
    setShowModalFilter(false);
  };

  // FUNCTION PAGINATION
  const handlePageClick = (each) => {
    setActivePage(each.selected)
  };

  return (
    <>
      <ModalTransfers
        showModalFilter={showModalFilter}
        hideModal={hideModal}
        hideModalFilter={hideModal}
        isTrueButtonsTransfers={true}
        newClass={"tranfers-modal--1"}
      />
      <div className="all-data-table-shop  card-style-2 table-border--1  margin-top">
        <HeaderTableSearchFilter
          newClassHeaderContentSearch={"search-customer"}
          isTrueSearchInputFilter={true}
          textPlaceholder={"بحث"}
          functionSearchFilter={(e) => {
            console.log(e.target.value);
          }}
          functionIconSearch={() => {
            console.log("search");
          }}
          isTrueFilterButton={true}
          functionButtonFilter={showModal}
          isTrueHighestScore={false}
          functionButtonHighestScore={false}
          isNewButton={false}
          contentNewButton={false}
          isTrueContentLeftHeaderSearch={true}
          InfoContentLeftSearchFilter={
            <>
              <ButtonsExport dataExport={"Data Table To Export"} />
            </>
          }
        />
      {
          fitlerData.length >0 ? <div className="table-actions-1">
          <DataTableTwo
            theadTrContent={
              <>
                {theadTrContent.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </>
            }
            tbodyContent={
              <>
                {fitlerData.slice(activePage*10,(activePage+1)*10).map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>
                        <IconDate /> {item.date}{" "}
                      </td>

                      <td>{item.numPrice}</td>
                      <td>{item.iban}</td>
                      <td>
                        <a className="d-flex gap-2 justify-content-center" href={item.typeRes}>
                      
                         <IconDownload />
                         {t("Download Reset")}
                        </a>
                   
                      </td>
                      <td>{item.notes}</td>

                      {/* <td>
                        <div className="success-bg">{item.status}</div>
                      </td>
                      <td>{item.nameEmployee}</td> */}
                    </tr>
                  );
                })}
              </>
            }
          />
          <PaginationPage itemCount={fitlerData?.length / 10} handlePageClick={handlePageClick} />
        </div>
        : <NoResults />
      }
      </div>
    </>
  );
};

export default TransfersData;
