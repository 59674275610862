import CustomModal from "Components/CustomModal/CustomModal";

const ModalCategory = ({ showModalCategory, hideShowModalCategory }) => {
  return (
    <CustomModal
      show={showModalCategory}
      onHide={hideShowModalCategory}
      title={"تصنيف حسب الدولة"}
      newClass={"modal-category--2"}
    >
      <div className="modal-category-info">
        <div className="cards-items-choose d-flex align-items-center gap-3 flex-wrap mt-3">
          <div className="button-category--1">مطاعم (52)</div>
          <div className="button-category--1">مطاعم (52)</div>
          <div className="button-category--1">مطاعم (52)</div>
          <div className="button-category--1">مطاعم (52)</div>
          <div className="button-category--1">مطاعم (52)</div>
          <div className="button-category--1">مطاعم (52)</div>
        </div>

        <div className="buttons-bottom-11 d-flex align-items-center gap-3 mt-4">
          <button onClick={hideShowModalCategory} className="btn-main w-100">
            تم
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalCategory;
