import { useState } from "react";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import imgUser from "../../../../../../../assets/images/user/01.png";
import EyeIcon2 from "assets/Icons/EyeIcon2";
import TrashIcon from "assets/Icons/TrashIcon";
import CheckIcon from "assets/Icons/CheckIcon";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import Arrows from "assets/Icons/Arrows";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";

const ComplaintsSuggestionsTable = () => {
  const theadTrContent = [
    "رقم التذكرة",
    "الإسم",
    "الرسالة",
    "حالة التذكرة",
    "تاريخ الإرسال",
    " ",
  ];

  const data = [
    {
      id: 1,
      imgUser: imgUser,
      name: "نور مدحت",
      dataComplain: "عندي مشكلة بخصوص البطاقة ",
      typeStatus: "مفتوح",
      classTypeStatus: "bg-warning",
      date: "منذ 40 دقيقة",
    },
    {
      id: 2,
      imgUser: imgUser,
      name: "نور مدحت",
      dataComplain: "عندي مشكلة بخصوص البطاقة ",
      typeStatus: "مغلق",
      classTypeStatus: "bg-secondary",
      date: "منذ 40 دقيقة",
    },
    {
      id: 3,
      imgUser: imgUser,
      name: "نور مدحت",
      dataComplain: "عندي مشكلة بخصوص البطاقة ",
      typeStatus: "تم الحل",
      classTypeStatus: "bg-success",
      date: "منذ 40 دقيقة",
    },
  ];

  //   DATA OF ICONS
  const initialRowStates = data.map(() => ({
    isActiveEye: false,
    isActiveCheck: false,
    isActiveTrash: false,
  }));

  const [rowStates, setRowStates] = useState(initialRowStates);
  //   TOGGLE CLASS ACTIVE FOR ICON EYE
  const toggleActiveClassEye = (index) => {
    setRowStates((prevRowStates) =>
      prevRowStates.map((rowState, i) =>
        i === index
          ? { ...rowState, isActiveEye: !rowState.isActiveEye }
          : rowState
      )
    );
  };

  //   TOGGLE CLASS ACTIVE FOR ICON CHECK
  const toggleActiveClassCheck = (index) => {
    setRowStates((prevRowStates) =>
      prevRowStates.map((rowState, i) =>
        i === index
          ? { ...rowState, isActiveCheck: !rowState.isActiveCheck }
          : rowState
      )
    );
  };
  //   TOGGLE CLASS ACTIVE FOR ICON TRASH
  const toggleActiveClassTrash = (index) => {
    setRowStates((prevRowStates) =>
      prevRowStates.map((rowState, i) =>
        i === index
          ? { ...rowState, isActiveTrash: !rowState.isActiveTrash }
          : rowState
      )
    );
  };

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };

  return (
    <>
      <ModalFilterContentInfo
        setShowModalFilter={setShowModalFilter}
        showModalFilter={showModalFilter}
        selectCountry={false}
        selectCategory={false}
        selectPay={true}
      />

      <HeaderTableSearchFilter
        newClassHeaderContentSearch={"search-file-customer mb-3"}
        isTrueSearchInputFilter={true}
        textPlaceholder={"بحث"}
        functionSearchFilter={(e) => {
          console.log(e.target.value);
        }}
        functionIconSearch={() => {
          console.log("search");
        }}
        isTrueFilterButton={true}
        functionButtonFilter={showModal}
        isTrueHighestScore={false}
        functionButtonHighestScore={() => {}}
        isNewButton={true}
        contentNewButton={
          <button onClick={() => {}} className="btn-main   btn-main-outline">
            من الأحدث للأقدم
            <Arrows />
          </button>
        }
        isTrueContentLeftHeaderSearch={true}
        InfoContentLeftSearchFilter={
          <>
            <ButtonsExport dataExport={"data Of Table"} />
          </>
        }
      />
      <DataTableTwo
        theadTrContent={
          <>
            {theadTrContent.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </>
        }
        tbodyContent={
          <>
            {data.map((item, index) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>
                    <div className="d-flex align-items-center gap-3 justify-content-center ">
                      <img
                        src={item.imgUser}
                        width={"35px"}
                        height={"35px"}
                        className="rounded-circle"
                        alt="img user"
                      />{" "}
                      {item.name}
                    </div>
                  </td>
                  <td>{item.dataComplain}</td>
                  <td>
                    <div className={`badge py-2 px-3 ${item.classTypeStatus}`}>
                      {item.typeStatus}
                    </div>
                  </td>
                  <td>{item.date}</td>
                  <td>
                    <div className="all-icons-table d-flex align-items-center gap-3">
                      <div
                        className={`icon-info-1 icon-eye cursor-pointer-event ${
                          rowStates[index].isActiveEye ? "active" : ""
                        }`}
                        onClick={() => toggleActiveClassEye(index)}
                      >
                        <EyeIcon2 />
                      </div>
                      <div
                        className={`icon-info-1 icon-check cursor-pointer-event ${
                          rowStates[index].isActiveCheck ? "active" : ""
                        }`}
                        onClick={() => toggleActiveClassCheck(index)}
                      >
                        <CheckIcon />
                      </div>
                      <div
                        className={`icon-info-1 icon-trash cursor-pointer-event ${
                          rowStates[index].isActiveTrash ? "active" : ""
                        }`}
                        onClick={() => toggleActiveClassTrash(index)}
                      >
                        <TrashIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </>
        }
      />
    </>
  );
};

export default ComplaintsSuggestionsTable;
