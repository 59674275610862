import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import TableTech from "./TableTech";

const TabTechnicalSupportPage = ({data}) => {
  const tabInfo = [
    { eventKey: "all", title: "الكل", tabInfo: <TableTech  TableData={data}/> },
    {
      eventKey: "open",
      title: "مفتوح",
      tabInfo: "مفتوح"
    },
    {
      eventKey: "close",
      title: "مغلق",
      tabInfo: "مغلق"
    },
    {
      eventKey: "solved",
      title: "تم الحل",
      tabInfo: "تم الحل"
    }
  ];
  return (
    <div className="all-tabs-items card-style-2 margin-top position-relative">
      {/* <TabsContentTb tabActive={"all"}>
        {tabInfo.map((item) => {
          return (
            <Tab
              key={item.eventKey}
              eventKey={item.eventKey}
              title={item.title}
            >
              {item.tabInfo}
            </Tab>
          );
        })}
      </TabsContentTb> */}
      <TableTech  TableData={data}/>
    </div>
  );
};

export default TabTechnicalSupportPage;
