import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import TabsWalletVendor from "./Components/TabsWalletVendor/TabsWalletVendor";
import "./WalletVendor.css";
import HeaderWalletVendor from "./Components/HeaderWalletVendor/HeaderWalletVendor";
import axiosInstance from "./../../../axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import { useSavedState } from "assets/hooks";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";

const WalletVendor = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [walletData, setWalletData, clearCustomerData] = useSavedState(
    false,
    `WalletVendorData`
  );

  const getWalletData = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/store/wallet`, {
        headers: {
          "Accept-Language": i18n.language,
        },
      });
      setWalletData(data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getWalletData();
  }, [refetch]);
  return (
    <>
      <HelmetInfo titlePage={t("walletPageTitle")} />
      <div>
        <BreadcrumbPage
          routPageHome={"/dashboardVendor/homedashboardvendor"}
          showMainTextPage={false}
          routePage={false}
          mainPageText={false}
          showPageTwo={true}
          textPageTwo={t("walletPageTitle")}
        />
        {loading && !walletData ? (
          <div className="w-100 bg-white">
            <Loading />
          </div>
        ) : !loading && !walletData ? (
          <div className="w-100 bg-white">
            <NoResults />
          </div>
        ) : (
          <>
            <header>
              <HeaderWalletVendor
                setRefetch={setRefetch}
                refetch={refetch}
                walletData={walletData}
              />
            </header>
            <main>
              <TabsWalletVendor walletData={walletData} />
            </main>
          </>
        )}
      </div>
    </>
  );
};

export default WalletVendor;
