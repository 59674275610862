import DataTable from "Components/DataTable/DataTable";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import IconDate from "assets/Icons/IconDate";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OrderTable = ({ information }) => {
  const { t } = useTranslation();
  const theadTrContent = [
    t("transaction_number"),
    t("store"),
    t("store_type"),
    t("payment_method"),
    t("invoice_amount"),
    t("points_earned"),
    t("ouro_commission"),
    t("invoice_date"),
  ];
  const [maindata, setMainData] = useState();
  const { i18n } = useTranslation();
  const [data, setData] = useState();
  useEffect(() => {
    console.log(information?.customer?.orders);

    setData(
      information?.customer?.orders?.map((item) => ({
        StoreId: item?.store.id,
        id: item?.id,
        numberPay: item?.id,
        name: item?.store.title,
        category: item?.store.category.title,
        type:
          i18n.language === "en"
            ? item?.payment_method
            : item?.payment_method === "cash"
            ? "كاش"
            : "محفظة",

        amount: item?.price,
        points: `${item?.points} نقطة`,
        payment: item?.ouro,
        date: moment(item.created_at).format("YYYY-MM-DD"),
      }))
    );
    setMainData(
      information?.customer?.orders?.map((item) => ({
        StoreId: item?.store.id,

        id: item?.id,
        numberPay: item?.id,
        name: item?.store.title,
        category: item?.store.category.title,
        type:
          i18n.language === "en"
            ? item?.payment_method
            : item?.payment_method === "cash"
            ? "كاش"
            : "محفظة",

        amount: item?.price,
        points: `${item?.points} نقطة`,
        payment: item?.ouro,
        date: moment(item.created_at).format("YYYY-MM-DD"),
      }))
    );
  }, [information]);

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [selectedPage, setSelectdPage] = useState(0);
  const showModal = () => {
    setShowModalFilter(true);
  };

  // FUNCTION PAGINATION
  const handlePageClick = (e) => {
    setSelectdPage(e.selected);
  };
  return (
    <>
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={false}
          selectCategory={false}
          selectPay={true}
        />
      </div>

      <HeaderTableSearchFilter
        newClassHeaderContentSearch={"search-customer"}
        isTrueSearchInputFilter={true}
        functionSearchFilter={(e) => {
          const newData = maindata.filter((item) => {
            return item.name
              .toLowerCase()
              .includes(e.target.value.toLowerCase());
          });

          setData(newData);
          console.log(e.target.value);
        }}
        functionIconSearch={() => {
          console.log("search");
        }}
        isTrueFilterButton={true}
        functionButtonFilter={showModal}
        isTrueHighestScore={false}
        functionButtonHighestScore={false}
        isNewButton={false}
        contentNewButton={false}
        isTrueContentLeftHeaderSearch={true}
        InfoContentLeftSearchFilter={
          <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
            <ButtonsExport
              dataName={"Orders"}
              dataExport={"Data Table Array"}
            />
          </div>
        }
      />
      <DataTableTwo
        theadTrContent={
          <>
            {theadTrContent.map((item) => {
              return <th>{item}</th>;
            })}
          </>
        }
        tbodyContent={
          <>
            {data
              ?.slice(selectedPage * 10, selectedPage + 1 * 10)
              ?.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.numberPay}</td>
                    <td>
                      <Link
                        to={`/dashboardAdmin/storeInformation/${item.StoreId}`}
                      >
                        {item.name}
                      </Link>
                    </td>
                    <td>
                      <div className="success-bg">{item.category}</div>
                    </td>
                    <td>{item.type}</td>
                    <td>{item.amount}</td>
                    <td>
                      {" "}
                      <div className="success-bg">{item.points}</div>
                    </td>
                    <td>{item.payment}</td>
                    <td>
                      <IconDate /> {item.date}
                    </td>
                  </tr>
                );
              })}
          </>
        }
      />
      <PaginationPage
        itemCount={data?.length / 10}
        handlePageClick={handlePageClick}
      />
    </>
  );
};

export default OrderTable;
