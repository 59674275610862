import { useEffect, useState } from "react";
import IconEdit from "assets/Icons/IconEdit";
import TrashIcon from "assets/Icons/TrashIcon";
import HeaderContentTab from "../HeaderContentTab";
import { toast } from "react-toastify";
import ToggleIcon from "assets/Icons/ToggleIcon";
import ModalTermsConditions from "./ModalTermsConditions";
import { useTranslation } from "react-i18next";
import axiosInstance from "./../../../../../../axios";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import CustomModal from "Components/CustomModal/CustomModal";

const TermsConditionsTab = () => {
  const { i18n, t } = useTranslation();
  const [cardContent, setCardContent] = useState([]);
  const [showModalTermsCon, setShowModalTermsCon] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);

  const getTerms = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/admin/terms`);
      setCardContent(
        data?.data?.map((term) => ({
          id: term?.id,
          arContent: term?.translations[1].content,
          enContent: term?.translations[0].content,
          arTitle: term?.translations[1].title,
          enTitle: term?.translations[0].title,
        }))
      );
      console.log(data);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTerms();
  }, [refetch]);

  const showModal = (item) => {
    setEditItem(item);
    setShowModalTermsCon(true);
  };

  const hideModalTermsCon = () => {
    setShowModalTermsCon(false);
  };

  const handleDeleteItem = async (id) => {
    try {
      await axiosInstance.delete(`/admin/terms/${id}`);
      toast.success(t("updated"));
      setDeleteModal(false);
      setRefetch(!refetch);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  // Function to handle adding new content
  const handleAddNewContent = () => {
    setShowModalTermsCon(true); // Show the modal for adding new content
    setEditItem(null); // Reset editItem to null to indicate it's a new addition
  };

  return (
    <>
      <ModalTermsConditions
        showModal={showModalTermsCon}
        hideModal={hideModalTermsCon}
        editItem={editItem}
        setRefetch={setRefetch}
        refetch={refetch}
      />
      <CustomModal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
        }}
        title={i18n.language === "ar" ? "حذف" : "Delete"}
        newClass={"modal-inter"}
      >
        <div className="row w-50 justify-content-around mx-auto">
          <p className="text-center">
            {i18n.language === "ar"
              ? "هل انت متاكد من الحذف ؟"
              : "Are You Sure Want To Delete ? "}
          </p>
          <button
            onClick={() => {
              handleDeleteItem(deletedIndex);
            }}
            className="col-md-5 my-3 btn btn-danger"
          >
            {i18n.language === "ar" ? "حذف" : "Delete"}
          </button>
          <button
            onClick={() => {
              setDeleteModal(false);
            }}
            className="col-md-5 my-3 btn btn-outline-dark"
          >
            {i18n.language === "ar" ? "الغاء" : "Cancel"}
          </button>
        </div>
      </CustomModal>
      <div className="currencies-control">
        <HeaderContentTab
          textTitle={t("termsAndConditions")}
          isShowButton={true}
          newClassButton={"button-one-add"}
          isTrueText={false}
          functionButton={handleAddNewContent} // ADD NEW CONTENT
          isShowButtonProfit={false}
          functionButtonProfit={false}
        />
        <div className="all-currencies-content">
          {loading ? (
            <div className="w-100 bg-white">
              <Loading />
            </div>
          ) : cardContent?.length === 0 ? (
            <NoResults />
          ) : (
            cardContent?.map((item) => (
              <div
                key={item.id}
                className="currency-card-one  terms-card-one  d-flex   justify-content-between gap-3 flex-wrap  flex-md-nowrap  mt-4"
              >
                <div className="right-content d-flex  gap-2">
                  <div className="icon-toggle">
                    <ToggleIcon />
                  </div>
                  <div className="top-content-info d-flex flex-column  gap-2">
                    <h2 className="title">
                      {i18n.language === "ar" ? item?.arTitle : item?.enTitle}
                    </h2>
                    <p className="text lh-lg pt-2">
                      {i18n.language === "ar"
                        ? item?.arContent
                        : item?.enContent}
                    </p>
                  </div>
                </div>
                <div className="left-actions-content d-flex  gap-3">
                  <div
                    className="icon-one--1 icon-edit  cursor-pointer-event"
                    onClick={() => showModal(item)}
                  >
                    <IconEdit />
                  </div>
                  <div
                    className="icon-one--1 icon-trash cursor-pointer-event"
                    onClick={() => {
                      setDeleteModal(true);
                      setDeletedIndex(item?.id);
                    }}
                  >
                    <TrashIcon />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default TermsConditionsTab;
