import { useEffect, useState } from "react";
import ProgressBarAnim from "Dashboard/Shared/ProgressBarAnim/ProgressBarAnim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../../../assets/images/logo/logo.svg";
import { useTranslation } from "react-i18next";
// import ModalEditBusinessInformation from "./ModalEditBusinessInformation";
// import img from "../../../../../assets/images/main/03.png";
// import ModalChangeProfitPercentage from "Dashboard/Pages/Shops/Components/ModalShops/ModalChangeProfitPercentage";

const HeaderPersonalVendor = ({ profileDetails }) => {
  const { data } = profileDetails;
  const { t, i18n } = useTranslation();

  // const [showModalProfit, setShowModalProfit] = useState(false);
  // const showModalProfitButton = () => {
  //   setShowModalProfit(true);
  // };

  // const hideModalProfit = () => {
  //   setShowModalProfit(false);
  // };

  // const [percentage, setPercentage] = useState(10);

  // const handleSave = (newPercentage) => {
  //   setPercentage(newPercentage);
  // };

  const [showModalEditBuss, setEditBuss] = useState(false);
  const showEditInfo = () => {
    setEditBuss(true);
  };
  const hideModalInfo = () => {
    setEditBuss(false);
  };
  return (
    <>
      {/* <ModalChangeProfitPercentage
        showModalProfit={showModalProfit}
        hideModalProfit={hideModalProfit}
        onSave={handleSave}
      /> */}

      {/* <ModalEditBusinessInformation
        profileData={data}
        showModalInfo={showModalEditBuss}
        hideModalInfo={hideModalInfo}
      /> */}
      <header>
        <div className="header-client-file card-style-2">
          <div className="d-flex justify-content-between gap-3 flex-wrap ">
            <div className="right-content-file">
              <div className="info-content d-flex  align-items-center  gap-3">
                <ProgressBarAnim imgProgress={data?.store?.image} />
                <div className="info-text">
                  {data?.store?.title && (
                    <h2 className="name-text d-flex align-items-center gap-2">
                      {data?.store?.title}
                    </h2>
                  )}
                </div>
              </div>
              <div className="status-file d-flex align-items-center gap-3">
                {`${t("status")}: `}
                <div className="checked-switch d-flex align-items-center gap-3">
                  <p className="text">
                    {data?.store?.status ? (
                      <span
                        className={`main-text ${
                          data?.store?.status ? "text-success" : ""
                        }`}
                      >
                        {t("activated")}
                      </span>
                    ) : (
                      t("deactivated")
                    )}
                  </p>
                </div>
              </div>
              <div className="all-interests-content mt-4">
                <div className="interests-contant d-flex align-items-center flex-wrap gap-4">
                  <div className="info-data-one">
                    <h2 className="title mb-3">{t("category")}</h2>
                    <div className="content-one success-bg-border">
                      {i18n.language === "ar"
                        ? data?.store?.category?.translations[0]?.title
                        : data?.store?.category?.translations[1]?.title}
                    </div>
                  </div>
                  <div className="info-data-one">
                    <h2 className="title mb-3">{t("ouro")}</h2>
                    <div className="content-all d-flex align-items-center gap-4">
                      <div className="content-one success-bg-border">
                        {data?.store?.profit} %
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="left-info-content--002 d-flex  flex-column  justify-content-between  align-items-end ">
              <button
                onClick={showEditInfo}
                className="btn-main btn-main-outline"
              >
                {t("editAccountInfo")} <FontAwesomeIcon icon={faEdit} />
              </button>
              <div className="logo-ouro">
                <img src={logo} alt="logo" width={"80px"} height={"80px"} />
              </div>
            </div> */}
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderPersonalVendor;
