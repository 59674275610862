import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CardHero.css";
import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
const CardHero = ({
  lineBg,
  textTop,
  iconImg,
  numberInfo,
  typeNumber,
  typeNumberInfo,
  isUpPercentage,
  isDownPercentage,
  numPercentage,
  textPercentageDate,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });
  return (
    <div className="card-hero-one card-style-1">
      {/* ======== START CARD HERO TOP ======== */}
      <div className="info-card-top d-flex justify-content-between align-items-center gap-3 flex-wrap">
        <h2 className="title">
          <span className={`${lineBg} line-bg`}></span> {textTop}
        </h2>
        <div className="icon-img">
          <img
            style={{
              width: "32px",
              height: "32px",
            }}
            src={iconImg}
            alt="iconImg"
            width={"32px"}
            height={"32px"}
            className="img-icon-1 object-fit-cover"
          />
        </div>
      </div>
      {/* ======== END CARD HERO TOP ======== */}
      {/* ======== START CONTENT CARD BOTTOM ======== */}
      <div className="content-card-bottom d-flex justify-content-between align-items-center gap-3  pt-3">
        <div className="number-info d-flex align-items-center gap-1" ref={ref}>
          {typeNumber && <span>{typeNumberInfo}</span>}
          {inView && numberInfo && <CountUp end={numberInfo} duration={2} />}
        </div>
        <div className="main-per d-flex align-items-center gap-2">
          {isUpPercentage && (
            <div className="main-info-card green-color d-flex align-items-center gap-2">
              <div className="num-per">{numPercentage}</div>
              <div className="icon-arrow">
                <FontAwesomeIcon icon={faArrowUpLong} />
              </div>
            </div>
          )}
          {isDownPercentage && (
            <div className="main-info-card red-color d-flex align-items-center gap-2">
              <div className="num-per">{numPercentage}</div>
              <div className="icon-arrow">
                <FontAwesomeIcon icon={faArrowUpLong} />
              </div>
            </div>
          )}
          {textPercentageDate && (
            <div className="text-per">{textPercentageDate}% </div>
          )}
        </div>
      </div>
      {/* ======== END CONTENT CARD BOTTOM ======== */}
    </div>
  );
};

export default CardHero;
