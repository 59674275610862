import CardHero from "Dashboard/Components/Ui/CardHero/CardHero";
import iconImg from "../../../../../assets/images/dashboard/icons/people1.svg";
import iconImg2 from "../../../../../assets/images/dashboard/icons/shop.svg";
import iconImg3 from "../../../../../assets/images/dashboard/icons/money-send.svg";
import iconImg4 from "../../../../../assets/images/dashboard/icons/ranking.svg";
import { useTranslation } from "react-i18next";

const CardsHero = ({ homeData }) => {
  const { t } = useTranslation();
  const cardItem = [
    {
      id: 0,
      lineColor: "blueLine",
      textTop: t("customers"),
      iconImg: iconImg,
      numberInfo: homeData?.customers_card?.total,
      textPercentageDate: homeData?.customers_card?.percent,
      isUpPercentage: homeData?.customers_card?.class === "success",
      isDownPercentage: !(homeData?.customers_card?.class === "success"),
    },
    {
      id: 1,
      lineColor: "greenLine",
      textTop: t("stores"),
      iconImg: iconImg2,
      numberInfo: homeData?.stores_card?.total,
      textPercentageDate: homeData?.stores_card?.percent,
      isUpPercentage: homeData?.stores_card?.class === "success",
      isDownPercentage: !(homeData?.stores_card?.class === "success"),
    },
    {
      id: 2,
      lineColor: "warningLine",
      textTop: t("sales"),
      iconImg: iconImg3,
      numberInfo: homeData?.sales_card?.total,
      textPercentageDate: homeData?.sales_card?.percent,
      isUpPercentage: homeData?.sales_card?.class === "success",
      isDownPercentage: !(homeData?.sales_card?.class === "success"),
    },
    {
      id: 3,
      lineColor: "darkLine",
      textTop: t("event bookings"),
      iconImg: iconImg4,
      numberInfo: homeData?.event_orders_card?.total,
      textPercentageDate: homeData?.event_orders_card?.percent,
      isUpPercentage: homeData?.event_orders_card?.class === "success",
      isDownPercentage: !(homeData?.event_orders_card?.class === "success"),
    },
  ];
  return (
    <div className="all-cards-hero mt-4">
      {/* ========== START ROW ========= */}
      <div className="row g-3">
        {cardItem.map((item) => {
          return (
            <>
              {/* ======== START COL ========= */}
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <CardHero
                  lineBg={item.lineColor}
                  textTop={item.textTop}
                  iconImg={item.iconImg}
                  numberInfo={`${item.numberInfo}`}
                  typeNumber={item.typeNumber}
                  typeNumberInfo={"$"}
                  isUpPercentage={item.isUpPercentage}
                  isDownPercentage={item.isDownPercentage}
                  numPercentage={item.numPercentage}
                  textPercentageDate={item.textPercentageDate}
                />
              </div>
              {/* ======== END COL ========= */}
            </>
          );
        })}
      </div>
      {/* ========== END ROW ========= */}
    </div>
  );
};

export default CardsHero;
