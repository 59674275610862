import CustomModal from "Components/CustomModal/CustomModal";
import iconDownload from "../../assets/images/download/download01.gif";
import imgApp1 from "../../assets/images/apps/01.svg";
import imgApp2 from "../../assets/images/apps/02.svg";
import imgApp3 from "../../assets/images/apps/03.svg";
import "./DownloadApp.css";
import { useTranslation } from "react-i18next";

const DownloadApp = ({ showDownloadApp, hideDownloadApp }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <CustomModal
        show={showDownloadApp}
        onHide={hideDownloadApp}
        title={`${i18n.language === "ar" ? "حمل التطبيق" : "Download App"}`}
        newClass={"download-app"}
      >
        <div className="all-download-app">
          {/* ======== START ICON DOWNLOAD ========= */}
          <div className="icon-download d-flex justify-content-center  align-items-center">
            <img
              src={iconDownload}
              alt="icon download"
              className="icon-app-down"
              width={"100px"}
              height={"100px"}
            />
          </div>
          {/* ======== END ICON DOWNLOAD ========= */}
          {/* ======== START INFO DOWN ========= */}
          <div className="info-down">
            <h2 className="title">
              {i18n.language === "ar"
                ? "الرجاء تحميل التطبيق لمزيد من التفاصيل"
                : "Please download the application for more details"}
            </h2>
            <div className="apps d-flex align-items-center justify-content-center flex-wrap flex-md-nowrap  gap-3">
              <a
                href="https://play.google.com/store/apps/details?id=com.syntech.ouro"
                target="_blank"
                className="app-one"
              >
                <img
                  src={imgApp1}
                  width={"148px"}
                  height={"44px"}
                  className="object-fit-cover"
                  alt="img app"
                />
              </a>
              <a
                href="https://apps.apple.com/eg/app/ouro-app/id6502297820"
                target="_blank"
                className="app-one"
              >
                <img
                  src={imgApp2}
                  width={"148px"}
                  height={"44px"}
                  className="object-fit-cover"
                  alt="img app"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.syntech.ouro"
                target="_blank"
                className="app-one"
              >
                <img
                  src={imgApp3}
                  width={"148px"}
                  height={"44px"}
                  className="object-fit-cover"
                  alt="img app"
                />
              </a>
            </div>
          </div>
          {/* ======== END INFO DOWN ========= */}
        </div>
      </CustomModal>
    </>
  );
};

export default DownloadApp;
