import React from "react";
import HeaderDebtBankAccount from "../HeaderDebtBankAccount/HeaderDebtBankAccount";
import TableAdvertisingProfits from "../TableDebtBankAccount/TableAdvertisingProfits";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useTranslation } from "react-i18next";

const AdvertisingProfits = ({ information }) => {
  const { t } = useTranslation();

  return (
    <>
      {/* <header>
        <HeaderDebtBankAccount firstText={t("Totals Ads Number")} firstNum={information?.advertise?.length} secondNum={information?.advertise_total} />
      </header> */}
      <main>
        {information?.store_finances?.length > 0 ? (
          <TableAdvertisingProfits information={information?.store_finances} />
        ) : (
          <NoResults />
        )}
      </main>
    </>
  );
};

export default AdvertisingProfits;
