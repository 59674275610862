import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormField from "Components/Forms/FormFiled";
import { useState } from "react";
import axiosInstance from "./../../../../../../../axios";
import { toast } from "react-toastify";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
// import PasswordIcon from "assets/Icons/PasswordIcon";
// import ModalChangePassword from "./ModalChangePassword";

const ModalEditInfoVendor = ({
  showModalInfo,
  hideModalInfo,
  initialData,
  refetch,
  setRefetch,
}) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageCom, setImageCom] = useState(null);

  const validationSchema = Yup.object().shape({
    commercial_register: Yup.number().required(t("required")),
    about: Yup.string().required(t("required")),
    website: Yup.string().required(t("required")),
    arTitle: Yup.string().required(t("required")),
    enTitle: Yup.string().required(t("required")),
    email: Yup.string()
      .email(
        i18n.language === "ar" ? "البريد الإلكتروني غير صحيح" : "Invalid email"
      )
      .required(
        i18n.language === "ar" ? "البريد الإلكتروني مطلوب" : "Email is required"
      ),
      phone: Yup.string().required(t("required")),
      iban: Yup.string().required(t("required")),
  });
  const initialValues = {
    arTitle: initialData?.store?.title,
    enTitle: initialData?.store?.title,
    email: initialData?.store?.email,
    commercial_register: initialData?.store?.commercial_register,
    about: initialData?.store?.about,
    website: initialData?.store?.website,
    phone: initialData?.store?.phone,
    iban: initialData?.store?.iban,
  };

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
  
  
    formData.append("ar[title]", values?.arTitle);
    formData.append("en[title]", values?.enTitle);
    formData.append("email", values?.email);
    formData.append("commercial_register", values?.commercial_register);
    formData.append("about", values?.about);
    formData.append("website", values?.website);
    formData.append("category_id", initialData?.store?.category?.id);
    formData.append("country_id", initialData?.store?.country?.id);
    formData.append("iban", values?.iban);
    formData.append("phone", values?.phone);
    if (image && image?.file) {
      formData.append("image", image.file);
    }
    if (imageCom && imageCom?.file) {
      formData.append("commercial_image", imageCom.file);
    }
  

    try {
      setLoading(true);
      const { data } = await axiosInstance.post(`store/updatestore`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "multipart/form-data",
        },
      });
      resetForm();
      hideModalInfo();
      setRefetch(perrv=>!perrv);
      toast?.success(data?.message);
    } catch (error) {
      toast?.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // SHOW MODAL
  // const [showModalPass, setShowModalPass] = useState(false);
  // const showModalPassword = () => {
  //   setShowModalPass(true);
  // };
  // const hideModalPass = () => {
  //   setShowModalPass(false);
  // };

  return (
    <>
      {/* <ModalChangePassword
        showModalEditPass={showModalPass}
        hideModalEditPass={hideModalPass}
      /> */}
      <CustomModal
        size={"xl"}
        show={showModalInfo}
        onHide={hideModalInfo}
        title={i18n.language === "ar" ? "تعديل المعلومات" : "Edit Information"}
        newClass={"modal-info-edit--1"}
      >
        <div className="modal-info-branch-add">
          <FormField
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <div className="row">
              <div className="col-md-6">
                <InputField
                  label={t("arTitle")}
                  name="arTitle"
                  type="text"
                  placeholder={t("arTitle")}
                  success
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label={t("enTitle")}
                  name="enTitle"
                  type="text"
                  placeholder={t("enTitle")}
                  success
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label={i18n.language === "ar" ? "البريد الإلكتروني" : "Email"}
                  name="email"
                  type="email"
                  placeholder={
                    i18n.language === "ar" ? "البريد الإلكتروني" : "Email"
                  }
                  success
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label={t("Commercial Register")}
                  name="commercial_register"
                  type="number"
                  placeholder={t("Commercial Register")}
                  success
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label={t("Phone")}
                  name="phone"
                  type="number"
                  placeholder={t("Phone")}
                  success
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label={t("iban")}
                  name="iban"
                  type="number"
                  placeholder={t("iban")}
                  success
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label={t("About")}
                  name="about"
                  type="textarea"
                  placeholder={t("About")}
                  success
                />
              </div>
              <div className="col-md-6">
                <InputField
                  label={t("Website")}
                  name="website"
                  type="text"
                  placeholder={t("Website")}
                  success
                />
              </div>
            </div>
            <div className="d-flex my-3 justify-content-center align-items-end gap-2">
              <div className="w-100">
                <label className="my-2" htmlFor="img">
                  {initialData ? t("changeImg") : t("offerImg")}
                </label>
                <FileUpload setImage={setImage} allowMultiple={false} />
              </div>{" "}
              {initialData?.image && (
                <img
                  style={{
                    maxWidth: 80,
                    maxHeight: 75,
                  }}
                  className="rounded my-auto"
                  src={initialData?.store?.image}
                  alt="userImg"
                />
              )}
            </div>
            <div className="d-flex my-3 justify-content-center align-items-end gap-2">
              <div className="w-100">
                <label className="my-2" htmlFor="img">
                  {initialData ? t("Change Commireial Register") : t("Register Image")}
                </label>
                <FileUpload    accept={"image/*,application/pdf"} setImage={setImageCom} allowMultiple={false} />
              </div>{" "}
              {initialData?.commercial_image && (
                <img
                  style={{
                    maxWidth: 80,
                    maxHeight: 75,
                  }}
                  className="rounded my-auto"
                  src={initialData?.commercial_image}
                  alt="userImg"
                />
              )}
            </div>
            <button
              disabled={loading}
              type="submit"
              className="btn-main btn-submit w-100 mt-4"
            >
              {loading ? t("loading") : t("submit")}
            </button>
          </FormField>
        </div>
      </CustomModal>
    </>
  );
};

export default ModalEditInfoVendor;
