import { Helmet } from "react-helmet-async";

const HelmetInfo = ({ titlePage }) => {
  return (
    <Helmet>
      <title>{titlePage}</title>
      <script
        type="text/javascript"
        src={`https://maps.googleapis.com/maps/api/js?key=AIzaSyCsmWfu1AwP4oZvfYxpVpdPwFMXSWyQ-sI&libraries=places`}
        async
        defer
      ></script>
      <meta
        name="description"
        content="Web site created using create-react-app"
      />
      <link rel="apple-touch-icon" href="/src/assets/images/logo/logo.svg" />
      <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
      <meta charSet="utf-8" />
      {/** <!-- #keywords --> */}
      <meta name="keywords" content="Ouro KeyWords Ouro KeyWords" />
      {/** <!-- #description --> */}
      <meta
        name="description"
        content="Ouro Discription Content info Ouro Discription Content info Ouro Discription Content info"
      />
      {/**   <!-- #author --> */}
      <meta name="author" content="Ouro Website" />
      {/**<!-- Facebook Meta Tags --> */}
      <meta property="og:url" content="Ouro link url" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Ouro Title" />
      <meta property="og:description" content="undefined" />
      <meta property="og:image" content="Ouro link  Image" />

      {/**   <!-- Twitter Meta Tags --> */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="Ouro link url" />
      <meta property="twitter:url" content="Ouro link url" />
      <meta name="twitter:title" content="Ouro link url" />
      <meta name="twitter:description" content="undefined" />
      <meta name="twitter:image" content="Ouro link  Image" />
      <link
        rel="shortcut icon"
        type="image/png"
        href="/src/assets/images/logo/logo.svg"
      />
    </Helmet>
  );
};

export default HelmetInfo;
