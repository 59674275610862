import ChartTwo from "../Home/Components/ChartsHome/ChartTwo";
import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../assets/images/dashboard/iconsBox/1.svg";
import icon2 from "../../../assets/images/dashboard/iconsBox/2.svg";
import icon3 from "../../../assets/images/dashboard/iconsBox/3.svg";
import imgFalg from "../../../assets/images/dashboard/flags/sa.svg";
import { useTranslation } from "react-i18next";
const BottomContent = ({ data, chartData }) => {
  const { t, i18n } = useTranslation();
  const cardsBox = [
    {
      id: 0,
      icon: icon1,
      title: t("Highest scoring countries"),
      text:
        i18n.language == "en"
          ? data?.topcountry?.en_title
          : data?.topcountry?.ar_title,
    },
    {
      id: 1,
      icon: icon2,
      title: t("Total points"),
      text: data?.totalpoints,
    },
    {
      id: 2,
      icon: icon3,
      title: t("Total wallet balance"),
      text: data?.totalwallet.toFixed(2),
    },
  ];

  return (
    <>
      {/* ========= START ROW ========== */}
      <div className="row g-3">
        {/* ========= START COL ======== */}
        <div className="col-12 col-md-8 col-lg-6">
          {/* ======= START CHART BOTTOM ========= */}
          <div className="chart-bottom">
            <ChartTwo
              chartHeight={"100px"}
              titleHead={t("Points by location")}
              imgFalg={imgFalg}
              numCounter={"25648"}
              textBottom={"أعلى عدد نقاط من السعودية"}
              hideTopBar={true}
              countries={chartData}
            />
          </div>
          {/* ======= END CHART BOTTOM ========= */}
        </div>
        {/* ========= END COL ======== */}
        {/* ========= START COL ======= */}
        <div className="col-12 col-md-4 col-lg-6">
          {/* ======= START ALL CARDS BOTTOM ========= */}
          <div className="all-cards-bottom" style={{ height: "100%" }}>
            <div className="row g-3" style={{ height: "100%" }}>
              {cardsBox.map((item) => {
                return (
                  <div className="col-12" key={item.id}>
                    <CardBox
                      iconBox={item.icon}
                      textInfo={item.title}
                      infoContent={item.text}
                      newClass={"card-item h-100"}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {/* ======= END ALL CARDS BOTTOM ========= */}
        </div>
        {/* ========= END COL ======= */}
      </div>
      {/* ========= END ROW ========== */}
    </>
  );
};

export default BottomContent;
