import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axiosInstance from "./../../../../../../axios";
import FormField from "Components/Forms/FormFiled";

const ModalEditProfit = ({
  showModal,
  hideModal,
  selectedItem,
  handleUpdateItem,
  setRefetch,
  refetch,
}) => {
  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    standardProfitRatio: Yup.string().required(
      i18n.language === "ar" ? t("required") : "Profit rate is required"
    ),
  });

  const initialValues = {
    standardProfitRatio: selectedItem ? selectedItem : "0",
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const { data } = await axiosInstance.post("/admin/profit", {
        profit: values.standardProfitRatio,
      });
      hideModal();
      setRefetch(!refetch);
      toast.success(t("updated"));
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <CustomModal
      show={showModal}
      onHide={hideModal}
      title={
        i18n.language === "ar"
          ? "تعديل نسبة الربح"
          : "Adjusting the profit rate"
      }
      newClass={"modal-profit--1"}
    >
      <FormField
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <InputField
          label={
            i18n.language === "ar"
              ? "نسبة الربح الموحدة"
              : "Standard profit ratio"
          }
          name="standardProfitRatio"
          type="text"
          placeholder={
            i18n.language === "ar"
              ? "نسبة الربح الموحدة"
              : "Standard profit ratio"
          }
          success
        />

        <button type="submit" className="btn-main btn-submit w-100 mt-3">
          {i18n.language === "ar" ? "حفظ" : "Save"}
        </button>
      </FormField>
    </CustomModal>
  );
};

export default ModalEditProfit;
