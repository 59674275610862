import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
import Autocomplete from "react-google-autocomplete";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const options = {
  zoom: true,
};

const Map = ({ disabled, selectedLocation, location, setLocation }) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const searchRef = useRef();
  const apiKey = "AIzaSyCsmWfu1AwP4oZvfYxpVpdPwFMXSWyQ-sI";
  const [located, setLocated] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // State to control animation

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    id: "google-map-script",
  });

  useEffect(() => {
    if (location?.address) {
      if (searchRef.current) {
        searchRef.current.value = location.address;
      }
      if (document.getElementById("address")) {
        document.getElementById("address").value = location.address;
      }
    }
  }, [location]);

  // to get the location name (address)
  const geocodeLocation = useCallback((lat, lng) => {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    fetch(geocodeUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK" && data.results.length > 0) {
          const address = data.results[0].formatted_address;
          setLocation((prev) => {
            return {
              ...prev,
              address: address,
            };
          });
        } else {
        }
      })
      .catch((error) => console.log("Error:", error));
  }, []);

  // to update the location point
  const handleMapClick = useCallback(
    (e) => {
      if (disabled) {
        return;
      }
      const latLng = e.latLng;
      setIsAnimating(true);

      setLocation((prev) => {
        return {
          ...prev,
          lat: latLng.lat(),
          lng: latLng.lng(),
        };
      });
      geocodeLocation(latLng.lat(), latLng.lng());
    },
    [disabled]
  );

  // to update the map icon location
  useEffect(() => {
    if (mapRef.current && location?.lat) {
      mapRef.current.panTo({ lat: location?.lat, lng: location?.lng });
      // geocodeLocation(location.lat, location.lng);
    }
    if (location?.lat && !located) {
      setLocated(true);
    }
  }, [location?.lat, located]);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setIsAnimating(false); // Disable animation after a short delay
    }, 500);

    return () => clearTimeout(animationTimeout);
  }, [isAnimating]);

  // to get the current location for first time
  useEffect(() => {
    if (selectedLocation && !location) {
      // Check if a specific location is selected
      if (selectedLocation?.lat && selectedLocation?.lng) {
        setLocation({
          ...selectedLocation,
          lat: Number(selectedLocation.lat),
          lng: Number(selectedLocation.lng),
          address: selectedLocation?.address,
        });
        setLocated(true);
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation((prev) => ({
              ...prev,
              lat: latitude,
              lng: longitude,
            }));
            setLocated(true);
            geocodeLocation(latitude, longitude);
          },
          (error) => {
            console.log("Error getting current position:", error);
          }
        );
      }
    }
  }, [
    selectedLocation,
    selectedLocation?.lat,
    selectedLocation?.lng,
    location,
    geocodeLocation,
  ]);

  // to handle map icon position
  const mapCoordinates = useMemo(() => {
    return location?.lat
      ? {
          lat: location?.lat,
          lng: location?.lng + (language === "en" ? -10 : 10),
        }
      : { lat: 24.774265, lng: 46.738586 };
  }, [location?.lat, language]);

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }
  return (
    <>
      <div className="d-flex flex-column justify-content-start w-100 my-2 gap-2">
        <label>{t("address")}</label>
        <Autocomplete
          className="form-control w-100"
          ref={searchRef}
          id="address"
          placeholder={t("searchAddress")}
          apiKey={apiKey}
          onPlaceSelected={(place) => {
            if (place && place.geometry && place.geometry.location) {
              setLocation({
                address: place.formatted_address,
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              });
            } else {
              console.error(
                "Invalid place object or missing location information"
              );
            }
          }}
        />
      </div>
      <div
        className="mx-auto rounded d-flex flex-column"
        style={{
          width: "100%",
          height: "450px",
        }}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          options={options}
          center={location ? location : mapCoordinates}
          zoom={7}
          onClick={handleMapClick}
        >
          {location?.lat && (
            <Marker
              position={location}
              ref={markerRef}
              draggable
              onDragEnd={(e) => handleMapClick(e)}
              animation={
                isAnimating ? window.google.maps.Animation.BOUNCE : null
              }
            />
          )}
        </GoogleMap>
      </div>
    </>
  );
};

Map.propTypes = {
  disabled: PropTypes.bool,
  apiKey: PropTypes.string.isRequired,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
    address: PropTypes.string,
  }),
  setLocation: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default Map;
