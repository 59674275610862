import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ModalAddNewCard from "./ModalAddNewCard/ModalAddNewCard";
import { useState, useEffect } from "react";
import "./ModalAddNewCard/ModalAddNewCard.css";
import { Link } from "react-router-dom";
import axiosInstance from "../../../axios";
import { useTranslation } from "react-i18next";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import IconEdit from "assets/Icons/IconEdit";
import ModalAddNewCard2 from "./ModalAddNewCard/ModalAddNewCard2";
import ModalAddNewCard3 from "./ModalAddNewCard/ModalAddNewCard3";

const CardsPage = () => {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showModalAdd2, setShowModalAdd2] = useState(false);
  const [showModalAdd3, setShowModalAdd3] = useState(false);

  const [savedData, setSavedData] = useState([]);
  const { t, i18n } = useTranslation();
  const [cards, SetCard] = useState();
  const [loading, setLoading] = useState(true);
  const [SelectedPeroid, setSelectedPeroid] = useState(1);
  const [activeCardforEdit, setActiveCardForEdit] = useState(null);
  // Load saved data from local storage on component mount

  const refetch = () => {
    setLoading(true);
    axiosInstance
      .get("/admin/card", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setLoading(false);
        SetCard(res.data.data);
      });
  };
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("savedData"));

    if (data) {
      setSavedData(data);
    }
    axiosInstance
      .get("/admin/card", {
        headers: {
          "Accept-Language": i18n.language,
        },
      })
      .then((res) => {
        setLoading(false);
        SetCard(res.data.data);
      });
  }, []);

  const showModalAddNewCard = () => {
    setShowModalAdd(true);
  };

  const hideModalAddNewCard = () => {
    setShowModalAdd(false);
  };
  const showModalAddNewCard2 = () => {
    setShowModalAdd2(true);
  };

  const hideModalAddNewCard2 = () => {
    setShowModalAdd2(false);
  };
  const showModalAddNewCard3 = () => {
    setShowModalAdd3(true);
  };

  const hideModalAddNewCard3 = () => {
    setShowModalAdd3(false);
  };
  const handleSave = (data) => {
    const newData = [...savedData, data];
    setSavedData(newData);
    // Save data to local storage
    localStorage.setItem("savedData", JSON.stringify(newData));
  };

  return (
    <>
      <HelmetInfo titlePage={"البطاقات"} />
      <BreadcrumbPage
        routPageHome="/dashboardAdmin/homedashboard"
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={"البطاقات"}
      />

      <ModalAddNewCard
        refetch={refetch}
        showModalAddNewCard={showModalAdd}
        hideModalAddNewCard={hideModalAddNewCard}
        handleSave={handleSave}
        activeCardforEdit={activeCardforEdit}
      />
      <ModalAddNewCard2
        refetch={refetch}
        showModalAddNewCard={showModalAdd2}
        hideModalAddNewCard={hideModalAddNewCard2}
        handleSave={handleSave}
        activeCardforEdit={activeCardforEdit}
      />
      <ModalAddNewCard3
        refetch={refetch}
        showModalAddNewCard={showModalAdd3}
        hideModalAddNewCard={hideModalAddNewCard3}
        handleSave={handleSave}
        activeCardforEdit={activeCardforEdit}
      />
      <main>
        <div className="all-cards-page margin-top">
          {/* <div className="button-add">
            <button onClick={showModalAddNewCard} className="btn-main">
              إضافة جديدة <FontAwesomeIcon icon={faPlus} />
            </button>
          </div> */}
          {/* =========== START ALL CARDS IMG ========= */}
          <div className="all-cards-img my-3">
            {/* ============ START ROW ========= */}
            {/* <div className="row g-3">
              {savedData.map((data, index) => (
                <Link
                  to={`${index}`}
                  key={index}
                  className="col-12 col-sm-6 col-md-4"
                >
               
                  <div className="card-item-add">
                    <img
                      src={data.image}
                      alt="Uploaded"
                      className="w-100 rounded-4 object-fit-cover"
                      height={"252px"}
                    />
                  </div>
                </Link>
              ))}
            </div> */}
            {loading ? (
              <Loading />
            ) : cards.length > 0 ? (
              <div className="row all-cards">
                {cards.map((item, index) => {
                  return (
                    <div className="col-12 col-md-6 col-lg-4">
                      {/* ============= START CARD ONE ========== */}
                      <div className="card h-100 Cardadmin ">
                        <div
                          onClick={() => {
                            setActiveCardForEdit(item);
                            if (item.id == 1) {
                              showModalAddNewCard3(true);
                            } else if (item.id == 2) {
                              showModalAddNewCard2(true);
                            } else if (item.id == 3) {
                              showModalAddNewCard(true);
                            }
                          }}
                          style={{
                            left: i18n.language == "ar" ? "10px" : "unset",
                            right: i18n.language == "en" ? "10px" : "unset",
                          }}
                          className="CardEditBtn"
                        >
                          <IconEdit />
                        </div>

                        <div className="imgs-rotate">
                          {/*========== START IMG FRONT ========== */}
                          <div className="img-front">
                            <img
                              src={item.image}
                              height={"220px"}
                              className="object-fit-cover w-100 card-img-top"
                              alt="img card"
                            />
                          </div>
                          {/*========== END IMG FRONT ========== */}
                          {/*========== START IMG FRONT ========== */}

                          {/*========== END IMG FRONT ========== */}
                        </div>

                        <div className="card-body">
                          <h2 className="card-title">
                            {i18n.language === "ar"
                              ? item.ar_title
                              : item.en_title}{" "}
                          </h2>
                          <p dir="ltr" className="card-text">
                            {i18n.language === "ar"
                              ? `${item.ar_content}`
                              : `${item.en_content}`}
                            {item?.id === 3 && ` - ${t("points")} X2`}
                          </p>
                          <div className="d-flex justify-content-between mb-2">
                            <div style={{ fontSize: "20px" }}>
                              {t("Cash Back")} :
                            </div>
                            <div
                              style={{
                                fontSize: "20px",
                                color: "#9c0c19",
                                fontWeight: "600",
                              }}
                            >
                              {item?.cash_back} %
                            </div>
                          </div>
                          {item.id == 3 && (
                            <div className="PeriodSelect">
                              <div
                                onClick={() => {
                                  setSelectedPeroid(1);
                                }}
                              >
                                {" "}
                                شهري <span> {item.monthly_price} </span>
                              </div>
                              <div
                                onClick={() => {
                                  setSelectedPeroid(2);
                                }}
                              >
                                {" "}
                                سنوي <span> {item.yearly_price} </span>
                              </div>
                            </div>
                          )}
                          {item.id == 1 && (
                            <div className="PeriodSelect justify-content-center">
                              <div
                                style={{
                                  textAlign: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() => {
                                  setSelectedPeroid(1);
                                }}
                              >
                                {" "}
                                مجانا{" "}
                              </div>
                            </div>
                          )}
                          {item.id == 2 && (
                            <div className="PeriodSelect justify-content-center">
                              <div
                                onClick={() => {
                                  setSelectedPeroid(1);
                                }}
                              >
                                {" "}
                                الاشتراك <span> {item.points} نقطة</span>
                              </div>
                            </div>
                          )}
                          {/* <button className="btn-main">
                      { item.id == 1 ? i18n.language === "ar" ? ` اشترك  مجانا` : `Get it free`:null}
                      {
                        item.id == 2 && (i18n.language === "ar" ? ` اشترك مقابل ${item.points} نقطة ` : `Get it for ${item.points} points`)
                      }
                        {
                        item.id == 3 && (i18n.language === "ar" ? ` اشترك مقابل ${ SelectedPeroid == 1? item.monthly_price :item.yearly_price} نقطة ` : `Get it for ${ SelectedPeroid == 1? item.monthly_price :item.yearly_price} points`)
                      }
                    </button> */}
                        </div>
                      </div>
                      {/* ============= END CARD ONE ========== */}
                    </div>
                  );
                })}
              </div>
            ) : (
              <NoResults />
            )}
            {/* ============ END ROW ========= */}
          </div>
          {/* =========== END ALL CARDS IMG ========= */}
        </div>
      </main>
    </>
  );
};

export default CardsPage;
