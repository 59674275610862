import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import GridIcon from "assets/Icons/GridIcon";
import Arrows from "assets/Icons/Arrows";
import "./OuroTopPage.css";
import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BottomContent from "./BottomContent";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import { useEffect, useState } from "react";
import HeaderButtonsFilter from "./HeaderButtonsFilter";
import DataTableInfo from "../../Components/DataTableInfo/DataTableInfo";
import { Column } from "primereact/column";
import imgUser1 from "../../../assets/images/user/01.png";
import imgUser2 from "../../../assets/images/user/02.png";
import imgUser3 from "../../../assets/images/user/03.png";
import iconFlag from "../../../assets/images/dashboard/flags/sa.svg";
import ActionData from "Dashboard/Components/DataTableInfo/ActionData";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import axiosInstance from "../../../axios";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
const OuroTopPage = () => {
  // SHOW MODAL FILTER
  const { t, i18n } = useTranslation();
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };
  const [loading, setLoading] = useState(true);
  const [rowsData, setRowsData] = useState(null);
  const [countryData, setcountryData] = useState(null);
  const [globalData, setglobalData] = useState(null);
  const [bottomCardData, setBottomCardData] = useState(null);
  const [CountryId, setCountryId] = useState(null);
  const [Active, setActive] = useState("world"); // ADD CLASS ACTIVE
  // paginationc
  const [currentPage, setCurrentPage] = useState(0);
  const [chartData, SetChartData] = useState();

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  // DATA TABLE
  useEffect(() => {
    console.log(CountryId);

    axiosInstance
      .get(
        CountryId?.value
          ? `/admin/ourotop?country_id=${CountryId?.value}`
          : "/admin/ourotop",
        {
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      )
      .then((res) => {
        setLoading(false);
        console.log(res.data.global);
        const newData = res.data.global.map((item, index) => {
          return {
            id: item?.order,
            name: [
              {
                user: item?.image || imgUser1,
                text: item?.name,
                numPercentage: `${Math.round(item?.percentage)} %`,
                numberTotal: item?.points,
                top_status: item?.top_status,
                old_order: item?.old_order,
              },
            ],

            country: item?.country?.image || iconFlag,
          };
        });
        const countryData = res.data.my_country.map((item, index) => {
          return {
            id: item?.order,
            name: [
              {
                user: item?.image || imgUser1,
                text: item?.name,
                numPercentage: `${Math.round(item?.percentage)} %`,
                numberTotal: item?.points,
                top_status: item?.top_status,
                old_order: item?.old_order,
              },
            ],

            country: item?.country?.image || iconFlag,
          };
        });
        console.log(countryData);
        SetChartData(res.data?.countries);
        setRowsData(newData);
        setglobalData(newData);
        setcountryData(countryData);
        setBottomCardData({
          totalpoints: res.data.totalpoints,
          totalwallet: res.data.totalwallet,
          topcountry: res.data.topcountry,
        });
      });
  }, [CountryId]);
  useEffect(() => {
    if (Active === "world") {
      setRowsData(globalData);
    } else {
      setRowsData(countryData);
    }
  }, [Active]);
  const columns = [
    {
      field: "id",
      header: "#",
      style: { width: "2%" },
    },
    { field: "name", header: t("Name") },
    { field: "country", header: t("Country") },
  ];

  return (
    <>
      <HelmetInfo titlePage={t("Ouro Top ")} />
      <ModalFilterContentInfo
        setShowModalFilter={setShowModalFilter}
        showModalFilter={showModalFilter}
        selectCountry={true}
        selectCategory={false}
        selectPay={false}
      />
      <BreadcrumbPage
        routPageHome="/dashboardAdmin/homedashboard"
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={t("Ouro Top ")}
      />
      <div className="bottom-content-ouro-top margin-top">
        <BottomContent chartData={chartData} data={bottomCardData} />
      </div>
      {/* ========== R START TOP CONTENT INFO OURO ============= */}
      <div className="top-content-info-ouro top-content-info-ouro--1 card-style-2 margin-top">
        {/* ========== START HEADER TOP OURO ========== */}
        <div className="header-top-ouro d-flex align-items-center flex-wrap  gap-5">
          {/* ========= START RIGHT DATA ========= */}
          <div className="right-data-filter d-flex align-items-center flex-wrap  gap-3">
            <h2 className="title">{t("Ouro Top ")} </h2>
            {/* <div className="buttons-filter-head d-flex  align-items-center gap-3">
              <button
                onClick={showModal}
                className="btn-main   btn-main-outline"
              >
                تصفية
                <GridIcon />
              </button>
              <button className="btn-main  btn-main-outline">
                الأعلى نقاط
                <Arrows />
              </button>
            </div> */}
          </div>
          {/* ========= END RIGHT DATA ========= */}

          {/* ========= START LEFT DATA ========= */}
          <div className="left-data">
            <ButtonsExport
              dataExport={rowsData?.map((res) => {
                return {
                  id: res.id,
                  name: res.name[0].text,

                  numPercentage: `${Math.round(res?.percentage)} %`,
                  numberTotal: res.points,

                  country: res.country.title,
                };
              })}
            />
          </div>
          {/* ========= END LEFT DATA ========= */}
        </div>
        {/* ========== END HEADER TOP OURO ========== */}

        <HeaderButtonsFilter
          activeButton={Active}
          setValue={setCountryId}
          setActive={setActive}
        />
        {loading ? (
          <div className="w-100 bg-white">
            <Loading />
          </div>
        ) : rowsData && rowsData?.length === 0 ? (
          <>
            <NoResults />
          </>
        ) : (
          <DataTableInfo
            data={rowsData?.slice(currentPage * 10, (currentPage + 1) * 10)}
            isTruePagination={true}
            itemsCountPagination={rowsData?.length / 10} //total of 10 items for pagination
            handlePageClick={handlePageClick} // Fuction Of Pagination
          >
            {columns.map((item, index) => (
              <Column
                key={index}
                field={item.field}
                header={item.header}
                style={item.style}
                sortable
                body={(rowData) =>
                  item.field === "name" ? (
                    <div>
                      {rowData.name.map((contentItem, contentIndex) => (
                        <div
                          className="content-user d-flex align-items-center gap-3"
                          key={contentIndex}
                        >
                          <div className="img-user">
                            <img
                              src={contentItem.user}
                              alt="img user"
                              width={"42px"}
                              height={"42px"}
                            />
                          </div>
                          <div className="details-user">
                            <h2 className="name-user fs-6 fw-bold d-flex align-items-center gap-2">
                              {contentItem.text}
                              {contentItem.top_status == "up" ? (
                                <div className="main-info-card green-color d-flex align-items-center gap-2">
                                  <div className="num-per">
                                    {Math.abs(
                                      rowData.id - contentItem.old_order
                                    )}
                                  </div>
                                  <div className="icon-arrow">
                                    <FontAwesomeIcon
                                      style={{ color: "#32a840" }}
                                      icon={faArrowUpLong}
                                    />
                                  </div>
                                </div>
                              ) : (
                                <div className="main-info-card red-color d-flex align-items-center gap-2">
                                  <div className="num-per">
                                    {Math.abs(
                                      rowData.id - contentItem.old_order
                                    )}
                                  </div>
                                  <div className="icon-arrow">
                                    <FontAwesomeIcon icon={faArrowUpLong} />
                                  </div>
                                </div>
                              )}
                            </h2>
                            <div className="info-user-total mt-1 d-flex align-items-center gap-3">
                              <h2 className="num-1 fs-6 fw-bold main-text">
                                {contentItem.numberTotal}
                              </h2>
                              <h2
                                className="num-1 fs-6 fw-bold  green-text"
                                style={{
                                  color: !contentItem.numPercentage.includes(
                                    "-"
                                  )
                                    ? "#32a840"
                                    : "#9c0c19",
                                }}
                              >
                                {contentItem.numPercentage}
                              </h2>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : item.field === "country" ? (
                    <img
                      width={48}
                      height={32}
                      src={rowData[item.field]}
                      alt=""
                    />
                  ) : (
                    rowData[item.field]
                  )
                }
              />
            ))}
            {/* <Column
            header=""
            body={
              <ActionData
                functionShow={() => {
                   
                }}
                functionEdit={() => {
                   
                }}
                functionDelete={() => {
                   
                }}
              />
            }
          /> */}
          </DataTableInfo>
        )}
      </div>
      {/* ========== R END TOP CONTENT INFO OURO ============= */}

      {/* ========== R START BOTTOM OUTO TOP CONTENT ============= */}

      {/* ========== R END BOTTOM OUTO TOP CONTENT ============= */}
    </>
  );
};

export default OuroTopPage;
