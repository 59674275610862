import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
import * as Yup from "yup";
import axiosInstance from "./../../../../../axios";
import ModalFilter from "Dashboard/Components/ModalFilter/ModalFilter";
import FormField from "./../../../../../Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import TextAreaInput from "Components/Forms/TextArea";

const ModalAddOfferVendor = ({
  showModalAddOfferVendor,
  hideModalAddOfferVendor,
  edittedItem,
  refetch,
  setRefetch,
}) => {
  const { t, i18n } = useTranslation();

  const validationSchema = Yup.object().shape({
    arTitle: Yup.string().required(t("required")),
    enTitle: Yup.string().required(t("required")),
    arContent: Yup.string().required(t("required")),
    enContent: Yup.string().required(t("required")),
  });

  const initialValues = {
    arTitle: edittedItem ? edittedItem?.arTitle : "",
    enTitle: edittedItem ? edittedItem?.enTitle : "",
    arContent: edittedItem ? edittedItem?.arContent : "",
    enContent: edittedItem ? edittedItem?.enContent : "",
  };

  const [image, setImage] = useState(null);
  const [gallery, setGallery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const addedItem = new FormData();

    // Append form values to FormData
    addedItem.append("en[title]", values.enTitle);
    addedItem.append("ar[title]", values.arTitle);
    addedItem.append("ar[content]", values.arContent);
    addedItem.append("en[content]", values.enContent);

    // Append image if available
    if (image && image?.file) {
      addedItem.append("image", image.file);
    }

    // Append gallery images if available
    if (gallery && gallery.length > 0) {
      gallery.forEach((img, index) => {
        if (img.file) {
          addedItem.append(`gallery[${index}]`, img.file);
        }
      });
    }

    // Append date values
    addedItem.append("start_date", startDate);
    addedItem.append("end_date", endDate);

    // Construct edited item data
    const edittedItemData = {
      "ar.title": values.arTitle,
      "en.title": values.enTitle,
      "ar.content": values.arContent,
      "en.content": values.enContent,
      start_date: startDate,
      end_date: endDate,
    };

    try {
      let response;
      if (!edittedItem) {
        response = await axiosInstance.post("/store/offer", addedItem, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "multipart/form-data",
          },
        });
      } else {
        response = await axiosInstance.post(
          `/store/offer/updateoffer/${edittedItem?.id}`,
          addedItem,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Accept: "multipart/form-data",
            },
          }
        );
      }

      // Handle success
      toast.success(response.data?.message);
      setRefetch(!refetch);
      setImage(null);
      resetForm();
      hideModalAddOfferVendor();
    } catch (error) {
      console.error("Error:", error);
      const errorMessage =
        i18n.language === "ar" ? "حدث خطأ" : "An error occurred";
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const getDates = () => {
    const today = new Date().toISOString().split("T")[0];
    setStartDate(edittedItem?.start_date || today);
    setEndDate(edittedItem?.end_date || today);
  };

  useEffect(() => {
    getDates();
  }, [edittedItem]);
  return (
    <>
      <ModalFilter
        size={"xl"}
        showModalFilter={showModalAddOfferVendor}
        hideModalFilter={hideModalAddOfferVendor}
        modalFilterText={edittedItem ? t("edit") : t("addNewOffer")}
        newClassModal="modal-offers-cotnent-02"
      >
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-md-6">
              <InputFiled
                label={t("arabicOfferTitle")}
                name="arTitle"
                type="text"
                placeholder={t("arabicOfferTitle")}
                success
              />
            </div>
            <div className="col-md-6">
              <InputFiled
                label={t("englishOfferTitle")}
                name="enTitle"
                type="text"
                placeholder={t("englishOfferTitle")}
                success
              />
            </div>

            <div className="col-md-6">
              <TextAreaInput
                label={t("arContent")}
                name="arContent"
                type="text"
                placeholder={t("arContent")}
                success
              />
            </div>
            <div className="col-md-6">
              <TextAreaInput
                label={t("enContent")}
                name="enContent"
                type="text"
                placeholder={t("enContent")}
                success
              />
            </div>
          </div>
          <div className="row my-2">
            <div className="col-md-6">
              <label htmlFor="date">{t("start_date")}</label>
              <input
                type="date"
                className="form-control"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <label htmlFor="date">{t("end_date")}</label>
              <input
                type="date"
                className="form-control"
                min={startDate}
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="d-flex my-3 justify-content-center align-items-center gap-2">
            <div className="w-100">
              <label className="my-2" htmlFor="img">
                {edittedItem ? t("changeImg") : t("offerImg")}
              </label>
              <FileUpload setImage={setImage} allowMultiple={false} />
            </div>{" "}
            {edittedItem?.image && (
              <img
                style={{
                  maxWidth: 80,
                  maxHeight: 75,
                }}
                className="rounded"
                src={edittedItem?.image}
                alt="userImg"
              />
            )}
          </div>
          <div className="d-flex my-3 justify-content-center align-items-center gap-2 flex-column">
            <div className="w-100">
              <label className="my-2" htmlFor="img">
                {edittedItem ? t("changeGalley") : t("offerGallery")}
              </label>
              <FileUpload setImage={setGallery} allowMultiple={true} />
              <br />
            </div>
            <div className=" d-flex justify-content-center align-items-center gap-1">
              {edittedItem?.gallery.length > 0 &&
                edittedItem?.gallery.map((img) => (
                  <img
                    style={{
                      maxWidth: 80,
                      maxHeight: 75,
                    }}
                    className="rounded"
                    src={img}
                    alt="userImg"
                  />
                ))}
            </div>
          </div>

          <button
            disabled={edittedItem ? loading : loading && image?.file}
            type="submit"
            className="btn-main btn-submit w-100 mt-4"
          >
            {loading ? t("loading") : t("save")}
          </button>
        </FormField>
      </ModalFilter>
    </>
  );
};

export default ModalAddOfferVendor;
