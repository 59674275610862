import React from 'react'

const HeaderModal = ({title, text}) => {
  return (
    <div className='header-modal'>
    <h2 className="title">{title}</h2>
    <p className="text">{text}</p>
    </div>
  )
}

export default HeaderModal