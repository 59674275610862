import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import IconDate from "assets/Icons/IconDate";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const OffersUsedTable = ({ information }) => {
  const { t } = useTranslation();
  const theadTrContent = [
    t("transaction_number"),
    t("store"),
    // t('store_type'), // This line is commented out as per the user's request.
    t("payment_method"),
    t("offer"),
    t("invoice_amount"),
    t("points_earned"),
    t("ouro_commission"),
    t("invoice_date"),
  ];

  const [maindata, setMainData] = useState();
  // SHOW MODAL FILTER
  const { i18n } = useTranslation();
  const [data, setData] = useState();
  useEffect(() => {
    console.log(information?.customer?.orders);

    setData(
      information?.offers?.map((item) => ({
        StoreId: item.store.id,
        id: 2,
        numberPay: "#78565",
        name: item.store.title,
        category: "مطاعم",
        type:
          i18n.language === "en"
            ? item?.order?.payment_method
            : item?.order?.payment_method === "cash"
            ? "كاش"
            : "محفظة",
        offers: item.offer.title,
        amount: `${item.order.price}`,
        points: `${item.order.points} نقطة`,
        payment: `${item.order.ouro} `,
        date: moment(item.created_at).format("YYYY-MM-DD"),
      }))
    );
    setMainData(
      information?.offers?.map((item) => ({
        StoreId: item.store.id,
        id: 2,
        numberPay: "#78565",
        name: item.store.title,
        category: "مطاعم",
        type: item?.order?.payment_method,

        offers: item.offer.title,
        amount: `${item.order.price}$`,
        points: `${item.order.points} نقطة`,
        payment: `${item.order.ouro} $`,
        date: moment(item.created_at).format("YYYY-MM-DD"),
      }))
    );
  }, [information]);

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [selectedPage, setSelectdPage] = useState(0);
  const showModal = () => {
    setShowModalFilter(true);
  };

  // FUNCTION PAGINATION
  const handlePageClick = (e) => {
    setSelectdPage(e.selected);
  };
  return (
    <>
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={true}
          selectCategory={false}
          selectPay={false}
        />
      </div>

      <HeaderTableSearchFilter
        newClassHeaderContentSearch={"search-customer"}
        isTrueSearchInputFilter={true}
        textPlaceholder={"بحث"}
        functionSearchFilter={(e) => {
          const newData = maindata.filter((item) => {
            return (
              item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
              item?.offers.toLowerCase().includes(e.target.value.toLowerCase())
            );
          });

          setData(newData);
          console.log(e.target.value);
        }}
        functionIconSearch={() => {
          console.log("search");
        }}
        isTrueFilterButton={true}
        functionButtonFilter={showModal}
        isTrueHighestScore={false}
        functionButtonHighestScore={false}
        isNewButton={false}
        contentNewButton={false}
        isTrueContentLeftHeaderSearch={true}
        InfoContentLeftSearchFilter={
          <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
            <ButtonsExport dataExport={"Data Table Array"} />
          </div>
        }
      />
      {data?.length === 0 ? (
        <NoResults />
      ) : (
        <DataTableTwo
          theadTrContent={
            <>
              {theadTrContent.map((item) => {
                return <th>{item}</th>;
              })}
            </>
          }
          tbodyContent={
            <>
              {data
                ?.slice(selectedPage * 10, selectedPage + 1 * 10)
                ?.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.numberPay}</td>
                      <td>
                        <Link
                          to={`/dashboardAdmin/storeInformation/${item.StoreId}`}
                        >
                          {item.name}
                        </Link>
                      </td>
                      {/* <td>
                    <div className="success-bg">{item.category}</div>
                  </td> */}
                      <td>{item.type}</td>
                      <td className="w-25">{item.offers}</td>
                      <td>{item.amount}</td>
                      <td>
                        {" "}
                        <div className="success-bg">{item.points}</div>
                      </td>
                      <td>{item.payment}</td>
                      <td>
                        <IconDate /> {item.date}
                      </td>
                    </tr>
                  );
                })}
            </>
          }
        />
      )}

      <PaginationPage
        itemCount={data?.length / 10}
        handlePageClick={handlePageClick}
      />
    </>
  );
};

export default OffersUsedTable;
