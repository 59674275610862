import CardBox from "Dashboard/Components/Ui/CardBox/CardBox";
import icon1 from "../../../../../assets/images/dashboard/iconsMainPage/04.svg";
import icon2 from "../../../../../assets/images/dashboard/iconsMainPage/02.svg";
import { useTranslation } from "react-i18next";

const HeaderCardsMoney = ({data}) => {
  const {t}=useTranslation()
  const cardsBox = [
    { id: 0, icon: icon1, title: t("Transfers today"), text: data?.today_total },
    { id: 1, icon: icon2, title: t("Total Amount"), text: data?.total }
  ];
  return (
    <div className="all-header-bank margin-top">
      {/* ======= START ALL CARDS BOTTOM ========= */}
      <div className="all-cards-bottom">
        <div className="row g-3">
          {cardsBox.map((item) => {
            return (
              <div className="col-12 col-sm-6" key={item.id}>
                <CardBox
                  iconBox={item.icon}
                  textInfo={item.title}
                  infoContent={item.text}
                  newClass={"card-main-page"}
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* ======= END ALL CARDS BOTTOM ========= */}
    </div>
  );
};

export default HeaderCardsMoney;
