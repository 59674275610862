import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Input from "../Input/Input";
import { countries } from "./countries";

export default function PhoneInput({
  value,
  setValue,
  label,
  country,
  setCountry,
  mandatory,
  placeholder,
  ...rest
}) {
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(countries);

  useEffect(() => {
    const oman = countries[73];
    if (country?.code) {
      const cleanedCode = country.code.replace("+", "");
      const foundCountry = countries.find(
        (c) => c.countryCallingCode === `+${cleanedCode}`
      );
      if (foundCountry) {
        setCountry(foundCountry);
      }
    } else setCountry(oman);
  }, []);

  const handleSearch = (text) => {
    setSearchText(text);
    const filtered = countries.filter(
      (country) =>
        country.name.toLowerCase().includes(text.toLowerCase()) ||
        country.countryCallingCode.includes(text)
    );
    setFilteredCountries(filtered);
  };
  return (
    <div className="form-group">
      {label && (
        <label className="font-weight-bold mb-2  text-xs text-gray-800">
          {label}
        </label>
      )}
      <div
        dir="ltr"
        className="d-flex justify-content-center align-items-center border-1 rounded border"
      >
        <div className="dropdown px-2">
          <div
            className="cursor-pointer-event bg-transparent dropdown-toggle d-flex gap-1 justify-content-center align-items-center border-0"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img
              width={"25px"}
              height={"25px"}
              src={
                country?.flags?.svg
                  ? country.flags.svg
                  : countries[73]?.flags?.svg
              }
              alt={country?.code}
              className="rounded-circle"
            />
            {country?.code ? country.code : "+968"}
          </div>
          <ul
            className="dropdown-menu"
            style={{
              overflow: "auto",
              maxHeight: "200px",
              width: "400px",
            }}
            aria-labelledby="dropdownMenuButton"
          >
            <li>
              <input
                style={{
                  width: "90%",
                  height: "30px",
                }}
                className="form-control border-1 rounded mx-auto my-2"
                placeholder={t("searchCountry")}
                type="number"
                value={searchText}
                onChange={(event) => handleSearch(event.target.value)}
              />
            </li>
            {filteredCountries.map(
              ({ name, flags, countryCallingCode }, index) => (
                <li className="py-2" key={name}>
                  <div
                    key={name}
                    className="cursor-pointer-event dropdown-item d-flex align-items-center justify-content-start gap-2"
                    onClick={() => {
                      if (setCountry)
                        setCountry({
                          index: index,
                          code: countryCallingCode,
                          name: name,
                          flags: flags,
                        });
                    }}
                  >
                    <img
                      width={"25px"}
                      height={"25px"}
                      src={flags?.svg}
                      alt={name}
                      className="rounded-circle"
                    />
                    <span className="ml-auto">{countryCallingCode}</span>
                    {name}
                  </div>
                </li>
              )
            )}
          </ul>
        </div>

        <input
          dir="ltr"
          className="form-control border-1 rounded w-100"
          placeholder={placeholder}
          type="tel"
          value={value}
          onChange={(event) => setValue(event.target.value)}
          {...rest}
        />
      </div>
    </div>
  );
}

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  mandatory: PropTypes.bool,
  setCountry: PropTypes.func.isRequired,
  country: PropTypes.shape({
    index: PropTypes.number.isRequired,
    country_code: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    flags: PropTypes.object,
  }).isRequired,
};

PhoneInput.defaultProps = {
  country: { index: 0, country_code: "+966" },
  mandatory: false,
};
