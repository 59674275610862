import "./Statistics.css";
import icon1 from "../../assets/images/icons/user.svg";
import icon2 from "../../assets/images/icons/download.svg";
import icon3 from "../../assets/images/icons/shop.svg";
import icon4 from "../../assets/images/icons/heart.svg";
import HeaderSection from "Components/HeaderSection/HeaderSection";
import { useTranslation } from "react-i18next";

import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const Statistics = () => {
  const [ref, inView] = useInView({
    triggerOnce: true
  });
  const { i18n } = useTranslation();
  const allSta = [
    {
      id: 0,
      icon: icon1,
      text: `${i18n.language === "ar" ? "المستخدمين" : "Users"}`,
      numberInfo: 300000,
      isTrue: true
    },
    {
      id: 1,
      icon: icon2,
      text: `${i18n.language === "ar" ? "التحميل" : "Download"}`,
      infoText: `${
        i18n.language === "ar"
          ? "قريبا ستظهر الاحصائية"
          : "Soon the statistics "
      }`,
      isTrue: false
    },
    {
      id: 2,
      icon: icon3,
      text: `${
        i18n.language === "ar" ? "المتاجر المسجلة" : "Registered stores"
      }`,

      numberInfo: 5000,
      isTrue: true
    },
    {
      id: 3,
      icon: icon4,
      text: `${i18n.language === "ar" ? "الهدايا" : "Gifts"}`,
      infoText: `${
        i18n.language === "ar"
          ? "قريبا ستظهر الاحصائية"
          : "Soon the statistics "
      }`,
      isTrue: false
    }
  ];
  return (
    <div className="statistics padding">
      <HeaderSection
        title={i18n.language === "ar" ? "احصائيات" : "Statistics"}
        isText={true}
        text={
          i18n.language === "ar"
            ? "أرقامنا دائماً تتحدث عن النجاحات والإنجازات التي نحققها مراراً وتكراراً"
            : "Our numbers always speak of the successes and achievements we achieve over and over again"
        }
      />

      {/* ========== START ALL STATISTICS ========== */}
      <div className="all-statistics" data-aos="fade-up">
        {/* ======== START ROW ======= */}
        <div className="row g-3">
          {allSta.map((item) => {
            return (
              <div className="col-12 col-md-6 col-lg-3" key={item.id}>
                {/* START STATISTICS ONE */}
                <div className="statistics-one d-flex align-items-center gap-3 justify-content-between">
                  {/* ======= START INFO STATISTICS ======== */}
                  <div className="info-statistics">
                    <h2 className="title">{item.text}</h2>

                    {item.isTrue === true ? (
                      <div className="text fw-bold " ref={ref}>
                        {inView && (
                          <CountUp end={`${item.numberInfo}`} duration={2} />
                        )}
                      </div>
                    ) : (
                      <p className="text">{item.infoText}</p>
                    )}
                  </div>
                  {/* ======= END INFO STATISTICS ======== */}
                  <div className="icon-statistics">
                    <img
                      src={item.icon}
                      alt="icon User"
                      width={"44px"}
                      height={"44px"}
                    />
                  </div>
                </div>
                {/* END STATISTICS ONE */}
              </div>
            );
          })}
        </div>
        {/* ======== END ROW ======= */}
      </div>
      {/* ========== END ALL STATISTICS ========== */}
    </div>
  );
};

export default Statistics;
