import React from "react";

const TrashIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 5.04736C15.735 5.04736 15.7125 5.04736 15.69 5.04736C11.7225 4.64986 7.7625 4.49986 3.84 4.89736L2.31 5.04736C1.995 5.07736 1.7175 4.85236 1.6875 4.53736C1.6575 4.22236 1.8825 3.95236 2.19 3.92236L3.72 3.77236C7.71 3.36736 11.7525 3.52486 15.8025 3.92236C16.11 3.95236 16.335 4.22986 16.305 4.53736C16.2825 4.82986 16.035 5.04736 15.75 5.04736Z"
        fill="#1E4D6D"
      />
      <path
        d="M6.37501 4.29C6.34501 4.29 6.31501 4.29 6.27751 4.2825C5.97751 4.23 5.76751 3.9375 5.82001 3.6375L5.98501 2.655C6.10501 1.935 6.27001 0.9375 8.01751 0.9375H9.98251C11.7375 0.9375 11.9025 1.9725 12.015 2.6625L12.18 3.6375C12.2325 3.945 12.0225 4.2375 11.7225 4.2825C11.415 4.335 11.1225 4.125 11.0775 3.825L10.9125 2.85C10.8075 2.1975 10.785 2.07 9.99001 2.07H8.02501C7.23001 2.07 7.21501 2.175 7.10251 2.8425L6.93001 3.8175C6.88501 4.095 6.64501 4.29 6.37501 4.29Z"
        fill="#1E4D6D"
      />
      <path
        d="M11.4075 17.0627H6.59248C3.97498 17.0627 3.86999 15.6152 3.78749 14.4452L3.29999 6.89268C3.27749 6.58518 3.51748 6.31518 3.82498 6.29268C4.13998 6.27768 4.40249 6.51018 4.42499 6.81768L4.91249 14.3702C4.99499 15.5102 5.02498 15.9377 6.59248 15.9377H11.4075C12.9825 15.9377 13.0125 15.5102 13.0875 14.3702L13.575 6.81768C13.5975 6.51018 13.8675 6.27768 14.175 6.29268C14.4825 6.31518 14.7225 6.57768 14.7 6.89268L14.2125 14.4452C14.13 15.6152 14.025 17.0627 11.4075 17.0627Z"
        fill="#1E4D6D"
      />
      <path
        d="M10.245 12.9375H7.7475C7.44 12.9375 7.185 12.6825 7.185 12.375C7.185 12.0675 7.44 11.8125 7.7475 11.8125H10.245C10.5525 11.8125 10.8075 12.0675 10.8075 12.375C10.8075 12.6825 10.5525 12.9375 10.245 12.9375Z"
        fill="#1E4D6D"
      />
      <path
        d="M10.875 9.9375H7.125C6.8175 9.9375 6.5625 9.6825 6.5625 9.375C6.5625 9.0675 6.8175 8.8125 7.125 8.8125H10.875C11.1825 8.8125 11.4375 9.0675 11.4375 9.375C11.4375 9.6825 11.1825 9.9375 10.875 9.9375Z"
        fill="#1E4D6D"
      />
    </svg>
  );
};

export default TrashIcon;
