import Select from "react-select";
const SelectOptions = ({
  textLabel,
  newClassSelect,
  options,
  placeholderText
}) => {
  return (
    <>
      <div className="select-option-one mt-3">
        <label htmlFor="exampleFormControlInput1" className="form-label">
          {textLabel}
        </label>
        <div className="select-box-option mt-1">
          <Select
            className={`select-info-option ${newClassSelect}`}
            options={options}
            placeholder={placeholderText}
          />
        </div>
      </div>
    </>
  );
};

export default SelectOptions;
