import React from 'react'

const EllipsisIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.33333 15.9998C9.33333 14.5271 8.13943 13.3332 6.66667 13.3332C5.19391 13.3332 4 14.5271 4 15.9998C4 17.4726 5.19391 18.6665 6.66667 18.6665C8.13943 18.6665 9.33333 17.4726 9.33333 15.9998Z" fill="#A2A2A2"/>
    <path d="M18.6667 15.9998C18.6667 14.5271 17.4728 13.3332 16 13.3332C14.5273 13.3332 13.3334 14.5271 13.3334 15.9998C13.3334 17.4726 14.5273 18.6665 16 18.6665C17.4728 18.6665 18.6667 17.4726 18.6667 15.9998Z" fill="#A2A2A2"/>
    <path d="M28 15.9998C28 14.5271 26.8061 13.3332 25.3333 13.3332C23.8605 13.3332 22.6666 14.5271 22.6666 15.9998C22.6666 17.4726 23.8605 18.6665 25.3333 18.6665C26.8061 18.6665 28 17.4726 28 15.9998Z" fill="#A2A2A2"/>
    </svg>
    
  )
}

export default EllipsisIcon