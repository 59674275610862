import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";

import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import HeaderContentTab from "../HeaderContentTab";
import EditIcon from "assets/Icons/EditIcon";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import { useEffect, useState } from "react";

import Arrows from "assets/Icons/Arrows";
import AddManger from "./AddManger";
import { useSavedState } from "assets/hooks";
import axiosInstance from "./../../../../../../axios";
import { toast } from "react-toastify";
import DataTable from "Components/DataTable/DataTable";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CustomModal from "Components/CustomModal/CustomModal";

const Mangers = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [edittedItem, setEdittedItem] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const [mangers, setMangers, clearCustomerData] = useSavedState(
    [],
    `MangersVendorData`
  );
  const [filtredData, setFiltredData, clearFilredData] = useSavedState(
    [],
    "mangerVendorFiltredData"
  );
  const [showModalFilter, setShowModalFilter] = useState(false);

  useEffect(() => {
    const updatedFiltredData = mangers?.map((manger) => ({
      id: manger?.id,
      store_id: manger?.store_id,
      branch_id: manger?.branch_id,
      name: manger?.name,
      email: manger?.email,
      phone: manger?.phone,
      image: manger?.image,
      status: manger?.status,
      is_verified: manger?.is_verified,
      country_code: manger?.country_code,
      created_at: moment(manger?.created_at).format("DD-MM-YYY , hh:mm"),
      updated_at: moment(manger?.updated_at).format("DD-MM-YYY , hh:mm"),
    }));

    setFiltredData(updatedFiltredData);
  }, [mangers, i18n.language]);
  const columnDefs = [
    {
      headerName: t("ID"),
      field: "id",
      flex: 0.5,
    },
    {
      headerName: t("image"),
      field: "image",
      flex: 0.5,
      cellRenderer: (params) => (
        <img
          onClick={() => {
            setShowModalFilter(params?.data?.image);
          }}
          alt="MNGRImg"
          width={40}
          className=" object-fit-cover rounded cursor-pointer-event"
          src={params.data.image}
        />
      ),
    },
    {
      headerName: t("name"),
      field: "name",
    },
    {
      headerName: t("branch_id"),
      field: "branch_id",
    },

    {
      headerName: t("email"),
      field: "email",
    },
    {
      headerName: t("phone"),
      field: "phone",
    },
    {
      headerName: t("is_verified"),
      field: "is_verified",
      cellRenderer: (params) => (
        <div
          style={{
            backgroundColor:
              params?.data?.is_verified === 1 ? "#32a8401a" : "#a832321a",
            color: params?.data?.is_verified === 1 ? "#32a840" : "#9c0c19",
          }}
          className={`btn px-4 fw-semibold`}
        >
          {params?.data?.is_verified === 1 ? t("yes") : t("no")}
        </div>
      ),
    },
    {
      headerName: t("created_at"),
      field: "created_at",
    },
    {
      headerName: t("Actions"),
      flex: 0.5,
      cellRenderer: (params) => (
        <button
          className="btn btn-sm"
          onClick={() => {
            setShowModal(true);
            setEdittedItem(params?.data);
          }}
        >
          <EditIcon />
        </button>
      ),
    },
    {
      headerName: t("status"),
      cellRenderer: (params) => (
        <CheckboxSwitch
          id={params?.data?.id}
          isChecked={params?.data?.status}
          handleCheckboxChange={() => {
            handleActivation(params?.data);
          }}
        />
      ),
    },
  ];

  const hideIMgModal = () => {
    setShowModalFilter(null);
  };

  const getMangers = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/store/manager`);
      setMangers(data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getMangers();
  }, [refetch]);
  const handleActivation = async (item) => {
    try {
      await axiosInstance.post(`/store/managerupdatestatus`, {
        manger_id: item?.id,
        status: item.status === 1 ? 0 : 1,
      });
      toast.success(t("updated"));
      setRefetch(!refetch);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };

  //   SHOW MODAL
  const showModalEmployee = () => {
    setShowModal(true);
  };

  const hideModalEmployee = () => {
    setEdittedItem(null);
    setShowModal(false);
  };

  return (
    <>
      <AddManger
        edittedItem={edittedItem}
        refetch={refetch}
        setRefetch={setRefetch}
        showModal={showModal}
        hideModal={hideModalEmployee}
      />
      <CustomModal
        show={showModalFilter}
        onHide={hideIMgModal}
        title={t("employeeImg")}
      >
        <img className="w-100 rounded" src={showModalFilter} alt="" />
      </CustomModal>
      <HeaderContentTab
        textTitle={t("employees")}
        isShowButton={true}
        newClassButton={false}
        isTrueText={false}
        functionButton={() => {
          showModalEmployee();
        }}
        isShowButtonProfit={false}
        functionButtonProfit={false}
      />
      <div className="all-data-table-shop all-data-table-shop-1 all-table-emp card-style-2 margin-top">
        <HeaderTableSearchFilter
          newClassHeaderContentSearch={"search-customer"}
          isTrueSearchInputFilter={true}
          functionIconSearch={() => {
            console.log("search");
          }}
          functionSearchFilter={(e) => {
            const searchValue = e.target.value.toLowerCase();

            const filteredData = mangers.filter((row) => {
              return (
                row.email.toString().toLowerCase().includes(searchValue) ||
                row.name.toString().toLowerCase().includes(searchValue)
              );
            });
            setFiltredData(filteredData);
          }}
          isTrueContentLeftHeaderSearch={true}
          InfoContentLeftSearchFilter={
            <>
              <ButtonsExport dataName={"mangers"} dataExport={mangers} />
            </>
          }
        />
        <DataTable rowData={filtredData} columnDefs={columnDefs} />
      </div>
    </>
  );
};

export default Mangers;
