import { useEffect, useState } from "react";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import img from "../../../../../../../../assets/images/main/02.png";
import ModalShowInfoPay from "./ModalShowInfoPay";
const MarketingStore = ({ information }) => {
  const theadTrContent = [
    "رسالة الإعلان",
    // " العميل",


    "تاريخ الحملة",
    "الدول المستهدفة",
    "عدد المهتمين",
    // "التكلفة لكل مهتم",
    // "عدد المشاهدين",
    // "المبلغ المدفوع",
    // "",
  ];
  const [data, setData] = useState([]);
  const [FilterData, setFilterData] = useState([]);
  const [activePage, setActivePage] = useState(0);
  useEffect(() => {
    setData(
      information?.marketings?.map((item) => ({
        id: 1,
        img: item?.image,
        title: item?.title,
        text: item?.content,
        customer: item?.customers,

        dateEnd: item?.date,
        time:item.time,
        regio: item?.countries?.map(
          (itemm, index) =>
            `${index != 0 ? "-" : ""} ${itemm?.title} ${
              index != item?.countries.length - 1 ? "-" : ""
            }`
        ),
        coast: item?.customers?.length,
        num: "10",
        views: "30",
        money: item?.price,
      }))
    );
    setFilterData(
      information?.marketings?.map((item) => ({
        id: 1,
        img: item?.image,
        title: item?.title,
        text: item?.content,
        customer: item?.customers,
  
        dateEnd: item?.date,
        time:item.time,
        regio: item?.countries?.map(
          (itemm, index) =>
            `${index != 0 ? "-" : ""} ${itemm?.title} ${
              index != item?.countries.length - 1 ? "-" : ""
            }`
        ),
        coast: item?.customers?.length,
        num: "10",
        views: "30",
        money: item?.price,
      }))
    );
  }, []);

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };

  // FUNCTION PAGINATION
  const handlePageClick = (e) => {
    setActivePage(e.selected)
  };

  // SHOW MODAL INFORMATION PAY
  const [showModalPay, setShowModalPay] = useState(false);
  const showModalInfoPay = () => {
    setShowModalPay(true);
  };
  const hideModalInfoPay = () => {
    setShowModalPay(false);
  };

  return (
    <>
      <ModalShowInfoPay
        showModalInfoPay={showModalPay}
        hideModalInfoPay={hideModalInfoPay}
      />
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={true}
          selectCategory={false}
          selectPay={false}
        />
      </div>
      <div className="main-current-active main-marketing-active">
        <div className="all-data-table-shop all-table-notification all-table-pending all-table-p card-style-2 table-border--1 margin-top">
          <HeaderTableSearchFilter
            newClassHeaderContentSearch={"search-customer"}
            isTrueSearchInputFilter={true}
            textPlaceholder={"بحث"}
            functionSearchFilter={(e) => {
              console.log(e.target.value);
            }}
            functionIconSearch={(e) => {
            const searchcontent=e.target.value
const filterData = data.filter((item) => {

  return item.title.includes(searchcontent)
})
setFilterData(filterData);

          }}
            isTrueFilterButton={true}
            functionButtonFilter={showModal}
            isTrueHighestScore={false}
            functionButtonHighestScore={false}
            isNewButton={false}
            contentNewButton={false}
            isTrueContentLeftHeaderSearch={true}
            InfoContentLeftSearchFilter={
              <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                <ButtonsExport dataExport={"Data Table Array"} />
              </div>
            }
          />
          <div className="table-actions-1">
            {data?.length > 0 ? (
              <DataTableTwo
                theadTrContent={
                  <>
                    {theadTrContent.map((item, index) => {
                      return <th key={index}>{item}</th>;
                    })}
                  </>
                }
                tbodyContent={
                  <>
                    {FilterData.slice(activePage*6,(activePage+1)*6)?.map((item) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <div className="d-flex align-items-center gap-3 text-start">
                              <img
                                src={item.img}
                                width={"85px"}
                                height={"85px"}
                                className="rounded-3 object-fit-cover"
                                alt="img user"
                              />{" "}
                              <div className="conent-info-2">
                                <h2 className="title fs-6 fw-medium pb-1">
                                  {item.title}
                                </h2>
                                <p className="text fs-6 fw-medium">
                                  {item.text}
                                </p>
                              </div>
                            </div>
                          </td>
                      
                          <td>
                            <IconDate /> {item.dateEnd}
                            <br /> {item.time}
                          </td>

                          <td>{item.regio}</td>
                          <td>{item.coast} </td>
                          {/* <td>{item.num}</td>
                          <td>{item.views}</td>
                          <td>{item.money} </td> */}
                          {/* <td>
                            <div
                              onClick={showModalInfoPay}
                              className="pay-info---1"
                            >
                              معلومات الدفع
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </>
                }
              />
            ) : (
              <div className="fs-6 fw-medium text-center text-dark ">
                لا توجد بيانات مضافة
              </div>
            )}
            <PaginationPage
              itemCount={FilterData?.length / 6}
              handlePageClick={handlePageClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingStore;
