const HeaderCardMain = () => {
  const cardItem = [
    { id: 0, title: "عدد المشتركين", text: "45892" },
    { id: 1, title: "الدولة الأعلى في الإشتراك", text: "السعودية" },
    { id: 2, title: "إجمالي عدد المبيعات", text: "163549 $" }
  ];
  return (
    <div className="middle-content">
      {/* ========= START ALL MIDDLE CONTENT ========== */}
      <div className="all-middle-content ">
        <div className="row g-4">
          {cardItem.map((item) => {
            return (
              <div className="col-content col-sm-6 col-md-4">
                {/* ========= START CONETN MIDDLE ONE ========= */}
                <div className="middle-content-one d-flex flex-column gap-3 text-center ">
                  <h2 className="title">{item.title}</h2>
                  <h2 className="info-title">{item.text}</h2>
                </div>
                {/* ========= END CONETN MIDDLE ONE ========= */}
              </div>
            );
          })}
        </div>
      </div>
      {/* ========= END ALL MIDDLE CONTENT ========== */}
    </div>
  );
};

export default HeaderCardMain;
