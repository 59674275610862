import DataTable from "Components/DataTable/DataTable";
import DataTableInfo from "Dashboard/Components/DataTableInfo/DataTableInfo";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import EyeIcon from "assets/Icons/EyeIcon";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TopIntersts = ({data}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const columnDefs = useMemo(
    () => [
      {
        headerName: i18n.language === "ar" ? "#" : "#",
        field: "id",
        width: "100px",
      },
      {
        headerName: i18n.language === "ar" ? "اسم الاهتمام" : "Interest Name",
        field: "name",
        flex: 1,
      },
      {
        headerName: i18n.language === "ar" ? "ألحالة " : "Status",
        field: "Status",
        flex: 1,
      },
      {
        headerName: i18n.language === "ar" ? " عدد المهتمين" : " Interested Count",
        field: "count",
        flex: 1,
      },

  
      
     
      {
        headerName:
          i18n.language === "ar" ? "تاريخ الانشاء" : "Creation Date",
        field: "date",
        flex: 1,
      },

    
    ],
    [i18n.language]
  );
  const [rowsData, setRowsData] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

useEffect(()=>{
console.log(     data?.topstores

  )
    setRowsData(
        data?.topInterests?.map((item) => ({
          id: item?.id,
          name: item?.title,
          Status: item?.status == 1 ? "Active" : "Inactive",
          count: item?.customers_count,
      
       
      
          date: moment(item?.created_at).format("DD-MM-YYY , hh mm"),
        }))
      );
      setFilteredData(   data?.topInterests?.map((item) => ({
        id: item?.id,
        name: item?.title,
        Status: item?.status == 1 ? "Active" : "Inactive",
        count: item?.customers_count,
    
     
    
        date: moment(item?.created_at).format("DD-MM-YYY , hh mm"),
      })))

},[data])

  return (
    <>
      <HeaderTableSearchFilter
        newClassHeaderContentSearch={"search-reports"}
        isTrueSearchInputFilter={true}
        textPlaceholder={"بحث"}
        functionSearchFilter={(e) => {
            const searchValue = e.target.value.toLowerCase();

            const filteredData = rowsData.filter((row) => {
              return row?.name
                .toString()
                .toLowerCase()
                .includes(searchValue);
            });
            setFilteredData(filteredData);
        }}
        functionIconSearch={() => {
          console.log("search");
        }}
        isTrueFilterButton={true}
        functionButtonFilter={() => {}}
        isTrueHighestScore={false}
        functionButtonHighestScore={false}
        isNewButton={true}
        contentNewButton={<></>}
        isTrueContentLeftHeaderSearch={true}
        InfoContentLeftSearchFilter={
          <>
            <ButtonsExport dataExport={"Data Table Array"} />
          </>
        }
      />
      <div className="table-customer table-width table-reports">

      <DataTable rowData={filteredData} columnDefs={columnDefs} />


      </div>
    </>
  );
};

export default TopIntersts;
