import NavBarDashboard from "Dashboard/Components/NavBarDashboard/NavBarDashboard";
import img from "../../../assets/images/main/02.png";
import { useLocation } from "react-router-dom";
import dashboardVendorText from "../../../Dashboard/Components/PagesTitle/PagesTitle.json";
import CrownIcon from "assets/IconsVendor/CrownIcon";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
const NavBarVendor = ({ toggleSidebar }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const routeTitles = {
    "/dashboardVendor/homedashboardvendor": t(
      `${dashboardVendorText.dashboardVendor.homedashboardvendor}`
    ),
    "/dashboardVendor/customersvendor": t(
      `${dashboardVendorText.dashboardVendor.customersvendor}`
    ),
    "/dashboardVendor/invoicesVendor": t(
      `${dashboardVendorText.dashboardVendor.invoicesVendor}`
    ),
    "/dashboardVendor/walletVendor": t(
      `${dashboardVendorText.dashboardVendor.walletVendor}`
    ),
    "/dashboardVendor/paymentsVendor": t(
      `${dashboardVendorText.dashboardVendor.paymentsVendor}`
    ),
    "/dashboardVendor/offersVendor": t(
      `${dashboardVendorText.dashboardVendor.offersVendor}`
    ),
    "/dashboardVendor/discountCodesPoints": t(
      `${dashboardVendorText.dashboardVendor.discountCodesPoints}`
    ),
    "/dashboardVendor/reportsVendor": t(
      `${dashboardVendorText.dashboardVendor.reportsVendor}`
    ),
    "/dashboardVendor/personalPageVendor": t(
      `${dashboardVendorText.dashboardVendor.personalPageVendor}`
    ),
    "/dashboardVendor/controlDashBoardVendor": t(
      `${dashboardVendorText.dashboardVendor.controlDashBoardVendor}`
    ),
    "/dashboardVendor/technicalSupportVendor": t(
      `${dashboardVendorText.dashboardVendor.technicalSupportVendor}`
    ),
    "/dashboardVendor/advertise": t(
      `${dashboardVendorText.dashboardVendor.advertise}`
    ),
    "/dashboardVendor/eventsVendor": t(
      `${dashboardVendorText.dashboardVendor.eventsVendor}`
    ),
    "/dashboardVendor/marketingCampaignsVendor": t(
      `${dashboardVendorText.dashboardVendor.marketingCampaignsVendor}`
    ),
  };

  // Get the title  route
  const titlePage = routeTitles[location.pathname] || "";
  return (
    <>
      <NavBarDashboard
        toggleSidebar={toggleSidebar}
        isSettingTrue={false}
        imgInfo={img}
        functionImgNavBar={() => {
          console.log("Route To Page You Need From This Function Use Navigate");
        }}
        ContentInfo={
          <>
            {location.pathname === "/dashboardVendor/homedashboardvendor" ? (
              <div>
                <h2 className="title">{t("hello")}</h2>
                <h2 className="name-user">{localStorage?.userName}</h2>
              </div>
            ) : (
              <h2 className="title">{titlePage}</h2>
            )}
          </>
        }
        leftContentInfo={
          <>
            <NavLink className="btn-main btn-advertise" to={"advertise"}>
              {" "}
              <span className="text-button">{t("advertiseYourself")}</span>{" "}
              <CrownIcon />
            </NavLink>
          </>
        }
        routeProfile={"/dashboardVendor/profileUserVendor"}
      />
    </>
  );
};

export default NavBarVendor;
