import Arrows2 from "assets/Icons/Arrows2";
import "./Branches.css";
import PhoneIcon from "assets/Icons/PhoneIcon";
import { useTranslation } from "react-i18next";

const Branches = ({ information }) => {
  const { i18n } = useTranslation();
  return (
    <>
      <div className="information-tab row information-branche    " style={{width:"100%"}}>
        {information.branches.map((item) => {
          return (
            <>
              {/* ========== START CONTENT CONATCT ========== */}
              <div className=" mt-4  col-4 px-2 shadow  rounded-3 p-3 content-info d-flex justify-content-between align-items-center gap-3 ">
                <div className="  main-info d-flex flex-column gap-3">
                  <h2 className="title">
                    {i18n.language === "ar"
                      ? `فرع رقم ${item.id}`
                      : `Branch Number ${item.id}`}
                  </h2>
                  <p className="info-contact-one d-flex align-items-center gap-3">
                    {item.address}
                  </p>
                  <p className="info-contact-one d-flex align-items-center gap-3">
                    <PhoneIcon />
                    {item.phone}
                  </p>
                  {/* showOnMap */}
                </div>
                <div className="icon-arrow">{item.icon}</div>
              </div>
              {/* ========== END CONTENT CONATCT ========== */}
            </>
          );
        })}
      </div>
    </>
  );
};

export default Branches;
