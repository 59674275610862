import React from "react";
import HeaderDebtBankAccount from "../HeaderDebtBankAccount/HeaderDebtBankAccount";
import TableDebtBankAccount from "../TableDebtBankAccount/TableDebtBankAccount";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useTranslation } from "react-i18next";

const DebtTab = ({ information }) => {
  const { t } = useTranslation();
  return (
    <>
      {/* <header>
        <HeaderDebtBankAccount firstText={t("Total Orders Number")} firstNum={information?.orders?.length} secondNum={information?.orders_total} information={information} />
      </header> */}
      <main>
        {information?.customer_wallet?.length > 0 ? (
          <TableDebtBankAccount information={information?.customer_wallet} />
        ) : (
          <NoResults />
        )}
      </main>
    </>
  );
};

export default DebtTab;
