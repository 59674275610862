import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React, { useEffect, useState, useMemo } from "react";
import HeaderCards from "./Components/HeaderCards/HeaderCards";
import ModalDeleteCustomer from "./Components/ModalsCustomers/ModalDeleteCustomer";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import TableCustomers from "./Components/TableCustomers/TableCustomers";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import axiosInstance from "./../../../axios";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import EyeIcon from "assets/Icons/EyeIcon";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import { useTranslation } from "react-i18next";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DataTable from "Components/DataTable/DataTable";

const OrdersPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalDeleteCustomer, setModalDeleteCustomer] = useState(false);
  const [rowsData, setRowsData] = useState(null);
  const [customersData, setCustomersData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const columnDefs = useMemo(
    () => [
      {
        headerName: i18n.language === "ar" ? "#" : "#",
        field: "id",
        width: "100px",
      },
      {
        headerName: i18n.language === "ar" ? "اسم العميل" : "Name",
        field: "name",
        flex: 1,
      },
      {
        headerName: t("Store Name"),
        field: "Store",
        flex: 1,
        cellRenderer: (params) => (
          <div
            onClick={() => {
              navigate(
                `/dashboardAdmin/storeInformation/${params.data.StoreId}`
              );
            }}
            className=" cursor-pointer-event"
          >
            {params.data.Store}
          </div>
        ),
      },
      {
        headerName: t("Branch Name"),
        field: "Branch",
        flex: 1,
      },
      {
        headerName: t("price"),
        field: "price",
        flex: 1,
      },
      {
        headerName: t("ouro_profit"),
        field: "Ouro",
        flex: 1,
      },
      {
        headerName: t("points"),
        field: "points",
        flex: 1,
      },

      {
        headerName: t("date"),
        field: "date",
        flex: 1,
      },
      // {
      //   headerName: i18n?.language === "ar" ? "الإجراءات" : "Actions",
      //   field: "Actions",
      //   flex: 0.5,
      //   cellRenderer: (params) => (
      //     <div
      //       onClick={() => {
      //         navigate(`/dashboardAdmin/OrderFile/${params.data.id}`);
      //       }}
      //       className=" cursor-pointer-event"
      //     >
      //       <EyeIcon />
      //     </div>
      //   ),
      // },
    ],
    [i18n.language]
  );

  const getCustomersData = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/admin/orders`, {
        headers: {
          "Accept-Language": i18n.language,
        },
      });
      setRowsData(
        data?.orders?.map((item) => ({
          id: item.id,
          CustomerId: item?.customer?.id,
          name: item?.customer?.name,
          Store: item?.store?.title,
          StoreId: item?.store?.id,

          Branch: item?.branch?.address,
          points: item?.points,
          price: item?.price,
          Ouro: item.ouro,
          payment_method: item?.payment_method,
          date: moment(item.created_at).format("DD-MM-YYY , hh mm"),
        }))
      );
      setCustomersData(data && data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!rowsData) return;

    setFilteredData(rowsData);
  }, [rowsData, i18n.language]);

  useEffect(() => {
    getCustomersData();
  }, [refetch]);

  return (
    <>
      <HelmetInfo titlePage={t("Orders")} />
      <BreadcrumbPage
        routPageHome="/dashboardAdmin/homedashboard"
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={t("Orders")}
      />

      <ModalDeleteCustomer
        showModalDeleteCustomer={showModalDeleteCustomer}
        hideModalDeleteCustomer={() => setModalDeleteCustomer(false)}
      />

      <ModalFilterContentInfo
        setShowModalFilter={setShowModalFilter}
        showModalFilter={showModalFilter}
        selectCountry={true}
        selectCategory={false}
        selectPay={false}
      />

      <div className="all-customer-page-info">
        <HeaderCards customersData={customersData} />
        {loading ? (
          <div className="w-100 bg-white">
            <Loading />
          </div>
        ) : rowsData && rowsData.length === 0 ? (
          <>
            <NoResults />
          </>
        ) : (
          <div className="main-info-customers card-style-2 margin-top">
            <HeaderTableSearchFilter
              newClassHeaderContentSearch={"search-customer"}
              isTrueSearchInputFilter={true}
              functionSearchFilter={(e) => {
                const searchValue = e.target.value.toLowerCase();

                const filteredData = rowsData.filter((row) => {
                  return row?.name
                    .toString()
                    .toLowerCase()
                    .includes(searchValue);
                });
                setFilteredData(filteredData);
              }}
              functionIconSearch={() => {
                console.log("search");
              }}
              isTrueFilterButton={true}
              functionButtonFilter={() => setShowModalFilter(true)}
              isTrueHighestScore={true}
              functionButtonHighestScore={() => {}}
              isNewButton={false}
              isTrueContentLeftHeaderSearch={true}
              InfoContentLeftSearchFilter={
                <>
                  <ButtonsExport dataName={"customers"} dataExport={rowsData} />
                </>
              }
              contentNewButton={false}
            />
            <DataTable rowData={filteredData} columnDefs={columnDefs} />
          </div>
        )}
      </div>
    </>
  );
};

export default OrdersPage;
