import CustomModal from "Components/CustomModal/CustomModal";
import FormField from "Components/Forms/FormFiled";
import InputFiled from "Components/Forms/InputField";
import { updateLocaleOption } from "primereact/api";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Yup from "yup";
import axiosInstance from "./../../../../../../axios";
import { Rate } from "rsuite";

const ModalCurrenciesTab = ({
  showModal,
  hideModal,
  setCardCurrancy,
  editItem,
  cardCurrancy,
  refetch,
  setRefetch,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {t, i18n } = useTranslation();
  const [rate, setRate] = useState(editItem?.rate || 3);
  // {VALUE OF INPUTS}
  const validationSchema = Yup.object().shape({
    currencyArabicName: Yup.string().required(
      i18n.language === "ar"
        ? "اسم العملة العربي مطلوب"
        : "Currency Arabic name is required"
    ),
    currencyEnglishName: Yup.string().required(
      i18n.language === "ar"
        ? "اسم العملة الانجليزي مطلوب"
        : "Currency English name is required"
    ),
    pointsValue: Yup.string().required(
      i18n.language === "ar" ? "قيمة النقاط مطلوبة" : "Points value is required"
    ),
    pointsValue2: Yup.string().required(
      i18n.language === "ar" ? "قيمة النقاط مطلوبة" : "Points value is required"
    ),
    ValueCurrency: Yup.string().required(
      i18n.language === "ar" ? "قيمة العملة مطلوبة" : "currency value is required"
    ),
    symbol: Yup.string()
      .required(
        i18n.language === "ar" ? "رمز العملة مطلوب" : "Symbol is required"
      )
      .test(
        "notOneOf",
        i18n.language === "ar" ? "رمز العملة مكرر" : "Symbol is repeated",
        function (value) {
          if (editItem && editItem.symbol === value) {
            // If editItem is present and the symbol value is unchanged, consider it valid
            return true;
          }
          return !cardCurrancy.some((currency) => currency.symbol === value);
        }
      ),
  });

  const initialValues = {
    currencyArabicName:
      editItem && editItem.ar_title ? editItem.ar_title : "",
    currencyEnglishName:
      editItem && editItem.en_title ? editItem.en_title : "",
    pointsValue: editItem && editItem.money_to_points ? editItem.money_to_points : "",
    pointsValue2: editItem && editItem.point_to_money ? editItem.point_to_money : "",

    symbol: editItem && editItem.symbol ? editItem.symbol : "",
    ValueCurrency: editItem && editItem.rate ? editItem.rate : "",
  };
  // ONCLIK BUTTON SAVE
  const handleSubmit = async (values, { resetForm }) => {
    try {
      setLoading(true);
      const data = {
        en: {
          title: values.currencyEnglishName,
        },
        ar: {
          title: values.currencyArabicName,
        },
        status: 1,
        symbol: values.symbol,
        money_to_points: values.pointsValue,
        point_to_money: values.pointsValue2,
        rate: rate

      };

      if (editItem) {
        await axiosInstance.put(`/admin/currency/${editItem.id}`, data);
        toast.success(
          i18n.language === "ar" ? "تم التعديل بنجاح" : "Edited Successfully"
        );
      } else {
        await axiosInstance.post(`/admin/currency`, data);
        toast.success("تم الإضافة بنجاح");
      }

      resetForm();
      hideModal();
      setRefetch(!refetch);
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setError(error);
    } finally {
      setLoading(false); // Set loading state to false, whether the request succeeds or fails
    }
  };
  console.log(editItem);
  useEffect(() => {
    console.log(i18n.language);
  }, [i18n.language]);
  console.log(initialValues);
  return (
    <CustomModal
      show={showModal}
      onHide={hideModal}
      title={
        editItem?t("Edit currency"): t("Add a new currency")
        
        }
      newClass={"modal-currencies"}
    >
      <FormField
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <InputFiled
          label={
            i18n.language === "ar"
              ? "إسم العملة العربي"
              : "Currency Arabic name"
          }
          name="currencyArabicName"
          type="text"
          placeholder={
            i18n.language === "ar"
              ? "إسم العملة العربي"
              : "Currency Arabic name"
          }
          success
        />
        <InputFiled
          label={
            i18n.language === "ar"
              ? "إسم العملة الانجليزي"
              : "Currency English name"
          }
          name="currencyEnglishName"
          type="text"
          placeholder={
            i18n.language === "ar"
              ? "إسم العملة الانجليزي"
              : "Currency English name"
          }
          success
        />
        <InputFiled
          label={
            i18n.language === "ar"
              ? "قيمة النقاط لكل 1 من العملة"
              : "The value of points per 1 of currency"
          }
          name="pointsValue"
          type="number"
          placeholder={
            i18n.language === "ar"
              ? "قيمة النقاط لكل 1 من العملة"
              : "The value of points per 1 of currency"
          }
          success
        />
            <InputFiled
          label={
            i18n.language === "ar"
              ? "عدد النقاط لكل عملة     "
              : "The number of points for each currency"
          }
          name="pointsValue2"
          type="number"
          placeholder={
            i18n.language === "ar"
            ? "عدد النقاط لكل عملة     "
            : "The number of points for each currency"
          }
          success
        />
        <InputFiled
          label={i18n.language === "ar" ? "رمز العملة" : "Symbol"}
          name="symbol"
          type="text"
          placeholder={i18n.language === "ar" ? "رمز العملة" : "Symbol"}
          success
        />
  <InputFiled
          label={i18n.language === "ar" ? "قيمة العملة بالنسبة للريال عماني " : "The value of the currency in relation to the Omani Riyal"}
          name="ValueCurrency"
          type="text"
          placeholder={i18n.language === "ar" ? "القيمة " : "Value"}
          success
        />
        <button
          disabled={loading}
          type="submit"
          className="btn-main btn-submit w-100 mt-3"
        >
          {i18n.language === "ar" ? "حفظ" : "Save"}
        </button>
      </FormField>
    </CustomModal>
  );
};

export default ModalCurrenciesTab;
