import TabsEventsVendor from "./TabsEventsVendor";
import "./MainEventsVendor.css";

const MainEventsVendor = ({ setRefetch, refetch, events }) => {
  return (
    <div className="all-main-events margin-top">
      <TabsEventsVendor
        setRefetch={setRefetch}
        refetch={refetch}
        events={events}
      />
    </div>
  );
};

export default MainEventsVendor;
