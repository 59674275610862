import { useEffect, useState } from "react";
import IconEdit from "assets/Icons/IconEdit";
import HeaderContentTab from "../HeaderContentTab";
import PercentageIcon from "assets/Icons/PercentageIcon";
import ModalEditProfit from "./ModalEditProfit";
import axiosInstance from "./../../../../../../axios";
import { toast } from "react-toastify";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import { useTranslation } from "react-i18next";

const ProfitsTab = () => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [profit, setProfit] = useState(null);

  const showModal = (profit) => {
    setSelectedItem(profit);
  };

  const hideModalProfit = () => {
    setSelectedItem(null);
  };

  const handleUpdateItem = (numberPer) => {
    const updatedProfit = { ...profit, numberPer: numberPer };
    setProfit(updatedProfit);
    hideModalProfit();
  };

  const getProfits = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get("/admin/profit");
      setProfit(data?.profit);
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfits();
  }, [refetch]);

  return (
    <>
      {selectedItem && (
        <ModalEditProfit
          refetch={refetch}
          setRefetch={setRefetch}
          showModal={true}
          hideModal={hideModalProfit}
          selectedItem={selectedItem}
          handleUpdateItem={handleUpdateItem}
        />
      )}

      <div className="currencies-control">
        <HeaderContentTab
          textTitle="الأرباح"
          isShowButton={false}
          newClassButton={false}
          isTrueText={false}
          functionButton={false}
          isShowButtonProfit={false}
          functionButtonProfit={() => {}}
        />

        <div className="all-currencies-content">
          {loading ? (
            <Loading />
          ) : !profit ? (
            <NoResults />
          ) : (
            <div className="currency-card-one d-flex align-items-center justify-content-between gap-3 flex-wrap mt-4">
              <div className="right-content d-flex gap-2">
                <div className="icon-per">
                  <PercentageIcon />
                </div>
                <div className="top-content-info d-flex flex-column gap-2">
                  <h2 className="title">{t("profit")}</h2>
                  <p className="text fw-bold green-text">{profit}%</p>
                </div>
              </div>
              <div className="left-actions-content d-flex align-items-center gap-3">
                <div
                  onClick={() => showModal(profit)}
                  className="icon-one--1 icon-edit cursor-pointer-event"
                >
                  <IconEdit />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfitsTab;
