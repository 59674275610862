import iconSettings from "../../../assets/images/dashboard/icons/setting-4.svg";

import "./NavBarDashboard.css";
import DropDownLanguage from "Components/Languages/DropDownLanguage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import ModalNotifications from "Dashboard/Pages/Home/Components/ModalNotifications/ModalNotifications";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Cookies from "js-cookie";
import VendorModalNotifications from "DashboardVendor/Pages/Notifcation/Notifcation";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const NavBarDashboard = ({
  ContentInfo,
  toggleSidebar,
  isSettingTrue,
  imgInfo,
  functionImgNavBar,
  leftContentInfo,
  routeProfile,
}) => {
  const type = Cookies.get("type");
  const [language, setLanguage] = useState("ar");
  const { i18n } = useTranslation();
  useEffect(() => {
    const storedLanguage = localStorage.getItem("i18nextLng");
    if (storedLanguage && storedLanguage !== language) {
      setLanguage(storedLanguage);
      i18n.changeLanguage(storedLanguage);
      const newDirection = storedLanguage === "ar" ? "rtl" : "ltr";

      document.documentElement.setAttribute("lang", storedLanguage);
      document.documentElement.setAttribute("dir", newDirection);
    }
  }, []);
  return (
    <>
      {/* ======== START NABAR HEADER PAGE ========= */}
      <div className="navbar-header-page d-flex justify-content-between align-items-center">
        {/* ======== START NABAR RIGHT INFO  ========= */}
        <div className="navbar-right-info">
          {/* ======= START DETAILS USER ========== */}
          <div className="details-user">{ContentInfo}</div>
          {/* ======= END DETAILS USER ========== */}
        </div>
        {/* ======== END NABAR RIGHT INFO  ========= */}
        {/* ======== START NABAR RIGHT INFO  ========= */}
        <div className="navbar-left-info">
          {/* ======== START MAIN INFO DETAILS ======== */}
          <div className="main-info-details-nav d-flex gap-4 align-items-center">
            {leftContentInfo}
            <div
              className="icon-toggle-sidebar cursor-pointer-event"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon icon={faBars} />
            </div>
            {/* ========= START ICON NOTIFICATION ========== */}
            {type === "admin" ? (
              <ModalNotifications />
            ) : (
              <VendorModalNotifications />
            )}

            {/* ========= END ICON NOTIFICATION ========== */}
            {isSettingTrue && (
              <Link to="/dashboardAdmin/controlDashboard">
                {/* ========= START ICON NOTIFICATION ========== */}
                <div className="icon-settings cursor-pointer-event">
                  <img
                    src={iconSettings}
                    alt="notification icon"
                    width={"32px"}
                    height={"32px"}
                  />
                </div>
                {/* ========= END ICON NOTIFICATION ========== */}
              </Link>
            )}
            {/* ========= START IMAGE USER ========= */}
            <Dropdown className="drop-menu-content--1">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <div onClick={functionImgNavBar} className="image-user">
                  <img
                    src={localStorage?.userImg}
                    alt="img info"
                    width={"44px"}
                    height={"44px"}
                    className="img-user-nav rounded-circle object-fit-cover cursor-pointer-event"
                  />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link className="nav-link" to={routeProfile}>
                    صفحة المستخدم
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link className="nav-link" to="/">
                    تسجيل الخروج
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            {/* ========= END IMAGE USER ========= */}
            <DropDownLanguage newClass={"dropdown-lang-dashboard"} />
          </div>
          {/* ======== END MAIN INFO DETAILS ======== */}
        </div>
        {/* ======== END NABAR RIGHT INFO  ========= */}
      </div>
      {/* ======== END NABAR HEADER PAGE ========= */}
    </>
  );
};

export default NavBarDashboard;
