import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderHomeVendor from "./Components/HeaderHomeVendor/HeaderHomeVendor";
import MainHomeVendor from "./Components/MainHomeVendor/MainHomeVendor";
import { useEffect, useState } from "react";
import axiosInstance from "./../../../axios";
import { useSavedState } from "assets/hooks";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import { useTranslation } from "react-i18next";

const HomeVendor = () => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [reportsData, setReportsData, clearReportsData] = useSavedState(
    null,
    `ReportsVendorData`
  );
  const [walletData, setWalletData, clearCustomerData] = useSavedState(
    false,
    `WalletVendorData`
  );
  const getReportsData = async () => {
    try {
      setLoading(true);
      const { data: reportsData } = await axiosInstance.get("/store/reports");
      setReportsData(reportsData);

      const { data: walletData } = await axiosInstance.get("/store/wallet", {
        headers: {
          "Accept-Language": i18n.language,
        },
      });
      setWalletData(walletData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getReportsData();
  }, []);
  return (
    <>
      <HelmetInfo titlePage={"الصفحة الرئيسية"} />
      <div>
        <BreadcrumbPage
          routPageHome={"/dashboardVendor/homedashboardvendor"}
          showMainTextPage={false}
          routePage={false}
          mainPageText={false}
          showPageTwo={false}
          textPageTwo={false}
        />
        {loading && !reportsData ? (
          <div className="w-100 vh-100 bg-white">
            <Loading />
          </div>
        ) : reportsData ? (
          <>
            <header>
              <HeaderHomeVendor
                walletData={walletData}
                reportsData={reportsData}
              />
            </header>
            <main>
              <MainHomeVendor reportsData={reportsData} />
            </main>
          </>
        ) : (
          <NoResults />
        )}
      </div>
    </>
  );
};

export default HomeVendor;
