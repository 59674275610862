import React from "react";
import "./HeaderSection.css";
const HeaderSection = ({ title, isText, text }) => {
  return (
    <div className="header-section d-flex flex-column gap-2 align-items-center justify-content-center" data-aos="fade-down">
      <h2 className="title">{title}</h2>
      {isText && <p className="text">{text}</p>}
    </div>
  );
};

export default HeaderSection;
