import jsPDF from "jspdf";

import * as XLSX from "xlsx";
import "jspdf-autotable";
import HeaderDataExport from "./HeaderDataExport";
import autoTable from "jspdf-autotable";

const ButtonsExport = ({ dataName, dataExport }) => {
  const convertDataToTable = (data) => {
    const table = document.createElement("table");
    const headerRow = document.createElement("tr");

    const columns = Object.keys(data[0]);

    columns.forEach((column) => {
      const headerCell = document.createElement("th");
      headerCell.innerText = column;
      headerRow.appendChild(headerCell);
    });
    table.appendChild(headerRow);

    data.forEach((row) => {
      const dataRow = document.createElement("tr");
      columns.forEach((column) => {
        const dataCell = document.createElement("td");
        dataCell.innerText = row[column];
        dataRow.appendChild(dataCell);
      });
      table.appendChild(dataRow);
    });

    return table;
  };

  const handleExportExcel = () => {
    const table = convertDataToTable(dataExport);

    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, dataName ? `${dataName}.xlsx` : `exported_table.xlsx`);
  };

  const handleExportPDF = () => {
    const table = convertDataToTable(dataExport);

    const exportToPDF = () => {
      const doc = new jsPDF();
      autoTable(doc, { html: table });
      doc.save(dataName ? `${dataName}pdf.pdf` : "exported_table.pdf");
    };
    exportToPDF();
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch((error) => console.error("Failed to copy text: ", error));
  };

  const handleCopy = () => {
    copyToClipboard(JSON.stringify(dataExport));
  };

  const handlePrint = () => {
    const table = convertDataToTable(dataExport);

    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      printWindow.document.write(
        '<table border="1" style="border-collapse: collapse;">'
      );
      printWindow.document.write(table.innerHTML);
      printWindow.document.write("</table></body></html>");

      printWindow.document.close();
      printWindow.print();
      printWindow.close();
    } else {
      console.error("Failed to open print window.");
    }
  };
  return (
    <>
      <HeaderDataExport
        dataExport={dataExport}
        handleExportExcel={handleExportExcel}
        handleExportPDF={handleExportPDF}
        handleCopy={handleCopy}
        handlePrint={handlePrint}
      />
    </>
  );
};

export default ButtonsExport;
