import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import InformationTab from "./DataTab/InformationTab/InformationTab";
import PhotoGalleryVendor from "./DataTab/PhotoGalleryVendor/PhotoGalleryVendor";
import BranchesVendor from "./DataTab/BranchesVendor/BranchesVendor";
import BankAccountsVendor from "./DataTab/BankAccountsVendor/BankAccountsVendor";
import { useTranslation, Trans } from "react-i18next";

const TabsPersonalVendor = ({ profileDetails, refetch, setRefetch }) => {
  const { data } = profileDetails;
  const { t } = useTranslation();

  const tabInfo = [
    {
      eventKey: "informations",
      title: t("information"),
      tabInfo: (
        <InformationTab
          refetch={refetch}
          setRefetch={setRefetch}
          profileDetails={data}
        />
      ),
    },
    {
      eventKey: "branches",
      title: t("branches"),
      tabInfo: <BranchesVendor />,
    },
    {
      eventKey: "photoGallery",
      title: t("photoGallery"),
      tabInfo: (
        <PhotoGalleryVendor
          refetch={refetch}
          setRefetch={setRefetch}
          profileDetails={data}
        />
      ),
    },
    // {
    //   eventKey: "bankAccount",
    //   title: t("bankAccounts"),
    //   tabInfo: <BankAccountsVendor />,
    // },
  ];

  return (
    <div className="all-tabs-items card-style-2 margin-top position-relative">
      <TabsContentTb tabActive={"informations"}>
        {tabInfo.map((item) => {
          return (
            <Tab
              key={item.eventKey}
              eventKey={item.eventKey}
              title={item.title}
            >
              {item.tabInfo}
            </Tab>
          );
        })}
      </TabsContentTb>
    </div>
  );
};

export default TabsPersonalVendor;
