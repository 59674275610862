import React from "react";

const StarOuroIcon = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2401 23.4312C15.8101 23.4312 15.2701 23.3012 14.6001 22.9012L12.3601 21.5712C12.1901 21.4712 11.8101 21.4712 11.6401 21.5712L9.40013 22.9012C7.98013 23.7412 7.12012 23.3913 6.74012 23.1113C6.36012 22.8313 5.76014 22.1112 6.13014 20.5112L6.66014 18.2012C6.70014 18.0212 6.60013 17.6812 6.47013 17.5412L4.61012 15.6812C3.61012 14.6812 3.69012 13.8112 3.83012 13.3712C3.97012 12.9312 4.41012 12.1812 5.80012 11.9412L8.19014 11.5412C8.36014 11.5112 8.63014 11.3112 8.70014 11.1612L10.0201 8.52124C10.6701 7.23124 11.5301 7.03125 11.9901 7.03125C12.4501 7.03125 13.3101 7.22124 13.9601 8.52124L15.2801 11.1612C15.3601 11.3112 15.6301 11.5112 15.7901 11.5412L18.1801 11.9412C19.5701 12.1712 20.0101 12.9312 20.1501 13.3712C20.2901 13.8112 20.3701 14.6812 19.3701 15.6812L17.5101 17.5412C17.3801 17.6712 17.2801 18.0212 17.3201 18.2012L17.8501 20.5013C18.2201 22.1113 17.6301 22.8212 17.2401 23.1012C17.0601 23.2612 16.7201 23.4312 16.2401 23.4312ZM12.0001 20.0013C12.4001 20.0013 12.8001 20.0913 13.1201 20.2812L15.3601 21.6113C15.9501 21.9613 16.3001 21.9612 16.3701 21.9012C16.4501 21.8412 16.5501 21.5112 16.4001 20.8512L15.8701 18.5512C15.7101 17.8712 15.9701 16.9913 16.4601 16.5013L18.3201 14.6413C18.6901 14.2713 18.7701 13.9812 18.7301 13.8512C18.6901 13.7212 18.4601 13.5312 17.9401 13.4512L15.5501 13.0512C14.9101 12.9412 14.2301 12.4413 13.9401 11.8613L12.6201 9.22125C12.3901 8.77125 12.1401 8.56125 11.9901 8.56125C11.8401 8.56125 11.5901 8.76125 11.3601 9.22125L10.0401 11.8613C9.75014 12.4413 9.07013 12.9412 8.43013 13.0512L6.04014 13.4512C5.52014 13.5412 5.29013 13.7212 5.25013 13.8512C5.21013 13.9812 5.29014 14.2713 5.66014 14.6413L7.52012 16.5013C8.01012 16.9913 8.27012 17.8812 8.11012 18.5512L7.58012 20.8613C7.43012 21.5313 7.53012 21.8612 7.61012 21.9112C7.69012 21.9712 8.04013 21.9712 8.62013 21.6212L10.8601 20.2912C11.2001 20.1012 11.6001 20.0013 12.0001 20.0013Z"
        fill="currentColor"
      ></path>
      <path
        d="M6 10.5112C5.59 10.5112 5.25 10.1712 5.25 9.76123V2.76123C5.25 2.35123 5.59 2.01123 6 2.01123C6.41 2.01123 6.75 2.35123 6.75 2.76123V9.76123C6.75 10.1712 6.41 10.5112 6 10.5112Z"
        fill="currentColor"
      ></path>
      <path
        d="M18 10.0112C17.8661 10.0112 17.75 9.89509 17.75 9.76123V2.76123C17.75 2.62737 17.8661 2.51123 18 2.51123C18.1339 2.51123 18.25 2.62737 18.25 2.76123V9.76123C18.25 9.89509 18.1339 10.0112 18 10.0112Z"
        stroke="currentColor"
      ></path>
      <path
        d="M12 5.51123C11.59 5.51123 11.25 5.17123 11.25 4.76123V2.76123C11.25 2.35123 11.59 2.01123 12 2.01123C12.41 2.01123 12.75 2.35123 12.75 2.76123V4.76123C12.75 5.17123 12.41 5.51123 12 5.51123Z"
        fill="currentColor"
      ></path>
    </svg>
  );
};

export default StarOuroIcon;
