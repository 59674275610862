import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React from "react";
import HeaderAffiliatesCards from "./HeaderAffiliatesCards";
import TableAffiliates from "./TableAffiliates";

const AffiliatesPage = () => {
  return (
    <>
      <HelmetInfo titlePage={"المسوقين بالعمولة"} />
      <BreadcrumbPage
        routPageHome="/dashboardAdmin/homedashboard"
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={"المسوقين بالعمولة"}
      />
      <header>
        <HeaderAffiliatesCards />
      </header>
      <main>
        <TableAffiliates />
      </main>
    </>
  );
};

export default AffiliatesPage;
