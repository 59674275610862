import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React, { useEffect, useState, useMemo } from "react";
import HeaderCards from "./Components/HeaderCards/HeaderCards";
import ModalDeleteCustomer from "./Components/ModalsCustomers/ModalDeleteCustomer";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import TableCustomers from "./Components/TableCustomers/TableCustomers";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import axiosInstance from "../../../axios";
import { toast } from "react-toastify";
import { AgGridReact } from "ag-grid-react";
import EyeIcon from "assets/Icons/EyeIcon";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import { useTranslation } from "react-i18next";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import DataTable from "Components/DataTable/DataTable";
import IconEdit from "assets/Icons/IconEdit";
import TrashIcon from "assets/Icons/TrashIcon";
import CustomModal from "Components/CustomModal/CustomModal";
import ModalEditContentGift from "./Components/ClientFile/ModalEditContent";
import ModalAddContent from "./Components/ClientFile/ModalAddContent";

const GiftsPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalDeleteCustomer, setModalDeleteCustomer] = useState(false);
  const [rowsData, setRowsData] = useState(null);
  const [customersData, setCustomersData] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);
  const [showModalEditContent, setShowModalEditContent] = useState(false);
  const [showModalAddContent, setShowModalAddContent] = useState(false);
  const [EditItem, setEditItem] = useState(null);
  const hideModalEdit = () => {
    setShowModalEditContent(false);
  };
  const handleDeleteItem = async (id) => {
    try {
      const respone = await axiosInstance.delete(`admin/gift/${id}`);
      setRefetch(!refetch);
      toast.success(
        `${
          i18n.language === "ar" ? "تم الحذف بنجاح .." : "Deleted Successfully"
        }`,
        { theme: "dark" }
      );
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }

    setDeleteModal(false);
  };
  const columnDefs = useMemo(
    () => [
      {
        headerName: i18n.language === "ar" ? "#" : "#",
        field: "id",
        width: "100px",
      },
      {
        headerName: t("Gift Title in Arabic"),
        field: "ar_title",
        flex: 1,
        cellRenderer: (params) => (
          <div className=" cursor-pointer-event">{params.data.ar_title}</div>
        ),
      },
      {
        headerName: t("Gift Title in English"),
        field: "en_title",
        flex: 1,
        cellRenderer: (params) => (
          <div className=" cursor-pointer-event">{params.data.en_title}</div>
        ),
      },
      {
        headerName: t("Arabic Image"),
        field: "ar_image",
        flex: 1,
        cellRenderer: (params) => (
          <a href={params.data.ar_image}>
            <img
              src={params.data.ar_image}
              style={{ borderRadius: "6px", height: "40px", width: "40px" }}
            />
          </a>
        ),
      },
      {
        headerName: t("English Image"),
        field: "en_image",
        flex: 1,
        cellRenderer: (params) => (
          <a href={params.data.en_image}>
            <img
              src={params.data.en_image}
              style={{ borderRadius: "6px", height: "40px", width: "40px" }}
            />
          </a>
        ),
      },
      {
        headerName: t("points"),
        field: "points",
        width: "200px",
      },

      {
        headerName: t("date"),
        field: "date",
        flex: 1,
      },
      {
        headerName: t(""),
        field: "action",
        flex: 1,
        cellRenderer: (params) => (
          <div className="d-flex gap-3">
            <div
              onClick={() => {
                setEditItem(params.data);
                setShowModalAddContent(true);
              }}
            >
              <IconEdit />
            </div>

            <div
              onClick={() => {
                setDeleteModal(true);
                setDeletedIndex(params.data.id);
              }}
              className="icon-one--1 icon-trash cursor-pointer-event"
            >
              <TrashIcon />
            </div>
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  const getCustomersData = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/admin/gift`, {
        headers: {
          "Accept-Language": i18n.language,
        },
      });
      console.log(data);
      setRowsData(
        data?.data?.map((item) => ({
          id: item.id,

          name: item?.title,

          ar_title: item.ar_title,
          en_title: item.en_title,
          ar_image: item.ar_image,
          en_image: item.en_image,
          Branch: item?.branch?.address,
          points: item?.points,
          price: item?.price,
          Ouro: item.ouro,
          payment_method: item?.payment_method,
          date: moment(item.created_at).format("DD-MM-YYY , hh mm"),
        }))
      );
      setCustomersData(data && data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!rowsData) return;

    setFilteredData(rowsData);
  }, [rowsData, i18n.language]);

  useEffect(() => {
    getCustomersData();
  }, [refetch]);

  return (
    <>
      <HelmetInfo titlePage={t("Orders")} />
      <div className="d-flex justify-content-between align-items-center mt-2">
        <BreadcrumbPage
          routPageHome="/dashboardAdmin/homedashboard"
          showMainTextPage={false}
          routePage={false}
          mainPageText={false}
          showPageTwo={true}
          textPageTwo={t("Gifts")}
        />
        <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
          <button
            onClick={() => setShowModalAddContent(true)}
            className="btn-send btn-main"
          >
            {t("add New Gift")}
          </button>
        </div>
      </div>
      <ModalDeleteCustomer
        showModalDeleteCustomer={showModalDeleteCustomer}
        hideModalDeleteCustomer={() => setModalDeleteCustomer(false)}
      />

      <ModalFilterContentInfo
        setShowModalFilter={setShowModalFilter}
        showModalFilter={showModalFilter}
        selectCountry={true}
        selectCategory={false}
        selectPay={false}
      />

      <ModalEditContentGift
        hideModalEdit={hideModalEdit}
        showModalEdit={showModalEditContent}
        item={EditItem}
      />
      <div className="all-customer-page-info">
        {/* <HeaderCards customersData={customersData} /> */}
        {loading ? (
          <div className="w-100 bg-white">
            <Loading />
          </div>
        ) : rowsData && rowsData.length === 0 ? (
          <>
            <NoResults />
          </>
        ) : (
          <div className="main-info-customers card-style-2 margin-top">
            <HeaderTableSearchFilter
              newClassHeaderContentSearch={"search-customer"}
              isTrueSearchInputFilter={true}
              functionSearchFilter={(e) => {
                const searchValue = e.target.value.toLowerCase();

                const filteredData = rowsData.filter((row) => {
                  return row?.name
                    .toString()
                    .toLowerCase()
                    .includes(searchValue);
                });
                setFilteredData(filteredData);
              }}
              functionIconSearch={() => {
                console.log("search");
              }}
              isTrueFilterButton={true}
              functionButtonFilter={() => setShowModalFilter(true)}
              isTrueHighestScore={true}
              functionButtonHighestScore={() => {}}
              isNewButton={false}
              isTrueContentLeftHeaderSearch={true}
              InfoContentLeftSearchFilter={
                <>
                  <ButtonsExport dataName={"customers"} dataExport={rowsData} />
                </>
              }
              contentNewButton={false}
            />
            <DataTable rowData={filteredData} columnDefs={columnDefs} />
          </div>
        )}
      </div>
      <CustomModal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
        }}
        title={i18n.language === "ar" ? "حذف" : "Delete"}
        newClass={"modal-inter"}
      >
        <div className="row w-50 justify-content-around mx-auto">
          <p className="text-center">
            {i18n.language === "ar"
              ? "هل انت متاكد من الحذف ؟"
              : "Are You Sure Want To Delete"}
          </p>
          <button
            onClick={() => {
              handleDeleteItem(deletedIndex);
            }}
            className="col-md-5 my-3 btn btn-danger"
          >
            {i18n.language === "ar" ? "حذف" : "Delete"}
          </button>
          <button
            onClick={() => {
              setDeleteModal(false);
            }}
            className="col-md-5 my-3 btn btn-outline-dark"
          >
            {i18n.language === "ar" ? "الغاء" : "Cancel"}
          </button>
        </div>
      </CustomModal>
      <ModalAddContent
        getInterests={getCustomersData}
        editItem={EditItem}
        showModalInter={showModalAddContent}
        hideModalInter={() => {
          setShowModalAddContent(false);
        }}
      />
    </>
  );
};

export default GiftsPage;
