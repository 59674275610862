import DownloadIcon from "assets/Icons/DownloadIcon";
import React, { Component } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

export default function BarChart({ reportsDats }) {
  const { t } = useTranslation();

  // Check if reportsDats is undefined or null, and provide an empty object as a default value
  const monthlyData = reportsDats?.monthly || {};

  const getMonths = () => {
    const months = Object.keys(monthlyData); // Extract month names from the monthly object
    return months || [];
  };

  const getProfitchart = () => {
    const customerData = Object.values(monthlyData).map(
      (month) => month?.profit
    );
    return customerData || [];
  };

  const Chartsdata = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        type: "category",
        categories: getMonths(),
      },
    },
    series: [
      {
        name: t("profits"),
        data: getProfitchart(),
      },
    ],
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
  return (
    <>
      <div className="chart-info card-style-1">
        {/* =========== START HEADER CHART INFO =========== */}
        <div className="header-chart-info d-flex justify-content-between align-items-center flex-wrap gap-3 mb-3">
          <div className="right-info-head d-flex flex-column gap-2">
            <h2 className="title"> {t("netProfts")}</h2>
            {/* <div className="main-info-bottom d-flex align-items-center gap-2">
              {t("invoicesNuber")}
            </div> */}
          </div>
        </div>
        {/* =========== END HEADER CHART INFO =========== */}
        <div id="chart">
          <Chart
            options={Chartsdata?.options}
            series={Chartsdata?.series}
            type="bar"
            height={350}
          />
        </div>
      </div>
    </>
  );
}
