import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderTechnicalSupport from "./Components/HeaderTechnicalSupport";
import TabTechnicalSupportPage from "./Components/TabsTechnicalSupport/TabTechnicalSupport";

import { Link } from "react-router-dom";
import { faRocketchat } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import "./TechnicalSupportPage.css";
import axiosInstance from "../../../axios";
import { useEffect, useState } from "react";
import ModalEditContent from "./Components/ModalEditContent";
import { useTranslation } from "react-i18next";
const TechnicalSupportVendor = () => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [ticketsdata, setTicketsData] = useState();
  const refetchData = () => {
    axiosInstance.get("/store/ticket").then((res) => {
      console.log(res.data);
      setData(res.data.data);
    });
  };
  useEffect(() => {
    axiosInstance.get("/store/ticket").then((res) => {
      console.log(res.data);
      setData(res?.data?.data);
      setTicketsData(res?.data);
    });
  }, []);
  const [showModalEdit, setShowModalEdit] = useState(false);

  return (
    <>
      <HelmetInfo titlePage={"الدعم الفني"} />
      <ModalEditContent
        refetchData={refetchData}
        showModalEdit={showModalEdit}
        hideModalEdit={() => setShowModalEdit(false)}
      />
      <div className="d-flex justify-content-between">
        <BreadcrumbPage
          routPageHome="/dashboardAdmin/homedashboard"
          showMainTextPage={false}
          routePage={false}
          mainPageText={false}
          showPageTwo={true}
          textPageTwo={"الدعم الفني"}
        />
        <div>
          <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
            <button
              onClick={() => setShowModalEdit(true)}
              className="btn-send btn-main"
            >
              {t("Send New Ticket")}
            </button>
          </div>
        </div>
      </div>
      <header>
        <HeaderTechnicalSupport data={ticketsdata} />
      </header>
      <main>
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="tooltip">الدعم</Tooltip>}
        >
          <Link
            to="/dashboardAdmin/technicalSupportVendor/chatTech"
            className="chat-button"
          >
            <FontAwesomeIcon icon={faRocketchat} />
          </Link>
        </OverlayTrigger>

        <TabTechnicalSupportPage data={data} />
      </main>
    </>
  );
};

export default TechnicalSupportVendor;
