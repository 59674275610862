import CustomModal from "Components/CustomModal/CustomModal";
import "./ModalAddNewCard.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../../../../../node_modules/filepond/dist/filepond.min.css";
import "../../../../../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { toast } from "react-toastify";
import axiosInstance from "../../../../axios";
import FileUpload from "Dashboard/Components/Ui/FileUpload/FileUpload";
import { useTranslation } from "react-i18next";
registerPlugin(FilePondPluginImagePreview, FilePondPluginImageExifOrientation);

const ModalAddNewCard = ({
  showModalAddNewCard,
  hideModalAddNewCard,
  handleSave,
  activeCardforEdit,
  refetch,
}) => {
  const [image, setImage] = useState(null);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const handleImageChange = (files) => {
    if (files.length > 0 && files[0].file) {
      const file = files[0].file;
      if (file instanceof Blob) {
        const imageUrl = URL.createObjectURL(file);
        setImage(imageUrl);
      } else {
        console.error("Invalid file type");
      }
    } else {
      setImage(null);
    }
  };

  const initialValues = {
    arabicName: activeCardforEdit?.ar_title,
    englishName: activeCardforEdit?.en_title,
    arabicContent: activeCardforEdit?.ar_content,
    englishContent: activeCardforEdit?.en_content,

    yearly_price: activeCardforEdit?.yearly_price,
    monthly_price: activeCardforEdit?.monthly_price,
    cash_back: activeCardforEdit?.cash_back,
  };

  const validationSchema = Yup.object().shape({
    arabicName: Yup.string().required("الرجاء إدخال إسم البطاقة بالعربية"),
    englishName: Yup.string().required("الرجاء إدخال إسم البطاقة بالانجليزية"),
    arabicContent: Yup.string().required("الرجاء إدخال محتوي البطاقة بالعربية"),
    englishContent: Yup.string().required(
      "الرجاء إدخال محتوي البطاقة بالانجليزية"
    ),

    yearly_price: Yup.number().required("الرجاء إدخال الاشتراك السنوي"),
    monthly_price: Yup.number().required("الرجاء إدخال الاشتراك الشهري"),
    cash_back: Yup.number().required("الرجاء إدخال الاشتراك الشهري"),
  });

  const saveData = (values) => {
    const formData = new FormData();

    formData.append("ar[title]", values.arabicName);
    formData.append("en[title]", values.englishName);
    formData.append("ar[content]", values.arabicContent);
    formData.append("en[content]", values.englishContent);
    formData.append("yearly_price", values.yearly_price);
    formData.append("monthly_price", values.monthly_price);
    formData.append("cash_back", values.cash_back);
    if (image?.file) {
      formData.append("image", image?.file);
    }

    setLoading(true);
    axiosInstance
      .post(`/admin/updatecard/${activeCardforEdit?.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        toast.success("تم الحفظ بنجاح.");
        hideModalAddNewCard();
        refetch();
        setLoading(false);
      })
      .catch(() => {
        toast.error("حدث خطأ ما الرجاء المحاولة مرة اخري");
        setLoading(false);
      });

    // if (image) {
    //   const data = {
    //     image: image,
    //     ...values
    //   };
    //   handleSave(data);
    //   toast.success("تم الحفظ بنجاح.");
    //   hideModalAddNewCard();
    // }
  };

  return (
    <CustomModal
      show={showModalAddNewCard}
      onHide={hideModalAddNewCard}
      title={"  تعديل"}
      newClass={"modal-add-new-card"}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={saveData}
      >
        {({ values, errors, touched }) => (
          <Form>
            <div className="mb-3">
              <label htmlFor="arabicName" className="form-label">
                إسم البطاقة بالعربية
              </label>
              <Field
                type="text"
                name="arabicName"
                placeholder="إسم البطاقة"
                className={`form-control ${
                  errors.arabicName && touched.arabicName ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="arabicName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="englishName" className="form-label">
                إسم البطاقة بالانجليزية
              </label>
              <Field
                type="text"
                name="englishName"
                placeholder="إسم البطاقة"
                className={`form-control ${
                  errors.englishName && touched.englishName ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="englishName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="arabicContent" className="form-label">
                محتوي البطاقة بالعربية
              </label>
              <Field
                as="textarea"
                name="arabicContent"
                placeholder="مميزات البطاقة"
                className={`form-control ${
                  errors.arabicContent && touched.arabicContent
                    ? "is-invalid"
                    : ""
                }`}
              />
              <ErrorMessage
                name="arabicContent"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="englishContent" className="form-label">
                محتوي البطاقة بالانجليزية
              </label>
              <Field
                as="textarea"
                name="englishContent"
                placeholder="مميزات البطاقة"
                className={`form-control ${
                  errors.englishContent && touched.englishContent
                    ? "is-invalid"
                    : ""
                }`}
              />
              <ErrorMessage
                name="englishContent"
                component="div"
                className="invalid-feedback"
              />
            </div>
            {/* <div className="mb-3">
              <label htmlFor="points" className="form-label">
                  النقاط
              </label>
              <Field
                type="number"
                name="points"
                placeholder="إسم البطاقة"
                className={`form-control ${errors.englishName && touched.englishName ? "is-invalid" : ""}`}
              />
              <ErrorMessage name="englishName" component="div" className="invalid-feedback" />
            </div> */}
            <div className="mb-3">
              <label htmlFor="monthly_price" className="form-label">
                الاشتراك الشهري
              </label>
              <Field
                type="number"
                name="monthly_price"
                placeholder="إسم البطاقة"
                className={`form-control ${
                  errors.englishName && touched.englishName ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="englishName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="yearly_price" className="form-label">
                {t("yearly Subscription")}
              </label>
              <Field
                type="number"
                name="yearly_price"
                placeholder={t("yearly Subscription")}
                className={`form-control ${
                  errors.englishName && touched.englishName ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="englishName"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="cash_back" className="form-label">
                {t("Cash Back")}
              </label>
              <Field
                type="number"
                name="cash_back"
                placeholder="كاش باك "
                className={`form-control ${
                  errors.cash_back && touched.cash_back ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                name="cash_back"
                component="div"
                className="invalid-feedback"
              />
            </div>
            {/* File upload */}
            {/* <div className="file-pond-upload">
              <label htmlFor="exampleFormControlInputPicFile" className="form-label">
                أضف صورة البطاقة
              </label>
              <FilePond
                allowMultiple={true}
                acceptedFileTypes={["image/*"]}
                onupdatefiles={handleImageChange}
                maxFiles={3}
                server="/api"
                name="files"
                labelIdle={`أضف صورة البطاقة`}
                className="file-upload--1"
              />
            </div> */}
            <div className="my-2">
              <FileUpload setImage={setImage} allowMultiple={false} />{" "}
            </div>

            <button
              disabled={loading}
              type="submit"
              className="btn-main w-100 mt-3"
            >
              حفظ
            </button>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default ModalAddNewCard;
