import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeaderEventsMain from "./Components/HeaderEventsMain/HeaderEventsMain";
import EventsMainTab from "./Components/EventsMainTab/EventsMainTab";
import axiosInstance from "./../../../axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loading from "./../../Components/LottieFiles/Loading";
import { useTranslation } from "react-i18next";

const EventsMain = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState(false);
  const [refetch, setRefetch] = useState(false);

  const getAllEvents = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/admin/event`);
      setEvents(data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getAllEvents();
  }, [refetch]);
  return (
    <>
      <HelmetInfo titlePage={t("Events")} />
      <BreadcrumbPage
        routPageHome="/dashboardAdmin/homedashboard"
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={t("Events")}
      />
      {loading ? (
        <div className="w-100 bg-white">
          <Loading />
        </div>
      ) : (
        <>
          <header>
            <HeaderEventsMain events={events} />
          </header>
          <main>
            <EventsMainTab
              setRefetch={setRefetch}
              refetch={refetch}
              events={events}
            />
          </main>
        </>
      )}
    </>
  );
};

export default EventsMain;
