import { useEffect, useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import { useTranslation } from "react-i18next";
import DataTable from "Components/DataTable/DataTable";
import moment from "moment";
const PendingActivities = ({ orders }) => {
  const { t } = useTranslation();
  const [rowsData, setRowsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fittedData = orders?.map((ad) => ({
      id: ad?.id,
      order: ad?.order,
      payment_status: ad?.payment_status,
      start_date: ad?.start_date,
      end_date: ad?.end_date,
      price: ad?.price,
      duration: ad?.duration,
      created_at: moment(ad?.created_at).format("DD-MM-YYYY , hh:mm A"),
    }));
    setRowsData(fittedData);
    setFilteredData(fittedData);
  }, [orders]);

  const columnDefs = [
    {
      headerName: t("ID"),
      field: "id",
      flex: 0.5,
    },

    {
      headerName: t("price"),
      field: "price",
      flex: 0.5,
    },
    {
      headerName: t("arrangeNumber"),
      field: "order",
      flex: 1,
    },
    {
      headerName: t("duration"),
      field: "duration",
      flex: 0.8,
      cellRenderer: (params) => (
        <div className="btn px-4 fw-semibold mx-auto btn btn-primary text-white cursor-default">
          {`${params?.data?.duration}  ${t("days")}`}
        </div>
      ),
    },
    {
      headerName: t("payment_status"),
      field: "payment_status",
      flex: 1,
      cellRenderer: (params) => (
        <div
          style={{
            backgroundColor:
              params?.data?.payment_status === 1 ? "#32a8401a" : "#a832321a",
            color: params?.data?.payment_status === 1 ? "#32a840" : "#9c0c19",
          }}
          className="btn px-4 fw-semibold mx-auto cursor-default"
        >
          {params?.data?.payment_status === 1 ? t("done") : t("failed")}
        </div>
      ),
    },
    {
      headerName: t("start_date"),
      field: "start_date",
      flex: 1,
    },
    {
      headerName: t("end_date"),
      field: "end_date",
      flex: 1,
    },

    {
      headerName: t("created_at"),
      field: "created_at",
      flex: 1,
    },
  ];

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };

  return (
    <>
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={true}
          selectCategory={false}
          selectPay={false}
        />
      </div>
      <div className="main-pending-active">
        <div className="all-data-table-shop all-table-notification all-table-pending all-table-p card-style-2 table-border--1 margin-top">
          <HeaderTableSearchFilter
            newClassHeaderContentSearch={"search-customer"}
            isTrueSearchInputFilter={true}
            functionIconSearch={() => {
              console.log("search");
            }}
            functionSearchFilter={(e) => {
              const searchValue = e.target.value.toLowerCase();

              const filteredData = rowsData.filter((row) => {
                return (
                  row.price.toString().includes(searchValue) ||
                  row.order.toString().includes(searchValue)
                );
              });
              setFilteredData(filteredData);
            }}
            isTrueContentLeftHeaderSearch={true}
            InfoContentLeftSearchFilter={
              <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                <ButtonsExport
                  dataName={"pending events"}
                  dataExport={filteredData}
                />
              </div>
            }
          />

          <DataTable rowData={filteredData} columnDefs={columnDefs} />
        </div>
      </div>
    </>
  );
};

export default PendingActivities;
