import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import HeaderContentTab from "../HeaderContentTab";
import { useEffect, useState } from "react";
import ModalAddNewInterest from "./ModalAddNewInterest";
import { useTranslation } from "react-i18next";
import IconEdit from "assets/Icons/IconEdit";
import TrashIcon from "assets/Icons/TrashIcon";
import { toast } from "react-toastify";
import CustomModal from "Components/CustomModal/CustomModal";
import axiosInstance from "./../../../../../../axios";

const InterestsTab = () => {
  const { i18n ,t} = useTranslation();
  const [editItem, setEditItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [itemsCard, setItemsCard] = useState([]);
  const [refetch, setRefetch] = useState(false);

  const getInterests = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/admin/interest`);

      const modifiedData = data.data.map((crr) => ({
        ...crr,
        title: {
          en: crr.title ? crr.title : "enTitle",
          ar: crr.title ? crr.title : "arTitle",
        },
      }));
      setItemsCard(modifiedData);
     
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getInterests();
  }, [refetch]);

  const handleCheckboxChangeCard = (itemId1) => {
    console.log(itemId1)
    // setItemsCard((prevItems1) =>
    //   prevItems1.map((item1) =>
    //     item1.id === itemId1
    //       ? { ...item1, status: item1.status === 1 ? 0 : 1 }
    //       : item1
    //   )
    // );
    const item = itemsCard.find((item) => item.id === itemId1);
    console.log(item)
    axiosInstance.post("/admin/intereststatus",{
      interest_id:itemId1,
      status : item?.status === 1 ? 0 : 1
    })
    .then(()=>{
      getInterests()
      toast.success(t("the status updated successfully"))
    })
  };

  // MODAL
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);

  const showModalInterest = () => {
    setShowModal(true);
  };
  const showEditModal = (item) => {
    setEditItem(item);
    setShowModal(true);
  };
  const handleDeleteItem = (id) => {

    axiosInstance.delete(`/admin/interest/${id}`).then(()=>{
      getInterests()
      toast.success(
        `${i18n.language === "ar" ? "تم الحذف بنجاح .." : "Deleted Successfully"}`
      );
      setDeleteModal(false);
    });
 
 
  
  };
  const hideModalInter = () => {
    setEditItem(null);
    setShowModal(false);
  };

  // ADD NEW CATEGORY INTERSTES

  const addCategory = (newItem) => {
    setItemsCard([...itemsCard, newItem]);
  };
  return (
    <>
      <CustomModal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
        }}
        title={i18n.language === "ar" ? "حذف" : "Delete"}
        newClass={"modal-inter"}
      >
        <div className="row w-50 justify-content-around mx-auto">
          <p className="text-center">
            {i18n.language === "ar"
              ? "هل انت متاكد من الحذف ؟"
              : "Are You Sure Want To Delete ? "}
          </p>
          <button
            onClick={() => {
              handleDeleteItem(deletedIndex);
            }}
            className="col-md-5 my-3 btn btn-danger"
          >
            {i18n.language === "ar" ? "حذف" : "Delete"}
          </button>
          <button
            onClick={() => {
              setDeleteModal(false);
            }}
            className="col-md-5 my-3 btn btn-outline-dark"
          >
            {i18n.language === "ar" ? "الغاء" : "Cancel"}
          </button>
        </div>
      </CustomModal>

      <ModalAddNewInterest
        itemsCard={itemsCard}
        setItemsCard={setItemsCard}
        editItem={editItem}
        showModalInter={showModal}
        hideModalInter={hideModalInter}
        addCategory={addCategory}
        refetch={refetch}
        setRefetch={setRefetch}
        getInterests={getInterests}
      />

      <HeaderContentTab
        textTitle={i18n.language === "ar" ? "الإهتمامات" : "Iterests"}
        isShowButton={true}
        newClassButton={false}
        isTrueText={false}
        functionButton={() => {
          showModalInterest();
        }}
        isShowButtonProfit={false}
        functionButtonProfit={false}
      />

      <div className="all-interests-card margin-top p-2">
        {itemsCard.map((itemsCard) => (
          <>
            {/* ========== START PURCHASE CARD ONE ========= */}
            <div
              key={itemsCard.id}
              className="purchase-card-one d-flex  justify-content-between  align-items-center  gap-3 flex-wrap mb-3"
            >
              {/* ========= START INFO RIGHT CONTENT ============ */}
              <div className="info-right-content row  w-25  align-items-center ">
                <h2 className=" col-md-6 fs-5 fw-medium  text-dark ">
                  {i18n.language === "ar" ? (
                    <>{itemsCard.ar_title}</>
                  ) : (
                    <>{itemsCard.en_title}</>
                  )}
                </h2>
                {/* <div className="col-md-6 rounded-circle  ">
                  {itemsCard.image && (
                    <img
                      className=" object-fit-cover rounded"
                      width={"44px"}
                      height={"44PX"}
                      src={itemsCard.image}
                      alt=""
                    />
                  )}
                </div> */}
              </div>
              {/* ========= END INFO RIGHT CONTENT ============ */}
              {/* ========= START ICON SWITCH ========== */}
              <div className="left-actions-content d-flex  w-25 justify-content-center align-items-center gap-3">
                <div
                  className="icon-one--1 icon-edit  cursor-pointer-event"
                  onClick={() => showEditModal(itemsCard)}
                >
                  <IconEdit />
                </div>
                <div
                  className="icon-one--1 icon-trash cursor-pointer-event"
                  onClick={() => {
                    setDeleteModal(true);
                    setDeletedIndex(itemsCard.id);
                  }}
                >
                  <TrashIcon />
                </div>
              </div>
              <div className="w-25 d-flex justify-content-end">
                <CheckboxSwitch
                  key={itemsCard.id}
                  id={itemsCard.id}
                  isChecked={itemsCard.status}
                  handleCheckboxChange={()=>{
                    handleCheckboxChangeCard(itemsCard.id)
                  }}
                />
              </div>

              {/* ========= END ICON SWITCH ========== */}
            </div>
            {/* ========== END PURCHASE CARD ONE ========= */}
          </>
        ))}
      </div>
    </>
  );
};

export default InterestsTab;
