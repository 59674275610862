import TabsMainMarketing from "./TabsMainMarketing/TabsMainMarketing";

const MainMarketingCamp = ({data}) => {
  return (
    <div className="all-main-events margin-top">
      <TabsMainMarketing data={data} />
    </div>
  );
};

export default MainMarketingCamp;
