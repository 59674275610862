import img from "../../../../../assets/images/main/04.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import { useEffect, useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IbanCopy from "Dashboard/Shared/IbanCopy/IbanCopy";
import ModalUpdateTransfersToday from "./ModalUpdateTransfersToday";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import { useTranslation } from "react-i18next";

const TransfersToday = ({
  dataa,refetch
}) => {
  const {t}=useTranslation()
  const theadTrContent = [
    '#',
    t("Store Name"),
    t("Total Amount"),
    t("IBAN"),

  ];
  const [TableData, setTableData] = useState()
  const [fitlerdData, setFitlerdData] = useState()
  const [ActiveData, setActiveData] = useState()
  useEffect(()=>{
    setTableData(dataa?.transfers?.map((item,index)=>({
      id: item?.id,
      imgUser:  item?.store?.image ||img,
      name: item?.store?.title,
      nameRes: item?.amount,
      ibanNum:  item?.iban
    }
    )))
    setFitlerdData(dataa?.transfers?.map((item,index)=>({
      id: item?.id,
      imgUser:  item?.store?.image ||img,
      name: item?.store?.title,
      nameRes: item?.amount,
      ibanNum:  item?.iban
    }
    )))

  },[])

 
  const [activePage, setActivePage] = useState(0);
  // FUNCTION PAGINATION
  const handlePageClick = (e) => {
    setActivePage(e.selected);
  };

  // MODAL UPDATE TRANSFERS TODAY
  const [showModalTrans, setShowModalTrans] = useState(false);
  const showModalTransfers = () => {
    setShowModalTrans(true);
  };

  const hideModalUpdateTrans = () => {
    setShowModalTrans(false);
  };

  //   LOADER BUTTON

  const [load, setIsload] = useState(false);
  const [loadingItem, setLoadingItem] = useState(null);
  const loadButton = (id,data) => {
    setIsload(true);
    setLoadingItem(id); // Get ID OF BUTTON CLIKED
    setIsload(false);
    setLoadingItem(null); // RESET LOADING  AFTER 1 SECOND
    showModalTransfers();
    setActiveData(data)
  };

  return (
    <>
      <ModalUpdateTransfersToday
        ShowModalUpdateTrans={showModalTrans}
        hideModalUpdateTrans={hideModalUpdateTrans}
        ActiveData={ActiveData}
        refetch={refetch}
      />
      {
        dataa?.today?.length > 0 ?   <div className="all-data-table-shop all-data-table-shop-1  margin-top">
        <HeaderTableSearchFilter
          newClassHeaderContentSearch={"search-customer"}
          isTrueSearchInputFilter={true}
          textPlaceholder={"بحث"}
          functionSearchFilter={(e) => {
            console.log(e.target.value);
            const search = e.target.value;
            const filter = TableData?.filter((item) => {
              return (
                item.name.toLowerCase().includes(search.toLowerCase()) ||
                item.ibanNum.toLowerCase().includes(search.toLowerCase())
              );
            });
          }}
          functionIconSearch={() => {
            console.log("search");
          }}
          isTrueFilterButton={false}
          functionButtonFilter={false}
          isTrueHighestScore={false}
          functionButtonHighestScore={false}
          isNewButton={false}
          contentNewButton={false}
          isTrueContentLeftHeaderSearch={true}
          InfoContentLeftSearchFilter={
            <>
              <ButtonsExport dataExport={dataa} />
            </>
          }
        />
        <div className="table-actions-1">
          <DataTableTwo
            theadTrContent={
              <>
                {theadTrContent.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </>
            }
            tbodyContent={
              <>
                {fitlerdData?.slice(activePage*10,(activePage+1)*10).map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>{item.id}</td>
                      <td>
                        <div className="d-flex align-items-center justify-content-center gap-3">
                          <img
                            src={item.imgUser}
                            width={"35px"}
                            height={"35px"}
                            className="rounded-circle"
                            alt="img user"
                          />{" "}
                          {item.name}
                        </div>
                      </td>
                      <td>{item.nameRes}</td>
                      <td>
                        <div className="d-flex justify-content-center align-items-center gap-2">
                          {item.ibanNum} <IbanCopy IbanId={item.ibanNum} />
                        </div>
                      </td>
                      <td>
                        <button
                          onClick={() => loadButton(item.id,item)} // Pass the item ID to loadButton
                          className="btn-update btn-main btn-main-outline"
                          disabled={loadingItem === item.id} // Disable the button if it's loading
                        >
                          {loadingItem === item.id ? (
                            <div
                              className="spinner-border text-dark"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          ) : (
                            "تحديث"
                          )}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
          <PaginationPage itemCount={fitlerdData?.length / 10} handlePageClick={handlePageClick} />
        </div>
      </div>:
      <NoResults />
      }
    
    </>
  );
};

export default TransfersToday;
