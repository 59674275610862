import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import "./TabsControlDashboard.css";
import Currencies from "../TabsControlContent/CurrenciesTab/Currencies";
import ProfitsTab from "../TabsControlContent/ProfitsTab/ProfitsTab";
import PurchaseCardsTab from "../TabsControlContent/PurchaseCardsTab/PurchaseCardsTab";
import InterestsTab from "../TabsControlContent/InterestsTab/InterestsTab";
import StoreDepartmentsTab from "../TabsControlContent/StoreDepartmentsTab/StoreDepartmentsTab";
import ContactInformationTab from "../TabsControlContent/ContactInformationTab/ContactInformationTab";
import BannerTab from "../TabsControlContent/BannerTab/BannerTab";
import EmployeesTab from "../TabsControlContent/EmployeesTab/EmployeesTab";
import PowersTab from "../TabsControlContent/PowersTab/PowersTab";
import TermsConditionsTab from "../TabsControlContent/TermsConditionsTab/TermsConditionsTab";
import OuroBankAccount from "../TabsControlContent/OuroBankAccount/OuroBankAccount";
import TermsConditionsAdvertising from "../TabsControlContent/TermsConditionsAdvertising/TermsConditionsAdvertising";
import DurationStoreAdvertisements from "../TabsControlContent/DurationStoreAdvertisements/DurationStoreAdvertisements";
import MarketingCampaignsTab from "../TabsControlContent/MarketingCampaignsTab/MarketingCampaignsTab";
import { useTranslation } from "react-i18next";
import Countries from "../TabsControlContent/Countries/Countries";
import Cities from "../TabsControlContent/Cities/Cities";

const TabsControlDashboard = () => {
  const { i18n } = useTranslation();
  const tabInfo = [
    {
      eventKey: "currencies",
      title: i18n.language === "ar" ? "العملات" : "Currencies",
      tabInfo: <Currencies />,
    },
    {
      eventKey: "profits",
      title: i18n.language === "ar" ? "الأرباح" : "Profits",
      tabInfo: <ProfitsTab />,
    },
    // {
    //   eventKey: "purchaseCards",
    //   title: i18n.language === "ar" ? "شراء الكروت" : "Purchase Cards",
    //   tabInfo: <PurchaseCardsTab />,
    // },
    {
      eventKey: "interests",
      title: i18n.language === "ar" ? "الإهتمامات" : "Interests",
      tabInfo: <InterestsTab />,
    },
    {
      eventKey: "storeDepartments",
      title: i18n.language === "ar" ? "أقسام المتاجر" : "Store Departments",
      tabInfo: <StoreDepartmentsTab />,
    },
    // {
    //   eventKey: "contactInformation",
    //   title: i18n.language === "ar" ? "معلومات الإتصال" : "Contact Information",
    //   tabInfo: <ContactInformationTab />,
    // },
    {
      eventKey: "banner",
      title: i18n.language === "ar" ? "البانر" : "Banner",
      tabInfo: <BannerTab />,
    },
    {
      eventKey: "durationStoreAdvertisements",
      title:
        i18n.language === "ar"
          ? "مدة إعلانات المتاجر"
          : "Duration Store Advertisements",
      tabInfo: <DurationStoreAdvertisements />,
    },
    {
      eventKey: "marketingCampaignsTab",
      title:
        i18n.language === "ar" ? "الحملات التسويقية" : "Marketing Campaigns",
      tabInfo: <MarketingCampaignsTab />,
    },
    {
      eventKey: "employees",
      title: i18n.language === "ar" ? "الموظفين" : "Employees",
      tabInfo: <EmployeesTab />,
    },
    {
      eventKey: "bankAccount",
      title: i18n.language === "ar" ? "الحساب البنكي" : "Bank Account",
      tabInfo: <OuroBankAccount />,
    },
    // {
    //   eventKey: "powers",
    //   title: i18n.language === "ar" ? "الصلاحيات" : "Powers",
    //   tabInfo: <PowersTab />,
    // },
    {
      eventKey: "termsConditions",
      title: i18n.language === "ar" ? "الشروط والأحكام" : "Terms & Conditions",
      tabInfo: <TermsConditionsTab />,
    },
    {
      eventKey: "termsConditionsAdvertising",
      title:
        i18n.language === "ar"
          ? "الشروط والأحكام الخاصة بالحملات الإعلانية"
          : "Terms & Conditions for Advertising Campaigns",
      tabInfo: <TermsConditionsAdvertising />,
    },
    {
      eventKey: "countries",
      title: i18n.language === "ar" ? "الدول" : "Countries",
      tabInfo: <Countries />,
    },
    // {
    //   eventKey: "cities",
    //   title: i18n.language === "ar" ? "المدن" : "Cities",
    //   tabInfo: <Cities />,
    // },
  ];

  return (
    <div className="all-tabs-items tabs-dashboard-control  margin-top position-relative">
      <TabsContentTb tabActive={"currencies"}>
        {tabInfo.map((item) => {
          return (
            <Tab
              key={item.eventKey}
              eventKey={item.eventKey}
              title={item.title}
            >
              {item.tabInfo}
            </Tab>
          );
        })}
      </TabsContentTb>
    </div>
  );
};

export default TabsControlDashboard;
