import "./SideNavBar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/images/logo/logoDashboard.svg";

const SideNavBar = ({
  isOpen,
  closeToggleSidebar,
  toggleSidebar,
  children,
  routeLogo
}) => {
  return (
    <>
      <div className={`all-sidebar ${isOpen ? "open" : ""}`}>
        <div
          className={`overlay-bg-1 ${isOpen ? "open" : ""}`}
          onClick={closeToggleSidebar}
        ></div>

        <div className={`sidebar ${isOpen ? "open" : ""}`}>
          <div className="icon-close" onClick={closeToggleSidebar}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <Link to={routeLogo}>
            <div className="logo-sidebar">
              <img src={logo} alt="logo" />
            </div>
          </Link>
          <div className="icon-open-sidebar" onClick={toggleSidebar}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>

          <ul className="navbar-nav">{children}</ul>
        </div>
      </div>
    </>
  );
};

export default SideNavBar;
