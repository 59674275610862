import ModalFilterContent from "Dashboard/Pages/OuroTop/ModalFilterContent";

const ModalFilterContentInfo = ({
  setShowModalFilter,
  showModalFilter,
  selectCountry,
  selectCategory,
  selectPay
}) => {
  const hideModal = () => {
    setShowModalFilter(false);
  };

  return (
    <>
      <ModalFilterContent
        showModalFilter={showModalFilter}
        hideModal={hideModal}
        hideModalFilter={hideModal}
        selectCountry={selectCountry}
        selectCategory={selectCategory}
        selectPay={selectPay}
      />
    </>
  );
};

export default ModalFilterContentInfo;
