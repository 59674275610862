import React from "react";

const CheckIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z"
        fill="#121212"
      />
      <path
        d="M10.5804 15.5796C10.3804 15.5796 10.1904 15.4996 10.0504 15.3596L7.22043 12.5296C6.93043 12.2396 6.93043 11.7596 7.22043 11.4696C7.51043 11.1796 7.99043 11.1796 8.28043 11.4696L10.5804 13.7696L15.7204 8.62961C16.0104 8.33961 16.4904 8.33961 16.7804 8.62961C17.0704 8.91961 17.0704 9.39961 16.7804 9.68961L11.1104 15.3596C10.9704 15.4996 10.7804 15.5796 10.5804 15.5796Z"
        fill="#121212"
      />
    </svg>
  );
};

export default CheckIcon;
