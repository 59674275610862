import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React from "react";
import TabsControlDashboard from "./Components/TabsControlDashboard/TabsControlDashboard";

const ControlDashboard = () => {
  return (
    <>
      <HelmetInfo titlePage={"لوحة التحكم"} />
      <BreadcrumbPage
        routPageHome="/dashboardAdmin/homedashboard"
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={"لوحة التحكم"}
      />
      <main>
        <TabsControlDashboard />
      </main>
    </>
  );
};

export default ControlDashboard;
