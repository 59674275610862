import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormField from "Components/Forms/FormFiled";
import { useEffect, useState } from "react";
import InputFiled from "Components/Forms/InputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import axiosInstance from "../../../../../../axios";
import { toast } from "react-toastify";
import CountrySelect from "Components/Ui/CountrySelect/ReactSelectCountry";

const ModalOuroBank = ({ showModal, hideModal, refetch, editItem }) => {
  const { t, i18n } = useTranslation();
  console.log(editItem)
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    account_number: Yup.number().required(
      i18n.language === "ar"
        ? "رقم الحساب مطلوب  "
        : "account number  is required"
    ),
    BankName: Yup.string().required(
      i18n.language === "ar" ? "البنك مطلوب" : "bank is required"
    ),
    iban: Yup.string().required(
      i18n.language === "ar" ? "الآيبان مطلوب" : "iban is required"
    ),
  });

  const [countryCode, setCountryCode] = useState();
  const [checkedItem, setCheckedItem] = useState(editItem?.default );
  const [countryCodeError, setCountryCodeError] = useState(false);
  const initialValues = {
    account_number: editItem?.account_number || "",
    BankName: editItem?.name || "",
    iban: editItem?.numIban || "",
  };
  const handleSubmit = (values) => {
    console.log(values);

    if (!countryCode) {
      setLoading(false);
      setCountryCodeError(true);
      return toast.error(
        i18n.language === "ar"
          ? "يرجى اختيار الدولة"
          : "Please select the country"
      );
    }
console.log(checkedItem)
    setLoading(true);
    axiosInstance
      .post(
        "/admin/bankaccount",
        {
          account_number: values.account_number,
          bank: values.BankName,
          iban: values.iban,
          default: checkedItem == undefined ? false : checkedItem,
          country_id: countryCode?.value,
        },
        {
          headers: {
            "Accept-Language": i18n.language,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setLoading(false);
        toast.success(
          i18n.language === "ar" ? "تمت الإضافة بنجاح" : "Added successfully"
        );

        hideModal();
        setTimeout(() => {
          refetch();
        }, 1000);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(err.response.data.message);
      });
  };
  useEffect(() => {
    if (countryCode) {
      setCountryCodeError(false);
    }
  }, [countryCode]);
  // SHOW PASSWORD INPUT
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const options = [
    { value: "1", label: "محاسب" },
    { value: "2", label: "محاسب 2" },
    { value: "3", label: "محاسب 3" },
  ];

  return (
    <CustomModal
      show={showModal}
      onHide={hideModal}
      title={i18n.language === "ar" ? "إضافة حساب بنكي" : "Add an bank Account"}
      newClass={"modal-add-employee"}
    >
      <FormField
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <InputField
          label={i18n.language === "ar" ? " رقم الحساب" : " Account number"}
          name="account_number"
          type="number"
          placeholder={
            i18n.language === "ar" ? " رقم الحساب" : " Account number"
          }
          success
        />
        <InputField
          label={i18n.language === "ar" ? " البنك" : " Bank"}
          name="BankName"
          type="text"
          placeholder={i18n.language === "ar" ? " البنك" : " Bank"}
          success
        />
        <InputField
          label={i18n.language === "ar" ? "iban " : "iban"}
          name="iban"
          type="text"
          placeholder={i18n.language === "ar" ? "iban " : "iban"}
          success
        />

        <label className="form-label mt-2">{t("Country")}</label>
        <CountrySelect
          placeholder={t("Country")}
          setValue={setCountryCode}
          width={"100%"}
        />

        {countryCodeError && (
          <div className="error">{t("Please Choose Country")}</div>
        )}

<div className="form-check mt-2">
  <input checked={checkedItem} onChange={(e)=>{
    
    setCheckedItem(e.target.checked)
  }} className="form-check-input" type="checkbox" value="" id="flexCheckIndeterminate" />
  <label className="form-check-label ms-2" >
    
    {
t("set as a Defualt")
    }
  </label>
</div>

        <button
          type="submit"
          disabled={loading}
          className="btn-main btn-submit w-100 mt-4"
        >
          {loading ? (
            <span className="loader"></span>
          ) : i18n.language === "ar" ? (
            "حفظ"
          ) : (
            "Save"
          )}
        </button>
      </FormField>
    </CustomModal>
  );
};

export default ModalOuroBank;
