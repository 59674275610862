import DataTable from "Components/DataTable/DataTable";
import DataTableInfo from "Dashboard/Components/DataTableInfo/DataTableInfo";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import EyeIcon from "assets/Icons/EyeIcon";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const TopCustomerTab = ({ data }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const columnDefs = useMemo(
    () => [
      {
        headerName: i18n.language === "ar" ? "#" : "#",
        field: "id",
        width: "100px",
      },
      {
        headerName: i18n.language === "ar" ? "اسم العميل" : "Name",
        field: "name",
        flex: 1,
      },
      {
        headerName: i18n.language === "ar" ? "عدد النقاط" : "Points",
        field: "points",
        flex: 1,
      },
      {
        headerName: i18n.language === "ar" ? "رصيد المحفظة" : "Balance",
        field: "balance",
        flex: 1,
      },
      {
        headerName: i18n.language === "ar" ? "النوع" : "Type",
        field: "type",
        flex: 1,
      },

      {
        headerName: i18n.language === "ar" ? "البريد الاكتروني" : "Mail",
        field: "mail",
        flex: 1,
      },
      {
        headerName: i18n.language === "ar" ? "رقم الهاتف" : "Phone",
        field: "phone",
        flex: 1,
      },
      {
        headerName:
          i18n.language === "ar" ? "تاريخ الاشتراك" : "Subscribtion Date",
        field: "date",
        flex: 1,
      },

      {
        headerName: i18n.language === "ar" ? "الإجراءات" : "Actions",
        field: "Actions",
        flex: 0.5,
        cellRenderer: (params) => (
          <div
            onClick={() => {
              navigate(`/dashboardAdmin/clientFile/${params.data.id}`);
            }}
            className=" cursor-pointer-event"
          >
            <EyeIcon />
          </div>
        ),
      },
    ],
    [i18n.language]
  );
  const [rowsData, setRowsData] = useState(null);

  const [filteredData, setFilteredData] = useState(null);

  useEffect(() => {
    console.log(data?.topcustomers);
    setRowsData(
      data?.topcustomers?.map((item) => ({
        id: item?.id,
        name: item?.name,
        points: item?.points,
        balance: item?.wallet,
        type: item?.device_type,

        mail: item?.email,
        phone: item?.phone,
        date: moment(item?.created_at).format("DD-MM-YYY , hh mm"),
      }))
    );
    setFilteredData(
      data?.topcustomers?.map((item) => ({
        id: item?.id,
        name: item?.name,
        points: item?.points,
        balance: item?.wallet,
        type: item?.device_type,

        mail: item?.email,
        phone: item?.phone,
        date: moment(item?.created_at).format("DD-MM-YYY , hh mm"),
      }))
    );
  }, [data]);

  return (
    <>
      <HeaderTableSearchFilter
        newClassHeaderContentSearch={"search-reports"}
        isTrueSearchInputFilter={true}
        textPlaceholder={"بحث"}
        functionSearchFilter={(e) => {
          const searchValue = e.target.value.toLowerCase();

          const filteredData = rowsData.filter((row) => {
            return row?.name.toString().toLowerCase().includes(searchValue);
          });
          setFilteredData(filteredData);
        }}
        functionIconSearch={() => {
          console.log("search");
        }}
        isTrueFilterButton={true}
        functionButtonFilter={() => {}}
        isTrueHighestScore={false}
        functionButtonHighestScore={false}
        isNewButton={true}
        contentNewButton={<></>}
        isTrueContentLeftHeaderSearch={true}
        InfoContentLeftSearchFilter={
          <>
            <ButtonsExport dataExport={"Data Table Array"} />
          </>
        }
      />
      <div className="table-customer table-width table-reports">
        <DataTable rowData={filteredData} columnDefs={columnDefs} />
      </div>
    </>
  );
};

export default TopCustomerTab;
