import CountrySelect from "Components/Ui/CountrySelect/CountrySelect";
import { useTranslation } from "react-i18next";

const CountrySelectFilter = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <CountrySelect
        selectedCountry={false}
        newClass={"mt-3"}
        isShowLabel={true}
        TextLabel={"الدولة"}
        CountryData={["OM", "EG", "AE", "SA", "JO", "KW", "QA", "BH"]}
        CustomLabelCountry={{
          OM: `${i18n.language === "ar" ? "عمان" : "Oman"}`,
          EG: `${i18n.language === "ar" ? "مصر" : "Egypt"}`,
          AE: `${i18n.language === "ar" ? "الامارات" : "Emirates"}`,
          SA: `${i18n.language === "ar" ? "السعودية" : "Saudi Arabia"}`,
          JO: `${i18n.language === "ar" ? "الاردن" : "Jordan"}`,
          KW: `${i18n.language === "ar" ? "الكويت" : "Kuwait"}`,
          QA: `${i18n.language === "ar" ? "قطر" : "Qatar"}`,
          BH: `${i18n.language === "ar" ? "البحرين" : "Bahrain"}`
        }}
        placholerText={"اختار الدولة"}
      />
    </>
  );
};

export default CountrySelectFilter;
