import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import React from "react";
import HeaderAdvertise from "./Components/HeaderAdvertise/HeaderAdvertise";
import MainAdvertise from "./Components/MainAdvertise/MainAdvertise";
import { useTranslation } from "react-i18next";

const Advertise = () => {
  const { t } = useTranslation();
  return (
    <>
      <HelmetInfo titlePage={t("advertiseYourself")} />
      <BreadcrumbPage
        routPageHome={"/dashboardVendor/homedashboardvendor"}
        showMainTextPage={false}
        routePage={false}
        mainPageText={false}
        showPageTwo={true}
        textPageTwo={t("advertiseYourself")}
      />
      <header>
        <HeaderAdvertise />
      </header>
      <main>
        <MainAdvertise />
      </main>
    </>
  );
};

export default Advertise;
