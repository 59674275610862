import { useEffect, useState } from "react";
import IconEdit from "assets/Icons/IconEdit";
import TrashIcon from "assets/Icons/TrashIcon";
import HeaderContentTab from "../HeaderContentTab";
import { toast } from "react-toastify";
import ToggleIcon from "assets/Icons/ToggleIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import CopyIcon from "assets/Icons/CopyIcon";
import ModalOuroBank from "./ModalOuroBank";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import axiosInstance from "../../../../../../axios";
import CustomModal from "Components/CustomModal/CustomModal";
import { useTranslation } from "react-i18next";

const OuroBankAccount = () => {
  const [cardContent, setCardContent] = useState([
    {
      id: 0,
      titleContent: "بنك الأهلي",
      numIban: "958 655 998 655788"
    }
  ]);
  const[dataa,setData]=useState([])
  const {t,i18n}=useTranslation()
  const [fitlerdData,setFilterdData]=useState([])
const [loading,setLoading]=useState(true)
  const [activePage, setActivePage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);
  const [editItem, setEditItem] = useState(null);
useEffect(()=>{

  axiosInstance.get("/admin/bankaccount").then((response) => {
setLoading(false)
    console.log(response.data);
    setData(response.data?.data?.map((item,index)=>{
      return    {
        id: item.id,
        name: item.bank,
        email: item.email,
        numIban: item.iban,
        account_number:item?.account_number,
        validity: "محاسب" ,
        date: "15/1/2024",
        default: item.default,
      }
    })
  )
  setFilterdData(response.data?.data?.map((item,index)=>{
    return    {
      id: item.id,
      name: item.bank,
      email: item.email,
      numIban: item.iban,
      account_number:item?.account_number,
      validity: "محاسب" ,
      date: "15/1/2024",
      default: item.default,
    }
  })
)
  })
},[])


const refetchData=()=>{
  setLoading(true)
  axiosInstance.get("/admin/bankaccount").then((response) => {
    setLoading(false)

    console.log(response.data);
    setData(response.data?.data?.map((item,index)=>{
      return    {
        id: item.id,
        name: item.bank,
        email: item.email,
        numIban: item.iban,
        account_number:item?.account_number,
        validity: "محاسب" ,
        date: "15/1/2024",
        default: item.default,
      }
    })
 
  )  
  setFilterdData(response.data?.data?.map((item,index)=>{
    return    {
      id: item.id,
      name: item.bank,
      email: item.email,
      numIban: item.iban,
      account_number:item?.account_number,
      validity: "محاسب" ,
      date: "15/1/2024",
      default: item.default,
    }
  })
)
  })}

  const handleSaveModalData = (data) => {
    setCardContent([
      ...cardContent,
      {
        id: cardContent.length,
        titleContent: data.bank.label,
        numIban: data.ibanSwiftCode
      }
    ]);
    toast.success("تمت الإضافة بنجاح.");
  };


  const handleDeleteItem = (id) => {

    axiosInstance.delete(`/admin/bankaccount/${id}`).then(()=>{
      refetchData()
      toast.success(
        `${i18n.language === "ar" ? "تم الحذف بنجاح .." : "Deleted Successfully"}`
      );
      setDeleteModal(false);

    });
 
 
  
  };
  const handleCopyIban = (numIban) => {
    navigator.clipboard
      .writeText(numIban)
      .then(() => {
        toast.success("تم نسخ رقم الآيبان بنجاح");
      })
      .catch((error) => {
        console.error("Failed to copy:", error);
        toast.error("حدث خطأ أثناء نسخ الآيبان");
      });
  };


  // MODAL OURO BANK ACCOUNT
  const [showModalOuroBank, setShowModalOuroBank] = useState(false);

  const showModal = () => {
    setShowModalOuroBank(true);
  };

  const hideModal = () => {
    setShowModalOuroBank(false);
    setEditItem(null)
  };

  return (
    <>
       <CustomModal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
        }}
        title={i18n.language === "ar" ? "حذف" : "Delete"}
        newClass={"modal-inter"}
      >
        <div className="row w-50 justify-content-around mx-auto">
          <p className="text-center">
            {i18n.language === "ar"
              ? "هل انت متاكد من الحذف ؟"
              : "Are You Sure Want To Delete ? "}
          </p>
          <button
            onClick={() => {
              handleDeleteItem(deletedIndex);
            }}
            className="col-md-5 my-3 btn btn-danger"
          >
            {i18n.language === "ar" ? "حذف" : "Delete"}
          </button>
          <button
            onClick={() => {
              setDeleteModal(false);
            }}
            className="col-md-5 my-3 btn btn-outline-dark"
          >
            {i18n.language === "ar" ? "الغاء" : "Cancel"}
          </button>
        </div>
      </CustomModal>

      <ModalOuroBank
        showModal={showModalOuroBank}
        hideModal={hideModal}
        refetch={refetchData}
        onSave={handleSaveModalData}
        editItem={editItem}
      />
      <div className="currencies-control">
        <HeaderContentTab
          textTitle={"الحساب البنكي لأورو"}
          isShowButton={true}
          newClassButton={"button-one-add"}
          isTrueText={false}
          functionButton={() => {
            showModal();
          }}
          isShowButtonProfit={false}
          functionButtonProfit={false}
        />
        <div className="all-currencies-content all-bank-account">
          {dataa.length === 0 ? (
            <p className="text fs-6 fw-bold  text-dark  text-center">
              لا يوجد حسابات بنكية مضافة
            </p>
          ) : (
            fitlerdData.map((item) => (
              <div
                key={item.id}
                className="currency-card-one  terms-card-one  d-flex   justify-content-between gap-3 flex-sm-wrap  flex-md-nowrap  mt-4"
              >
                <div className="right-content d-flex  gap-2">
                  <div className="icon-toggle">
                    <ToggleIcon />
                  </div>
                  <div className="top-content-info d-flex flex-column  gap-2">
                    <h2 className="title">{item.name}</h2>
                    <div className="text cursor-pointer-event lh-lg pt-2 d-flex  flex-row flex-wrap  gap-2">
                      <div className="text-iban">IBAN</div>
                      <div
                        className="num-iban"
                        onClick={() => handleCopyIban(item.numIban)}
                      >
                        {item.numIban}
                      </div>
                      <div
                        className="icon-copy"
                        onClick={() => handleCopyIban(item.numIban)}
                      >
                        <CopyIcon />
                      </div>
                    </div>
                    {
                      item?.default &&    <div className="info-details--check d-flex  align-items-center  gap-2">
                      <div className="icon-check---2">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>{" "}
                      حساب إفتراضي
                    </div>
                    }
                 
                  </div>
                </div>
                <div className="left-actions-content d-flex  gap-3">
                  <div
                    className="icon-one--1 icon-edit  cursor-pointer-event"
                    onClick={() => {
                      showModal();
                      setEditItem(item)
                    }}
                  >
                    <IconEdit />
                  </div>
                  {/* <div
                    className="icon-one--1 icon-trash cursor-pointer-event"
                    onClick={() => {
                      setDeleteModal(true);
                      setDeletedIndex(item.id);
                    }}
                  >
                    <TrashIcon />
                  </div> */}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default OuroBankAccount;
