import "./MainPage.css";
import { useEffect, useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import Arrows from "assets/Icons/Arrows";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import { useNavigate } from "react-router-dom";
import axiosInstance from "./../../../../../axios";
import { AgGridReact } from "ag-grid-react";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import IconEdit from "assets/Icons/IconEdit";
import moment from "moment/moment";
import CheckboxSwitch from "Dashboard/Shared/CheckboxSwitch/CheckboxSwitch";
import { toast } from "react-toastify";
import EyeIcon from "assets/Icons/EyeIcon";
import DataTable from "Components/DataTable/DataTable";

const TableMainPage = ({ data, setRefetch, refetch }) => {
  const { i18n, t } = useTranslation();
  const [rowsData, setRowsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setRowsData(
      data.map((shop) => ({
        ...shop,
        id: shop?.id,
        category: shop?.title,
        subdate: moment(shop?.created_at).format("DD-MM-YYYY"),
        orders_count: shop?.orders_count,
        Cate: shop?.category?.title,
      }))
    );

    setFilteredData(
      data.map((shop) => ({
        status: shop.status,
        id: shop?.id,
        category: shop?.title,
        orders_count: shop?.orders_count,
        subdate: moment(shop?.created_at).format("DD-MM-YYYY"),
        Cate: shop?.category?.title,
      }))
    );
  }, [data, i18n?.language]);

  // Column definitions
  const columnDefs = [
    {
      headerName: i18n?.language === "ar" ? "#" : "#",
      field: "id",
      width: "100px",
    },
    {
      headerName: i18n?.language === "ar" ? "الاسم" : "Name",
      field: "category",
      flex: 1,
    },
    {
      headerName: i18n?.language === "ar" ? "الفئة" : "category",
      field: "Cate",
      flex: 1,
    },
    {
      headerName: i18n?.language === "ar" ? "عدد الطلبات" : "orders Number",
      field: "orders_count",
      flex: 1,
    },
    // { headerName: i18n?.language === "ar" ? "الدولة" : "Country", field: "country" },
    // { headerName: i18n?.language === "ar" ? "إسم المتجر" : "Store Name", field: "name" },
    // { headerName: i18n?.language === "ar" ? "عدد العملاء" : "Customer Count", field: "cusCount" },
    // { headerName: i18n?.language === "ar" ? "عدد الفواتير" : "Receipt Counts", field: "recieptCounts" },

    // { headerName: i18n?.language === "ar" ? "رصيد المحفظة" : "Wallet Balance", field: "balance" },
    // { headerName: i18n?.language === "ar" ? "أرباح أورو" : "Euro Profits", field: "profits" },

    {
      headerName:
        i18n?.language === "ar" ? "تاريخ الإشتراك" : "Subscription Date",
      field: "subdate",
      flex: 1,
    },
    // {
    //   headerName: i18n?.language === "ar" ? "إسم الموظف" : "Employee Name",
    //   field: "cusName",
    // },
    {
      headerName: i18n?.language === "ar" ? "الحالة" : "Status",
      field: "status",
      flex: 1,
      cellRenderer: (params) => (
        <CheckboxSwitch
          key={params.data.id}
          id={params.data.id}
          isChecked={params.data.status}
          handleCheckboxChange={() => {
            handleActivation(params.data.id);
          }}
        />
      ),
    },
    {
      headerName: i18n?.language === "ar" ? "الإجراءات" : "Actions",
      field: "Actions",
      flex: 0.5,
      cellRenderer: (params) => (
        <div
          onClick={() => {
            navigate(`/dashboardAdmin/storeInformation/${params.data.id}`);
          }}
          className=" cursor-pointer-event"
        >
          <EyeIcon />
        </div>
      ),
    },
  ];

  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };
  const handleActivation = async (id) => {
    try {
      await axiosInstance.post(`/admin/changestatus`, { store_id: id });
      toast.success(
        i18n?.language === "ar" ? "تم التحديث" : "Updated Successfully"
      );
      setRefetch(!refetch);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <ModalFilterContentInfo
        setShowModalFilter={setShowModalFilter}
        showModalFilter={showModalFilter}
        selectCountry={true}
        selectCategory={true}
        selectPay={false}
      />
      <div className="all-data-table-shop all-data-table-shop-1 card-style-2 margin-top">
        <HeaderTableSearchFilter
          contentNewButton={false}
          newClassHeaderContentSearch={"search-customer"}
          isTrueSearchInputFilter={true}
          functionSearchFilter={(e) => {
            const searchValue = e.target.value.toLowerCase();

            const filteredData = rowsData.filter((row) => {
              return (
                row.id.toString().toLowerCase().includes(searchValue) ||
                row.category.toLowerCase().includes(searchValue) ||
                row.subdate.toLowerCase().includes(searchValue)
              );
            });
            setFilteredData(filteredData);
          }}
          functionIconSearch={() => {
            console.log("search");
          }}
          isTrueFilterButton={true}
          functionButtonFilter={showModal}
          isTrueHighestScore={false}
          functionButtonHighestScore={false}
          isNewButton={true}
          // contentNewButton={
          //   <button
          //     onClick={() => {
          //
          //     }}
          //     className="btn-main  btn-main-outline"
          //   >
          //     الأعلى رصيد
          //     <Arrows />
          //   </button>
          // }
          isTrueContentLeftHeaderSearch={true}
          InfoContentLeftSearchFilter={
            <>
              <ButtonsExport dataName="Shops" dataExport={filteredData} />
            </>
          }
        />
        <DataTable rowData={filteredData} columnDefs={columnDefs} />
        <div className="table-actions-1"></div>
      </div>
    </>
  );
};
export default TableMainPage;
