import "./NotFound.css";
import Lottie from "lottie-react";
import notFoundPage from "../../assets/images/404/404.json";
import { Link } from "react-router-dom";
const NotFound = ({ newClass, routePage }) => {
  return (
    <div
      className={`not-found-page  d-flex align-items-center  justify-content-center  flex-column ${newClass}`}
    >
      <div className="main-info-found">
        <div className="animtion-lottie">
          <Lottie animationData={notFoundPage} />
        </div>
        <Link to={routePage} className="btn-main">
          <svg
            width="20"
            height="24"
            viewBox="0 0 20 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.3501 23.2501H2.6501C1.2626 23.2501 0.100098 22.1251 0.100098 20.7001V9.33755C0.100098 8.58755 0.437598 7.87505 1.0376 7.38755L8.3876 1.31255C9.3251 0.525049 10.6751 0.525049 11.6126 1.31255L18.9626 7.35005C19.5626 7.83755 19.9001 8.55005 19.9001 9.30005V20.6626C19.9001 22.1251 18.7376 23.2501 17.3501 23.2501ZM10.0001 2.25005C9.7751 2.25005 9.5126 2.32505 9.3251 2.47505L1.9751 8.55005C1.7501 8.73755 1.6001 9.03755 1.6001 9.33755V20.7001C1.6001 21.2626 2.0501 21.7501 2.6501 21.7501H17.3501C17.9126 21.7501 18.4001 21.3001 18.4001 20.7001V9.33755C18.4001 9.03755 18.2501 8.73755 18.0251 8.55005L10.6751 2.47505C10.4876 2.32505 10.2251 2.25005 10.0001 2.25005Z"
              fill="white"
            />
            <path
              d="M14.5 19.9501H5.5C5.0875 19.9501 4.75 19.6126 4.75 19.2001C4.75 18.7876 5.0875 18.4501 5.5 18.4501H14.5C14.9125 18.4501 15.25 18.7876 15.25 19.2001C15.25 19.6126 14.9125 19.9501 14.5 19.9501Z"
              fill="white"
            />
          </svg>
          الصفحة الرئيسية
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
