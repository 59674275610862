import React from 'react'

const IconEdit = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28 29.334H4C3.45333 29.334 3 28.8807 3 28.334C3 27.7873 3.45333 27.334 4 27.334H28C28.5467 27.334 29 27.7873 29 28.334C29 28.8807 28.5467 29.334 28 29.334Z" fill="#87888B"/>
    <path d="M25.36 4.64086C22.7734 2.05419 20.24 1.98753 17.5867 4.64086L15.9734 6.25419C15.84 6.38753 15.7867 6.60086 15.84 6.78753C16.8534 10.3209 19.68 13.1475 23.2134 14.1609C23.2667 14.1742 23.32 14.1875 23.3734 14.1875C23.52 14.1875 23.6534 14.1342 23.76 14.0275L25.36 12.4142C26.68 11.1075 27.32 9.84086 27.32 8.56086C27.3334 7.24086 26.6934 5.96086 25.36 4.64086Z" fill="#87888B"/>
    <path d="M20.8133 15.3731C20.4267 15.1864 20.0534 14.9997 19.6934 14.7864C19.4 14.6131 19.12 14.4264 18.84 14.2264C18.6134 14.0797 18.3467 13.8664 18.0933 13.6531C18.0667 13.6397 17.9733 13.5597 17.8667 13.4531C17.4267 13.0797 16.9334 12.5997 16.4934 12.0664C16.4534 12.0397 16.3867 11.9464 16.2934 11.8264C16.16 11.6664 15.9334 11.3997 15.7334 11.0931C15.5734 10.8931 15.3867 10.5997 15.2134 10.3064C15 9.94641 14.8134 9.58641 14.6267 9.21307C14.5605 9.07134 14.4994 8.93129 14.4421 8.7935C14.3022 8.4569 13.8662 8.36018 13.6085 8.61794L5.78668 16.4397C5.61335 16.6131 5.45335 16.9464 5.41335 17.1731L4.69335 22.2797C4.56002 23.1864 4.81335 24.0397 5.37335 24.6131C5.85335 25.0797 6.52002 25.3331 7.24002 25.3331C7.40002 25.3331 7.56002 25.3197 7.72002 25.2931L12.84 24.5731C13.08 24.5331 13.4134 24.3731 13.5734 24.1997L21.388 16.3851C21.6475 16.1255 21.5491 15.6816 21.2099 15.5413C21.081 15.488 20.9495 15.4321 20.8133 15.3731Z" fill="#87888B"/>
    </svg>
    
  )
}

export default IconEdit