import DownloadIcon from "assets/Icons/DownloadIcon";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

const ChartInvoice = ({ reportsDats }) => {
  const { t } = useTranslation();
  const getCustmoerchart = () => {
    const customerData = Object.values(reportsDats?.monthly || {}).map(
      (month) => month?.customers.toFixed(2)
    );
    return customerData || [];
  };
  const getProfitchart = () => {
    const customerData = Object.values(reportsDats?.monthly || {}).map(
      (month) => month?.orders.toFixed(2)
    );
    return customerData || [];
  };
  const getTransfairchart = () => {
    const customerData = Object.values(reportsDats?.monthly || {}).map(
      (month) => month?.transfers.toFixed(2)
    );
    return customerData || [];
  };
  const getMonths = () => {
    const months = Object.keys(reportsDats?.monthly || {}); // Extract month names from the monthly object
    return months || [];
  };
  const state = {
    series: [
      {
        name: t("orders"),
        data: getProfitchart(),
      },
      {
        name: t("customers"),
        data: getCustmoerchart(),
      },
    ],
    alloptions: {
      chart: {
        height: 350,
        type: "area",
        toolbar: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "category",
        categories: getMonths(),
        labels: {
          style: {
            colors: "#96979A",
            fontSize: "14px",
            fontFamily: "IBMPlexSansArabic",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      yaxis: {
        opposite: true,
        labels: {
          align: "center",
          style: {
            colors: "#96979A",
            fontSize: "14px",
            fontFamily: "IBMPlexSansArabic",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  };
  const exportData = () => {
    const data = Object.values(reportsDats.monthly);

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate a unique filename
    const fileName = `reportsDats.xlsx`;

    // Save the file
    XLSX.writeFile(wb, fileName);
  };
  return (
    <>
      <div className="chart-info card-style-1">
        {/* =========== START HEADER CHART INFO =========== */}
        <div className="header-chart-info d-flex justify-content-between align-items-center flex-wrap gap-3 mb-3">
          <div className="right-info-head d-flex flex-column gap-2">
            <h2 className="title">{t("invoicesNuber")}</h2>
          </div>
          <div className="button-invoice-down">
            <button onClick={exportData} className="btn-main btn-main-outline">
              {t("download")} <DownloadIcon />
            </button>
          </div>
        </div>
        {/* =========== END HEADER CHART INFO =========== */}
        <div id="chart">
          <ReactApexChart
            options={state.alloptions}
            series={state.series}
            type="area"
            height={350}
          />
        </div>
      </div>
    </>
  );
};

export default ChartInvoice;
