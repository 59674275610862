import { Tab } from "react-bootstrap";
import TabsContentTb from "Dashboard/Shared/TabsContentTb/TabsContentTb";
import PendingActivities from "./TabsEvents/PendingActivities";

import AcceptableActivities from "./TabsEvents/AcceptableActivities";

import FinishedActivities from "./TabsEvents/FinishedActivities";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ModalAddNewEvent from "./ModalsEvent/ModalAddNewEvent";
import { t } from "i18next";
const TabsEventsVendor = ({ setRefetch, refetch, events }) => {
  const { i18n } = useTranslation();
  const { approved, pending, refused } = events;
  const [edittedItem, setEdittedItem] = useState(null);
  // SHOW MODAL
  const [showModalEvent, setShowModalEvent] = useState(false);
  const showModalAddNewEvent = () => {
    setShowModalEvent(true);
  };

  const hideModalAddNewEvent = () => {
    setShowModalEvent(false);
    setEdittedItem(null);
  };

  const tabInfo = [
    {
      eventKey: "pendingActivities",
      title: i18n.language === "ar" ? "فعاليات معلقة" : "Pending Activities",
      tabInfo: (
        <PendingActivities
          modalRender={
            <ModalAddNewEvent
              setRefetch={setRefetch}
              refetch={refetch}
              edittedItem={edittedItem}
              showModalAddEventVendor={showModalEvent}
              hideModalAddEventVendor={hideModalAddNewEvent}
            />
          }
          addBtnOnClick={showModalAddNewEvent}
          pending={pending}
        />
      ),
    },
    {
      eventKey: "acceptableActivities",
      title: i18n.language === "ar" ? "فعاليات مقبولة" : "Accepted Activities",
      tabInfo: (
        <AcceptableActivities
          modalRender={
            <ModalAddNewEvent
              setRefetch={setRefetch}
              refetch={refetch}
              edittedItem={edittedItem}
              showModalAddEventVendor={showModalEvent}
              hideModalAddEventVendor={hideModalAddNewEvent}
            />
          }
          addBtnOnClick={showModalAddNewEvent}
          approved={approved}
        />
      ),
    },
    {
      eventKey: "OtherPayments",
      title: i18n.language === "ar" ? "فعاليات منتهية" : "Finished Activities",
      tabInfo: (
        <FinishedActivities
          modalRender={
            <ModalAddNewEvent
              setRefetch={setRefetch}
              refetch={refetch}
              edittedItem={edittedItem}
              showModalAddEventVendor={showModalEvent}
              hideModalAddEventVendor={hideModalAddNewEvent}
            />
          }
          addBtnOnClick={showModalAddNewEvent}
          refused={refused}
        />
      ),
    },
  ];

  return (
    <>
      <div className="all-tabs-items card-style-2 margin-top position-relative ">
        <TabsContentTb tabActive={"pendingActivities"}>
          {tabInfo.map((item) => {
            return (
              <Tab
                key={item.eventKey}
                eventKey={item.eventKey}
                title={item.title}
              >
                {item.tabInfo}
              </Tab>
            );
          })}
        </TabsContentTb>
      </div>
    </>
  );
};

export default TabsEventsVendor;
