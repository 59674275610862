import ReactFlagsSelect from "react-flags-select";
import { useState } from "react";
import "./CountrySelect.css"
const CountrySelect = ({
  newClass,
  isShowLabel,
  TextLabel,
  CountryData,
  CustomLabelCountry,
  placholerText,
  selectedCountry
}) => {
  const [select, setSelect] = useState(`${selectedCountry}`);
  const onSelect = (code) => setSelect(code);

  return (
    <>
      <div className={`country-select-option ${newClass}`}>
    {isShowLabel &&     <label htmlFor="formSelectCountry" className="form-label mb-3">
    {TextLabel}
  </label>}
        <div className="country-select">
          <ReactFlagsSelect
            selected={select}
            onSelect={onSelect}
            className="menu-flags"
            countries={CountryData}
            customLabels={CustomLabelCountry}
            placeholder={placholerText}
          />
        </div>
      </div>
    </>
  );
};

export default CountrySelect;
