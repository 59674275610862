import { useEffect, useState } from "react";
import { faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "Components/CustomModal/CustomModal";
import InputField from "Components/Forms/InputField";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axiosInstance from "./../../../../../../../axios";
import FormField from "./../../../../../../../Components/Forms/FormFiled";
import Map from "Components/Map/Map";
import PhoneInput from "Components/Ui/PhoneInput/PhoneInput";

const ModalBranchesVendor = ({
  refetch,
  setRefetch,
  showModalBranches,
  hideModalBranches,
  edittedItem,
  selectedLocation,
  setSelectedLocation,
  location,
  setLocation,
}) => {
  const { t, i18n } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [country, setCountry] = useState(null);
  const validationSchema = Yup.object().shape({
    lat: Yup.number().required(t("required")),
    lng: Yup.number().required(t("required")),
    is_main: Yup.boolean().required(t("required")),
  });

  const initialValues = {
    lat: edittedItem ? edittedItem.lat : 47.55,
    lng: edittedItem ? edittedItem.lng : 47.55,
    is_main: edittedItem ? edittedItem.is_main : false,
  };

  useEffect(() => {
    setPhoneNumber(edittedItem?.phone);
  }, [edittedItem]);

  const handleSubmit = async (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("address", location?.address);
    formData.append("phone", phoneNumber ? phoneNumber : undefined);
    // formData.append("country_code", country?.code ? country?.code : "+968");
    formData.append("lat", location?.lat);
    formData.append("lng", location?.lng);
    formData.append("is_main", 1);

    if (edittedItem) {
      try {
        await axiosInstance.put(`/store/branch/${edittedItem?.id}`, formData);
        resetForm();
        hideModalBranches();
        setRefetch(!refetch);
        toast.success(t("updated"));
        setPhoneNumber(null);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      try {
        await axiosInstance.post(`/store/branch`, formData);
        resetForm();
        hideModalBranches();
        setRefetch(!refetch);
        toast.success(t("updated"));
        setPhoneNumber(null);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };
  useEffect(() => {
    console.log(edittedItem);
    if (edittedItem) {
      setSelectedLocation({
        lat: edittedItem?.lat,
        lng: edittedItem?.lng,
        address: edittedItem?.address,
      });
    } else {
      setSelectedLocation(null);
    }
  }, [edittedItem]);
  return (
    <CustomModal
      size="xl"
      show={showModalBranches}
      onHide={hideModalBranches}
      title={edittedItem ? t("edit") : t("addNewBranch")}
      newClass="modal-branch"
    >
      <div className="modal-info-branch-add ">
        <FormField
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <PhoneInput
            label={i18n.language === "ar" ? "رقم الهاتف" : "Phone number"}
            placeholder={i18n.language === "ar" ? "رقم الهاتف" : "phone number"}
            value={phoneNumber}
            setValue={setPhoneNumber}
            country={country}
            setCountry={setCountry}
            mandatory
          />

          <Map
            selectedLocation={selectedLocation}
            location={location}
            setLocation={setLocation}
          />
          {/* <InputField
            label={t("latitude")}
            name="lat"
            type="number"
            placeholder={t("latitude")}
            success
          />
          <InputField
            label={t("longitude")}
            name="lng"
            type="number"
            placeholder={t("longitude")}
            success
          /> */}

          <button
            disabled={!location || !phoneNumber}
            type="submit"
            className="btn-main btn-submit w-100 mt-3"
          >
            {t("save")}
          </button>
        </FormField>
      </div>
    </CustomModal>
  );
};

export default ModalBranchesVendor;
