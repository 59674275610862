import { useEffect, useState } from "react";
import ModalCountriesTab from "./ModalCountriesTab";
import iconDollar from "../../../../../../assets/images/dashboard/icons/dollar.svg";
import IconEdit from "assets/Icons/IconEdit";
import TrashIcon from "assets/Icons/TrashIcon";
import "./Countries.css";
import HeaderContentTab from "../HeaderContentTab";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CustomModal from "Components/CustomModal/CustomModal";
import axiosInstance from "./../../../../../../axios";
import NoResults from "../../../../../Components/LottieFiles/NoResults";
import Loading from "../../../../../Components/LottieFiles/Loading";

const Countries = () => {
  const { t, i18n } = useTranslation();
  const [cardCurrancy, setCardCurrancy] = useState([]);
  const [loading, setLoading] = useState(false);

  const getCountries = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/admin/country`);

      setCardCurrancy(data?.data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  // FILTER ITEM BY ID TO DELETE ITEM
  const handleDeleteItem = async (id) => {
    try {
      const respone = await axiosInstance.delete(`admin/country/${id}`);
      setRefetch(!refetch);
      toast.success(t("updated"));
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }

    setDeleteModal(false);
  };

  // MODAL ADD NEW CURRANCY
  const [showModalCountries, setShowModalCountries] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedIndex, setDeletedIndex] = useState(null);
  const [refetch, setRefetch] = useState(false);

  const showModal = (item) => {
    setEditItem(item);
    setShowModalCountries(true);
  };

  const hideModalCountries = () => {
    setEditItem(null);
    setShowModalCountries(false);
  };
  useEffect(() => {
    getCountries();
  }, [refetch]);

  return (
    <>
      <CustomModal
        show={deleteModal}
        onHide={() => {
          setDeleteModal(false);
        }}
        title={i18n.language === "ar" ? "حذف" : "Delete"}
        newClass={"modal-inter"}
      >
        <div className="row w-50 justify-content-around mx-auto">
          <p className="text-center">
            {i18n.language === "ar"
              ? "هل انت متاكد من الحذف ؟"
              : "Are You Sure Want To Delete"}
          </p>
          <button
            onClick={() => {
              handleDeleteItem(deletedIndex);
            }}
            className="col-md-5 my-3 btn btn-danger"
          >
            {i18n.language === "ar" ? "حذف" : "Delete"}
          </button>
          <button
            onClick={() => {
              setDeleteModal(false);
            }}
            className="col-md-5 my-3 btn btn-outline-dark"
          >
            {i18n.language === "ar" ? "الغاء" : "Cancel"}
          </button>
        </div>
      </CustomModal>
      <ModalCountriesTab
        refetch={refetch}
        setRefetch={setRefetch}
        showModal={showModalCountries}
        hideModal={hideModalCountries}
        setCardCurrancy={setCardCurrancy}
        editItem={editItem}
        cardCurrancy={cardCurrancy}
      />
      <div className="currencies-control">
        {loading ? (
          <>
            <Loading />
          </>
        ) : (
          <>
            <HeaderContentTab
              textTitle={i18n.language === "ar" ? "الدول" : "Countries"}
              isShowButton={true}
              newClassButton={"button-one-add"}
              isTrueText={false}
              functionButton={() => {
                showModal();
              }}
              isShowButtonProfit={false}
              functionButtonProfit={false}
            />

            <div className="all-currencies-content">
              {cardCurrancy.length === 0 ? (
                <NoResults />
              ) : (
                cardCurrancy.map((item) => (
                  <div
                    key={item.id}
                    className="currency-card-one  d-flex  align-items-center  justify-content-between gap-3 flex-wrap mt-4"
                  >
                    <div className="right-content d-flex align-items-center  gap-2">
                      <img
                        className="rounded"
                        src={item.image}
                        alt="country"
                        width={"80px"}
                        height={"80px"}
                      />
                      <div className="top-content-info d-flex flex-column  gap-2">
                        <h2 className="title">
                          {i18n.language === "ar"
                            ? item.ar_title
                            : item.en_title}
                        </h2>
                        {i18n.language === "ar" ? (
                          <p className="text">
                            رقم العملة: {item.currency_id}{" "}
                          </p>
                        ) : (
                          <p className="text">
                            Currency id: {item.currency_id}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="left-actions-content d-flex  align-items-center gap-3">
                      <div
                        className="icon-one--1 icon-edit  cursor-pointer-event"
                        onClick={() => showModal(item)}
                      >
                        <IconEdit />
                      </div>
                      <div
                        className="icon-one--1 icon-trash cursor-pointer-event"
                        onClick={() => {
                          setDeleteModal(true);
                          setDeletedIndex(item.id);
                        }}
                      >
                        <TrashIcon />
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Countries;
