import { useState } from "react";
import NavBar from "../Components/NavBar/NavBar";
import Header from "../Components/Header/Header";
import Category from "../Components/Category/Category";
import Banner from "../Components/Banner/Banner";
import Cards from "../Components/Cards/Cards";
import BannerJoin from "../Components/Banner/BannerJoin";
import JoinUs from "../Components/JoinUs/JoinUs";
import OuroTop from "Components/OuroTop/OuroTop";
import Statistics from "Components/Statistics/Statistics";
import Contact from "Components/Contact/Contact";
import Footer from "Components/Footer/Footer";
import DownloadApp from "Components/DownloadApp/DownloadApp";
const Home = () => {
  const [showDownloadApp, setShowDownloadApp] = useState(false);
  const hideDownloadApp = () => {
    setShowDownloadApp(false);
  };
  const showModalDownloadApp = (e) => {
    e.preventDefault();
    setShowDownloadApp(true);
  };

  return (
    <div className="content-page">
      <DownloadApp
        showDownloadApp={showDownloadApp}
        hideDownloadApp={hideDownloadApp}
      />
      <header>
        <NavBar showModalDownloadApp={showModalDownloadApp} />
        <Header showModalDownloadApp={showModalDownloadApp} />
      </header>
      <div className="container">
        <main>
          <Category />
          <OuroTop showModalDownloadApp={showModalDownloadApp} />
          {/* <Banner /> */}
          <Cards showModalDownloadApp={showModalDownloadApp} />
          <JoinUs />
          <BannerJoin showModalDownloadApp={showModalDownloadApp} />
          <Statistics />
        </main>
      </div>
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
