import React, { useEffect, useState } from "react";
import Chat from "./Chat";
import userImage from "../../../../../assets/images/user/01.png";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../axios";
import { useTranslation } from "react-i18next";

const ChatTech = () => {
  const [messages, setMessages] = useState([]);
  const { t, i18n } = useTranslation();
  const useLocationn = useLocation();
  const useParms = useParams();
  const [data, setData] = useState({});
  const [valueInputChat, setValueInputChat] = useState("");
  // BUTTONS CHAT
  const navigate = useNavigate();
  const refetchData = () => {
    if (useLocationn?.state?.id) {
      axiosInstance
        .get(`/admin/ticket/${useLocationn?.state?.id}`, {
          headers: {
            "Accept-Language": i18n.language,
          },
        })
        .then((response) => {
          setMessages(response.data?.data?.messages);
          setData(response.data?.data);
        });
    }
  };
  useEffect(() => {
    if (useLocationn?.state?.id) {
      axiosInstance
        .get(`/admin/ticket/${useLocationn?.state?.id}`, {
          headers: {
            "Accept-Language": i18n.language,
          },
        })
        .then((response) => {
          setMessages(response.data?.data?.messages);
          setData(response.data?.data);
        });
    }
  }, [useLocationn]);
  const buttonSuccess = () => {
    toast.success("تمت الحل بنجاح.");
    setTimeout(() => {
      navigate("/dashboardAdmin/homedashboard");
    }, 800);
  };

  const buttonClose = () => {
    navigate("/dashboardAdmin/homedashboard");
  };
  return (
    <>
      <Chat
        messages={messages}
        setMessages={setMessages}
        refetchData={refetchData}
        id={useLocationn?.state?.id}
        infoUserChatTop={
          <div className="all-info-header-chat d-flex flex-column gap-4 w-100">
            <div className="info-content-user-chat d-flex align-items-center gap-3">
              <img
                src={data?.store?.image || userImage}
                alt="User"
                className="img support rounded-circle "
                width={"50px"}
                height={"50px"}
              />
              <div className="content-user">
                <span className="name-user fs-6 fw-medium text-dark">
                  {data?.store?.title}
                </span>
                <div className="email-user fs-6 fw-medium text-dark">
                  {data?.store?.email}
                </div>
              </div>
            </div>
          </div>
        }
        inputValueChat={valueInputChat}
        valueInputOnChange={(e) => {
          setValueInputChat(e.target.value);
        }}
        data={data}
        buttonChatSend={() => {
          if (valueInputChat !== "") {
            axiosInstance.post("/admin/sendmessage", {
              ticket_id: useLocationn?.state?.id ? useLocationn?.state?.id : 1,
              content: valueInputChat,
            });
          }
        }}
      />
    </>
  );
};

export default ChatTech;
