import HelmetInfo from "Dashboard/Components/HelmetInfo/HelmetInfo";
import BreadcrumbPage from "Dashboard/Components/Ui/BreadcrumbPage/BreadcrumbPage";
import HeadeInvoicesVendor from "./Components/HeadeInvoicesVendor";
import MainInvoicesVendor from "./Components/MainInvoicesVendor";
import moment from "moment";
import { toast } from "react-toastify";
import axiosInstance from "./../../../axios";
import EyeIcon from "assets/Icons/EyeIcon";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TableCustomerVendor from "../CustomersVendor/Components/TableCustomerVendor";
import Loading from "Dashboard/Components/LottieFiles/Loading";
import NoResults from "Dashboard/Components/LottieFiles/NoResults";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import DataTable from "Components/DataTable/DataTable";
import { useSavedState } from "assets/hooks";

const InvoicesVendor = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [showModalDeleteCustomer, setModalDeleteCustomer] = useState(false);
  const [loading, setLoading] = useState(false);

  const [invoiceData, setInvoiceData, clearInvoicesData] = useSavedState(
    null,
    `InvoicesVendorData`
  );
  const [rowsData, setRowsData, clearRowsData] = useSavedState(
    [],
    `InvoicesVendorRowsData`
  );
  const [filteredData, setFilteredData, clearFilterData] = useSavedState(
    [],
    `InvoicesVendorFilterData`
  );

  const columnDefs = useMemo(
    () => [
      {
        headerName: i18n.language === "ar" ? "#" : "#",
        field: "id",
        flex: 1,
      },

      {
        headerName: t("price"),
        field: "price",
        flex: 1,
      },
      {
        headerName: t("ouro"),
        field: "ouro",
        flex: 1,
      },
      {
        headerName: t("points"),
        field: "points",
        flex: 1,
      },
      {
        headerName: t("payment_method"),
        field: "payment_method",
        flex: 1,
      },
      {
        headerName: t("created_at"),
        field: "created_at",
        flex: 1,
      },

      {
        headerName: i18n.language === "ar" ? "الإجراءات" : "Actions",
        field: "Actions",
        flex: 0.5,
        cellRenderer: (params) => (
          <div
            onClick={() => {
              navigate(`/dashboardVendor/invoicesVendor/${params.data.id}`);
            }}
            className=" cursor-pointer-event"
          >
            <EyeIcon />
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  const getInvoicesData = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/store/orders`);

      setRowsData(
        data?.orders.map((item) => ({
          id: item?.id,
          price: item?.price,
          ouro: item?.ouro,
          points: item?.points,
          payment_method: item?.payment_method,
          created_at: moment(item?.created_at).format("DD-MM-YYYY , hh:mm"),
        }))
      );
      setFilteredData(
        data?.orders.map((item) => ({
          id: item?.id,
          price: item?.price,
          ouro: item?.ouro,
          points: item?.points,
          payment_method: item?.payment_method,
          created_at: moment(item?.created_at).format("DD-MM-YYYY , hh:mm"),
        }))
      );
      setInvoiceData(data && data);
    } catch (error) {
      toast.error(error?.response?.data?.error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoicesData();
  }, []);

  return (
    <>
      <HelmetInfo titlePage={t("invoices")} />
      <div>
        <BreadcrumbPage
          routPageHome={"/dashboardVendor/homedashboardvendor"}
          showMainTextPage={false}
          routePage={false}
          mainPageText={false}
          showPageTwo={true}
          textPageTwo={t("invoices")}
        />
        {loading && !rowsData.length ? (
          <div className="w-100 bg-white rounded">
            <Loading />
          </div>
        ) : rowsData && rowsData.length > 0 ? (
          <>
            <header>
              <HeadeInvoicesVendor invoiceData={invoiceData} />
            </header>
            <main>
              <div className="main-info-customers card-style-2 margin-top">
                <HeaderTableSearchFilter
                  newClassHeaderContentSearch={"search-customer"}
                  isTrueSearchInputFilter={true}
                  functionSearchFilter={(e) => {
                    const searchValue = e.target.value.toLowerCase();

                    const filteredData = rowsData.filter((row) => {
                      return row.points
                        .toString()
                        .toLowerCase()
                        .includes(searchValue);
                    });
                    setFilteredData(filteredData);
                  }}
                  functionIconSearch={() => {
                    console.log("search");
                  }}
                  isTrueFilterButton={true}
                  functionButtonFilter={() => setShowModalFilter(true)}
                  isTrueHighestScore={true}
                  functionButtonHighestScore={() => {}}
                  isNewButton={false}
                  isTrueContentLeftHeaderSearch={true}
                  InfoContentLeftSearchFilter={
                    <>
                      <ButtonsExport
                        dataName={"customers"}
                        dataExport={rowsData}
                      />
                    </>
                  }
                  contentNewButton={false}
                />
                <DataTable rowData={filteredData} columnDefs={columnDefs} />
              </div>{" "}
            </main>
          </>
        ) : (
          <div className="w-100 bg-white">
            <NoResults />
          </div>
        )}
      </div>
    </>
  );
};

export default InvoicesVendor;
