import { useEffect, useState } from "react";
import Select from "react-select";
import axiosInstance from "./../../../../../../../axios";
import { useTranslation } from "react-i18next";
const StepTwoMarketing = ({ validation, setValidation }) => {
  const { t } = useTranslation();
  const [countriesOptions, setCountriesOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // CITY
  const citySele = [
    { value: "sa", label: "الرياض" },
    { value: "jo", label: "عمان" },
    { value: "eg", label: "القاهرة" },
  ];
  const [selectCity, setSelectCity] = useState(null);

  const getData = async () => {
    try {
      const { data: countries } = await axiosInstance.get(`store/country`);
      setCountriesOptions(
        countries?.data?.map((country) => ({
          value: country?.id,
          label: country?.title,
        }))
      );
      const { data: cityies } = await axiosInstance.get(`store/city`);
      setCitiesOptions(
        cityies?.data?.map((city) => ({
          value: city?.id,
          label: city?.title,
        }))
      );
      const { data: interests } = await axiosInstance.get(`store/interest`);
      setCategoriesOptions(
        interests?.data?.map((interest) => ({
          value: interest?.id,
          label: interest?.title,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="step-two-info">
      <div className="all-select-content">
        <div className="row g-3">
          <div className="col-12 col-md-6">
            <div className="select-country-info">
              <label htmlFor="countrySelect" className=" form-label">
                {t("country")}
              </label>
              <Select
                id="countrySelect"
                value={selectedCountry}
                onChange={(option) => {
                  setSelectedCountry(option);
                }}
                options={countriesOptions}
                isMulti
                placeholder={t("Select")}
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="select-country-info">
              <label htmlFor="citySelect" className=" form-label">
                {t("Interests")}
              </label>
              <Select
                id="categorySelect"
                value={selectedCategory}
                onChange={(option) => {
                  setSelectedCategory(option);
                }}
                options={categoriesOptions}
                isMulti
                placeholder={t("Select")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoMarketing;
