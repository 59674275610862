import img from "../../../assets/images/user/01.png";
import DataTableTwo from "Dashboard/Components/DataTableInfo/DataTableTwo";
import { useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import ModalFilterContentInfo from "Dashboard/Shared/ModalFilterContent/ModalFilterContentInfo";
import PaginationPage from "Dashboard/Components/Pagination/Pagination";
import IconDate from "assets/Icons/IconDate";
import ActionData from "Dashboard/Components/DataTableInfo/ActionData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import "./AffiliatesPage.css";
const TableAffiliates = () => {
  const theadTrContent = [
    "رقم العضوية",
    "الإسم",
    "المشتركين",
    "العمولة",
    "المبلغ المحصل",
    "البريد الإلكتروني",
    "رقم الهاتف",
    "تاريخ الإشتراك",
    " ",
  ];

  const data = [
    {
      id: 1,
      imgUser: img,
      name: "محمد احمد",
      unmUsers: "350",
      numViews: "10",
      moneyEr: "350",
      email: "amira@gmail.com",
      numPhone: "02(0123456790)",
      date: "11/11/2024",
    },
    {
      id: 2,
      imgUser: img,
      name: "محمد احمد",
      unmUsers: "350",
      numViews: "10",
      moneyEr: "350",
      email: "amira@gmail.com",
      numPhone: "02(0123456790)",
      date: "11/11/2024",
    },
    {
      id: 3,
      imgUser: img,
      name: "محمد احمد",
      unmUsers: "350",
      numViews: "10",
      moneyEr: "350",
      email: "amira@gmail.com",
      numPhone: "02(0123456790)",
      date: "11/11/2024",
    },
    {
      id: 4,
      imgUser: img,
      name: "محمد احمد",
      unmUsers: "350",
      numViews: "10",
      moneyEr: "350",
      email: "amira@gmail.com",
      numPhone: "02(0123456790)",
      date: "11/11/2024",
    },
    {
      id: 5,
      imgUser: img,
      name: "محمد احمد",
      unmUsers: "350",
      numViews: "10",
      moneyEr: "350",
      email: "amira@gmail.com",
      numPhone: "02(0123456790)",
      date: "11/11/2024",
    },
  ];
  // SHOW MODAL FILTER
  const [showModalFilter, setShowModalFilter] = useState(false);
  const showModal = () => {
    setShowModalFilter(true);
  };

  // FUNCTION PAGINATION
  const handlePageClick = () => {};

  //   CHECKED INPUT TABLE
  const [allChecked, setAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (event, id) => {
    const { checked } = event.target;
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: checked,
    }));
  };

  const handleAllCheckboxChange = () => {
    const newAllChecked = !allChecked;
    setAllChecked(newAllChecked);
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = newAllChecked;
    });
    setCheckedItems(newCheckedItems);
  };

  return (
    <>
      <div className="modal-notifications-1">
        <ModalFilterContentInfo
          setShowModalFilter={setShowModalFilter}
          showModalFilter={showModalFilter}
          selectCountry={true}
          selectCategory={false}
          selectPay={false}
        />
      </div>

      <div className="all-data-table-shop  card-style-2 margin-top">
        <HeaderTableSearchFilter
          newClassHeaderContentSearch={"search-customer"}
          isTrueSearchInputFilter={true}
          textPlaceholder={"بحث"}
          functionSearchFilter={(e) => {
            console.log(e.target.value);
          }}
          functionIconSearch={() => {
            console.log("search");
          }}
          isTrueFilterButton={true}
          functionButtonFilter={showModal}
          isTrueHighestScore={true}
          functionButtonHighestScore={() => {}}
          isNewButton={false}
          contentNewButton={false}
          isTrueContentLeftHeaderSearch={true}
          InfoContentLeftSearchFilter={
            <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
              <ButtonsExport dataExport={data} />
              <button className="btn-send btn-main">
                إضافة جديدة
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          }
        />
        <div className="table-actions-1 affiliate-table">
          <DataTableTwo
            theadTrContent={
              <>
                <th>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={allChecked}
                    onChange={handleAllCheckboxChange}
                  />
                </th>
                {theadTrContent.map((item, index) => {
                  return <th key={index}>{item}</th>;
                })}
              </>
            }
            tbodyContent={
              <>
                {data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={checkedItems[item.id]}
                          onChange={(event) =>
                            handleCheckboxChange(event, item.id)
                          }
                        />
                      </td>
                      <td>{item.id}</td>
                      <td>
                        <div className="d-flex align-items-center gap-3">
                          <img
                            src={item.imgUser}
                            width={"35px"}
                            height={"35px"}
                            className="rounded-circle"
                            alt="img user"
                          />{" "}
                          {item.name}
                        </div>
                      </td>

                      <td>{item.unmUsers}</td>
                      <td>{item.numViews}%</td>
                      <td>{item.moneyEr}$</td>
                      <td>{item.email}</td>
                      <td>{item.numPhone}</td>
                      <td>
                        <IconDate /> {item.date}
                      </td>
                      <td>
                        <ActionData
                          functionShow={() => {}}
                          functionEdit={() => {}}
                          functionDelete={() => {}}
                        />
                      </td>
                    </tr>
                  );
                })}
              </>
            }
          />
          <PaginationPage itemCount={"15"} handlePageClick={handlePageClick} />
        </div>
      </div>
    </>
  );
};

export default TableAffiliates;
