import CardHero from "Dashboard/Components/Ui/CardHero/CardHero";
import iconImg from "../../../../../assets/images/dashboard/icons/people1.svg";
import iconImg2 from "../../../../../assets/images/dashboard/icons/shop.svg";
import iconImg3 from "../../../../../assets/images/dashboard/icons/money-send.svg";
import iconImg4 from "../../../../../assets/images/dashboard/icons/ranking.svg";
import { useTranslation } from "react-i18next";

const ReportsCards = ({ data }) => {
  const { t } = useTranslation();

  const cardItem = [
    {
      id: 0,
      lineColor: "blueLine",
      textTop: t("customers"),
      iconImg: iconImg,
      numberInfo: data?.customers?.total,
      textPercentageDate: data?.customers?.percent,
      isUpPercentage: data?.customers?.class == "success",
      isDownPercentage: !data?.customers?.class == "success",
    },
    {
      id: 1,
      lineColor: "greenLine",
      textTop: t("stores"),
      iconImg: iconImg2,
      numberInfo: data?.stores?.total,
      textPercentageDate: data?.stores?.percent,
      isUpPercentage: data?.stores?.class == "success",
      isDownPercentage: !data?.stores?.class == "success",
    },
    {
      id: 2,
      lineColor: "warningLine",
      textTop: t("sales"),
      iconImg: iconImg3,
      numberInfo: data?.orders?.total,
      textPercentageDate: data?.orders?.percent,
      isUpPercentage: data?.orders?.class == "success",
      isDownPercentage: !data?.orders?.class == "success",
    },
    {
      id: 3,
      lineColor: "darkLine",
      textTop: t("Events Orders"),
      iconImg: iconImg4,
      numberInfo: data?.eventsorders?.total,
      textPercentageDate: data?.eventsorders?.percent,
      isUpPercentage: data?.eventsorders?.class == "success",
      isDownPercentage: !data?.eventsorders?.class == "success",
    },
  ];
  return (
    <div className="all-cards-hero mt-4">
      {/* ========== START ROW ========= */}
      <div className="row g-3">
        {cardItem.map((item) => {
          return (
            <>
              {/* ======== START COL ========= */}
              <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <CardHero
                  lineBg={item.lineColor}
                  textTop={item.textTop}
                  iconImg={item.iconImg}
                  numberInfo={`${item.numberInfo}`}
                  typeNumber={item.typeNumber}
                  typeNumberInfo={"$"}
                  isUpPercentage={item.isUpPercentage}
                  isDownPercentage={item.isDownPercentage}
                  numPercentage={item.numPercentage}
                  textPercentageDate={item.textPercentageDate}
                />
              </div>
              {/* ======== END COL ========= */}
            </>
          );
        })}
      </div>
      {/* ========== END ROW ========= */}
    </div>
  );
};

export default ReportsCards;
