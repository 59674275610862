

const EditIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.15499 14.6402C3.69749 14.6402 3.26999 14.4827 2.96249 14.1902C2.57249 13.8227 2.38499 13.2677 2.45249 12.6677L2.72999 10.2377C2.78249 9.78016 3.05999 9.17266 3.38249 8.84266L9.53999 2.32516C11.0775 0.697659 12.6825 0.652659 14.31 2.19016C15.9375 3.72766 15.9825 5.33266 14.445 6.96016L8.28749 13.4777C7.97249 13.8152 7.38749 14.1302 6.92999 14.2052L4.51499 14.6177C4.38749 14.6252 4.27499 14.6402 4.15499 14.6402ZM11.9475 2.18266C11.37 2.18266 10.8675 2.54266 10.3575 3.08266L4.19999 9.60766C4.04999 9.76516 3.87749 10.1402 3.84749 10.3577L3.56999 12.7877C3.53999 13.0352 3.59999 13.2377 3.73499 13.3652C3.86999 13.4927 4.07249 13.5377 4.31999 13.5002L6.73499 13.0877C6.95249 13.0502 7.31249 12.8552 7.46249 12.6977L13.62 6.18016C14.55 5.19016 14.8875 4.27516 13.53 3.00016C12.93 2.42266 12.4125 2.18266 11.9475 2.18266Z" fill="#121212"/>
    <path d="M13.005 8.21263C12.99 8.21263 12.9675 8.21263 12.9525 8.21263C10.6125 7.98013 8.73002 6.20263 8.37002 3.87763C8.32502 3.57013 8.53502 3.28513 8.84252 3.23263C9.15002 3.18763 9.43502 3.39763 9.48752 3.70513C9.77252 5.52013 11.2425 6.91513 13.0725 7.09513C13.38 7.12513 13.605 7.40263 13.575 7.71013C13.5375 7.99513 13.29 8.21263 13.005 8.21263Z" fill="#121212"/>
    <path d="M15.75 17.0625H2.25C1.9425 17.0625 1.6875 16.8075 1.6875 16.5C1.6875 16.1925 1.9425 15.9375 2.25 15.9375H15.75C16.0575 15.9375 16.3125 16.1925 16.3125 16.5C16.3125 16.8075 16.0575 17.0625 15.75 17.0625Z" fill="#121212"/>
    </svg>
    
  )
}

export default EditIcon