import React, { useState } from "react";
import imageHero from "../../assets/images/main/01.png";
import iconDown from "../../assets/images/icons/import-outline.svg";
import bannerArea from "../../assets/images/main/banner.jpg";
import "./Header.css";
import StepsForm from "Components/StepsForm/StepsForm";
import { useTranslation } from "react-i18next";
const Header = ({ showModalDownloadApp }) => {
  const { i18n } = useTranslation();
  // OEPN ANDCLOSE MODAL CONFIRM NUMBER
  const [showModalJoin, setShowModalJoin] = useState(false);
  // CLOSE MODAL
  const hideModalJoin = () => {
    setShowModalJoin(false);
  };
  // OPEN MODAL
  const handleShowModalJoin = () => {
    setShowModalJoin(true);
  };
  return (
    <>
      <StepsForm showModalJoin={showModalJoin} hideModalJoin={hideModalJoin} />
      <div
        className="hero-section padding bg-image-hero"
        id="hero"
        style={{ backgroundImage: `url(${bannerArea})` }}
      >
        {/* ========== START ALL HERO SECTION ========== */}
        <div className="all-hero-section">
          <div className="container">
            {/* =========== START ROW ========= */}
            <div className="row g-3 align-items-center">
              {/* ========== START COL ========= */}
              <div className="col-12 col-md-7">
                {/* ============= START INFO HERO ========== */}
                <div className="info-hero" data-aos="fade-down">
                  <p className="text-head">
                    {i18n.language === "ar"
                      ? "مرحبا بك في اورو"
                      : "Welcome to Ouro"}
                  </p>
                  <h2 className="title">
                    {i18n.language === "ar"
                      ? "‏أكبر منصة للتسوق والتوفير"
                      : "The largest platform for shopping and saving"}
                  </h2>
                  {i18n.language === "ar" ? (
                    <p className="text">
                      مرحبا بك في عالم اورو الحصري الذي يفتح أبواب عالم مدهش من
                      النقاط والإسترداد النقدي
                      <br />
                      استمتع بتجربة تسوق واكتشف توفيرات هائلة بلمسة واحدة إنضم
                      إلى عالم التسوق الذكي اليوم
                    </p>
                  ) : (
                    <p className="text">
                      Welcome to the exclusive world of OURO, which opens the
                      doors to an amazing world of points and cashback.
                      <br />
                      Enjoy a shopping experience and discover huge savings with
                      a single touch. Join the world of smart shopping today
                    </p>
                  )}

                  {/* ========= START MAIN BUTTONS =========== */}
                  <div className="main-buttons d-flex  align-items-center  gap-2">
                    <button onClick={showModalDownloadApp} className="btn-main">
                      <img src={iconDown} alt="icon" />
                      {i18n.language === "ar"
                        ? "تحميل التطبيق"
                        : "Download App"}
                    </button>
                    <button
                      onClick={handleShowModalJoin}
                      className="btn-main btn-dark "
                    >
                      {i18n.language === "ar" ? "أنضم كتاجر" : "Join Trader"}
                    </button>
                  </div>
                  {/* ========= END MAIN BUTTONS =========== */}
                </div>
                {/* ============= END INFO HERO ========== */}
              </div>
              {/* ========== END COL ========= */}
              {/* ========== START COL ========= */}
              <div className="col-12 col-md-5">
                {/* ============= START IMAGE HERO =========== */}
                <div
                  className="image-hero position-relative"
                  data-aos="fade-up"
                >
                  <img
                    src={imageHero}
                    alt="imageHero"
                    className="object-fit-cover w-100  h-100 "
                  />
                  <a href="#" className="text-image">
                    {i18n.language === "ar" ? "أسحب الى الاسفل" : "Swipe down"}
                    <span className="line-text"></span>
                  </a>
                </div>
                {/* ============= END IMAGE HERO =========== */}
              </div>
              {/* ========== END COL ========= */}
            </div>
            {/* =========== END ROW ========= */}
          </div>
        </div>
        {/* ========== END ALL HERO SECTION ========== */}
      </div>
    </>
  );
};

export default Header;
