import CustomModal from "Components/CustomModal/CustomModal";
import "./ModalCustomer.css";
import { toast } from "react-toastify";
const ModalDeleteCustomer = ({
  showModalDeleteCustomer,
  hideModalDeleteCustomer
}) => {
  const NotDeleteButton = () => {
    hideModalDeleteCustomer();
    toast.success("لم يتم الحذف");
  };
  const deleteButton = () => {
    hideModalDeleteCustomer();
    toast.success("تم الحذف بنجاح.");
  };

  return (
    <CustomModal
      show={showModalDeleteCustomer}
      onHide={hideModalDeleteCustomer}
      title={"حذف العميل"}
      newClass={"modal-delete-customer"}
    >
      <div className="content-modal-delete">
        <h2 className="title">هل أنت متأكد من حذف هذا العميل ؟</h2>
        <p className="text">
          هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا
          النص من مولد النص العربي، حيث يمكنك أن تولد مثل هذا النص أو العديد من
          النصوص
        </p>
        <div className="buttons-modal-bottom mt-3 d-flex align-items-center gap-3">
          <button onClick={NotDeleteButton} className="btn-main btn-dark w-100">
            لا رجوع
          </button>
          <button onClick={deleteButton} className="btn-main">
            حذف
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default ModalDeleteCustomer;
