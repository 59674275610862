import { useEffect, useState } from "react";
import HeaderTableSearchFilter from "Dashboard/Shared/HeaderTableSearchFilter/HeaderTableSearchFilter";
import ButtonsExport from "Dashboard/Components/Ui/HeaderDataExport/ButtonsExport";
import { useTranslation } from "react-i18next";
import { faMapMarker } from "@fortawesome/free-solid-svg-icons";

import DataTable from "Components/DataTable/DataTable";
import EyeIcon from "assets/Icons/EyeIcon";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from "Components/CustomModal/CustomModal";
import { toast } from "react-toastify";
import Map from "Components/Map/Map";
const PendingActivities = ({ approved, modalRender, addBtnOnClick }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [rowsData, setRowsData] = useState([]);
  const [mapModal, setMapModal] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [location, setLocation] = useState({});
  useEffect(() => {
    const fittedData = approved?.map((event) => ({
      location: {
        lat: event.lat,
        lng: event.lng,
      },
      title: event?.title,
      content: event?.content,
      arTitle: event.ar_title,
      enTitle: event.en_title,
      arContent: event.ar_content,
      enContent: event.en_content,
      id: event.id,
      image: event.image,
      gallery: event.gallery,
      views: event.views,
      date: event.date,
      places: event.places,
      price: event.price,
      discount: event.discount,
    }));
    setFilteredData(fittedData);
    setRowsData(fittedData);
  }, [approved]);
  const columnDefs = [
    {
      headerName: t("eventTitle"),
      field: "title",
      cellRenderer: (params) => (
        <div className=" d-flex gap-1 ">
          <img
            alt="offerImg"
            width={40}
            className=" object-fit-cover rounded"
            src={params.data.image}
          />
          <p>
            {i18n.language === "ar" ? params.data.arTitle : params.data.enTitle}
          </p>
        </div>
      ),
      flex: 2,
    },
    {
      headerName: t("content"),
      field: "content",
      cellRenderer: (params) => (
        <div className=" d-flex gap-1 ">
          <p>
            {i18n.language === "ar"
              ? params.data.arContent
              : params.data.enContent}
          </p>
        </div>
      ),
      flex: 2,
    },
    {
      headerName: t("date"),
      field: "date",
    },
    {
      headerName: t("places"),
      field: "places",
      flex: 1,
    },

    {
      headerName: t("location"),
      field: "location",
      flex: 1,
      cellRenderer: (params) => (
        <div
          onClick={() => {
            handleLocationClick(params);
          }}
          className="cursor-pointer-event"
        >
          <FontAwesomeIcon color="green" size="xl" icon={faMapMarker} />{" "}
        </div>
      ),
    },
    {
      headerName: t("ticketPrice"),
      field: "price",
      flex: 1,
    },

    {
      headerName: t("discount"),
      field: "discount",
      flex: 1,
    },
    {
      headerName: t("actions"),
      field: "actions",
      flex: 2,
      cellRenderer: (params) => (
        <div className=" my-1 d-flex justify-content-start align-items-center gap-2">
          {/* <button
            onClick={() => {
              setEdittedItem(params.data);
              setShowModalEvent(true);
            }}
            className=" btn-sm btn "
          >
            <EditIcon />
          </button> */}
          <button
            onClick={() => {
              navigate(`${params?.data?.id}`);
            }}
            className=" btn-sm btn "
          >
            <EyeIcon />
          </button>
        </div>
      ),
    },
  ];
  const hideModalLogin = () => {
    setLocation(null);
  };
  const handleLocationClick = (params) => {
    if (params?.data?.location?.lat && params?.data?.location?.lng) {
      setLocation({
        lat: params.data.location.lat,
        lng: params.data.location.lng,
      });
    } else {
      toast.warning(t("noLocationFound"));
    }
  };
  return (
    <>
      <div className="main-pending-active">
        <div className="all-data-table-shop all-table-notification all-table-pending all-table-p card-style-2 table-border--1 margin-top">
          <CustomModal
            show={location?.lat}
            onHide={hideModalLogin}
            title=""
            newClass="modal-login"
          >
            <Map
              disabled={true}
              setLocation={setLocation}
              location={location}
              selectedLocation={location}
            />
          </CustomModal>
          <HeaderTableSearchFilter
            addBtnOnClick={addBtnOnClick}
            addBtnText={t("addNewEvent")}
            modalRender={modalRender}
            newClassHeaderContentSearch={"search-customer"}
            isTrueSearchInputFilter={true}
            functionIconSearch={() => {
              console.log("search");
            }}
            functionSearchFilter={(e) => {
              const searchValue = e.target.value.toLowerCase();

              const filteredData = rowsData.filter((row) => {
                return (
                  row.arTitle.toString().toLowerCase().includes(searchValue) ||
                  row.enTitle.toLowerCase().includes(searchValue)
                );
              });
              setFilteredData(filteredData);
            }}
            isTrueContentLeftHeaderSearch={true}
            InfoContentLeftSearchFilter={
              <div className="info-top-table d-flex align-items-center gap-3  flex-wrap-reverse">
                <ButtonsExport
                  dataName={"pending events"}
                  dataExport={filteredData}
                />
              </div>
            }
          />

          <DataTable rowData={filteredData} columnDefs={columnDefs} />
        </div>
      </div>
    </>
  );
};

export default PendingActivities;
